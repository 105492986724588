/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';

import { Dialog, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { FileUploader, TagBox } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { Controller, useForm } from 'react-hook-form';
import { FiX } from 'react-icons/fi';
import { useParams } from 'react-router';
import { WhisperSpinner } from 'react-spinners-kit';
import { BoardHeader } from '../../../components/BoardHeader';
import { Button } from '../../../components/Button';
import { DateBox } from '../../../components/DateBox';
import { SelectBox } from '../../../components/SelectBox';
import { TextArea } from '../../../components/TextArea';
import { TextBox } from '../../../components/TextBox';
import { useMethodology } from '../../../hooks/methodologyContext';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import master, { CustomStoreProps } from '../../../services/master';
import { ContainerLoading } from '../../ProjectDuring/DashboardModal/styles';
import { Body, Container, TabMenu } from './styles';


export const ProjectClosure: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const [loading, setLoading] = useState(false);
  const { control, setValue, watch } = useForm();
  const { control: controlSanitized, handleSubmit: handleSubmitSanitized } =
    useForm();
  const { control: controlCRM, handleSubmit: handleSubmitCRM } = useForm();
  const { control: controlCase, handleSubmit: handleSubmitCase } = useForm();
  const { addToast } = useToast();
  const [filteredProfessionals, setFilteredProfessionals] =
    useState<DataSource>();
  const [openTab, setOpenTab] = useState<number>(
    document.location.search && document.location.search.split('=')[1] === 'crm'
      ? 4
      : 1,
  );
  const [openPopUpTab, setPopUpOpenTab] = useState<number>(1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [hasSanitizedFile, setHasSanitizedFile] = useState(false);
  const [hasCRMFile, setHasCRMFile] = useState(false);
  const [hasCaseFile, setHasCaseFile] = useState(false);
  const [projectCode, setProjectCode] = useState(null);
  const [gettingCRMFile, setGettingCRMFile] = useState(false);
  const { setMethodology } = useMethodology();
  const [openDialog, setOpenDialog] = useState(false);
  const [methodologies] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('master/methodologies');
        return data.filter((item: any) => !item.Name.includes('No Methodology'));
      },
    }),
    paginate: true,
  });

  const [offices] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('master/offices');
        return data;
      },
    }),
    paginate: true,
  });

  const [geographies] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('master/geographics');
        return data;
      },
    }),
    paginate: true,
  });

  const loadProjectInfo = useCallback(async () => {
    const { data } = await api.get(`/api/projects/${id}`);
    setProjectCode(data.code);
    const professionalResponse = await master.get('/master/professionals', {
      params: {
        where: {
          IsAnyStatus: true,
        },
      },
    });

    const projectTeamIds = data.projectTeam.map((x: any) => {
      return x.professional?.id;
    });

    setFilteredProfessionals(
      professionalResponse.data
        .map((p: any) => {
          return { ID: p.ID, Name: p.Name };
        })
        .filter((x: any) => projectTeamIds.includes(x.ID)),
    );

    return data;
  }, [id]);

  const loadProjectClosureExternalInfo = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(
      `/api/project-closure-external/project/${id}`,
    );

    setValue(
      'signedWeeklyStatusIDPersonResponsible',
      data.signedWeeklyStatusIDPersonResponsible,
    );
    setValue('signedWeeklyStatusAttachFile', data.signedWeeklyStatusAttachFile);
    setValue('signedWeeklyStatusStatus', data.signedWeeklyStatusStatus);
    setValue(
      'legalClosureDocumentIDPersonResponsible',
      data.legalClosureDocumentIDPersonResponsible,
    );
    setValue(
      'legalClosureDocumentLogoAuthorization',
      data.legalClosureDocumentLogoAuthorization,
    );
    setValue('legalClosureDocumentStatus', data.legalClosureDocumentStatus);
    setValue(
      'clientTestimonialIDPersonResponsible',
      data.clientTestimonialIDPersonResponsible,
    );
    setValue(
      'clientTestimonialResponsibleRequested',
      data.clientTestimonialResponsibleRequested,
    );
    setValue('clientTestimonialStatus', data.clientTestimonialStatus);
    setValue(
      'thankYouLetterIDPersonResponsible',
      data.thankYouLetterIDPersonResponsible,
    );
    setValue('thankYouLetterTeamOffer', data.thankYouLetterTeamOffer);
    setValue('thankYouLetterStatus', data.thankYouLetterStatus);
    setLoading(false);
  }, [id, setValue]);

  const loadProjectClosureInternalInfo = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(
      `/api/project-closure-internal/project/${id}`,
    );
    setHasSanitizedFile(!!data.sanitizedReferenceAttach);
    setHasCRMFile(!!data.crmAttach);
    setHasCaseFile(!!data.formCompleteCaseAttach);
    setValue('formIDPersonResponsible', data.formIDPersonResponsible);
    setValue('formStatus', data.formStatus);
    setValue(
      'sanitizedReferenceIDPersonResponsible',
      data.sanitizedReferenceIDPersonResponsible,
    );
    setValue(
      'projectNetworkIDPersonResponsible',
      data.projectNetworkIDPersonResponsible,
    );
    setValue('projectNetworkStatus', data.projectNetworkStatus);
    setValue('sanitizedReferenceStatus', data.sanitizedReferenceStatus);
    setValue('crmidPersonResponsible', data.crmidPersonResponsible);
    setValue('crmStatus', data.crmStatus);

    // form info
    setValue('closureProjectName', data.closureProjectName);
    setValue('formProjectStartDate', data.formProjectStartDate);
    setValue('formProjectEndDate', data.formProjectEndDate);
    setValue('formResponsiblePartner', data.formResponsiblePartner);
    setValue('formProjectManager', data.formProjectManager);
    setValue('formOffice', data.formOffice);
    setValue('formGeography', data.formGeography);
    setValue('formGeographyScope', data.formGeographyScope);
    setValue('formPractice', data.formPractice);
    setValue('formMethodology', verifyMethodology(data.formMethodology));
    setValue('formMiniCaseComment', data.formMiniCaseComment);
    setValue(
      'formResultAchievedQualitative',
      data.formResultAchievedQualitative,
    );
    setValue(
      'formResultAchievedQuantitative',
      data.formResultAchievedQuantitative,
    );
    setValue('formCompleteCaseAttach', data.formCompleteCaseAttach);
    setValue('formNewMethodologySelect', data.formNewMethodologySelect);
    setValue('formNewMethodologyContact', data.formNewMethodologyContact);
    setValue('formNewMethodologyComment', data.formNewMethodologyComment);
    setValue(
      'formEvolveExistingMethodologySelect',
      data.formEvolveExistingMethodologySelect,
    );
    setValue(
      'formEvolveExistingMethodologyContact',
      data.formEvolveExistingMethodologyContact,
    );
    setValue(
      'formEvolveExistingMethodologyComment',
      data.formEvolveExistingMethodologyComment,
    );
    setValue('formCreateToolSelect', data.formCreateToolSelect);
    setValue('formCreateToolContact', data.formCreateToolContact);
    setValue('formCreateToolComment', data.formCreateToolComment);
    setValue('formExternalExpertSelect', data.formExternalExpertSelect);
    setValue('formExternalExpertContact', data.formExternalExpertContact);
    setValue('formExternalExpertComment', data.formExternalExpertComment);
    setValue('formInternalExpertSelect', data.formInternalExpertSelect);
    setValue('formInternalExpertContact', data.formInternalExpertContact);
    setValue('formInternalExpertComment', data.formInternalExpertComment);
    setValue('formBuyReportSelect', data.formBuyReportSelect);
    setValue('formBuyReportContact', data.formBuyReportContact);
    setValue('formBuyReportComment', data.formBuyReportComment);
    setValue(
      'formAnalysedDistributorSelect',
      data.formAnalysedDistributorSelect,
    );
    setValue(
      'formAnalysedDistributorContact',
      data.formAnalysedDistributorContact,
    );
    setValue(
      'formAnalysedDistributorComment',
      data.formAnalysedDistributorComment,
    );
    setValue('formRetailContactSelect', data.formRetailContactSelect);
    setValue('formRetailContactContact', data.formRetailContactContact);
    setValue('formRetailContactComment', data.formRetailContactComment);
    setValue('formCreateBenchmarkSelect', data.formCreateBenchmarkSelect);
    setValue('formCreateBenchmarkContact', data.formCreateBenchmarkContact);
    setValue('formCreateBenchmarkComment', data.formCreateBenchmarkComment);
    setValue('formAccessCompanyPELSelect', data.formAccessCompanyPELSelect);
    setValue('formAccessCompanyPELContact', data.formAccessCompanyPELContact);
    setValue('formAccessCompanyPELComment', data.formAccessCompanyPELComment);
    // setValue('formCaseAuthorizationSelect', data.formCaseAuthorizationSelect);
    setValue(
      'formNewIndustryOrCountryKnowledgeSelect',
      data.formNewIndustryOrCountryKnowledgeSelect,
    );
    setValue(
      'formNewIndustryOrCountryKnowledgeContact',
      data.formNewIndustryOrCountryKnowledgeContact,
    );
    setValue(
      'formNewIndustryOrCountryKnowledgeComment',
      data.formNewIndustryOrCountryKnowledgeComment,
    );
    setValue(
      'formMaterialForOtherProjectSelect',
      data.formMaterialForOtherProjectSelect,
    );
    setValue(
      'formMaterialForOtherProjectContact',
      data.formMaterialForOtherProjectContact,
    );
    setValue(
      'formMaterialForOtherProjectComment',
      data.formMaterialForOtherProjectComment,
    );
    setValue(
      'formPresentContentInNewOrDifferentWaySelect',
      data.formPresentContentInNewOrDifferentWaySelect,
    );
    setValue(
      'formPresentContentInNewOrDifferentWayContact',
      data.formPresentContentInNewOrDifferentWayContact,
    );
    setValue(
      'formPresentContentInNewOrDifferentWayComment',
      data.formPresentContentInNewOrDifferentWayComment,
    );
    setValue(
      'formRegisteredClientOrganizationalStructureSelect',
      data.formRegisteredClientOrganizationalStructureSelect,
    );
    setValue(
      'formRegisteredClientOrganizationalStructureContact',
      data.formRegisteredClientOrganizationalStructureContact,
    );
    setValue(
      'formRegisteredClientOrganizationalStructureComment',
      data.formRegisteredClientOrganizationalStructureComment,
    );
    setValue(
      'formTechnologyMethodologyWasOutdated',
      data.formTechnologyMethodologyWasOutdated,
    );
    setValue('formTechnologyUseGapSelect', data.formTechnologyUseGapSelect);
    setValue('formTechnologyUseGapComment', data.formTechnologyUseGapComment);
    setValue(
      'formTechnologyBetterDeliveryComment',
      data.formTechnologyBetterDeliveryComment,
    );
    setValue(
      'formSomeTypeOfTechnologySelect',
      data.formSomeTypeOfTechnologySelect,
    );
    setValue(
      'formSomeTypeofTechnologyComment',
      data.formSomeTypeofTechnologyComment,
    );
    setValue(
      'formPartneredWithAnyTechCompanySelect',
      data.formPartneredWithAnyTechCompanySelect,
    );
    setValue(
      'formPartneredWithAnyTechCompanyComment',
      data.formPartneredWithAnyTechCompanyComment,
    );
    setValue(
      'formIdentifyNewTypeTechnologyDuringProjectSelect',
      data.formIdentifyNewTypeTechnologyDuringProjectSelect,
    );
    setValue(
      'formIdentifyNewTypeTechnologyDuringProjectComment',
      data.formIdentifyNewTypeTechnologyDuringProjectComment,
    );
    setValue(
      'formClientLeveragingOnNewTechnologySelect',
      data.formClientLeveragingOnNewTechnologySelect,
    );
    setValue(
      'formTopicOfSustainabilityTouchedSelect',
      data.formTopicOfSustainabilityTouchedSelect,
    );
    setValue(
      'formTopicOfSustainabilityTouchedComment',
      data.formTopicOfSustainabilityTouchedComment,
    );
    setValue(
      'formClientFocusedOnSustainabilitySelect',
      data.formClientFocusedOnSustainabilitySelect,
    );
    setValue(
      'formClientFocusedOnSustainabilityComment',
      data.formClientFocusedOnSustainabilityComment,
    );
    setLoading(false);
  }, [id, setValue]);

  useEffect(() => {
    loadProjectInfo();
    loadProjectClosureExternalInfo();
    loadProjectClosureInternalInfo();
  }, [
    loadProjectInfo,
    loadProjectClosureExternalInfo,
    loadProjectClosureInternalInfo,
  ]);

  const updateClosureInternal = useCallback(
    async (fieldName, value) => {
      try {
        await api.patch(`/api/project-closure-internal/project/${id}`, {
          [fieldName]: value,
        });
        addToast({
          type: 'success',
          title: 'Saved',
          description: '',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          description: '',
        });
      }
    },
    [addToast, id],
  );

  const updateClosureInternalForm = useCallback(
    async (fieldName, value) => {
      try {
        await api.patch(`/api/project-closure-internal/project/${id}/form`, {
          [fieldName]: value,
        });
        addToast({
          type: 'success',
          title: 'Saved',
          description: '',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          description: '',
        });
      }
    },
    [addToast, id],
  );

  const handleUploadSanitizedReferences = useCallback(
    async data => {
      try {
        addToast({
          type: 'info',
          title: 'Uploading your file...',
        });
        const formData = new FormData();

        if (!data.file || !data.file[0]) {
          addToast({
            title: 'Please, select a file',
            type: 'error',
          });
          return;
        }

        formData.append('file', data.file[0], data.file[0].name);

        await api.post(
          `api/projects/${id}/sanitized-references/file`,
          formData,
        );

        addToast({
          title: 'File uploaded',
          type: 'success',
        });
        loadProjectClosureInternalInfo();
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }
    },
    [id, addToast, loadProjectClosureInternalInfo],
  );

  const handleUploadCRM = useCallback(
    async data => {
      try {
        addToast({
          type: 'info',
          title: 'Uploading your file...',
        });
        const formData = new FormData();

        if (!data.file || !data.file[0]) {
          addToast({
            title: 'Please, select a file',
            type: 'error',
          });
          return;
        }

        formData.append('file', data.file[0], data.file[0].name);

        await api.post(`api/projects/${id}/crm/file`, formData);

        addToast({
          title: 'File uploaded',
          type: 'success',
        });

        loadProjectClosureInternalInfo();
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }
    },
    [id, addToast, loadProjectClosureInternalInfo],
  );

  const handleUploadCase = useCallback(
    async data => {
      try {
        addToast({
          type: 'info',
          title: 'Uploading your file...',
        });
        const formData = new FormData();

        if (!data.file || !data.file[0]) {
          addToast({
            title: 'Please, select a file',
            type: 'error',
          });
          return;
        }

        formData.append('file', data.file[0], data.file[0].name);

        await api.post(
          `api/projects/${id}/close-out/complete-case/file`,
          formData,
        );

        addToast({
          title: 'File uploaded',
          type: 'success',
        });

        loadProjectClosureInternalInfo();
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }
    },
    [id, addToast, loadProjectClosureInternalInfo],
  );

  const handleDownloadCaseTemplate = useCallback(async () => {
    addToast({
      type: 'info',
      title: 'We are generating your file. It could take a while',
    });

    const { data, headers } = await api.get(
      `api/projects/${id}/close-out/complete-case/template`,
      {
        responseType: 'blob',
      },
    );

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', headers['x-file-name']);
    document.body.appendChild(link);
    link.click();
    link.remove();

    addToast({
      type: 'success',
      title: 'Your file is ready',
    });
  }, [id, addToast]);

  const handleDownloadCRMTemplate = useCallback(async () => {
    setGettingCRMFile(true);

    addToast({
      type: 'info',
      title: 'We are generating your file. It could take a while',
    });

    const { data, headers } = await api.get(`api/projects/${id}/CRM/template`, {
      responseType: 'blob',
    });

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', headers['x-file-name']);
    document.body.appendChild(link);
    link.click();
    link.remove();

    addToast({
      type: 'success',
      title: 'Your file is ready',
    });
    setGettingCRMFile(false);
  }, [id, addToast]);

  const handleDownloadSanitizedReferences = useCallback(async () => {
    addToast({
      type: 'info',
      title: 'We are generating your file. It could take a while',
    });

    const { data, headers } = await api.get(
      `api/projects/${id}/sanitized-references/file`,
      {
        responseType: 'blob',
      },
    );

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', headers['x-file-name']);
    document.body.appendChild(link);
    link.click();
    link.remove();

    addToast({
      type: 'success',
      title: 'Your file is ready',
    });
  }, [id, addToast]);

  const handleDownloadCRM = useCallback(async () => {
    addToast({
      type: 'info',
      title: 'We are generating your file. It could take a while',
    });

    const { data, headers } = await api.get(`api/projects/${id}/crm/file`, {
      responseType: 'blob',
    });

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement('a');
    link.href = downloadUrl;
    const fileType = headers['x-file-name'].split('.')[1];
    link.setAttribute('download', `CRM - ${projectCode}.${fileType}`);
    document.body.appendChild(link);
    link.click();
    link.remove();

    addToast({
      type: 'success',
      title: 'Your file is ready',
    });
  }, [addToast, id, projectCode]);

  const handleDownloadCase = useCallback(async () => {
    addToast({
      type: 'info',
      title: 'We are generating your file. It could take a while',
    });

    const { data, headers } = await api.get(
      `api/projects/${id}/close-out/complete-case/file`,
      {
        responseType: 'blob',
      },
    );

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', headers['x-file-name']);
    document.body.appendChild(link);
    link.click();
    link.remove();

    addToast({
      type: 'success',
      title: 'Your file is ready',
    });
  }, [id, addToast]);

  const formMethodologyValue = watch('formMethodology') || '';

  const verifyMethodology = useCallback((value) => {
    const splitedMethodology = value ? String(value).split(',').map(Number) : [];
    const idsToCheck = [0, 13, 15, 37, 47, 48, 49, 50, 76];

    const filteredMethodology = splitedMethodology.filter((id: number) => !idsToCheck.includes(id));

    return filteredMethodology;
  }, [formMethodologyValue]);

  useEffect(() => {
    setMethodology(formMethodologyValue);
  }, [formMethodologyValue]);

  return (
    <Container>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}

      <BoardHeader title="CLOSURE" subtitle="" />
      <span>
        <h1
          style={{
            textTransform: 'uppercase',
            color: '#6c6c6c',
            fontSize: '24px',
            fontWeight: 700,
            letterSpacing: '0.1em',
          }}
        >
          Closure Internal
        </h1>
      </span>
      <TabMenu>
        <li
          onClick={() => setOpenTab(1)}
          className={openTab === 1 ? 'focused' : ''}
        >
          Close-out
        </li>
        <li
          onClick={() => setOpenTab(2)}
          className={openTab === 2 ? 'focused' : ''}
        >
          Sanitized References
        </li>
        <li
          onClick={() => setOpenTab(3)}
          className={openTab === 3 ? 'focused' : ''}
        >
          Project Network
        </li>
        <li
          onClick={() => setOpenTab(4)}
          className={openTab === 4 ? 'focused' : ''}
        >
          CRM
        </li>
      </TabMenu>
      {openTab === 1 && (
        <Body className="content">
          <div className="smallTextField">
            <label>Person Responsible</label>
            <Controller
              name="formIDPersonResponsible"
              control={control}
              render={({ field }) => (
                <SelectBox
                  searchEnabled
                  dataSource={filteredProfessionals}
                  valueExpr="ID"
                  displayExpr="Name"
                  value={field.value}
                  onChanged={value => {
                    setValue(field.name, value);
                    updateClosureInternal(field.name, value);
                  }}
                />
              )}
            />
          </div>

          <div className="smallTextField">
            <label>Form</label>
            <Button
              secondary
              style={{ justifyContent: 'center', width: '50%' }}
              onClick={() => setIsDialogOpen(true)}
            >
              Open form
            </Button>
          </div>
          <div className="smallTextField">
            <label>Status</label>
            <Controller
              name="formStatus"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  style={{ display: 'flex', width: '100%' }}
                  onChange={e => {
                    setValue(field.name, e.target.value);
                    updateClosureInternal(field.name, e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="false"
                    checked={field.value === false || field.value === 'false'}
                    control={<Radio />}
                    label="Not started"
                  />
                  <FormControlLabel
                    value="true"
                    checked={field.value === true || field.value === 'true'}
                    control={<Radio />}
                    label="Done"
                  />
                </RadioGroup>
              )}
            />
          </div>
        </Body>
      )}

      {openTab === 2 && (
        <Body className="content">
          <div className="smallTextField">
            <label>Person Responsible</label>
            <Controller
              name="sanitizedReferenceIDPersonResponsible"
              control={control}
              render={({ field }) => (
                <SelectBox
                  searchEnabled
                  dataSource={filteredProfessionals}
                  valueExpr="ID"
                  displayExpr="Name"
                  value={field.value}
                  onChanged={value => {
                    setValue(field.name, value);
                    updateClosureInternal(field.name, value);
                  }}
                />
              )}
            />
          </div>

          <div className="smallTextField">
            <form
              onSubmit={handleSubmitSanitized(handleUploadSanitizedReferences)}
            >
              <Controller
                name="file"
                control={controlSanitized}
                render={({ field }) => (
                  <FileUploader
                    uploadMode="useForm"
                    showFileList={false}
                    onValueChanged={e => {
                      field.onChange(e.value);
                      handleSubmitSanitized(handleUploadSanitizedReferences)();
                    }}
                  />
                )}
              />
            </form>
            {hasSanitizedFile && (
              <Button onClick={handleDownloadSanitizedReferences}>
                Download sanitized file
              </Button>
            )}
          </div>

          <div className="smallTextField">
            <label>Status</label>
            <Controller
              name="sanitizedReferenceStatus"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  style={{ display: 'flex', width: '100%' }}
                  onChange={e => {
                    setValue(field.name, e.target.value);
                    updateClosureInternal(field.name, e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="false"
                    checked={field.value === false || field.value === 'false'}
                    control={<Radio />}
                    label="Not started"
                  />
                  <FormControlLabel
                    value="true"
                    checked={field.value === true || field.value === 'true'}
                    control={<Radio />}
                    label="Done"
                  />
                </RadioGroup>
              )}
            />
          </div>
        </Body>
      )}

      {openTab === 3 && (
        <Body className="content">
          <div className="smallTextField">
            <label>Person Responsible</label>
            <Controller
              name="projectNetworkIDPersonResponsible"
              control={control}
              render={({ field }) => (
                <SelectBox
                  searchEnabled
                  dataSource={filteredProfessionals}
                  valueExpr="ID"
                  displayExpr="Name"
                  value={field.value}
                  onChanged={value => {
                    setValue(field.name, value);
                    updateClosureInternal(field.name, value);
                  }}
                />
              )}
            />
          </div>
          <div className="smallTextField">
            <label>Status</label>
            <Controller
              name="projectNetworkStatus"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  style={{ display: 'flex', width: '100%' }}
                  onChange={e => {
                    setValue(field.name, e.target.value);
                    updateClosureInternal(field.name, e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="false"
                    checked={field.value === false || field.value === 'false'}
                    control={<Radio />}
                    label="Not started"
                  />
                  <FormControlLabel
                    value="true"
                    checked={field.value === true || field.value === 'true'}
                    control={<Radio />}
                    label="Done"
                  />
                </RadioGroup>
              )}
            />
          </div>
        </Body>
      )}

      {openTab === 4 && (
        <Body className="content">
          <div className="smallTextField">
            <label>Person Responsible</label>

            <Controller
              name="crmidPersonResponsible"
              control={control}
              render={({ field }) => (
                <SelectBox
                  searchEnabled
                  dataSource={filteredProfessionals}
                  valueExpr="ID"
                  displayExpr="Name"
                  value={field.value}
                  onChanged={value => {
                    setValue(field.name, value);
                    updateClosureInternal(field.name, value);
                  }}
                />
              )}
            />
          </div>
          <div className="smallTextField">
            <label>CRM Template</label>
            <Button
              secondary
              disabled={gettingCRMFile}
              style={{ justifyContent: 'center', width: '50%' }}
              onClick={() => {
                if (!gettingCRMFile) handleDownloadCRMTemplate();
              }}
            >
              {gettingCRMFile ? (
                <>
                  Downloading...{' '}
                  <WhisperSpinner
                    size={16}
                    backColor="#eee"
                    frontColor="#fff"
                  />
                </>
              ) : (
                'Download'
              )}
            </Button>
          </div>
          <div className="smallTextField">
            <label>CRM File</label>
            <form onSubmit={handleSubmitCRM(handleUploadCRM)}>
              <Controller
                name="file"
                control={controlCRM}
                render={({ field }) => (
                  <FileUploader
                    uploadMode="useForm"
                    showFileList={false}
                    onValueChanged={e => {
                      field.onChange(e.value);
                      handleSubmitCRM(handleUploadCRM)();
                    }}
                  />
                )}
              />
            </form>
            {hasCRMFile && (
              <Button disabled={!projectCode} onClick={handleDownloadCRM}>
                {!projectCode ? 'Loading CRM file...' : 'Download CRM file'}
              </Button>
            )}
          </div>

          <div className="smallTextField">
            <label>Status</label>
            <Controller
              name="crmStatus"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  style={{ display: 'flex', width: '100%' }}
                  onChange={e => {
                    setValue(field.name, e.target.value);
                    updateClosureInternal(field.name, e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="false"
                    checked={field.value === false || field.value === 'false'}
                    control={<Radio />}
                    label="Not started"
                  />
                  <FormControlLabel
                    value="true"
                    checked={field.value === true || field.value === 'true'}
                    control={<Radio />}
                    label="Done"
                  />
                </RadioGroup>
              )}
            />
          </div>
        </Body>
      )}

      {isDialogOpen && (
        <>
          <div
            className="customDialogBG"
            onMouseUp={() => {
              setIsDialogOpen(false);
            }}
          />

          <div className="customDialog">
            <FiX
              className="closeButton"
              onMouseUp={() => {
                setIsDialogOpen(false);
              }}
            />

            <TabMenu>
              <li
                onClick={() => setPopUpOpenTab(1)}
                className={openPopUpTab === 1 ? 'focused' : ''}
              >
                Close-out
              </li>
              {/* <li
                onClick={() => setPopUpOpenTab(2)}
                className={openPopUpTab === 2 ? 'focused' : ''}
              >
                Case Use Authorization
              </li> */}
              <li
                onClick={() => setPopUpOpenTab(3)}
                className={openPopUpTab === 3 ? 'focused' : ''}
              >
                References
              </li>
              <li
                onClick={() => setPopUpOpenTab(4)}
                className={openPopUpTab === 4 ? 'focused' : ''}
              >
                Sustainability
              </li>
            </TabMenu>

            {openPopUpTab === 1 && (
              <Body className="content">
                <div className="smallTextField">
                  <label>Project Name (Agreed with Client)</label>
                  <Controller
                    name="closureProjectName"
                    control={control}
                    render={({ field }) => (
                      <TextBox
                        value={field.value}
                        onChanged={value => {
                          setValue(field.name, value);
                          updateClosureInternalForm(field.name, value);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="smallTextField">
                  <label>Start Date</label>
                  <Controller
                    name="formProjectStartDate"
                    control={control}
                    render={({ field }) => (
                      <DateBox
                        value={field.value}
                        openOnFieldClick
                        onChanged={value => {
                          setValue(field.name, value);
                          updateClosureInternalForm(field.name, value);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="smallTextField">
                  <label>End Date</label>
                  <Controller
                    name="formProjectEndDate"
                    control={control}
                    render={({ field }) => (
                      <DateBox
                        value={field.value}
                        openOnFieldClick
                        onChanged={value => {
                          setValue(field.name, value);
                          updateClosureInternalForm(field.name, value);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="smallTextField">
                  <label>Responsible partner/director</label>
                  <Controller
                    name="formResponsiblePartner"
                    control={control}
                    render={({ field }) => (
                      <SelectBox
                        searchEnabled
                        dataSource={filteredProfessionals}
                        valueExpr="ID"
                        displayExpr="Name"
                        value={parseInt(field.value, 10)}
                        onChanged={value => {
                          setValue(field.name, value);
                          updateClosureInternalForm(field.name, value);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="smallTextField">
                  <label>Project Manager</label>
                  <Controller
                    name="formProjectManager"
                    control={control}
                    render={({ field }) => (
                      <SelectBox
                        searchEnabled
                        dataSource={filteredProfessionals}
                        valueExpr="ID"
                        displayExpr="Name"
                        value={parseInt(field.value, 10)}
                        onChanged={value => {
                          setValue(field.name, value);
                          updateClosureInternalForm(field.name, value);
                        }}
                      />
                    )}
                  />
                </div>

                <div className="smallTextField">
                  <label>Office Responsbile for the Project</label>
                  <Controller
                    name="formOffice"
                    control={control}
                    render={({ field }) => (
                      <SelectBox
                        searchEnabled
                        dataSource={offices}
                        valueExpr="ID"
                        displayExpr="Name"
                        value={parseInt(field.value, 10)}
                        onChanged={value => {
                          setValue(field.name, value);
                          updateClosureInternalForm(field.name, value);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="smallTextField">
                  <label>Geography of The Project</label>
                  <Controller
                    name="formGeography"
                    control={control}
                    render={({ field }) => (
                      <SelectBox
                        searchEnabled
                        dataSource={geographies}
                        valueExpr="ID"
                        displayExpr="Name"
                        value={parseInt(field.value, 10)}
                        onChanged={value => {
                          setValue(field.name, value);
                          updateClosureInternalForm(field.name, value);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="smallTextField">
                  <label>Complete One Page Case Template</label>
                  <div className="button_video">
                  <Button
                    primary
                    style={{width:'100%', alignContent:'center' }}
                    onClick={handleDownloadCaseTemplate}
                  >
                    Download
                  </Button>
                  <Button onClick={() => setOpenDialog(true)} style={{width:'100%', alignContent:'center' }}>Tips to write a good case</Button>
                  </div>
                </div>

                <div className="smallTextField">
                  <label>Complete One Page Case File</label>
                  <form onSubmit={handleSubmitCase(handleUploadCase)}>
                    <Controller
                      name="file"
                      control={controlCase}
                      render={({ field }) => (
                        <FileUploader
                          uploadMode="useForm"
                          showFileList={false}
                          onValueChanged={e => {
                            field.onChange(e.value);
                            handleSubmitCase(handleUploadCase)();
                          }}
                        />
                      )}
                    />
                  </form>
                  {hasCaseFile && (
                    <Button onClick={handleDownloadCase}>
                      Download case file
                    </Button>
                  )}
                </div>

                <div className="smallTextField">
                  <div className="text_box">
                    <label className="text_box_label">Project Summary</label>
                    <p className="text_box_text">
                      Important: English and Sanitized
                    </p>
                  </div>
                  <small>500 characters max.</small>
                  <Controller
                    name="formMiniCaseComment"
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        value={field.value}
                        onChanged={value => {
                          setValue(field.name, value);
                          updateClosureInternalForm(field.name, value);
                        }}
                      />
                    )}
                  />
                </div>

                <div className="smallTextField">
                  <label>Methodology</label>
                  <Controller
                    name="formMethodology"
                    control={control}
                    render={({ field }) => (
                      <TagBox
                        showSelectionControls
                        dataSource={methodologies}
                        valueExpr="ID"
                        displayExpr="Name"
                        value={field.value && verifyMethodology(field.value)}
                        onValueChanged={e => {
                          const value = e.value.length > 0 ? e.value.join(',') : "";
                          setValue(field.name, value);
                          updateClosureInternalForm(field.name, value);
                        }}
                      />
                    )}
                  />
                </div>
              </Body>
            )}

            {/* {openPopUpTab === 2 && (
              <Body className="content">
                <div className="smallTextField">
                  <label>Decision</label>
                  <Controller
                    name="formCaseUseAuthorization"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        row
                        onChange={e => {
                          setValue(field.name, parseInt(e.target.value, 10));
                          updateClosureInternalForm(
                            field.name,
                            parseInt(e.target.value, 10),
                          );
                        }}
                      >
                        <FormControlLabel
                          value="1"
                          checked={field.value === 1}
                          control={<Radio />}
                          label="Not Authorized"
                        />
                        <FormControlLabel
                          value="2"
                          checked={field.value === 2}
                          control={<Radio />}
                          label="Authorized If Sanitized"
                        />
                        <FormControlLabel
                          value="3"
                          checked={field.value === 3}
                          control={<Radio />}
                          label="Authorized, see the Knowledge Base"
                        />
                      </RadioGroup>
                    )}
                  />
                </div>
              </Body>
            )} */}

            {openPopUpTab === 3 && (
              <Body className="content">
                <div className="groupTextField">
                  <div className="smallTextField">
                    <label>Create a new methodology</label>
                    <Controller
                      name="formNewMethodologySelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Person Resposible</label>
                    <Controller
                      name="formNewMethodologyContact"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          searchEnabled
                          dataSource={filteredProfessionals}
                          valueExpr="ID"
                          displayExpr="Name"
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Comment</label>
                    <Controller
                      name="formNewMethodologyComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="groupTextField">
                  <div className="smallTextField">
                    <label>Evolve an existing methodology</label>
                    <Controller
                      name="formEvolveExistingMethodologySelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Person Resposible</label>
                    <Controller
                      name="formEvolveExistingMethodologyContact"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          searchEnabled
                          dataSource={filteredProfessionals}
                          valueExpr="ID"
                          displayExpr="Name"
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Comment</label>
                    <Controller
                      name="formEvolveExistingMethodologyComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="groupTextField">
                  <div className="smallTextField">
                    <label>Create a tool</label>
                    <Controller
                      name="formCreateToolSelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Person Responsible</label>
                    <Controller
                      name="formCreateToolContact"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          searchEnabled
                          dataSource={filteredProfessionals}
                          valueExpr="ID"
                          displayExpr="Name"
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Comment</label>
                    <Controller
                      name="formCreateToolComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="groupTextField">
                  <div className="smallTextField">
                    <label> Use external experts</label>
                    <Controller
                      name="formExternalExpertSelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Person Responsible</label>
                    <Controller
                      name="formExternalExpertContact"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          searchEnabled
                          dataSource={filteredProfessionals}
                          valueExpr="ID"
                          displayExpr="Name"
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Comment</label>
                    <Controller
                      name="formExternalExpertComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="groupTextField">
                  <div className="smallTextField">
                    <label> Use internal experts</label>
                    <Controller
                      name="formInternalExpertSelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Person Responsible</label>
                    <Controller
                      name="formInternalExpertContact"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          searchEnabled
                          dataSource={filteredProfessionals}
                          valueExpr="ID"
                          displayExpr="Name"
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Comment</label>
                    <Controller
                      name="formInternalExpertComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="groupTextField">
                  <div className="smallTextField">
                    <label>Buy a report/data</label>
                    <Controller
                      name="formBuyReportSelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Person Responsible</label>
                    <Controller
                      name="formBuyReportContact"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          searchEnabled
                          dataSource={filteredProfessionals}
                          valueExpr="ID"
                          displayExpr="Name"
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Comment</label>
                    <Controller
                      name="formBuyReportComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="groupTextField">
                  <div className="smallTextField">
                    <label>Analysed at least one distributor</label>
                    <Controller
                      name="formAnalysedDistributorSelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Person Responsible</label>
                    <Controller
                      name="formAnalysedDistributorContact"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          searchEnabled
                          dataSource={filteredProfessionals}
                          valueExpr="ID"
                          displayExpr="Name"
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Comment</label>
                    <Controller
                      name="formAnalysedDistributorComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="groupTextField">
                  <div className="smallTextField">
                    <label> Make retail contacts</label>
                    <Controller
                      name="formRetailContactSelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Person Responsible</label>
                    <Controller
                      name="formRetailContactContact"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          searchEnabled
                          dataSource={filteredProfessionals}
                          valueExpr="ID"
                          displayExpr="Name"
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Comment</label>
                    <Controller
                      name="formRetailContactComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="groupTextField">
                  <div className="smallTextField">
                    <label> Create benchmarks</label>
                    <Controller
                      name="formCreateBenchmarkSelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Person Responsible</label>
                    <Controller
                      name="formCreateBenchmarkContact"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          searchEnabled
                          dataSource={filteredProfessionals}
                          valueExpr="ID"
                          displayExpr="Name"
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Comment</label>
                    <Controller
                      name="formCreateBenchmarkComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="groupTextField">
                  <div className="smallTextField">
                    <label>Access company P&amp;Ls</label>
                    <Controller
                      name="formAccessCompanyPELSelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Person Responsible</label>
                    <Controller
                      name="formAccessCompanyPELContact"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          searchEnabled
                          dataSource={filteredProfessionals}
                          valueExpr="ID"
                          displayExpr="Name"
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Comment</label>
                    <Controller
                      name="formAccessCompanyPELComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="groupTextField">
                  <div className="smallTextField">
                    <label> Build new industry or country knowledge</label>
                    <Controller
                      name="formNewIndustryOrCountryKnowledgeSelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Person Responsible</label>
                    <Controller
                      name="formNewIndustryOrCountryKnowledgeContact"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          searchEnabled
                          dataSource={filteredProfessionals}
                          valueExpr="ID"
                          displayExpr="Name"
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Comment</label>
                    <Controller
                      name="formNewIndustryOrCountryKnowledgeComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="groupTextField">
                  <div className="smallTextField">
                    <label>
                      Create content that should be reference material for other
                      projects
                    </label>
                    <Controller
                      name="formMaterialForOtherProjectSelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Person Responsible</label>
                    <Controller
                      name="formMaterialForOtherProjectContact"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          searchEnabled
                          dataSource={filteredProfessionals}
                          valueExpr="ID"
                          displayExpr="Name"
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Comment</label>
                    <Controller
                      name="formMaterialForOtherProjectComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="groupTextField">
                  <div className="smallTextField">
                    <label> Present content in new or different ways</label>

                    <Controller
                      name="formPresentContentInNewOrDifferentWaySelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Person Responsible</label>

                    <Controller
                      name="formPresentContentInNewOrDifferentWayContact"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          searchEnabled
                          dataSource={filteredProfessionals}
                          valueExpr="ID"
                          displayExpr="Name"
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Comment</label>
                    <Controller
                      name="formPresentContentInNewOrDifferentWayComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="groupTextField">
                  <div className="smallTextField">
                    <label> Registered Client Organizational Structure</label>
                    <Controller
                      name="formRegisteredClientOrganizationalStructureSelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Person Responsible</label>

                    <Controller
                      name="formRegisteredClientOrganizationalStructureContact"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          searchEnabled
                          dataSource={filteredProfessionals}
                          valueExpr="ID"
                          displayExpr="Name"
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="smallTextField">
                    <label>Comment</label>
                    <Controller
                      name="formRegisteredClientOrganizationalStructureComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Body>
            )}

            {openPopUpTab === 4 && (
              <Body className="content">
                <div className="groupTextField">
                  <div className="smallTextField">
                    <label>
                      Was the topic of sustainability touched by the project in
                      any way?
                    </label>
                    <Controller
                      name="formTopicOfSustainabilityTouchedSelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>

                  <div className="smallTextField">
                    <label>Comment</label>
                    <Controller
                      name="formTopicOfSustainabilityTouchedComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="groupTextField">
                  <div className="smallTextField">
                    <label> Is the client focused on sustainability?</label>
                    <Controller
                      name="formClientFocusedOnSustainabilitySelect"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          style={{ display: 'flex', width: '100%' }}
                          onChange={e => {
                            setValue(field.name, e.target.value);
                            updateClosureInternalForm(
                              field.name,
                              e.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="false"
                            checked={
                              field.value === false || field.value === 'false'
                            }
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="true"
                            checked={
                              field.value === true || field.value === 'true'
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>

                  <div className="smallTextField">
                    <label>Comment</label>

                    <Controller
                      name="formClientFocusedOnSustainabilityComment"
                      control={control}
                      render={({ field }) => (
                        <TextArea
                          value={field.value}
                          onChanged={value => {
                            setValue(field.name, value);
                            updateClosureInternalForm(field.name, value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Body>
            )}
          </div>
        </>
      )}
      {openDialog && (
          <Dialog
            title="Tips to write a good case"
            onClose={() => setOpenDialog(false)}
            open={openDialog}
            fullWidth
          >
            <div style={{
              display:"flex",
              flexDirection:"column",
              justifyContent:"center",
              alignItems:"center",
              gap:"20px"
            }}>
            <DialogTitle>Tips to write a good case</DialogTitle>
            <DialogContent>
              <div>
                <iframe
                  src="https://player.vimeo.com/video/863091796?h=fcd6fcbce5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22"
                  style={{
                    width: "400px",
                    padding: "0px",
                    position: "relative",
                    top: 0,
                    left: 0,
                    marginBottom: "50px",
                    height: "300px",
                  }}
                  allow="autoplay; fullscreen; picture-in-picture"
                  title="Tips to write a good case"
                ></iframe>
              </div>
            </DialogContent>
            </div>
          </Dialog>
        )}
    </Container>
  );
};
