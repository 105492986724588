import React, { useCallback, useEffect, useState } from 'react';

import { DataGrid } from 'devextreme-react';
import { Column, SearchPanel } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { IconButton, Tooltip } from '@material-ui/core';
import { FiEdit2 } from 'react-icons/fi';
import { Container, Body } from './styles';
import master from '../../../../services/master';
import { DialogEditClient } from './DialogEditClient';
import { BoardHeader } from '../../../../components/BoardHeader';

export const KNMClient: React.FC = () => {
  const [clients, setClients] = useState<DataSource>();
  const [openClientEdit, setOpenClientEdit] = useState(false);
  const [clientModalKey, setClientModalKey] = useState(0);

  const loadClients = useCallback(async () => {
    const projectStore = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/clients');
        return data;
      },
    });

    setClients(
      new DataSource({
        store: projectStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    loadClients();
  }, [loadClients]);

  const editCell = useCallback(e => {
    return (
      <Tooltip title="Edit Client">
        <IconButton
          aria-label="edit"
          onClick={() => {
            setClientModalKey(e.key);
            setOpenClientEdit(true);
          }}
        >
          <FiEdit2 style={{ color: '#8b0305', fontSize: '18px' }} />
        </IconButton>
      </Tooltip>
    );
  }, []);

  const textCells = useCallback(e => {
    return (
      <p
        style={{
          fontWeight: '600',
          textTransform: 'uppercase',
          letterSpacing: '0.05em',
        }}
      >
        {e.text}
      </p>
    );
  }, []);

  return (
    <Container>
      <Body>
        <div
          style={{
            padding: '8px 14px',
          }}
        >
          <BoardHeader title="KNM - Project Client" subtitle="" />
        </div>
        <hr />
        <DataGrid
          style={{ marginBottom: '10px' }}
          className="grid"
          dataSource={clients}
        >
          <SearchPanel visible />
          <Column
            dataField="Name"
            caption="Client Name"
            // cellRender={textCells}
          />
          <Column cellRender={editCell} width={80} alignment="center" />
        </DataGrid>
      </Body>

      {openClientEdit && (
        <DialogEditClient
          open={openClientEdit}
          handleClose={() => {
            setOpenClientEdit(false);
            // eslint-disable-next-line no-unused-expressions
            clients?.reload();
          }}
          idClient={clientModalKey}
        />
      )}
    </Container>
  );
};
