import React, { createContext, useCallback, useState, useContext } from 'react';

interface DocumentTitleContextData {
  setTitle(title: string | undefined): void;
  setDefaultTitle(): void;
  getTitle(): string;
}

const DocumentTitleContext = createContext<DocumentTitleContextData>(
  {} as DocumentTitleContextData,
);

export const DocumentTitleProvider: React.FC = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('');

  const setTitle = useCallback(title => {
    if (title) {
      document.title = `${title} - Portal V`;
      window.name = `Window${title}`;
      setPageTitle(title);
    } else {
      document.title = `Portal V`;
      window.name = `WindowPortalV`;
      setPageTitle('PortalV');
    }
  }, []);

  const setDefaultTitle = useCallback(() => {
    document.title = `Portal V`;
    window.name = `PortalV`;
  }, []);

  const getTitle = useCallback(() => document.title, []);

  return (
    <DocumentTitleContext.Provider
      value={{ setDefaultTitle, setTitle, getTitle }}
    >
      {children}
    </DocumentTitleContext.Provider>
  );
};

export function useDocumentTitle(): DocumentTitleContextData {
  const context = useContext(DocumentTitleContext);

  if (!context) {
    throw new Error('useToast must be used within an DocumentTitleContext');
  }

  return context;
}
