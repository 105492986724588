/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import { useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { WhisperSpinner } from 'react-spinners-kit';
import api from '../../../services/api';
import { TextArea } from '../../../components/TextArea';
import { useToast } from '../../../hooks/toast';
import { BoardHeader } from '../../../components/BoardHeader';
import { Body, Container } from './styles';
import { ContainerLoading } from '../ClientTab/styles';
import { useAuth } from '../../../hooks/auth';
import portalGroups from '../../../config/portalGroups';

export const SetupAssessmentTab: React.FC<{ title?: string }> = ({ title }) => {
  const { id } = useParams<{
    id: string;
  }>();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const { user, hasScopes } = useAuth();
  const { control, setValue } = useForm();
  const [setupFinished, setSetupFinished] = useState(false);
  const [professionalIsInTeam, setProfessionalIsInTeam] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isPartner, setIsPartner] = useState(false);

  const allowEdit = useMemo(() => {
    return (
      hasScopes([portalGroups.Admin]) ||
      (!isManager && !isPartner && !setupFinished)
    );
  }, [isManager, isPartner, setupFinished, hasScopes]);

  const loadProjectInfos = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/api/projects/${id}`);
    setSetupFinished(data.idStatus !== 2);

    const professionalAccess = data.projectTeam.find(
      (x: any) => x.professional.id === user.userId,
    );

    setProfessionalIsInTeam(professionalAccess);

    setIsManager(professionalAccess?.isManager);
    setIsPartner(professionalAccess?.isPartner);

    const projectAssessmentResponse = await api.get(
      `/api/project-setup-assessments/project/${id}`,
    );

    setValue('rating', projectAssessmentResponse.data.rating);
    setValue(
      'ratingDescription',
      projectAssessmentResponse.data.ratingDescription,
    );
    setValue('watchedToolkit', projectAssessmentResponse.data.watchedToolkit);
    setValue(
      'doYouFeelComfortable',
      projectAssessmentResponse.data.doYouFeelComfortable,
    );
    setValue(
      'howCouldBeenDoneBetter',
      projectAssessmentResponse.data.howCouldBeenDoneBetter,
    );
    setLoading(false);
  }, [id, setValue, user]);

  useEffect(() => {
    loadProjectInfos();
  }, [loadProjectInfos]);

  const updateSetupAssessment = useCallback(
    async (fieldName, value) => {
      try {
        await api.patch(`/api/project-setup-assessments/project/${id}`, {
          [fieldName]: value,
        });
        addToast({
          type: 'success',
          title: 'Saved',
          description: '',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          description: '',
        });
      }
    },
    [addToast, id],
  );

  return (
    <>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}
      <Container>
        <BoardHeader title="SETUP" subtitle="" />

        <span>
          <h1
            style={{
              textTransform: 'uppercase',
              color: '#6c6c6c',
              fontSize: '24px',
              fontWeight: 700,
              letterSpacing: '0.1em',
            }}
          >
            Assessment
          </h1>
        </span>
        <Body className="content">
          {professionalIsInTeam && !isManager && !isPartner ? (
            <>
              <div className="smallTextField">
                <label>
                  How do you evaluate the quality of the Set Up? Why? (1 - low,
                  10 - high)
                </label>
                <Controller
                  name="rating"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      row
                      onChange={e => {
                        setValue(field.name, parseInt(e.target.value, 10));
                        updateSetupAssessment(
                          field.name,
                          parseInt(e.target.value, 10),
                        );
                      }}
                    >
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="1"
                        checked={field.value === 1}
                        control={<Radio />}
                        label="1"
                      />
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="2"
                        checked={field.value === 2}
                        control={<Radio />}
                        label="2"
                      />
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="3"
                        checked={field.value === 3}
                        control={<Radio />}
                        label="3"
                      />
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="4"
                        checked={field.value === 4}
                        control={<Radio />}
                        label="4"
                      />
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="5"
                        checked={field.value === 5}
                        control={<Radio />}
                        label="5"
                      />
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="6"
                        checked={field.value === 6}
                        control={<Radio />}
                        label="6"
                      />
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="7"
                        checked={field.value === 7}
                        control={<Radio />}
                        label="7"
                      />
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="8"
                        checked={field.value === 8}
                        control={<Radio />}
                        label="8"
                      />
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="9"
                        checked={field.value === 9}
                        control={<Radio />}
                        label="9"
                      />
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="10"
                        checked={field.value === 10}
                        control={<Radio />}
                        label="10"
                      />
                    </RadioGroup>
                  )}
                />
                <Controller
                  name="ratingDescription"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      disabled={!allowEdit}
                      value={field.value}
                      onChanged={value => {
                        setValue(field.name, value);
                        updateSetupAssessment(field.name, value);
                      }}
                    />
                  )}
                />
              </div>
              <div className="smallTextField">
                <label>
                  Have you watched the toolkit(s) related to this project?
                </label>
                <Controller
                  name="watchedToolkit"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      row
                      onChange={e => {
                        setValue(field.name, parseInt(e.target.value, 10));
                        updateSetupAssessment(
                          field.name,
                          parseInt(e.target.value, 10),
                        );
                      }}
                    >
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="1"
                        checked={field.value === 1}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="2"
                        checked={field.value === 2}
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="3"
                        checked={field.value === 3}
                        control={<Radio />}
                        label="There are no toolkits available"
                      />
                    </RadioGroup>
                  )}
                />
              </div>
              <div className="smallTextField">
                <label>Do you feel comfortable to deliver the project?</label>
                <Controller
                  name="doYouFeelComfortable"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      row
                      onChange={e => {
                        setValue(field.name, parseInt(e.target.value, 10));
                        updateSetupAssessment(
                          field.name,
                          parseInt(e.target.value, 10),
                        );
                      }}
                    >
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="1"
                        checked={field.value === 1}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="2"
                        checked={field.value === 2}
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        disabled={!allowEdit}
                        value="3"
                        checked={field.value === 3}
                        control={<Radio />}
                        label="Partial"
                      />
                    </RadioGroup>
                  )}
                />
              </div>
              <div className="smallTextField">
                <label>How could this Set Up have been done better?</label>
                <Controller
                  name="howCouldBeenDoneBetter"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      disabled={!allowEdit}
                      value={field.value}
                      onChanged={value => {
                        setValue(field.name, value);
                        updateSetupAssessment(field.name, value);
                      }}
                    />
                  )}
                />
              </div>
            </>
          ) : (
            <p>
              {`You are not able to fill the setup assessment because you are ${
                isManager
                  ? 'the project manager'
                  : isPartner
                  ? 'a partner'
                  : 'not part of the team'
              }.`}
            </p>
          )}
        </Body>
      </Container>
    </>
  );
};
