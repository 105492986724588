import styled from 'styled-components';

export const Container = styled.div`
  img {
    filter: brightness(90%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #f5f5f5;
    object-fit: cover;
  }
`;
