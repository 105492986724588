import { IconButton, Tooltip } from '@material-ui/core';
import { DataGrid } from 'devextreme-react';
import { Column, Editing } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useState } from 'react';
import { FiSearch, FiX, FiPlus } from 'react-icons/fi';
import { useParams } from 'react-router';
import api from '../../../services/api';
import { Button } from '../../../components/Button';
import { Body, Container, HeaderContent } from './styles';
import { BoardHeader } from '../../../components/BoardHeader';
import { DialogConfirmSubmitOrUndoWeeklyStatus } from '../DialogConfirmSubmitOrUndoWeeklyStatus';
import { DialogWeeklyStatus } from '../DialogWeeklyStatus';
import { useToast } from '../../../hooks/toast';

export const WeeklyStatus: React.FC<{ title?: string }> = ({ title }) => {
  const { id } = useParams<{
    id: string;
  }>();
  const [weeklyStatuses, setWeeklyStatuses] = useState<DataSource>();
  const [weeklyStatusEditKey, setWeeklyStatusEditKey] = useState(0);
  const [openWeeklyStatusDialog, setOpenWeeklyStatusDialog] = useState(false);
  const [
    openConfirmSubmitOrUndoWeeklyStatus,
    setOpenConfirmSubmitOrUndoWeeklyStatus,
  ] = useState(false);
  const { addToast } = useToast();

  const loadWeeklyStatuses = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(`api/weekly-status/project/${id}`);
        return data;
      },
      update: async (key, data) => {
        await api.put(`/api/weekly-status/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/weekly-status/${key}`);
      },
    });

    setWeeklyStatuses(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id]);

  useEffect(() => {
    loadWeeklyStatuses();
  }, [loadWeeklyStatuses]);

  const handleDownloadSignedFile = useCallback(
    async weeklyStatusId => {
      addToast({
        type: 'info',
        title: 'We are generating your file. It could take a while',
      });

      const { data, headers } = await api.get(
        `api/weekly-status/${weeklyStatusId}/file?signed=1`,
        {
          responseType: 'blob',
        },
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', headers['x-file-name']);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: 'success',
        title: 'Your file is ready',
      });
    },
    [addToast],
  );

  const handleResendFile = useCallback(
    async weeklyStatusId => {
      addToast({
        type: 'info',
        title: 'Resending your file...',
      });

      await api.post(`api/weekly-status/${weeklyStatusId}/resend`);

      addToast({
        type: 'success',
        title: 'Sent!',
      });
    },
    [addToast],
  );

  const downloadCell = useCallback(
    e => {
      return (
        (e.data.signedStatus === 'completed' &&
          !!e.data.signatureFilename && (
            <div style={{ margin: '0 auto', width: 'fit-content' }}>
              <Button
                onClick={() => {
                  handleDownloadSignedFile(e.key);
                }}
                disabled={e.row.isEditing}
              >
                Download signed file
              </Button>
            </div>
          )) || <div style={{ textAlign: 'left' }}>Not available</div>
      );
    },
    [handleDownloadSignedFile],
  );

  const resendCell = useCallback(
    e => {
      return (
        (e.data.signedStatus === 'sent' && e.data.signedType === 2 && (
          <div style={{ margin: '0 auto', width: 'fit-content' }}>
            <Button
              onClick={() => {
                handleResendFile(e.key);
              }}
              disabled={e.row.isEditing}
            >
              Resend
            </Button>
          </div>
        )) || <div style={{ textAlign: 'left' }}>Not available</div>
      );
    },
    [handleResendFile],
  );

  const weeklyStatusCell = useCallback(e => {
    return (
      <IconButton
        onClick={() => {
          setWeeklyStatusEditKey(e.key);
          setOpenWeeklyStatusDialog(true);
        }}
        disabled={e.row.isEditing}
      >
        <FiSearch size={20} />
      </IconButton>
    );
  }, []);

  const handleAdd = useCallback(() => {
    setOpenWeeklyStatusDialog(true);
  }, []);

  const statusCell = useCallback(e => {
    if (e.value === 'declined')
      return (
        <Tooltip title="Declined (contact Legal for more details)">
          <div
            style={{
              width: 35,
              height: 35,
              boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
              borderRadius: '50%',
              margin: '0 auto',
              border: '5px solid white',
              backgroundColor: '#e74c3c',
            }}
          />
        </Tooltip>
      );
    if (e.value === 'deleted')
      return (
        <Tooltip title="Deleted (contact Legal for more details)">
          <div
            style={{
              width: 35,
              height: 35,
              boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
              borderRadius: '50%',
              margin: '0 auto',
              border: '5px solid white',
              backgroundColor: '#e74c3c',
            }}
          />
        </Tooltip>
      );

    if (e.value === 'sent')
      return (
        <Tooltip title="Sent to sign">
          <div
            style={{
              width: 35,
              height: 35,
              boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
              borderRadius: '50%',
              margin: '0 auto',
              border: '5px solid white',
              backgroundColor: '#3f3ce7',
            }}
          />
        </Tooltip>
      );
    if (e.value === 'completed')
      return (
        <Tooltip title="Completed">
          <div
            style={{
              width: 35,
              height: 35,
              boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
              borderRadius: '50%',
              margin: '0 auto',
              border: '5px solid white',
              backgroundColor: '#2ecc71',
            }}
          />
        </Tooltip>
      );
    return (
      <Tooltip title="Draft">
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#bdc3c7',
          }}
        />
      </Tooltip>
    );
  }, []);

  return (
    <>
      <HeaderContent>
        <BoardHeader title="DURING" subtitle="" />
      </HeaderContent>
      <div className="content">
        <Container>
          <span
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <h1
              style={{
                textTransform: 'uppercase',
                color: '#6c6c6c',
                fontSize: '24px',
                fontWeight: 700,
                letterSpacing: '0.1em',
              }}
            >
              Weekly Status
            </h1>
            {/* <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Button secondary className="download-ws-button">
                Download all
              </Button>
            </div> */}
          </span>
        </Container>
      </div>
      <div className="client">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            aria-label="add"
            style={{ padding: 6 }}
            onClick={handleAdd}
          >
            <FiPlus color="#8b0304" size={22} />
          </IconButton>
        </div>

        <Body>
          <DataGrid
            dataSource={weeklyStatuses}
            onRowUpdating={options => {
              options.newData = {
                ...options.oldData,
                ...options.newData,
              };
            }}
          >
            <Column
              dataField="createdAt"
              dataType="date"
              format="dd MMM yyyy"
              allowEditing={false}
            />
            <Column
              dataField="clientSubmissionDate"
              caption="Client Submission Date"
              dataType="date"
              format="dd MMM yyyy"
            />
            <Column
              dataField="submittedAt"
              dataType="date"
              format="dd MMM yyyy"
              allowEditing={false}
            />
            <Column
              dataField="signedStatus"
              caption="Signed Status"
              alignment="center"
              cellRender={statusCell}
              allowEditing={false}
            />
            <Column
              cellRender={weeklyStatusCell}
              caption="Weekly Status"
              allowEditing={false}
            />
            <Column
              caption="Download file"
              allowEditing={false}
              cellRender={downloadCell}
            />
            <Column
              caption="Resend"
              allowEditing={false}
              cellRender={resendCell}
            />
            <Editing
              allowDeleting={(e: any) => e.row.data.signedStatus !== 'draft'}
              allowUpdating={(e: any) =>
                e.row.data.signedStatus !== 'deleted' &&
                e.row.data.signedStatus !== 'declined'
              }
            />
          </DataGrid>
        </Body>
      </div>

      {openConfirmSubmitOrUndoWeeklyStatus && (
        <DialogConfirmSubmitOrUndoWeeklyStatus
          open={openConfirmSubmitOrUndoWeeklyStatus}
          handleClose={() => {
            setOpenConfirmSubmitOrUndoWeeklyStatus(false);
            // eslint-disable-next-line no-unused-expressions
            weeklyStatuses?.reload();
          }}
          id={weeklyStatusEditKey}
        />
      )}

      {openWeeklyStatusDialog && (
        <>
          <div className="customDialogBG" />
          <div className="customDialog">
            <FiX
              className="closeButton"
              onMouseUp={() => {
                setOpenWeeklyStatusDialog(false);
                setWeeklyStatusEditKey(0);
                // eslint-disable-next-line no-unused-expressions
                weeklyStatuses?.reload();
              }}
            />

            <DialogWeeklyStatus
              handleClose={() => {
                setOpenWeeklyStatusDialog(false);
                setWeeklyStatusEditKey(0);
                // eslint-disable-next-line no-unused-expressions
                weeklyStatuses?.reload();
              }}
              id={weeklyStatusEditKey}
              projectId={Number(id)}
              refresh={() => weeklyStatuses?.reload()}
            />
          </div>
        </>
      )}
    </>
  );
};
