/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { Controller, useForm } from 'react-hook-form';
import { WhisperSpinner } from 'react-spinners-kit';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { SelectBox } from '../../../components/SelectBox';
import api from '../../../services/api';
import { Body, Container, TabMenu } from './styles';
import { BoardHeader } from '../../../components/BoardHeader';
import master from '../../../services/master';
import { useToast } from '../../../hooks/toast';
import { ContainerLoading } from '../../ProjectHome/styles';
import { DialogLegalDocument } from './DialogLegalDocument';
import { DialogRemoveDocument } from './DialogRemoveDocument';
import { Alert } from '@mui/material';

export const ClosureWithClient: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const [loading, setLoading] = useState(false);
  const { control, setValue } = useForm();
  const { addToast } = useToast();

  const [filteredProfessionals, setFilteredProfessionals] =
    useState<DataSource>();
  const [openTab, setOpenTab] = useState<number>(1);
  const [openLegalDocument, setOpenLegalDocument] = useState(false);
  const [hasLegalFile, setHasLegalFile] = useState(false);
  const [openRemoveDocument, setOpenRemoveDocument] = useState(false);

  const loadProjectInfo = useCallback(async () => {
    const { data } = await api.get(`/api/projects/${id}`);
    const professionalResponse = await master.get('/master/professionals', {
      params: {
        where: {
          IsAnyStatus: true,
        },
      },
    });

    const projectTeamIds = data.projectTeam.map((x: any) => {
      return x.professional?.id;
    });

    setFilteredProfessionals(
      professionalResponse.data
        .map((p: any) => {
          return { ID: p.ID, Name: p.Name };
        })
        .filter((x: any) => projectTeamIds.includes(x.ID)),
    );

    return data;
  }, [id]);

  const loadProjectClosureInternalInfo = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(
      `/api/project-closure-external/project/${id}`,
    );

    setHasLegalFile(
      data.legalClosureDocumentAttachFile || data.unSignatureFilename,
    );
    setValue(
      'signedWeeklyStatusIDPersonResponsible',
      data.signedWeeklyStatusIDPersonResponsible,
    );
    setValue('signedWeeklyStatusStatus', data.signedWeeklyStatusStatus);
    setValue(
      'legalClosureDocumentIDPersonResponsible',
      data.legalClosureDocumentIDPersonResponsible,
    );
    setValue('legalClosureDocumentStatus', data.legalClosureDocumentStatus);
    setValue(
      'legalClosureDocumentLogoAuthorization',
      data.legalClosureDocumentLogoAuthorization,
    );
    setValue(
      'clientTestimonialIDPersonResponsible',
      data.clientTestimonialIDPersonResponsible,
    );
    setValue(
      'clientTestimonialResponsibleRequested',
      data.clientTestimonialResponsibleRequested,
    );
    setValue('clientTestimonialStatus', data.clientTestimonialStatus);
    setValue(
      'thankYouLetterIDPersonResponsible',
      data.thankYouLetterIDPersonResponsible,
    );
    setValue('thankYouLetterTeamOffer', data.thankYouLetterTeamOffer);
    setValue('thankYouLetterStatus', data.thankYouLetterStatus);
    setLoading(false);
  }, [id, setValue]);

  const updateClosureExternal = useCallback(
    async (fieldName, value) => {
      try {
        await api.patch(`/api/project-closure-external/project/${id}`, {
          [fieldName]: value,
        });
        addToast({
          type: 'success',
          title: 'Saved',
          description: '',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          description: '',
        });
      }
    },
    [addToast, id],
  );

  const handleUploadLegalDocuments = useCallback(
    async data => {
      try {
        addToast({
          type: 'info',
          title: 'Uploading your file...',
        });
        const formData = new FormData();

        if (!data.file || !data.file[0]) {
          addToast({
            title: 'Please, select a file',
            type: 'error',
          });
          return;
        }

        formData.append('file', data.file[0], data.file[0].name);

        await api.post(`api/projects/${id}/legal-documents/file`, formData);

        addToast({
          title: 'File uploaded',
          type: 'success',
        });

        loadProjectClosureInternalInfo();
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }
    },
    [id, addToast, loadProjectClosureInternalInfo],
  );

  const handleDownloadLegalDocuments = useCallback(async () => {
    addToast({
      type: 'info',
      title: 'We are generating your file. It could take a while',
    });

    const { data, headers } = await api.get(
      `api/projects/${id}/legal-documents/file`,
      {
        responseType: 'blob',
      },
    );

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', headers['x-file-name']);
    document.body.appendChild(link);
    link.click();
    link.remove();

    addToast({
      type: 'success',
      title: 'Your file is ready',
    });
  }, [id, addToast]);

  const handleDownloadLegalDocumentTemplate = useCallback(async () => {
    addToast({
      type: 'info',
      title: 'We are generating your file. It could take a while',
    });

    const { data, headers } = await api.get(
      `api/projects/${id}/legal-documents/template`,
      {
        responseType: 'blob',
      },
    );

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', headers['x-file-name']);
    document.body.appendChild(link);
    link.click();
    link.remove();

    addToast({
      type: 'success',
      title: 'Your file is ready',
    });
  }, [id, addToast]);

  useEffect(() => {
    loadProjectInfo();
    loadProjectClosureInternalInfo();
  }, [loadProjectInfo, loadProjectClosureInternalInfo]);

  const handleDownloadAllWeeklyStatus = useCallback(async () => {
    addToast({
      type: 'info',
      title: 'We are generating your file. It could take a while',
    });

    const { data, headers } = await api.get(
      `api/weekly-status/project/${id}/download`,
      {
        responseType: 'blob',
      },
    );

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', headers['x-file-name']);
    document.body.appendChild(link);
    link.click();
    link.remove();

    addToast({
      type: 'success',
      title: 'Your file is ready',
    });
  }, [addToast, id]);

  return (
    <Container>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}
      <BoardHeader title="CLOSURE" subtitle="" />
      <span>
        <h1
          style={{
            textTransform: 'uppercase',
            color: '#6c6c6c',
            fontSize: '24px',
            fontWeight: 700,
            letterSpacing: '0.1em',
          }}
        >
          Closure With Client
        </h1>
      </span>

      <TabMenu>
        <li
          onClick={() => setOpenTab(1)}
          className={openTab === 1 ? 'focused' : ''}
        >
          All Signed Weekly Status
        </li>
        <li
          onClick={() => setOpenTab(2)}
          className={openTab === 2 ? 'focused' : ''}
        >
          Legal Closure Documents
        </li>
        <li
          onClick={() => setOpenTab(3)}
          className={openTab === 3 ? 'focused' : ''}
        >
          Client Testimonials
        </li>
        <li
          onClick={() => setOpenTab(4)}
          className={openTab === 4 ? 'focused' : ''}
        >
          Thank You Letter/Gift
        </li>
      </TabMenu>
      {openTab === 1 && (
        <Body className="content">
          <div className="smallTextField">
            <label>Person Responsible</label>
            <Controller
              name="signedWeeklyStatusIDPersonResponsible"
              control={control}
              render={({ field }) => (
                <SelectBox
                  searchEnabled
                  dataSource={filteredProfessionals}
                  valueExpr="ID"
                  displayExpr="Name"
                  value={field.value}
                  onChanged={value => {
                    setValue(field.name, value);
                    updateClosureExternal(field.name, value);
                  }}
                />
              )}
            />
          </div>

          <div className="smallTextField">
            <label>Download all signed weekly status</label>
            <Button
              style={{ justifyContent: 'center', width: 'fit-content' }}
              onClick={handleDownloadAllWeeklyStatus}
            >
              Download files
            </Button>
          </div>

          <div className="smallTextField">
            <label>Status</label>
            <Controller
              name="signedWeeklyStatusStatus"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  style={{ display: 'flex', width: '100%' }}
                  onChange={e => {
                    setValue(field.name, e.target.value);
                    updateClosureExternal(field.name, e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="false"
                    checked={field.value === false || field.value === 'false'}
                    control={<Radio />}
                    label="Not started"
                  />
                  <FormControlLabel
                    value="true"
                    checked={field.value === true || field.value === 'true'}
                    control={<Radio />}
                    label="Done"
                  />
                </RadioGroup>
              )}
            />
          </div>
        </Body>
      )}
      {openTab === 2 && (
        <Body className="content">
          <div className="smallTextField">
            <label>Person Responsible</label>
            <Controller
              name="legalClosureDocumentIDPersonResponsible"
              control={control}
              render={({ field }) => (
                <SelectBox
                  searchEnabled
                  dataSource={filteredProfessionals}
                  valueExpr="ID"
                  displayExpr="Name"
                  value={field.value}
                  onChanged={value => {
                    setValue(field.name, value);
                    updateClosureExternal(field.name, value);
                  }}
                />
              )}
            />
          </div>

          <div className="smallTextField">
            <label>Template</label>
            <Button
              style={{ justifyContent: 'center', width: 'fit-content' }}
              onClick={handleDownloadLegalDocumentTemplate}
            >
              Download
            </Button>
          </div>
          <div className="smallTextField">
            <label>Legal Documents File</label>
            <Button
              style={{ justifyContent: 'center', width: 'fit-content' }}
              onClick={() => setOpenLegalDocument(true)}
            >
              UPLOAD LEGAL DOCUMENT
            </Button>
            {hasLegalFile && (
              <>
                <div className="legalDocActionBtns">
                  <Button onClick={handleDownloadLegalDocuments}>
                    Download file
                  </Button>
                  <Button primary onClick={() => setOpenRemoveDocument(true)}>
                    Remove file
                  </Button>
                </div>
                <Alert severity="info" style={{ marginTop: 10 }}>
                  There is no resend feature for now. If you need to resend,
                  please remove the document and re-upload it.
                </Alert>
              </>
            )}
          </div>

          <div className="smallTextField">
            <label>Status</label>
            <Controller
              name="legalClosureDocumentStatus"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  style={{ display: 'flex', width: '100%' }}
                  onChange={e => {
                    setValue(field.name, e.target.value);
                    updateClosureExternal(field.name, e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="false"
                    checked={field.value === false || field.value === 'false'}
                    control={<Radio />}
                    label="Not started"
                  />
                  <FormControlLabel
                    value="true"
                    checked={field.value === true || field.value === 'true'}
                    control={<Radio />}
                    label="Done"
                  />
                </RadioGroup>
              )}
            />
          </div>

          <div className="smallTextField">
            <label>Do we need this Client Logo Authorization?</label>
            <Controller
              name="legalClosureDocumentLogoAuthorization"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  style={{ display: 'flex', width: '100%' }}
                  onChange={e => {
                    setValue(field.name, e.target.value);
                    updateClosureExternal(field.name, e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="false"
                    checked={field.value === false || field.value === 'false'}
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value="true"
                    checked={field.value === true || field.value === 'true'}
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
              )}
            />
          </div>
        </Body>
      )}
      {openTab === 3 && (
        <Body className="content">
          <div className="smallTextField">
            <label>Person Responsible</label>
            <Controller
              name="clientTestimonialIDPersonResponsible"
              control={control}
              render={({ field }) => (
                <SelectBox
                  searchEnabled
                  dataSource={filteredProfessionals}
                  valueExpr="ID"
                  displayExpr="Name"
                  value={field.value}
                  onChanged={value => {
                    setValue(field.name, value);
                    updateClosureExternal(field.name, value);
                  }}
                />
              )}
            />
          </div>
          <div className="smallTextField">
            <label>
              Did the Responsible Partner and Manager requested a testimonial?
            </label>
            <Controller
              name="clientTestimonialResponsibleRequested"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  style={{ display: 'flex', width: '100%' }}
                  onChange={e => {
                    setValue(field.name, e.target.value);
                    updateClosureExternal(field.name, e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="false"
                    checked={field.value === false || field.value === 'false'}
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value="true"
                    checked={field.value === true || field.value === 'true'}
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
              )}
            />
          </div>
          <div className="smallTextField">
            <label>Status</label>
            <Controller
              name="clientTestimonialStatus"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  style={{ display: 'flex', width: '100%' }}
                  onChange={e => {
                    setValue(field.name, e.target.value);
                    updateClosureExternal(field.name, e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="false"
                    checked={field.value === false || field.value === 'false'}
                    control={<Radio />}
                    label="Not started"
                  />
                  <FormControlLabel
                    value="true"
                    checked={field.value === true || field.value === 'true'}
                    control={<Radio />}
                    label="Done"
                  />
                </RadioGroup>
              )}
            />
          </div>
        </Body>
      )}
      {openTab === 4 && (
        <Body className="content">
          <div className="smallTextField">
            <label>Person Responsible</label>
            <Controller
              name="thankYouLetterIDPersonResponsible"
              control={control}
              render={({ field }) => (
                <SelectBox
                  searchEnabled
                  dataSource={filteredProfessionals}
                  valueExpr="ID"
                  displayExpr="Name"
                  value={field.value}
                  onChanged={value => {
                    setValue(field.name, value);
                    updateClosureExternal(field.name, value);
                  }}
                />
              )}
            />
          </div>

          <div className="smallTextField">
            <label>Will the Team offer a Thank You Gift?</label>
            <Controller
              name="thankYouLetterTeamOffer"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  style={{ display: 'flex', width: '100%' }}
                  onChange={e => {
                    setValue(field.name, e.target.value);
                    updateClosureExternal(field.name, e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="false"
                    checked={field.value === false || field.value === 'false'}
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value="true"
                    checked={field.value === true || field.value === 'true'}
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
              )}
            />
          </div>
          <div className="smallTextField">
            <label>Status</label>
            <Controller
              name="thankYouLetterStatus"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  style={{ display: 'flex', width: '100%' }}
                  onChange={e => {
                    setValue(field.name, e.target.value);
                    updateClosureExternal(field.name, e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="false"
                    checked={field.value === false || field.value === 'false'}
                    control={<Radio />}
                    label="Not started"
                  />
                  <FormControlLabel
                    value="true"
                    checked={field.value === true || field.value === 'true'}
                    control={<Radio />}
                    label="Done"
                  />
                </RadioGroup>
              )}
            />
          </div>
        </Body>
      )}
      {openLegalDocument && (
        <>
          <div className="customDialogBG" />
          <div className="customDialog">
            <FiX
              className="closeButton"
              onMouseUp={() => {
                setOpenLegalDocument(false);
              }}
            />

            <DialogLegalDocument
              handleClose={() => {
                loadProjectClosureInternalInfo();
                setOpenLegalDocument(false);
              }}
              projectId={Number(id)}
            />
          </div>
        </>
      )}
      {openRemoveDocument && (
        <DialogRemoveDocument
          open={openRemoveDocument}
          handleClose={() => {
            loadProjectClosureInternalInfo();
            setOpenRemoveDocument(false);
          }}
          projectId={Number(id)}
        />
      )}
    </Container>
  );
};
