import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 14px;
  line-height: 24px;

  h1 {
    color: #333333;
    font-size: 1.5rem;
    letter-spacing: 0.05em;
    font-weight: 700;
    margin-bottom: 0.25em;
    text-transform: uppercase;
    text-align: left;
  }

  h2 {
    color: #333333;
    font-size: 1.5rem;
    letter-spacing: 0.05em;
    font-weight: 700;
    margin-bottom: 0.25em;
    text-transform: uppercase;
    text-align: left;
  }

  p {
    font-size: 1.25rem;
    font-weight: 700;
    color: #999999;
    text-transform: uppercase;
    text-align: left;
  }

  .home {
    margin: auto;
  }
  .MuiCircularProgress-root {
    color: #8b0304;
  }
`;
