import styled from 'styled-components';

export const Container = styled.div`
  .fqqBnr .header-name h1 {
    font-weight: bold;
    text-transform: uppercase;
  }

  .fqqBnr .header-name p {
    text-transform: uppercase;
  }
`;

export const Body = styled.section`
 margin-top: 25px;


  label {
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 0.5em;
    line-height: 1.25;
   
    b {
      font-weight: bold;
    }
  }

  
  label.MuiFormControlLabel-root  {
  line-height: 1;
  font-size: 0.7rem;
  margin-left: -5px;
  margin-right: 5px;
  
}

  small {
    font-size: 0.8em;
    line-height: 1.25;
    margin-top: 1em;
    color: #999;
  }

  .smallTextField {
    display: flex;
    flex-direction: column;
    flex: 0 0 48%;
    border-radius: 7px;
    padding: 10px;
    box-shadow: 0 0 30px rgba(0 0 0 / 7%);
  }

  .groupTextField {
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    gap: 25px 0;
  }

  &.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 50px 0;
  }

  img {
    width: 90px;
    height: 75px;
    align-self: center;
  }
`;
