import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppProvider from './hooks';

import Routes from './routes';

import 'devextreme/dist/css/dx.common.css';
import './assets/dx.material.integration.css';
import './assets/custom.css';

const App: React.FC = () => (
  <BrowserRouter>
    <AppProvider>
      <Routes />
    </AppProvider>
  </BrowserRouter>
);

export default App;
