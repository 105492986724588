import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { useParams } from 'react-router';
import { WhisperSpinner } from 'react-spinners-kit';
import CustomStore from 'devextreme/data/custom_store';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  Editing,
  HeaderFilter,
  Lookup,
} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import api, { IProjectToolkit } from '../../../services/api';
import { Body, Container, ContainerLoading } from './styles';
import { BoardHeader } from '../../../components/BoardHeader';
import master, { CustomStoreProps } from '../../../services/master';
import { useAuth } from '../../../hooks/auth';
import { Button } from '../../../components/Button';
import { DialogConfirmApproveAllToolkits } from './DialogConfirmApproveAllToolkits';
import { DialogConfirmApproveToolkit } from './DialogConfirmApproveToolkit';
import { DialogAddToolkit } from './DialogAddToolkit';
import { DialogEditToolkit } from './DialogEditToolkit';
import portalGroups from '../../../config/portalGroups';

export const ToolkitsTab: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const { hasScopes } = useAuth();
  const isAdmin = useMemo(() => {
    return hasScopes([portalGroups.Admin]);
  }, [hasScopes]);
  const [setupFinished, setSetupFinished] = useState(false);
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isUserManagerOrPartner, setIsUserManagerOrPartner] = useState(false);
  const [openAddToolkit, setOpenAddToolkit] = useState(false);
  const [openEditToolkit, setOpenEditToolkit] = useState(false);
  const [idToolkit, setIdToolkit] = useState(0);
  const [openApproveToolkit, setOpenApproveToolkit] = useState(false);
  const [idApproveToolkit, setIdApproveToolkit] = useState(0);
  const [openApproveAllToolkits, setOpenApproveAllToolkits] = useState(false);
  const [projectToolkits, setProjectToolkits] = useState<DataSource>();
  const [isManager, setIsManager] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [hasPending, setHasPending] = useState(false);
  const [hasDraft, setHasDraft] = useState(false);
  const [statusChangeAll, setStatusChangeAll] = useState(0);

  const loadProjectToolkits = useCallback(() => {
    const projectToolkitsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get<IProjectToolkit[]>(
          `/api/project/${id}/toolkits`,
        );

        setHasDraft(data.filter(x => x.idStatus === 0).length > 0);
        setHasPending(data.filter(x => x.idStatus === 1).length > 0);

        return data;
      },
      insert: async data => {
        await api.post(`/api/project/${id}/toolkits`, data);
      },
      update: async (key, data) => {
        await api.put(`/api/project/${id}/toolkits/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project/${id}/toolkits/${key}`);
      },
    });

    setProjectToolkits(
      new DataSource({
        store: projectToolkitsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id]);

  const loadIsManagerOrPartner = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/api/projects/${id}`);
    setSetupFinished(data.idStatus !== 2);

    const professional = data.projectTeam.find(
      (x: any) =>
        (x.isManager || x.isPartner) && x.idProfessional === user.userId,
    );

    setIsManager(professional?.isManager);
    setIsPartner(professional?.isPartner);
    setIsUserManagerOrPartner(professional);
    setLoading(false);
  }, [id, user.userId]);

  useEffect(() => {
    loadProjectToolkits();
    loadIsManagerOrPartner();
  }, [loadProjectToolkits, loadIsManagerOrPartner]);

  const allowEdit = useMemo(
    () => isAdmin || (isUserManagerOrPartner && !setupFinished),
    [isAdmin, isUserManagerOrPartner, setupFinished],
  );

  const [toolkits] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('master/toolkits');
        const practiceResponse = await master.get('/master/practices');
        const dataWithPractice = data
          .map((t: any) => {
            return {
              ...t,
              fullName: `${
                practiceResponse.data.find(
                  (p: any) => p.ID === t.Methodology?.IDPractice,
                )?.Name
              } - ${t.Name}`,
            };
          })
          .filter((x: any) => x.IsActive)
          .sort((a: any, b: any) => {
            if (a.Methodology?.IDPractice < b.Methodology?.IDPractice) {
              return -1;
            }
            if (a.Methodology?.IDPractice > b.Methodology?.IDPractice) {
              return 1;
            }
            return 0;
          });
        return dataWithPractice;
      },
    }),
    paginate: true,
  });

  const projectToolkitStatusCell = useCallback(e => {
    if (e.value === 0) {
      return (
        <Tooltip title="Draft">
          <div
            style={{
              width: 35,
              height: 35,
              boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
              borderRadius: '50%',
              margin: '0 auto',
              border: '5px solid white',
              backgroundColor: '#bdc3c7',
            }}
          />
        </Tooltip>
      );
    }
    if (e.value === 1) {
      return (
        <Tooltip title="Request">
          <div
            style={{
              width: 35,
              height: 35,
              boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
              borderRadius: '50%',
              margin: '0 auto',
              border: '5px solid white',
              backgroundColor: '#F1C40F',
            }}
          />
        </Tooltip>
      );
    }
    if (e.value === 2) {
      return (
        <Tooltip title="Approved">
          <div
            style={{
              width: 35,
              height: 35,
              boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
              borderRadius: '50%',
              margin: '0 auto',
              border: '5px solid white',
              backgroundColor: '#2ecc71',
            }}
          />
        </Tooltip>
      );
    }
    if (e.value === 3) {
      return (
        <Tooltip title="Not Approved">
          <div
            style={{
              width: 35,
              height: 35,
              boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
              borderRadius: '50%',
              margin: '0 auto',
              border: '5px solid white',
              backgroundColor: '#e74c3c',
            }}
          />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Pending">
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#F1C40F',
          }}
        />
      </Tooltip>
    );
  }, []);

  const approveCell = useCallback(
    e => {
      const isApproved = e.data.idStatus === 2;
      const isReproved = e.data.idStatus === 3;
      if (!isApproved && !isReproved && allowEdit) {
        return (
          <Button
            style={{ margin: '0 auto' }}
            disabled={e.row.isEditing}
            secondary
            onClick={() => {
              setIdApproveToolkit(e.key);
              setOpenApproveToolkit(true);
            }}
          >
            CHANGE
          </Button>
        );
      }
      return '';
    },
    [allowEdit],
  );

  const onToolbarPreparing = useCallback(e => {
    return e.toolbarOptions.items.map((i: any) => {
      if (i.name === 'addRowButton') {
        i.options.onClick = () => setOpenAddToolkit(true);
      }
    });
  }, []);

  const onEditingStart = useCallback(e => {
    e.cancel = true;
    setIdToolkit(e.key);
    setOpenEditToolkit(true);
  }, []);

  const statusOptions = useMemo(
    () => [
      {
        id: 0,
        name: 'Draft',
      },
      {
        id: 1,
        name: 'Request',
      },
      {
        id: 2,
        name: 'Approved',
      },
      {
        id: 3,
        name: 'Not Approved',
      },
    ],
    [],
  );

  const filteredStatus = useMemo(() => {
    if (isAdmin) return statusOptions;
    if (isManager && isPartner) return statusOptions.filter(x => x.id !== 0);
    if (isManager) return statusOptions.filter(x => x.id === 1);
    if (isPartner) return statusOptions.filter(x => x.id === 2 || x.id === 3);

    return [];
  }, [statusOptions, isAdmin, isManager, isPartner]);

  return (
    <Container>
      <div>
        {loading && (
          <ContainerLoading>
            <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
          </ContainerLoading>
        )}
        <BoardHeader title="SETUP" subtitle="" />
        <span>
          <h1
            style={{
              textTransform: 'uppercase',
              color: '#6c6c6c',
              fontSize: '24px',
              fontWeight: 700,
              letterSpacing: '0.1em',
            }}
          >
            Toolkits
          </h1>
        </span>
      </div>
      {!loading && (
        <>
          <Body>
            <DataGrid
              dataSource={projectToolkits}
              onRowUpdating={options => {
                options.newData = {
                  ...options.oldData,
                  ...options.newData,
                };
              }}
              onToolbarPreparing={onToolbarPreparing}
              onEditingStart={onEditingStart}
            >
              <HeaderFilter visible allowSearch />
              <Column dataField="idToolkit" caption="Toolkit">
                <Lookup
                  dataSource={toolkits}
                  displayExpr="fullName"
                  valueExpr="ID"
                />
              </Column>
              <Column
                dataField="idStatus"
                caption="Status"
                cellRender={projectToolkitStatusCell}
                alignment="center"
                width={120}
              >
                <Lookup
                  dataSource={statusOptions}
                  displayExpr="name"
                  valueExpr="id"
                />
              </Column>
              <Column
                width={140}
                alignment="center"
                cellRender={approveCell}
                visible={allowEdit}
              />

              <Editing allowDeleting={allowEdit} allowAdding={allowEdit} />
            </DataGrid>
            {allowEdit && (
              <Button
                primary
                className="approveAllBtn"
                onClick={() => {
                  setOpenApproveAllToolkits(true);
                }}
              >
                Change all
              </Button>
            )}
          </Body>
        </>
      )}

      {openAddToolkit && (
        <DialogAddToolkit
          open={openAddToolkit}
          handleClose={() => {
            setOpenAddToolkit(false);
            // eslint-disable-next-line no-unused-expressions
            projectToolkits?.reload();
          }}
        />
      )}

      {openEditToolkit && (
        <DialogEditToolkit
          open={openEditToolkit}
          handleClose={() => {
            setOpenEditToolkit(false);
            // eslint-disable-next-line no-unused-expressions
            projectToolkits?.reload();
          }}
          idEditToolkit={idToolkit}
        />
      )}

      {openApproveToolkit && (
        <DialogConfirmApproveToolkit
          open={openApproveToolkit}
          handleClose={() => {
            setOpenApproveToolkit(false);
            // eslint-disable-next-line no-unused-expressions
            projectToolkits?.reload();
          }}
          statusOptions={filteredStatus}
          idToolkit={idApproveToolkit}
          idProject={Number(id)}
        />
      )}

      {openApproveAllToolkits && (
        <DialogConfirmApproveAllToolkits
          open={openApproveAllToolkits}
          handleClose={() => {
            setOpenApproveAllToolkits(false);
            // eslint-disable-next-line no-unused-expressions
            projectToolkits?.reload();
          }}
          statusOptions={filteredStatus}
          idProject={Number(id)}
        />
      )}
    </Container>
  );
};
