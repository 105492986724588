import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { WhisperSpinner } from 'react-spinners-kit';
import { IconButton } from '@material-ui/core';
import { FiX } from 'react-icons/fi';
import api from '../../../services/api';
import { Button } from '../../../components/Button';
import { TagBox } from '../../../components/TagBox';
import { ContainerLoading } from '../DashboardModal/styles';
import { useToast } from '../../../hooks/toast';
import { IProject, IProjectTeam } from '../../../services/master';

interface DialogCustomProps {
  id: number;
  open: boolean;
  handleClose(): void;
}

export const DialogGrantAccessOnePage: React.FC<DialogCustomProps> = ({
  id,
  open,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [projectTeam, setProjectTeam] = useState<IProjectTeam[]>();
  const [permittedProfessionals, setPermittedProfessionals] = useState<
    IProjectTeam[]
  >([]);

  const loadProjectInfo = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get<IProject>(`/api/projects/${id}`);
    setProjectTeam(data.projectTeam);
    setPermittedProfessionals(data.projectTeam?.filter(x => x.isOnePageAllow));
    setLoading(false);
  }, [id]);

  useEffect(() => {
    loadProjectInfo();
  }, [loadProjectInfo]);

  const handleChangeAccess = useCallback(
    async idProjectTeam => {
      setLoading(true);
      try {
        await api.patch(
          `/api/projects/${id}/project-team/grant/${idProjectTeam}`,
        );
        addToast({
          type: 'success',
          title: 'Saved',
          description: '',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          description: '',
        });
      }
      setLoading(false);
      loadProjectInfo();
    },
    [addToast, id, loadProjectInfo],
  );

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
          Grant Access
          <IconButton onClick={handleClose}>
            <FiX size={20} />
          </IconButton>
        </span>
      </DialogTitle>
      <DialogContent>
        {loading && (
          <ContainerLoading>
            <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
          </ContainerLoading>
        )}
        {!loading && (
          <DialogContentText>
            <p>Who can access One-Page?</p>
            <TagBox
              dataSource={projectTeam}
              value={permittedProfessionals}
              displayExpr="professional.name"
              valueExpr="id"
              onAdd={e => handleChangeAccess(e)}
              onRemove={e => handleChangeAccess(e)}
            />
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button primary onClick={handleClose}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
