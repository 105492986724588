import {
  Backdrop,
  Box,
  Dialog,
  DialogContent,
  Fade,
  Modal,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Column,
  DataGrid,
  Editing,
  Form,
  HeaderFilter,
  Lookup,
  Paging,
} from 'devextreme-react/data-grid';

import { FiX } from 'react-icons/fi';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { useParams } from 'react-router';
import { Popup } from 'devextreme-react';
import { Item } from 'devextreme-react/form';
import { WhisperSpinner } from 'react-spinners-kit';
import { BoardBody } from '../../../components/BoardBody';

import { Body, Container, ContainerLoading } from './styles';
import api from '../../../services/api';
import master, { CustomStoreProps } from '../../../services/master';

interface DialogCustomProps {
  openStakeholderModalPage: boolean;
  handleClose(): void;
  idSession: number;
}

export const StakeholderModalPage: React.FC<DialogCustomProps> = ({
  openStakeholderModalPage,
  handleClose,
  idSession,
}) => {
  const [stakeholder, setStakeholder] = useState<DataSource>();
  const [filteredProfessionals, setFilteredProfessionals] =
    useState<DataSource>();
  const [loading, setLoading] = useState(false);
  const [stakeholderSubmitted, setStakeholderSubmitted] = useState('');
  const { id } = useParams<{
    id: string;
  }>();

  const projectSessionInformations = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/api/projects/${id}/sessions/${idSession}`);
    setStakeholderSubmitted(data.submittedAt);
    setLoading(false);
    return data;
  }, [id, idSession]);

  const loadStakeholders = useCallback(async () => {
    setLoading(true);
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `/api/project/${id}/stakeholders/sessions/${idSession}`,
        );
        return data.sort((a: any, b: any) => {
          if (a.createAt > b.createAt) {
            return -1;
          }
          if (a.createAt < b.createAt) {
            return 1;
          }
          return 0;
        });
      },
      insert: async data => {
        await api.post(
          `/api/project/${id}/stakeholders/sessions/${idSession}`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/project/${id}/stakeholders/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project/${id}/stakeholders/${key}`);
      },
    });
    setStakeholder(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
    setLoading(false);
  }, [id, idSession]);

  const loadProjectInfo = useCallback(async () => {
    const { data } = await api.get(`/api/projects/${id}`);
    const professionalResponse = await master.get('/master/professionals', {
      params: {
        where: {
          IsAnyStatus: true,
        },
      },
    });

    const projectTeamIds = data.projectTeam.map((x: any) => {
      return x.professional?.id;
    });

    setFilteredProfessionals(
      professionalResponse.data
        .map((p: any) => {
          return { ID: p.ID, Name: p.Name };
        })
        .filter((x: any) => projectTeamIds.includes(x.ID)),
    );

    return data;
  }, [id]);

  const [languages] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('master/languages');
        return data.sort((a: any, b: any) => {
          if (a.Name < b.Name) {
            return -1;
          }
          if (a.Name > b.Name) {
            return 1;
          }
          return 0;
        });
      },
    }),
    paginate: true,
  });

  useEffect(() => {
    loadStakeholders();
    loadProjectInfo();
    projectSessionInformations();
  }, [loadStakeholders, loadProjectInfo, projectSessionInformations]);

  // console.log(stakeholderSubmitted);

  const currentStatus = [
    {
      statusId: 1,
      status: 'Green',
    },
    {
      statusId: 2,
      status: 'Yellow',
    },
    {
      statusId: 3,
      status: 'Red',
    },
    {
      statusId: 4,
      status: 'Blue',
    },
    {
      statusId: 5,
      status: 'Legacy',
    },
  ];

  const statusCell = useCallback(e => {
    if (e.value === 1) {
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#2ecc71',
          }}
        />
      );
    }
    if (e.value === 2) {
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#F1C40F',
          }}
        />
      );
    }
    if (e.value === 3) {
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#e74c3c',
          }}
        />
      );
    }
    if (e.value === 4) {
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#2980b9',
          }}
        />
      );
    }
    if (e.value === 5) {
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#bdc3c7',
          }}
        />
      );
    }

    return '';
  }, []);

  return (
    <>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}
      <Container>
        <h2>Stakeholder</h2>
        <DataGrid
          dataSource={stakeholder}
          onRowUpdating={options => {
            options.newData = {
              ...options.oldData,
              ...options.newData,
            };
          }}
        >
          <HeaderFilter visible />
          <Column caption="Name" dataField="name" sortOrder="asc" />
          <Column caption="Responsible" dataField="idProfessionalResponsible">
            <Lookup
              dataSource={filteredProfessionals}
              displayExpr="Name"
              valueExpr="ID"
            />
          </Column>
          <Column caption="Position" dataField="position" />
          <Column
            caption="Birthday"
            dataField="birthday"
            dataType="date"
            format="dd MMM yyyy"
          />
          <Column caption="Language" dataField="language">
            <Lookup dataSource={languages} displayExpr="Name" valueExpr="ID" />
          </Column>
          <Column
            caption="Status"
            dataField="currentStatus"
            alignment="center"
            cellRender={statusCell}
          >
            <Lookup
              dataSource={currentStatus}
              valueExpr="statusId"
              displayExpr="status"
            />
          </Column>
          <Column caption="Action Plan" dataField="actionPlan" />
          <Paging pageSize={8} />

          <Editing
            mode="popup"
            allowAdding={!stakeholderSubmitted}
            allowUpdating={!stakeholderSubmitted}
            allowDeleting={!stakeholderSubmitted}
          >
            <Popup />
            <Form>
              <Item dataField="name" colSpan={2} />
              <Item itemType="group" colCount={2} colSpan={2}>
                <Item
                  dataField="idProfessionalResponsible"
                  colSpan={1}
                  editorType="dxSelectBox"
                />
                <Item dataField="position" colSpan={1} />
                <Item dataField="birthday" editorType="dxDateBox" colSpan={1} />
                <Item
                  dataField="language"
                  editorType="dxSelectBox"
                  colSpan={1}
                />
                <Item
                  dataField="currentStatus"
                  editorType="dxSelectBox"
                  colSpan={1}
                />
                <Item
                  dataField="actionPlan"
                  editorType="dxTextArea"
                  colSpan={1}
                />
              </Item>
            </Form>
          </Editing>
        </DataGrid>
      </Container>
    </>
  );
};
