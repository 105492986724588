import styled from 'styled-components';

export const Container = styled.div`
  & > .dx-texteditor.dx-editor-outlined {
    background-color: #928989;
    box-shadow: none;
  }

  & > .dx-textbox {
    line-height: 0;
    font-size: 14px;

    input {
      padding: 10px 10px 9px !important;
      font-size: 14px;
    }
  }
`;

interface TextAreaProps {
  disabled?: boolean;
}
export const Input = styled.input<TextAreaProps>`
  border-radius: 7px;
  padding: 10px;
  font-size: 14px;
  background-color: ${props => (props.disabled ? '#ddd' : 'white')};
  color: ${props => (props.disabled ? '#666' : '#333')};
  font-family: 'Roboto Condensed', sans-serif;
  width: 100%;
  font-size: 14px;
  border: 1px #e5e5e5 solid;

  box-shadow: 0 0 0px rgba(0 0 0 / 7%);
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    box-shadow: 0 0 15px rgba(0 0 0 / 20%);
    outline: none;
  }
`;
