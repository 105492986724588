import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-left: 24px;
`;

export const CloseModalButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FloatsContainer = styled.div`
  margin-top: 15px;
  gap: 15px;
  display: flex;
  justify-content: space-between;

  .float {
    width: 25%;
  }

  .square {
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #f6f6f6;
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-shadow: 0px 1px 3px #333;
    border-left: 5px solid #8b0305;

    .title {
      color: #8b0305;
      font-weight: 700;
      text-transform: uppercase;
    }

    .progressContainer {
      display: flex;
      gap: 5px;

      .percentageNumber {
        color: #666;
        font-size: 20px;
        font-weight: 700;
      }

      .backBar {
        background-color: #bababa;
        border-radius: 10px;
        width: 60%;
        height: 20px;
        align-self: center;
      }
    }
  }
`;

export const AllRitualsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  .ritualContainer {
    width: 49%;
    height: 110px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #f6f6f6;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    box-shadow: 0px 1px 3px #333;
    border-left: 5px solid #8b0305;

    .title {
      color: #8b0305;
      font-weight: 700;
      text-transform: uppercase;
    }

    .lastDate,
    .nextDate {
      font-weight: 700;
    }

    .status {
      color: #333;
      font-weight: 700;
      font-size: 20px;
    }

    .number {
      color: #333;
      font-weight: 700;
      font-size: 20px;
    }
  }
`;

export const AllOnePagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  div {
    display: flex;
    gap: 5px;
  }

  .ritualContainer {
    width: 49%;
    height: 110px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #f6f6f6;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    box-shadow: 0px 1px 3px #333;
    border-left: 5px solid #8b0305;

    .title {
      color: #8b0305;
      font-weight: 700;
      text-transform: uppercase;
    }

    .lastDate,
    .nextDate {
      font-weight: 700;
    }

    .status {
      color: #333;
      font-weight: 700;
      font-size: 20px;
    }

    .number {
      color: #333;
      font-weight: 700;
      font-size: 20px;
    }
  }
`;

interface IProgressProps {
  value: number;
}

export const ProgressBar = styled.div<IProgressProps>`
  background-color: #8b0304;
  height: inherit;
  border-radius: 10px;

  ${props =>
    css`
      width: ${props.value}%;
      max-width: 100% !important;
    `}
`;

export const SectionTitle = styled.h1`
  font-weight: 700;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const ContainerLoading = styled.div`
  z-index: 9999;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;
