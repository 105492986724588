import React, { Dispatch, ReactNode, SetStateAction } from 'react';

const MethodologyContext = React.createContext<any>(null);

// Create a provider component
export function MethodologyProvider({ children }: { children: ReactNode }) {
  const [methodology, setMethodology] = React.useState(null);

  return (
    <MethodologyContext.Provider value={{ methodology, setMethodology }}>
      {children}
    </MethodologyContext.Provider>
  );
}

// Create a hook to use the context
export function useMethodology() {
  const context = React.useContext(MethodologyContext);
  if (context === null) {
    throw new Error('useMethodology must be used within a MethodologyProvider');
  }
  return context;
}
