import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { WhisperSpinner } from 'react-spinners-kit';
import api from '../../../services/api';
import { Button } from '../../../components/Button';
import { useToast } from '../../../hooks/toast';
import { IProject } from '../../../services/master';

interface DialogCustomProps {
  id: number;
  open: boolean;
  handleClose(): void;
  handleCloseAndReload(): void;
  statusId: number;
  project: IProject;
}

export const DialogConfirmChangeProjectStatus: React.FC<DialogCustomProps> = ({
  id,
  open,
  handleClose,
  handleCloseAndReload,
  statusId,
  project,
}) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  //
  const changeProjectStatus = useCallback(async () => {
    setLoading(true);
    try {
      await api.put(`/api/projects/${id}/status`, {
        idStatus: statusId,
      });
      addToast({
        type: 'success',
        title: 'Project status changed successfully.',
        description: '',
      });
    } catch (e) {
      addToast({
        type: 'error',
        title:
          'Unable to change status. Verify every ritual has been done correctly, or try contacting IT.',
        description: '',
      });
    }
    setLoading(false);
    handleCloseAndReload();
  }, [addToast, handleCloseAndReload, id, statusId]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        Confirm Project Status Change
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to change this project&apos;s status?
        </DialogContentText>
        {statusId === 3 && project.missingAssessmentNames !== '' && (
          <div
            style={{
              textAlign: 'center',
              border: '1px solid #ff000085',
              backgroundColor: '#ff00004d',
              marginTop: '20px',
              padding: '10px',
            }}
          >
            <p style={{ fontWeight: 700 }}>
              {project.missingAssessmentNames} is/are missing their assessments.
            </p>
            <p style={{ fontWeight: 500 }}>
              Please guarantee they have fulfilled their assessments. Once they
              have fullfilled, please refresh this page and try again.
            </p>
            <p style={{ fontWeight: 700, marginTop: 15, fontSize: 14 }}>
              NOTE: ALL Interns, Analysts, Consultants and Specialists must
              fullfill their assessments in order to advance to During.
            </p>
          </div>
        )}
        {statusId === 5 && (
          <DialogContentText id="alert-dialog-description">
            You&lsquo;re trying to close this project. Knowledge Management will
            receive an e-mail message requesting to close it.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          primary
          onClick={changeProjectStatus}
          autoFocus
          disabled={
            loading || (project.missingAssessmentNames !== '' && statusId === 3)
          }
        >
          Confirm{' '}
          {loading && (
            <WhisperSpinner size={15} backColor="#fff" frontColor="#fff" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
