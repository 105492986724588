import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material';

import { Column, Lookup } from 'devextreme-react/data-grid';

import { DataGrid } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { FiX } from 'react-icons/fi';
import { Button } from '../../../../components/Button';
import { Body } from './styles';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import master, { CustomStoreProps } from '../../../../services/master';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
  idSetupAssessment: number;
}

export const DialogSetupAssessmentDetails: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  idSetupAssessment,
}) => {
  const [projectAssessment, setProjectsAssessment] = useState<DataSource>();

  const loadProjects = useCallback(async () => {
    const projectStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `api/project-setup-assessments/project/${idSetupAssessment}/all`,
        );
        return data;
      },
    });

    setProjectsAssessment(
      new DataSource({
        store: projectStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [idSetupAssessment]);

  const [professionals] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } =await master.get('/master/professionals', {
          params: {
            where: {
              IsAnyStatus: true,
            },
          },
        });
        return data;
      },
    }),
    paginate: true,
  });

  useEffect(() => {
    loadProjects();
  }, [loadProjects]);

  const textCells = useCallback(e => {
    return (
      <p
        style={{
          fontWeight: '600',
          textTransform: 'uppercase',
          letterSpacing: '0.05em',
        }}
      >
        {e.text}
      </p>
    );
  }, []);

  return (
    <Dialog open={open} fullWidth fullScreen onClose={handleClose}>
      <DialogTitle id="form-dialog-title">
        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
          Setup Assessment
          <IconButton onClick={handleClose}>
            <FiX size={20} />
          </IconButton>
        </span>
      </DialogTitle>
      <Body>
        <hr />
        <DataGrid
          style={{ marginBottom: '10px' }}
          className="grid"
          dataSource={projectAssessment}
        >
          <Column
            caption="Created by"
            dataField="idCreatedBy"
            width={150}
            cellRender={textCells}
            alignment="center"
          >
            <Lookup
              dataSource={professionals}
              displayExpr="Name"
              valueExpr="ID"
            />
          </Column>
          <Column
            dataField="idUpdatedBy"
            caption="Updated By"
            cellRender={textCells}
            alignment="center"
          >
            <Lookup
              dataSource={professionals}
              displayExpr="Name"
              valueExpr="ID"
            />
          </Column>
          <Column
            dataField="watchedToolkit"
            caption="WatchedToolkit"
            cellRender={textCells}
            alignment="center"
          />
          <Column
            dataField="doYouFeelComfortable"
            caption="Do you feel comfortable"
            cellRender={textCells}
            alignment="center"
          />
          <Column
            dataField="rating"
            caption="Rating"
            cellRender={textCells}
            alignment="center"
          />
          <Column
            dataField="ratingDescription"
            caption="Rating Description"
            cellRender={textCells}
            alignment="left"
          />
          <Column
            dataField="howCouldBeenDoneBetter"
            caption="How Could Been Done Better"
            cellRender={textCells}
            alignment="left"
          />
          <Column
            dataField="createdAt"
            caption="Created At"
            cellRender={textCells}
            dataType="date"
            alignment="center"
          />
          <Column
            dataField="updatedAt"
            caption="Updated At"
            cellRender={textCells}
            dataType="date"
            alignment="center"
          />
        </DataGrid>
      </Body>
    </Dialog>
  );
};
