import axios from 'axios';

const UNAUTHORIZED = 401;
const FORBBIDEN = 403;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const { status } = error.response;
      if (status === UNAUTHORIZED || status === FORBBIDEN) {
        localStorage.clear();
        window.location.href = '/';
      }
    }

    return Promise.reject(error);
  },
);
api.defaults.headers.common.Authorization = localStorage.getItem('accessToken');

export default api;

export const Permissions = {
  Admin: 'API.Portal.Admin',
};

export type IWeeklySupervision = {
  clientName: string;
  idClient: number;
  projectCode: string;
  projectName: string;
  idStatus: number;
  onePageProblemLevel: number;
  onePageSubmitted: number;
  idProject: number;
  internal: boolean;
  priority: boolean;
  idCoManagement: number;
  coManagementName: string;
};

export type IProfessionalProjectEvaluation = {
  id: number;
  cycle: ICycle;
  evaluationStatus: IEvaluationStatus;
};

export type IEvaluationStatus = {
  id: number;
  description: string;
};

export type ICycle = {
  id: number;
  period: number;
  year: number;
  isActive: boolean;
};

export type IAllocationSupervision = {
  clientName: string;
  idClient: number;
  projectCode: string;
  projectName: string;
  idStatus: number;
  onePageSubmitted: boolean;
  idProject: number;
  idPractice: number;
  practiceName: string;
  hasAllocation: boolean;
  hasGovernanceAction: boolean;
};

export type IPDCSupervision = {
  clientName: string;
  idClient: number;
  projectCode: string;
  projectName: string;
  idStatus: number;
  onePageSubmitted: boolean;
  idProject: number;
  idPractice: number;
  practiceName: string;
  hasAllocation: boolean;
  hasGovernanceAction: boolean;
};

export type IProjectKPI = {
  onePageSubmitted: boolean;
  weeklyStatusSubmitted: boolean;
  hasProfessionalDNA: boolean;
  hasTechDNA: boolean;
  hasIDOne: boolean;
};

export type SignType = 1 | 2 | 3 | null;

export type IWeeklyStatus = {
  id: number;
  signedStatus: string;
  signedType: SignType;
  signatureFilename: string;
  isSubmitted: boolean;
  unSignatureFilename: string;
};

export type IWeeklyStatusRecipient = {
  id: number;
  isActive: boolean;
  name: string;
  email: string;
  signedCode: string;
};

export type IProjectClosureExternalRecipient = {
  id: number;
  isActive: boolean;
  name: string;
  email: string;
  signedCode: string;
};

export type IProjectClosureExternal = {
  id: number;
  signedStatus: string;
  signedType: SignType;
};

export type IProjectToolkit = {
  id: number;
  idStatus?: number;
  idToolkit: number;
  idProject: number;
};
