import styled from 'styled-components';

export const ContainerLoading = styled.div`
  z-index: 9;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;

  .fqqBnr .header-name h1 {
    font-weight: bold;
    text-transform: uppercase;
  }

  .fqqBnr .header-name p {
    text-transform: uppercase;
  }

  .tabsContainer {
    width: 95%;
    margin: 0 auto;

    .dx-tab {
      width: 150px;
      font-size: 12px;
    }

    .dx-tab-text {
      line-height: 15px;
      white-space: normal;
    }

    .dx-tab:not(:last-child) {
      border-right: 10px solid #f5f5f5;
    }

    .content {
      display: flex;
      background-color: white;
      padding: 20px;

      span {
        text-align: center;
        width: 70%;
        margin: 0 auto;

        h1 {
          text-transform: uppercase;
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }

        p {
          font-size: 13px;
        }
      }

      img {
        width: 90px;
        height: 75px;
        align-self: center;
      }
    }
  }

  .span-style {
    margin: 0 auto;
    display: content;
    align-items: center;
    gap: 10px;

    h1 {
      font-weight: bold;
      color: #bbbbbb;
      font-size: 14px;
    }
  }

  .inputArea {
    margin: 15px 0;
    display: flex;
    gap: 15px;
    align-items: flex-start;

    p {
      color: #999999;
      font-weight: 700;
      text-align: right;
      max-width: 95px;
      min-width: 95px;
    }

    div {
      width: 100%;

      input {
        height: 150px;
      }
    }

    hr {
      width: 100%;
      height: 1px;
    }
  }

  .accordion {
    margin: 15px auto 15px auto !important;
    background-color: #bbbbbb;

    .header {
      .MuiAccordionSummary-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      color: white;

      span {
        display: contents !important;
        flex-direction: column;
      }

      p {
        font-weight: bold;
        text-align: center;
      }
    }

    .MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .content {
      background-color: white;

      .multipleFields {
        display: flex;
        gap: 15px;
      }

      .smallTextField {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        div {
          display: flex;
        }

        p,
        small {
          color: #999999;
        }

        small {
          font-size: 12px;
        }

        .Mui-checked {
          color: #8b0304;
        }

        .MuiTypography-root {
          white-space: nowrap;
          margin-left: 0;
        }
      }
    }
  }
`;
