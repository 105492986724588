import React, { useCallback, useEffect, useState } from 'react';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { DataGrid, Lookup } from 'devextreme-react';
import {
  Column,
  Scrolling,
  Paging,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import { IconButton, Tooltip } from '@material-ui/core';
import { FiExternalLink } from 'react-icons/fi';
import { WhisperSpinner } from 'react-spinners-kit';
import { Container, Body, ContainerLoading, WeeklyLevel } from './styles';
// import { DialogEdit } from './DialogEdit';
import api, { IPDCSupervision } from '../../../services/api';
// import { useToast } from '../../../hooks/toast';

export const PDCSupervision: React.FC = () => {
  // const { addToast } = useToast();
  const [projects, setProjects] = useState<DataSource>({} as DataSource);
  const [loading, setLoading] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true);
  // const [excelLoading, setExcelLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [green, setGreen] = useState(0);
  const [yellow, setYellow] = useState(0);
  const [red, setRed] = useState(0);
  const [notSubmitted, setNotSubmitted] = useState(0);
  // const [openEdit, setOpenEdit] = useState(false);
  // const [editId, setEditId] = useState(0);

  const fetchData = useCallback(async () => {
    const projectStore = new CustomStore({
      key: 'idProject',
      loadMode: 'raw',
      load: async () => {
        setLoading(true);
        const response = await api.get<IPDCSupervision[]>(
          '/api/pdc-supervision',
        );
        setTotal(response.headers['x-total'] || 0);
        setGreen(response.headers['x-total-green'] || 0);
        setYellow(response.headers['x-total-yellow'] || 0);
        setRed(response.headers['x-total-red'] || 0);
        setNotSubmitted(response.headers['x-total-notsubmitted'] || 0);
        setLoading(false);
        setFirstLoading(false);

        return response.data;
      },
      update(key, values) {
        return api.patch(`/api/projects/${key}`, values);
      },
    });

    setProjects(
      new DataSource({
        store: projectStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const textCells = useCallback(e => {
    return (
      <p
        style={{
          fontWeight: '600',
          textTransform: 'uppercase',
          letterSpacing: '0.05em',
        }}
      >
        {e.text}
      </p>
    );
  }, []);

  // const handleExport = useCallback(async () => {
  //   try {
  //     addToast({
  //       title: 'We are generating your report. It could take a while',
  //       type: 'info',
  //     });
  //     setExcelLoading(true);

  //     const response = await api.get(`api/pdc-supervision/export`, {
  //       responseType: 'blob',
  //     });

  //     const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

  //     const link = document.createElement('a');

  //     link.href = downloadUrl;
  //     link.setAttribute('download', `report-all-pdc.xlsx`);
  //     document.body.appendChild(link);
  //     link.click();
  //     link.remove();

  //     addToast({
  //       type: 'success',
  //       title: 'Your report is ready. Open or save it below',
  //     });
  //   } catch {
  //     addToast({
  //       type: 'error',
  //       title: 'Something went wrong with your report. Try again please.',
  //     });
  //   }

  //   setExcelLoading(false);
  // }, [addToast]);

  const buttonsCell = useCallback(e => {
    return (
      <div style={{ display: 'flex' }}>
        <Tooltip title="Open Project">
          <IconButton
            onClick={() => {
              window.open(`/project/${e.key}/home`);
            }}
          >
            <FiExternalLink size={20} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }, []);

  const weeklyLevelCell = useCallback(
    e => <WeeklyLevel level={e.value || 0} />,
    [],
  );

  return (
    <>
      {firstLoading && loading && (
        <ContainerLoading>
          <WhisperSpinner size={50} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}
      <Container>
        <Body>
          <div className="header">
            <h1>PDC Supervision</h1>
            {/* <Button primary onClick={handleExport} loading={excelLoading}>
              Export Excel
            </Button> */}
          </div>
          <hr />
          <div className="kpi">
            <div className="totalProjects">
              <p>PROJECTS</p>
              <p>{total}</p>
            </div>
            <div className="onePage">
              <p>ONE-PAGE</p>
              <hr />
              <div className="statuses">
                <div style={{ borderTop: '0.25rem solid #808080' }}>
                  <p>NOT SUBMITTED</p>
                  <p>{notSubmitted}</p>
                </div>
                <div style={{ borderTop: '0.25rem solid #2e7d32' }}>
                  <p>GREEN</p>
                  <p>{green}</p>
                </div>
                <div style={{ borderTop: '0.25rem solid #F7D900' }}>
                  <p>YELLOW</p>
                  <p>{yellow}</p>
                </div>
                <div style={{ borderTop: '0.25rem solid #D60000' }}>
                  <p>RED</p>
                  <p>{red}</p>
                </div>
              </div>
            </div>
          </div>
          <DataGrid
            className="grid"
            dataSource={projects}
            allowColumnResizing
            editing={{ allowUpdating: true }}
          >
            <HeaderFilter visible allowSearch />
            <Column
              dataField="projectCode"
              caption="Project Code"
              cellRender={textCells}
              allowEditing={false}
            />
            <Column
              dataField="projectName"
              caption="Project Name"
              allowEditing={false}
              cellRender={textCells}
            />
            <Column
              dataField="clientName"
              caption="Client Name"
              allowEditing={false}
              cellRender={textCells}
            />
            <Column
              dataField="statusName"
              caption="Project Status"
              allowEditing={false}
              cellRender={textCells}
            />
            <Column
              dataField="practiceName"
              caption="Practice"
              allowEditing={false}
              cellRender={textCells}
            />
            <Column
              dataField="businessUnitName"
              caption="Business Unit"
              allowEditing={false}
              cellRender={textCells}
            />
            <Column dataField="pdcPriority" dataType="boolean" allowEditing />
            <Column
              dataField="onePageSubmitted"
              dataType="boolean"
              allowEditing={false}
            />
            <Column
              dataField="onePageProblemLevel"
              caption="OP Status"
              allowEditing={false}
              cellRender={weeklyLevelCell}
            >
              <Lookup
                dataSource={[
                  { id: 0, color: 'Not Submitted' },
                  { id: 1, color: 'Green' },
                  { id: 2, color: 'Yellow' },
                  { id: 3, color: 'Red' },
                ]}
                valueExpr="id"
                displayExpr="color"
              />
            </Column>
            <Column
              dataField="environmentStatus"
              cellRender={weeklyLevelCell}
              allowEditing={false}
            >
              <Lookup
                dataSource={[
                  { id: 1, color: 'Green' },
                  { id: 2, color: 'Yellow' },
                  { id: 3, color: 'Red' },
                ]}
                valueExpr="id"
                displayExpr="color"
              />
            </Column>
            <Column
              type="button"
              cellRender={buttonsCell}
              allowEditing={false}
            />

            <Paging enabled={false} />
            <Scrolling mode="infite" />
          </DataGrid>
        </Body>
      </Container>

      {/* {openEdit && (
        <DialogEdit
          handleClose={() => {
            setOpenEdit(false);
            projects.reload();
          }}
          open={openEdit}
          projectId={editId}
        />
      )} */}
    </>
  );
};
