import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { TextBox, TextArea, SelectBox, DateBox } from 'devextreme-react';
import { useParams } from 'react-router';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Button } from '../../../../components/Button';
import { Container } from './styles';
import api from '../../../../services/api';
import { FormGroup } from '../../../../components/FormGroup';
import { useToast } from '../../../../hooks/toast';
import master, { CustomStoreProps } from '../../../../services/master';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
  idEditToolkit: number;
}

export const DialogEditToolkit: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  idEditToolkit,
}) => {
  const { id } = useParams<{
    id: string;
  }>();

  const { addToast } = useToast();

  const [idToolkit, setIdToolkit] = useState(0);
  const [idStatus, setIdStatus] = useState(0);
  const [toolkitLoaded, setToolkitLoaded] = useState({});

  const [toolkits] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('master/toolkits');
        const practiceResponse = await master.get('/master/practices');
        const dataWithPractice = data.map((t: any) => {
          return {
            ...t,
            fullName: `${
              practiceResponse.data.find(
                (p: any) => p.ID === t.Methodology?.IDPractice,
              )?.Name
            } - ${t.Name}`,
          };
        });
        return dataWithPractice.sort((a: any, b: any) => {
          if (a.Methodology?.IDPractice < b.Methodology?.IDPractice) {
            return -1;
          }
          if (a.Methodology?.IDPractice > b.Methodology?.IDPractice) {
            return 1;
          }
          return 0;
        });
      },
    }),
    paginate: true,
  });

  const loadToolkit = useCallback(async () => {
    const { data } = await api.get(
      `/api/project/${id}/toolkits/${idEditToolkit}`,
    );
    setToolkitLoaded(data);
    setIdToolkit(data.idToolkit);
    setIdStatus(data.idStatus);
  }, [id, idEditToolkit]);

  useEffect(() => {
    loadToolkit();
  }, [loadToolkit]);

  const handleEditToolkit = useCallback(async () => {
    try {
      await api.put(`/api/project/${id}/toolkits/${idEditToolkit}`, {
        ...toolkitLoaded,
        idToolkit,
        idStatus,
      });

      addToast({
        type: 'success',
        title: 'Added with success.',
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
    }
    handleClose();
  }, [
    handleClose,
    id,
    idEditToolkit,
    toolkitLoaded,
    idToolkit,
    idStatus,
    addToast,
  ]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">Toolkit</DialogTitle>
      <Container>
        <DialogContent className="dialog-content">
          <div>
            <FormGroup fieldSetLabel="Toolkit">
              <SelectBox
                value={idToolkit}
                onValueChange={e => setIdToolkit(e)}
                openOnFieldClick
                searchEnabled
                dataSource={toolkits}
                valueExpr="ID"
                displayExpr="fullName"
              />
            </FormGroup>
            <FormGroup fieldSetLabel="Status">
              <SelectBox
                value={idStatus}
                onValueChange={e => setIdStatus(e)}
                openOnFieldClick
                searchEnabled
                dataSource={[
                  {
                    id: 1,
                    name: 'Pending',
                  },
                  {
                    id: 2,
                    name: 'Approved',
                  },
                  {
                    id: 3,
                    name: 'Not Approved',
                  },
                ]}
                displayExpr="name"
                valueExpr="id"
              />
            </FormGroup>
          </div>
        </DialogContent>
      </Container>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleEditToolkit} primary>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
