import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 50px;
  .bigProgress {
    color: #8b0304;
    margin-left: 48%;
    margin-top: 20%;
    width: 60px !important;
    height: 60px !important;
  }

  .header {
    text-align: center;
    margin-bottom: 25px;

    h1 {
      font-size: 2rem;
      font-weight: 700;
      color: #333;
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 700;
      color: #666;
    }
  }
`;

export const ContainerLoading = styled.div`
  z-index: 9999;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

export const Body = styled.section`
  width: 100%;
  background-color: transparent;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;

  .card {
    background: white;
    box-shadow: 0 0 30px rgba(0 0 0 / 7%);
    padding: 2em;
    box-sizing: border-box;
    line-height: 1.25;
    width: 20%;
    text-align: center;
    transition: all 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
    overflow-wrap: break-word;

    &:hover {
      box-shadow: 0 0 50px rgba(0 0 0 / 15%);
      transition: all 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
      transform: scale(1.05);
    }

    h2 {
      font-weight: bold;
      color: #8b0304;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-size: 0.75rem;
      margin-bottom: 1em;
      text-align: center;
      line-height: 1;
    }

    svg {
      stroke: #8b0304;
      font-size: 25px;
    }
  }

  .team {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .userRow {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 10px;
      max-height: 300px;

      img {
        filter: brightness(90%);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        overflow: hidden;
        background-color: #f5f5f5;
      }
    }
  }
`;
