import styled, { css } from 'styled-components';

interface ButtonProps {
  primary?: boolean;
  secondary?: boolean;
  loading?: boolean;
}

export const Container = styled.button<ButtonProps>`
  outline: none;
  border: none;
  border-radius: 0;
  /* min-width: 100%; */
  font-weight: 700;
  font-size: 12px;
  color: #999999;
  background-color: #eaeaea;
  min-height: 28px;
  padding: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Roboto Condensed', sans-serif;
  display: flex;
  align-items: center;
  border-radius: 5px;

  & > div {
    margin-left: 10px;
  }

  ${props =>
    props.primary &&
    css`
      background-color: #8b0304;
      color: #fff;
    `}

  ${props =>
    props.secondary &&
    css`
      background: #8b8b8b;
      color: #fff;
    `}

  &:disabled {
    background-color: rgba(51, 51, 51, 0.2);
  }

  ${props =>
    props.loading &&
    css`
      cursor: default;
    `}
`;
