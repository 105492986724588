/* eslint-disable jsx-a11y/no-static-element-interactions */
import { IconButton } from '@material-ui/core';
import { DataGrid } from 'devextreme-react';
import { Column, Lookup } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiAlertCircle, FiFlag, FiSearch, FiX } from 'react-icons/fi';
import { useParams } from 'react-router';
import { format, parseISO } from 'date-fns';
import { WhisperSpinner } from 'react-spinners-kit';
import api from '../../../services/api';
import { Button } from '../../../components/Button';
import { Body, Container, ContainerLoading, HeaderContent } from './styles';
import { BoardHeader } from '../../../components/BoardHeader';
import { useAuth } from '../../../hooks/auth';
import master, { CustomStoreProps } from '../../../services/master';
import { DialogConfirmSubmitOrUndoOnePage } from '../DialogConfirmSubmitOrUndoOnePage';
import { DialogGrantAccessOnePage } from '../DialogGrantAccessOnePage';
import { DialogOnePage } from '../DialogOnePage';
import portalGroups from '../../../config/portalGroups';

export const OnePage: React.FC<{ title?: string }> = ({ title }) => {
  const { id } = useParams<{
    id: string;
  }>();
  const { hasScopes, user } = useAuth();

  const [openOnePageGrantAccessDialog, setOpenOnePageGrantAccessDialog] =
    useState(false);
  const [onePages, setOnePages] = useState<DataSource>();
  const [loading, setLoading] = useState(false);
  const [hasAccessOnePage, setHasAccessOnePage] = useState(false);
  const [isManagerOrPartner, setIsManagerOrPartner] = useState(false);
  const [onePageEditKey, setOnePageEditKey] = useState(0);
  const [openOnePageDialog, setOpenOnePageDialog] = useState(false);
  const [openConfirmSubmitOrUndoOnePage, setOpenConfirmSubmitOrUndoOnePage] =
    useState(false);

  const allowSubmit = useMemo(() => {
    return hasScopes([portalGroups.Admin]);
  }, [hasScopes]);

  const allowViewOnePage = useMemo(() => {
    return (
      hasScopes([portalGroups.Admin, portalGroups.OnePage, portalGroups.PDC]) ||
      hasAccessOnePage ||
      isManagerOrPartner
    );
  }, [hasAccessOnePage, hasScopes, isManagerOrPartner]);

  const loadOnePages = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(`api/one-pages/project/${id}`);
        return data.sort((a: any, b: any) => {
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          if (a.createdAt < b.createdAt) {
            return 1;
          }
          return 0;
        });
      },
    });

    setOnePages(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id]);

  const hasAccessOnePageFunction = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/api/projects/${id}`);

    const professionalAccess = data.projectTeam.find(
      (x: any) => x.professional?.id === user.userId,
    );

    if (
      (professionalAccess && professionalAccess.isOnePageAllow) ||
      allowSubmit
    ) {
      setHasAccessOnePage(true);
    }
    if (
      professionalAccess &&
      (professionalAccess.isManager || professionalAccess.isPartner)
    ) {
      setIsManagerOrPartner(true);
    }
    setLoading(false);
  }, [id, allowSubmit, user]);

  const [professionals] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/professionals', {
          params: {
            where: {
              IsAnyStatus: true,
            },
          },
        });
        return data;
      },
    }),
    paginate: true,
  });

  useEffect(() => {
    hasAccessOnePageFunction();
    loadOnePages();
  }, [hasAccessOnePageFunction, loadOnePages]);

  const fromToCell = useCallback(e => {
    return (
      <p>
        {format(parseISO(e.data.initialDate), 'dd MMM yyyy')}
        <br />
        {format(parseISO(e.data.endDate), 'dd MMM yyyy')}
      </p>
    );
  }, []);

  const onePageCell = useCallback(
    e => {
      return (
        <IconButton
          onClick={() => {
            setOnePageEditKey(e.key);
            setOpenOnePageDialog(true);
          }}
          disabled={!allowViewOnePage}
        >
          <FiSearch size={20} />
        </IconButton>
      );
    },
    [allowViewOnePage],
  );

  const submitOnePageCell = useCallback(
    e => {
      return (
        e.data.submittedAt &&
        allowSubmit && (
          <div style={{ margin: '0 auto', width: 'fit-content' }}>
            <Button
              style={{ width: '8em', justifyContent: 'center' }}
              secondary
              onClick={() => {
                setOnePageEditKey(e.key);
                setOpenConfirmSubmitOrUndoOnePage(true);
              }}
            >
              Undo
            </Button>
          </div>
        )
      );
    },
    [allowSubmit],
  );

  const onePageIsDelayedCell = useCallback(e => {
    return e.value === true && <FiAlertCircle size={25} color="#f1c40f" />;
  }, []);

  return (
    <>
      <HeaderContent>
        <BoardHeader title="DURING" subtitle="" />
      </HeaderContent>
      <Container>
        {loading && (
          <ContainerLoading>
            <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
          </ContainerLoading>
        )}
        <span
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <h1
            style={{
              textTransform: 'uppercase',
              color: '#6c6c6c',
              fontSize: '24px',
              fontWeight: 700,
              letterSpacing: '0.1em',
            }}
          >
            One-Page
          </h1>
          {(isManagerOrPartner || hasScopes([portalGroups.Admin, portalGroups.OnePage])) && (
            <div>
              <Button
                primary
                onClick={() => setOpenOnePageGrantAccessDialog(true)}
              >
                Grant access
              </Button>
            </div>
          )}
        </span>
      </Container>
      <div className="client">
        <div style={{ display: 'flex' }} />
        <Body>
          <DataGrid dataSource={onePages}>
            <Column
              caption="One-Page Week (From-To)"
              cellRender={fromToCell}
              alignment="center"
            />
            <Column
              dataField="createdAt"
              dataType="date"
              format="dd MMM yyyy"
              alignment="center"
            />
            <Column
              dataField="idSubmittedBy"
              caption="Submitted By"
              alignment="center"
            >
              <Lookup
                dataSource={professionals}
                displayExpr="Name"
                valueExpr="ID"
              />
            </Column>
            <Column
              dataField="submittedAt"
              dataType="date"
              format="dd MMM yyyy"
              alignment="center"
            />
            <Column
              dataField="onePage"
              cellRender={onePageCell}
              alignment="center"
            />
            <Column
              dataField="submit"
              cellRender={submitOnePageCell}
              alignment="center"
              visible={allowSubmit || false}
            />
            <Column
              caption="Delayed Submit"
              dataField="isDelayedSubmitted"
              cellRender={onePageIsDelayedCell}
              alignment="center"
            />
          </DataGrid>
        </Body>
      </div>

      {openConfirmSubmitOrUndoOnePage && (
        <DialogConfirmSubmitOrUndoOnePage
          open={openConfirmSubmitOrUndoOnePage}
          handleClose={() => {
            setOpenConfirmSubmitOrUndoOnePage(false);
            // eslint-disable-next-line no-unused-expressions
            onePages?.reload();
          }}
          id={onePageEditKey}
        />
      )}

      {openOnePageGrantAccessDialog && (
        <DialogGrantAccessOnePage
          open={openOnePageGrantAccessDialog}
          handleClose={() => {
            setOpenOnePageGrantAccessDialog(false);
            // eslint-disable-next-line no-unused-expressions
            onePages?.reload();
          }}
          id={parseInt(id, 10)}
        />
      )}

      {openOnePageDialog && (
        <>
          <div
            className="customDialogBG"
            onMouseUp={() => setOpenOnePageDialog(false)}
          />
          <div className="customDialog">
            <FiX
              className="closeButton"
              onMouseUp={() => setOpenOnePageDialog(false)}
            />
            <DialogOnePage
              handleClose={() => {
                setOpenOnePageDialog(false);
                // eslint-disable-next-line no-unused-expressions
                onePages?.reload();
              }}
              id={onePageEditKey}
              projectId={Number(id)}
            />
          </div>
        </>
      )}
    </>
  );
};
