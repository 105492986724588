import { DataGrid } from 'devextreme-react';
import {
  Column,
  Editing,
  Form,
  HeaderFilter,
  Lookup,
  Popup,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { WhisperSpinner } from 'react-spinners-kit';
import { Item } from 'devextreme-react/form';
import api from '../../../services/api';
import master from '../../../services/master';
import { Body, Container } from './styles';
import { BoardHeader } from '../../../components/BoardHeader';
import { ContainerLoading } from '../ClientTab/styles';
import portalGroups from '../../../config/portalGroups';
import { useAuth } from '../../../hooks/auth';

export const ProfessionalDNATab: React.FC<{ title?: string }> = ({ title }) => {
  const { id } = useParams<{
    id: string;
  }>();
  const { hasScopes } = useAuth();
  const isAdmin = useMemo(() => {
    return hasScopes([portalGroups.Admin]);
  }, [hasScopes]);
  const [setupFinished, setSetupFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [professionalDNAs, setProfessionalDNAs] = useState<DataSource>();
  const [filteredProfessionals, setFilteredProfessionals] =
    useState<DataSource>();
  const [idProjectProfessionalDNA, setIdProjectProfessionalDNA] = useState(0);

  const loadProjectInfos = useCallback(async () => {
    setLoading(true);
    const projectResponse = await api.get(`/api/projects/${id}`);
    setSetupFinished(projectResponse.data.idStatus !== 2);
    const projectProfessionalDNAResponse = await api.get(
      `/api/project-professional-dnas/project/${id}/session-type/1`,
    );
    const professionalResponse = await master.get('/master/professionals', {
      params: {
        where: {
          IsAnyStatus: true,
        },
      },
    });

    if (
      projectProfessionalDNAResponse.data &&
      projectProfessionalDNAResponse.data.length > 0
    )
      setIdProjectProfessionalDNA(projectProfessionalDNAResponse.data[0].id);

    const projectTeamIds = projectResponse.data.projectTeam.map((x: any) => {
      return x.professional?.id;
    });

    setFilteredProfessionals(
      professionalResponse.data
        .map((p: any) => {
          return { ID: p.ID, Name: p.Name };
        })
        .filter((x: any) => projectTeamIds.includes(x.ID)),
    );

    setLoading(false);
  }, [id]);

  const loadProfessionalDNAs = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `/api/professional-dnas/project/${id}/session-type/1`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `/api/professional-dnas/project/${id}/session-type/1`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/professional-dnas/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/professional-dnas/${key}`);
      },
    });

    setProfessionalDNAs(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id]);

  useEffect(() => {
    loadProjectInfos();
    loadProfessionalDNAs();
  }, [loadProfessionalDNAs, loadProjectInfos]);

  return (
    <>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}
      <Container>
        <BoardHeader title="SETUP" subtitle="" />
        <span>
          <h1
            style={{
              textTransform: 'uppercase',
              color: '#6c6c6c',
              fontSize: '24px',
              fontWeight: 700,
              letterSpacing: '0.1em',
            }}
          >
            Professional DNA
          </h1>
        </span>

        <Body>
          <DataGrid
            dataSource={professionalDNAs}
            onRowUpdating={options => {
              options.newData = {
                ...options.oldData,
                ...options.newData,
              };
            }}
          >
            <HeaderFilter visible />
            <Column
              dataField="idProfessional"
              caption="Professional"
              alignment="left"
            >
              <Lookup
                dataSource={filteredProfessionals}
                displayExpr="Name"
                valueExpr="ID"
              />
            </Column>
            <Column
              dataField="goalsForProject"
              caption="Goals for the Project"
              alignment="left"
            />
            <Column
              dataField="individualNeeds"
              caption="Individual needs to be guaranteed"
              alignment="left"
            />
            <Editing
              mode="popup"
              allowDeleting={isAdmin || !setupFinished}
              allowAdding={isAdmin || !setupFinished}
              allowUpdating={isAdmin || !setupFinished}
            >
              <Popup />
              <Form>
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="idProfessional" colSpan={1} />
                  <Item
                    dataField="goalsForProject"
                    editorType="dxTextArea"
                    colSpan={1}
                  />
                  <Item
                    dataField="individualNeeds"
                    editorType="dxTextArea"
                    colSpan={1}
                  />
                </Item>
              </Form>
            </Editing>
          </DataGrid>
        </Body>
      </Container>
    </>
  );
};
