import styled from 'styled-components';

interface Props {
  isProjectOverviewExpanded: boolean;
  onClick?: () => void;
}

export const ExpandButton = styled.div<Props>`
  position: absolute;
  left: -17px;
  top: 50%;
  background: white;
  box-shadow: -10px 0 30px rgba(0 0 0/ 7%);
  padding: 10px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: -1;

  svg {
    transform-origin: center;
    transform: rotate(
      ${props => (props.isProjectOverviewExpanded ? '180deg' : '0')}
    );
    transition: all 1s ease-out;
  }
`;

export const Container = styled.div<Props>`
  max-width: 500px;
  width: ${props => (props.isProjectOverviewExpanded ? '25%' : '80px')};
  height: 100%;
  position: relative;
  z-index: 10;
  float: right;
  height: 100%;
  background: white;
  box-shadow: 0 0 30px rgba(0 0 0/ 7%);
  box-sizing: border-box;
  padding: 15px;

  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);

  .bigProgress {
    color: #8b0304;
    margin-left: 48%;
    margin-top: 20%;
    width: 60px !important;
    height: 60px !important;
  }

  h2 {
    font-weight: bold;
    color: #333;
    font-size: ${props =>
    props.isProjectOverviewExpanded ? '0.75rem' : '0.7rem'};
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    margin-top: 1em;
    width: 100%;
    text-align: center;
    display: ${props => (props.isProjectOverviewExpanded ? 'block' : 'none')};
  }

  .row {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;

    .card {
      display: flex;
      flex-direction: column;
      width: 33%;
      border-radius: 7px;
      box-shadow: 0 0 30px rgba(0 0 0 / 10%);
      text-align: center;
      padding: 1em;
      transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);

      .titleContainer {
        height: 30%;

        .title {
          padding: 7px;
          font-weight: bold;
          color: #333;
          font-size: 1rem;
          line-height: 1;
        }

        hr {
          height: 1px;
        }
      }

      button {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        width: 50%;
        margin: 0 auto;
        place-content: center;
        background-color: #bbb;
        transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);

        &:hover {
          background-color: #8b0304;
          transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }

      .disclaimer {
        color: #333;
        font-weight: 500;
        padding-bottom: 20px;
        font-size: 0.75rem;
      }
    }

    .card:hover {
      box-shadow: 0 0 30px rgba(0 0 0 / 20%);
      transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }

  .bordered {
    align-items: center;
    justify-content: space-around;
    border-radius: 7px;
    padding: 15px 25px;
    box-shadow: 0 0 30px rgba(0 0 0 / 10%);
    transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);

    .title {
      color: #333;
      font-size: 1rem;
      font-weight: bold;
      width: 30%;
      line-height: 1;
    }

    .description {
      color: #333;
      font-weight: 300;
      width: 60%;
      line-height: 1.5;
      font-size: 0.8rem;
    }

    button {
      background-color: #bbb;
      transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);

      &:hover {
        background-color: #8b0304;
        transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }
  }

  .bordered:hover {
    box-shadow: 0 0 30px rgba(0 0 0 / 20%);
    transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
`;

export const Body = styled.section<Props>`
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;

  .MuiAccordion-root.Mui-expanded {
    margin: 0 auto;
  }

  .accordion {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin: 0 auto;
    width: 95%;
    color: #bbbbbb;

    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      background-color: white;
    }
  }

  .accordion-learning {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin: 0 auto;
    width: 97.5%;
    color: #bbbbbb;

    .header {
      .MuiAccordionSummary-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      span {
        display: flex;
        flex-direction: column;
      }

      p {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        display: ${props =>
    props.isProjectOverviewExpanded ? 'block' : 'none'};
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      background-color: white;

      .row {
        display: flex;
        gap: 20px;
        margin-bottom: 15px;

        .card {
          display: flex;
          flex-direction: column;
          width: 33%;
          border-radius: 5px;
          box-shadow: 0 0 5px 0;
          text-align: center;

          .titleContainer {
            background-color: #ededed;
            height: 30%;

            .title {
              padding: 7px;
              font-weight: bold;
              color: #666666;
              font-size: 20px;
            }

            hr {
              height: 1px;
            }
          }

          button {
            margin-top: 20px !important;
            margin-bottom: 20px !important;
            width: 50%;
            margin: 0 auto;
            place-content: center;
          }

          .disclaimer {
            color: #333;
            font-weight: 500;
            padding-bottom: 20px;
          }
        }

        .card:hover {
          box-shadow: 0 0 30px rgba(0 0 0 / 20%);
        }
      }

      .bordered {
        height: 100px;
        align-items: center;
        justify-content: space-around;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        padding: 15px 25px;
        box-shadow: 0 0 5px 0;

        .title {
          color: #333;
          font-size: 18px;
          font-weight: bold;
          width: 30%;
        }

        .description {
          color: #646464;
          font-weight: 500;
          width: 60%;
        }
      }

      .bordered:hover {
        box-shadow: 0 0 8px 0;
      }
    }
  }

  .statusRows {
    width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: ${props =>
    props.isProjectOverviewExpanded ? 'row' : 'column'};
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: ${props => (props.isProjectOverviewExpanded ? '0' : '1em')};

    @media (max-height: 600px) {
      gap: 5px;
    }
  }

  .iconCard {
    background-color: white;
    box-shadow: 0 0 30px rgba(0 0 0 / 10%);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: ${props =>
    props.isProjectOverviewExpanded ? 'flex-start' : 'center'};
    gap: 0.7em;
    padding: ${props => (props.isProjectOverviewExpanded ? '0.6em' : '1em')};
    color: #999;
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 1;
    width: ${props => (props.isProjectOverviewExpanded ? '47%' : '50px')};
    cursor: pointer;
    transition: all 200ms cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      box-shadow: 0 0 40px rgba(0 0 0 / 10%);
      transform: scale(1.05);
    }

    /* &:last-child {
      width: 100%;
    } */

    svg {
      width: ${props => (props.isProjectOverviewExpanded ? '25px' : '15px')};
    }
    h3 {
      display: ${props => (props.isProjectOverviewExpanded ? 'block' : 'none')};
    }
  }

  .team {
    width: 100%;
    display: ${props => (props.isProjectOverviewExpanded ? 'flex' : 'none')};
    flex-wrap: wrap;
    padding: 1em;
    background-color: white;
    box-shadow: 0 0 30px 0 rgba(0 0 0 / 10%);
    border-radius: 7px;
    justify-content: center;
    align-items: center;
    max-height: 180px;
    overflow-y: auto;
    gap: 5px;

    @media (max-height: 600px) {
      max-height: 60px;
    }

    h3 {
      font-weight: bold;
      color: #9999;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-size: 0.75rem;
    }

    img {
      filter: brightness(90%);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      overflow: hidden;
      background-color: #f5f5f5;
    }
  }

  .quickTools {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    @media (max-height: 600px) {
      gap: 5px;
    }

    button {
      cursor: pointer;
      font-family: 'Roboto Condensed', sans-serif;
      width: 100%;
      border: none;
      border-radius: 7px;
      font-size: 0.75rem;
      font-weight: 700;
      background-color: white;
      transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
      box-shadow: 0 0 30px rgba(0 0 0 / 10%);
      padding: 1em;
      letter-spacing: 0.025em;
      display: flex;
      gap: 0.5em;
      justify-content: center;
      align-items: center;
    }

    button:hover {
      color: white;
      background-color: #bbb;
      transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    span {
      display: ${props => (props.isProjectOverviewExpanded ? 'block' : 'none')};
    }
  }

  .header {
    margin-bottom: ${props => (props.isProjectOverviewExpanded ? '0' : '1em')};

    h1 {
      font-weight: bold;
      color: #999;
      font-size: 1.25rem;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      width: 100%;
      line-height: 1;
      display: ${props => (props.isProjectOverviewExpanded ? 'block' : 'none')};

      @media (max-height: 600px) {
        font-size: 1rem;
      }
    }

    h2 {
      color: #666;
      margin-bottom: 0;
      margin-top: 0;
      display: block;
      text-align: left;
    }

    p {
      margin-top: 1em;
      font-size: 0.75rem;
      line-height: 1.25;
      display: ${props => (props.isProjectOverviewExpanded ? 'block' : 'none')};
    }

    .titleStatus {
      display: flex;
      align-items: center;
      gap: ${props => (props.isProjectOverviewExpanded ? '10px' : '0')};
      flex-direction: ${props =>
    props.isProjectOverviewExpanded ? 'row' : 'column'};

      svg {
        fill: #333;
        width: ${props => (props.isProjectOverviewExpanded ? '50px' : '30px')};
        height: ${props => (props.isProjectOverviewExpanded ? '50px' : '30px')};

        @media (max-height: 600px) {
          width: 30px;
          height: 30px;
        }
      }

      .statusIcon {
        text-align: center;
        position: relative;
      }

      .dx-selectbox {
        width: 100px;
      }

      .dx-textbox input,
      .dx-placeholder {
        font-size: 0.75rem !important;
      }
      .dx-list-item-content {
        font-weight: bold;
        font-size: 0.75rem !important;
      }
    }
  }

  .options {
    margin-top: 10px !important;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    background-color: white;

    .dx-texteditor-buttons-container {
      background: #fff;
    }

    .smallTextField {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      div {
        display: flex;
      }

      p {
        color: #999999;
      }

      .dx-selectbox-container {
        width: 100%;
      }

      .textarea {
        padding-bottom: 10px;
      }
    }

    span {
      margin: 0 auto;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    hr {
      width: 100%;
      height: 1px;
    }

    .tdev {
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    .buttons {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }

  .options-header {
    margin-top: 10px !important;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: none;
    padding: 15px;

    .smallTextField {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      div {
        display: flex;
      }

      p {
        color: #999999;
      }

      .dx-selectbox-container {
        width: 100%;
      }

      .textarea {
        padding-bottom: 10px;
      }
    }

    span {
      margin: 0 auto;
      display: flex;
      align-items: center;
      gap: 10px;

      h1 {
        font-weight: bold;
        color: #bbbbbb;
        font-size: 14px;
      }
    }

    hr {
      width: 100%;
      height: 1px;
    }

    .tdev {
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    .buttons {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }

  .bar {
    margin-top: 40px !important;
    padding: 15px;
    display: flex;
    align-items: center;
    width: 50%;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0px 0px 10px 0px #0000002e;

    .search {
      border: none;
      width: 100%;
      padding-left: 15px;
      outline: none;
    }
  }

  .grid {
    margin-top: 40px !important;
    width: 95%;
    margin: 0 auto;

    .dx-datagrid-action {
      font-weight: bold;
      color: #bbbbbb;
    }
  }

  .span-title {
    display: contents !important;
    width: 100% !important;
  }
`;

export const CycleTitle = styled.h3`
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  margin: 1.5rem 0 1rem 0;
`;
