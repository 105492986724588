import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Body = styled.section`
  width: 100%;
  background-color: transparent;

  .header {
    padding: 20px 30px 5px 30px;

    h1 {
      font-size: 24px;
      letter-spacing: 0.1em;
      font-weight: 700;
      color: #6c6c6c;
    }
  }

  hr {
    width: 95%;
    height: 1px;
  }

  .row {
    display: flex;
    width: 95%;
    margin: 0 auto;
    margin-top: 20px;
    gap: 20px;

    .col {
      display: flex;
      width: 32%;
      border-radius: 5px;
      background-color: white;
      box-shadow: 0px 0px 5px 0px #7c7c7c;
      padding: 20px;

      p:first-child {
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 10px;
      }

      p:last-child {
        font-size: 14px;
        font-weight: 400;
      }

      div:first-child {
        width: 85%;
      }

      .downloadIconWrapper {
        width: 45px;
        height: 45px;
        text-align: center;
        align-self: center;
        font-size: 20px;
        background-color: #8b0305;
        border-radius: 50%;
        padding: 10px;
        color: white;
        cursor: pointer;

        .downloadIcon {
          margin-bottom: 3px;
        }
      }
      .datePickers {
        display: flex;
        gap: 10px;

        p {
          font-size: 14px;
          font-weight: 400;
          padding-bottom: 0;
        }

        div {
          display: flex;
          flex-direction: column;
          width: 45%;

          input {
            font-family: inherit;
            outline: none;
            border: 1px solid #898989;
          }
        }
      }
    }

    .col:hover {
      box-shadow: 0px 0px 7px 0px #7c7c7c;
      transition: 0.3s;
    }
  }

  .row:last-child {
    padding-bottom: 20px;
  }
`;
