import styled from 'styled-components';

export const TabMenu = styled.ul`
  display: flex;
  list-style: none;
  gap: 5px;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #f5f5f5;

  li {
    background-color: white;
    cursor: pointer;
    padding: 1em;
    font-size: 0.8rem;
    font-weight: bold;
    color: #333;
    box-shadow: 0 0 10px rgba(0 0 0 / 15%);
    transition: box-shadow 500ms ease-in-out, opacity 500ms ease-in-out;
    opacity: 0.5;
  }

  li:hover {
    box-shadow: 0 0 20px rgba(0 0 0 / 30%);
    transition: box-shadow 500ms ease-in-out, opacity 500ms ease-in-out;
    opacity: 0.8;
  }

  li.focused {
    opacity: 1;
    border-bottom: #8b0304 solid 3px;
    transition: box-shadow 500ms ease-in-out, opacity 500ms ease-in-out;
  }
`;

export const Body = styled.section`
  width: 100%;
  background-color: transparent;

  .pathContainer {
    display: flex;
    gap: 30px;
    justify-content: center;
    height: 480px;
    padding: 50px;
    padding-bottom: 10px;
  }

  .pathDiv {
    box-shadow: 0px 0px 30px 0px rgba(0 0 0 / 7%);
    border-radius: 7px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    flex: 1;
    border: none;
    background: #ffffff;

    &:hover {
      box-shadow: 0px 0px 50px 0px rgba(0 0 0 / 30%);
      transition: all 500ms ease-in-out;
    }

    &:hover .imageHolder img {
      transform: translateY(15px);
      transition: all 500ms ease-in-out;
    }

    &:hover h3 {
      color: #8b0304;
      transition: all 500ms ease-in-out;
    }

    .imageHolder {
      background-color: #f5f5f5;
      border-radius: 50%;
      overflow: visible;
      position: relative;
      width: 70%;
      padding-bottom: 70%;

      img {
        position: absolute;
        width: 100%;
        max-width: 100%;
        filter: drop-shadow(5px 0 30px rgba(0 0 0 / 30%));
        transition: all 500ms ease-in-out;
        left: 0;
      }

      img.path1 {
        top: -25%;
      }
    }

    img {
      max-width: 100px;
      height: auto;
    }

    h3 {
      font-weight: bold;
      text-align: center;
      line-height: 1.15;
      transition: all 500ms ease-in-out;
    }

    p {
      text-align: center;
      line-height: 1.15;
      font-size: 0.8rem;
    }
  }
  width: 100%;
  position: relative;

  label {
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 0.5em;
    line-height: 1.25;
    b {
      font-weight: bold;
    }
  }

  small {
    font-size: 0.8em;
    line-height: 1.25;
    margin-top: 1em;
    color: #999;
  }

  .smallTextField {
    display: flex;
    flex-direction: column;
    flex: 0 0 32%;
  }

  .groupTextField {
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    gap: 50px 0;
  }

  &.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 50px 0;
  }

  img {
    width: 90px;
    height: 75px;
    align-self: center;
  }

  /* .dx-datagrid .dx-row > td {
    font-weight: bold;
  } */

  .pillars {
    padding: 18px 28px;
    background-color: white;
    width: 95%;
    margin: 0 auto;
    display: flex;
    text-align: center;
    box-shadow: 0px 0px 10px 0px #0000002e;

    div {
      padding: 15px;
      display: flex;
      flex-direction: column;

      h1 {
        font-weight: bold;
        font-size: 16px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .dx-datagrid {
    background-color: initial;
  }

  .dx-datagrid .statuscell {
    background-color: #333;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: auto;
    position: relative;
  }

  .dx-datagrid .statuscell svg {
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  .dx-datagrid .statuscell svg.closure {
    transform: translate(calc(-50% + 1px), -50%);
    will-change: transform;
  }

  .dx-datagrid .statuscell svg.setup {
    transform: translate(calc(-50% - 1px), -50%);
    will-change: transform;
  }

  .dx-datagrid-content.dx-datagrid-table {
    border-collapse: separate;
    border-spacing: 0;
  }

  .dx-datagrid .dx-row-lines > td {
    border-bottom: 0 !important;
    font-size: 0.8rem;
    line-height: 1.25;
  }

  .dx-datagrid-headers.dx-datagrid-nowrap,
  .dx-datagrid-header-panel {
    border: none;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border: none;
  }

  .dx-datagrid-rowsview {
    overflow: visible;
  }

  .dx-datagrid-table tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .dx-datagrid-table tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .dx-row {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 7%);
    border: none !important;
  }

  .dx-row.dx-header-row {
    background: transparent;
    box-shadow: none;
    border: none;
  }

  .dx-datagrid
    .dx-datagrid-content
    .dx-datagrid-table
    .dx-row
    > td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending),
  .dx-datagrid
    .dx-datagrid-content
    .dx-datagrid-table
    .dx-row
    > td.dx-datagrid-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-datagrid-select-all),
  .dx-datagrid
    .dx-datagrid-content
    .dx-datagrid-table
    .dx-row
    > td:not(.dx-validation-pending):not(.dx-datagrid-select-all) {
    vertical-align: top;
  }

  .grid {
    margin-top: 40px !important;
    width: 95%;
    margin: 0 auto;

    .dx-datagrid-header-panel,
    .dx-toolbar-items-container {
      background-color: #f5f5f5;
      height: 2em !important;
    }

    input {
      margin-left: 3%;
      outline: none;
    }

    .dx-row-lines {
      cursor: pointer;
    }
    .dx-datagrid-action {
      font-weight: bold;
      color: #bbbbbb;
      background-color: none;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;

  h2 {
    text-align: center;
  }

  .backwardsHandler {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(1);
    will-change: transform;
    left: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease-in-out 250ms;
    border: none;
    background: transparent;
    flex-direction: column;

    &:hover {
      transition: all ease-in-out 250ms;
      transform: translateY(-50%) scale(1.1);
      will-change: transform;
    }
  }

  .forwardsHandler {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(1);
    will-change: transform;
    right: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease-in-out 250ms;
    border: none;
    background: transparent;
    flex-direction: column;

    &:hover {
      transition: all ease-in-out 250ms;
      transform: translateY(-50%) scale(1.1);
      will-change: transform;
    }
  }

  .tabsContainer {
    width: 95%;
    margin: 0 auto;

    .dx-tab {
      width: 150px;
      font-size: 12px;
    }

    .dx-tab-text {
      line-height: 15px;
      white-space: normal;
    }

    .dx-tab:not(:last-child) {
      border-right: 10px solid #f5f5f5;
    }
  }

  .span-style {
    margin: 0 auto;
    display: content;
    align-items: center;
    gap: 10px;

    h1 {
      font-weight: bold;
      color: #bbbbbb;
      font-size: 14px;
    }
  }

  .inputArea {
    margin: 15px 0;
    display: flex;
    gap: 15px;
    align-items: flex-start;

    p {
      color: #999999;
      font-weight: 700;
      text-align: right;
      max-width: 95px;
      min-width: 95px;
    }

    div {
      width: 100%;

      input {
        height: 150px;
      }
    }

    hr {
      width: 100%;
      height: 1px;
    }
  }

  .accordion {
    margin: 15px auto 15px auto !important;
    background-color: #bbbbbb;

    .header {
      .MuiAccordionSummary-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      color: white;

      span {
        display: contents !important;
        flex-direction: column;
      }

      p {
        font-weight: bold;
        text-align: center;
      }
    }

    .MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .content {
      background-color: white;

      .multipleFields {
        margin-top: 10px;
        display: flex;
        gap: 15px;
      }

      div {
        display: flex;
      }

      p,
      small {
        color: #999999;
      }

      small {
        font-size: 12px;
      }

      .Mui-checked {
        color: #8b0304;
      }

      .MuiTypography-root {
        white-space: nowrap;
        margin-left: 0;
      }
    }
  }
`;

export const SubmitContainer = styled.div`
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  text-align: center;

  & > button {
    width: 100%;
    justify-content: center;
    height: 54px;
  }
`;

export const SubmitConfirmMessage = styled.div`
  background-color: #f5f5f5;
  padding: 10px 15px;
  margin-bottom: 15px;
  border-radius: 6px;
`;

export const LoadingContainer = styled.div`
  z-index: 9999;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;
