import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin: 64px 0;
  display: flex;
  /* gap: 22px; */
  padding: 20px;
`;

export const Buttons = styled.button`
  border: none;
  background: #fff;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transition: 0.2s ease-in-out;

  &:hover {
    /* background: #8b0304; */
    color: #8b0304;
  }
`;
