import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { WhisperSpinner } from 'react-spinners-kit';
import api from '../../../services/api';
import { Button } from '../../../components/Button';

interface DialogCustomProps {
  id: number;
  open: boolean;
  handleClose(): void;
}

export const DialogConfirmSubmitOrUndoWeeklyStatus: React.FC<DialogCustomProps> =
  ({ id, open, handleClose }) => {
    const [loading, setLoading] = useState(false);
    const [weeklyStatusInfo, setWeeklyStatusInfo] = useState<any>();

    const loadWeeklyStatusInfo = useCallback(async () => {
      setLoading(true);
      const { data } = await api.get(`/api/weekly-status/${id}`);
      setWeeklyStatusInfo(data);
      setLoading(false);
    }, [id]);

    useEffect(() => {
      loadWeeklyStatusInfo();
    }, [loadWeeklyStatusInfo]);

    const submitOrUndo = useCallback(async () => {
      setLoading(true);
      if (weeklyStatusInfo?.isSubmitted) {
        await api.put(`/api/weekly-status/${id}`, {
          ...weeklyStatusInfo,
          submittedAt: null,
          isSubmitted: false,
        });
      } else {
        await api.put(`/api/weekly-status/${id}`, {
          ...weeklyStatusInfo,
          submittedAt: new Date(),
          isSubmitted: true,
        });
      }
      setLoading(false);
      handleClose();
    }, [handleClose, id, weeklyStatusInfo]);

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {weeklyStatusInfo?.isSubmitted ? 'Undo Submit' : 'Submit'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to{' '}
            {weeklyStatusInfo?.isSubmitted
              ? 'undo the submit'
              : 'submit the weekly status'}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button primary onClick={submitOrUndo} autoFocus disabled={loading}>
            Confirm{' '}
            {loading && (
              <WhisperSpinner size={15} backColor="#fff" frontColor="#fff" />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
