import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { DataGrid } from 'devextreme-react';
import {
  HeaderFilter,
  Column,
  Editing,
  Lookup,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { WhisperSpinner } from 'react-spinners-kit';
import { FiX } from 'react-icons/fi';
import api from '../../../services/api';
import master from '../../../services/master';
import { LoadingContainer } from '../../Admin/WeeklySupervision/DialogEdit/styles';
import portalGroups from '../../../config/portalGroups';
import { useAuth } from '../../../hooks/auth';

interface DialogCustomProps {
  id: number;
  open: boolean;
  handleClose(): void;
}

export const DialogIDOne: React.FC<DialogCustomProps> = ({
  id,
  open,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const { hasScopes } = useAuth();
  const isAdmin = useMemo(() => {
    return hasScopes([portalGroups.Admin]);
  }, [hasScopes]);
  const [projectIsBrazil, setProjectIsBrazil] = useState(false);
  const [setupFinished, setSetupFinished] = useState(false);
  const [projectIDOnes, setProjectIDOnes] = useState<DataSource>();
  const [filteredProfessionals, setFilteredProfessionals] =
    useState<DataSource>();

  const loadProjectInfo = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/api/projects/${id}`);
    setSetupFinished(data.idStatus !== 2);
    setProjectIsBrazil(
      data.projectGeographics.find((x: any) => x.idGeographic === 223),
    );
    const professionalResponse = await master.get('/master/professionals', {
      params: {
        where: {
          IsAnyStatus: true,
        },
      },
    });

    const projectTeamIds = data.projectTeam.map((x: any) => {
      return x.professional?.id;
    });

    setFilteredProfessionals(
      professionalResponse.data
        .map((p: any) => {
          return { ID: p.ID, Name: p.Name };
        })
        .filter((x: any) => projectTeamIds.includes(x.ID)),
    );
    setLoading(false);
    return data;
  }, [id]);

  const loadProjectIDOnes = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(`/api/project-id-ones/project/${id}`);
        return data;
      },
      insert: async data => {
        await api.post('/api/project-id-ones', {
          ...data,
          idProject: id,
        });
      },
      update: async (key, data) => {
        await api.put(`/api/project-id-ones/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project-id-ones/${key}`);
      },
    });

    setProjectIDOnes(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id]);

  useEffect(() => {
    loadProjectInfo();
    loadProjectIDOnes();
  }, [loadProjectInfo, loadProjectIDOnes]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // aria-labelledby="alert-dialog-title"
      // aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <h1>IDOne® Human Factor Restitutions</h1>
        <FiX
          style={{ cursor: 'pointer' }}
          size={18}
          className="closeButton"
          onClick={handleClose}
        />
      </DialogTitle>
      <DialogContent>
        {loading && (
          <LoadingContainer>
            <WhisperSpinner size={30} backColor="#8b0304" frontColor="#fff" />
          </LoadingContainer>
        )}
        {!projectIsBrazil && (
          <p
            style={{
              margin: '0 auto',
              fontStyle: 'italic',
              fontWeight: 500,
              textAlign: 'justify',
            }}
          >
            Project is not applicable for ID One® Human Factor Restitutions.
            Only projects in Brazil have it for now. If your project has a
            work-front in Brazil with professionals participating in the Project
            from Brazil and you want to include the ID One® Core & ID One®
            Reach, please send an email to{' '}
            <a href="mailto:idone@integrationconsulting.com">
              idone@integrationconsulting.com
            </a>{' '}
            to receive further details.
          </p>
        )}
        {projectIsBrazil && (
          <DataGrid
            dataSource={projectIDOnes}
            onRowUpdating={options => {
              options.newData = {
                ...options.oldData,
                ...options.newData,
              };
            }}
          >
            <HeaderFilter visible />
            <Column
              dataField="idResponsibleDefinition"
              caption="Responsible for Definition"
              alignment="center"
            >
              <Lookup
                dataSource={filteredProfessionals}
                displayExpr="Name"
                valueExpr="ID"
              />
            </Column>
            <Column
              dataField="numberProfile"
              caption="ID One® Core &amp; ID One® Reach"
              alignment="center"
              dataType="number"
            />
            <Column
              dataField="additionalProfiles"
              caption="Additional ID One® Core &amp; ID One® Reach"
              alignment="center"
              dataType="number"
            />
            <Column
              dataField="clientNameDefined"
              caption="Client Name Defined"
              alignment="center"
              dataType="bool"
            >
              <Lookup
                dataSource={[
                  { value: true, name: 'Yes' },
                  { value: false, name: 'No' },
                ]}
                displayExpr="name"
                valueExpr="value"
              />
            </Column>
            <Editing
              allowUpdating={isAdmin || !setupFinished}
              allowAdding={isAdmin || !setupFinished}
              allowDeleting={isAdmin || !setupFinished}
            />
          </DataGrid>
        )}
      </DialogContent>
    </Dialog>
  );
};
