import {
  Backdrop,
  Box,
  Dialog,
  DialogContent,
  Fade,
  Modal,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Column,
  DataGrid,
  Editing,
  Form,
  HeaderFilter,
  Lookup,
  Paging,
  Popup,
} from 'devextreme-react/data-grid';

import { FiX } from 'react-icons/fi';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { useParams } from 'react-router';
import { Item } from 'devextreme-react/form';
import { BoardBody } from '../../../components/BoardBody';

import { Body, Container } from './styles';
import api from '../../../services/api';
import master, { CustomStoreProps } from '../../../services/master';

interface DialogCustomProps {
  openRARModalPage: boolean;
  idSession: number;
  handleClose(): void;
}

export const RARModalPage: React.FC<DialogCustomProps> = ({
  openRARModalPage,
  handleClose,
  idSession,
}) => {
  const [rolesNResponsibilities, setRolesNResponsibilities] =
    useState<DataSource>();
  const [filteredProfessionals, setFilteredProfessionals] =
    useState<DataSource>();
  const [loading, setLoading] = useState(false);
  const [rarSubmitted, setRarSubmitted] = useState('');

  const { id } = useParams<{
    id: string;
  }>();

  const projectSessionInformations = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/api/projects/${id}/sessions/${idSession}`);
    setRarSubmitted(data.submittedAt);
    setLoading(false);
    return data;
  }, [id, idSession]);

  const loadProjectRAR = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `/api/project/${id}/rar/session/${idSession}`,
        );
        return data;
      },
      insert: async data => {
        await api.post(`/api/project/${id}/rar/session/${idSession}`, data);
      },
      update: async (key, data) => {
        await api.put(`/api/project/${id}/rar/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project/${id}/rar/${key}`);
      },
    });

    setRolesNResponsibilities(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id, idSession]);

  const loadProjectInfo = useCallback(async () => {
    const { data } = await api.get(`/api/projects/${id}`);
    const professionalResponse = await master.get('/master/professionals', {
      params: {
        where: {
          IsAnyStatus: true,
        },
      },
    });

    const projectTeamIds = data.projectTeam.map((x: any) => {
      return x.professional?.id;
    });

    setFilteredProfessionals(
      professionalResponse.data
        .map((p: any) => {
          return { ID: p.ID, Name: p.Name };
        })
        .filter((x: any) => projectTeamIds.includes(x.ID)),
    );

    return data;
  }, [id]);

  useEffect(() => {
    loadProjectRAR();
    loadProjectInfo();
    projectSessionInformations();
  }, [loadProjectRAR, loadProjectInfo, projectSessionInformations]);

  // console.log(rarSubmitted);

  // const handleClose = () => setOpen(false);

  return (
    <Container>
      <h2>Roles and Responsabilities</h2>
      <DataGrid
        dataSource={rolesNResponsibilities}
        onRowUpdating={options => {
          options.newData = {
            ...options.oldData,
            ...options.newData,
          };
        }}
      >
        <HeaderFilter visible />
        <Column caption="Professional" dataField="idProfessional">
          <Lookup
            dataSource={filteredProfessionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>
        <Column caption="Support Point" dataField="rarSupportingPoint" />
        <Column caption="Technical Role" dataField="rarTechnicalRole" />
        <Column caption="Non-Technical Role" dataField="rarNonTechnicalRole" />
        <Paging pageSize={8} />
        <Editing
          mode="popup"
          allowAdding={!rarSubmitted}
          allowUpdating={!rarSubmitted}
          allowDeleting={!rarSubmitted}
        >
          <Popup />
          <Form>
            <Item dataField="idProfessional" colSpan={2} />
            <Item
              dataField="rarSupportingPoint"
              colSpan={2}
              editorType="dxTextArea"
            />
            <Item
              dataField="rarTechnicalRole"
              colSpan={1}
              editorType="dxTextArea"
            />
            <Item
              dataField="rarNonTechnicalRole"
              colSpan={1}
              editorType="dxTextArea"
            />
          </Form>
        </Editing>
      </DataGrid>
    </Container>
  );
};
