/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';

import { IconButton, TextField, Tooltip } from '@material-ui/core';
import { FiDownload, FiExternalLink } from 'react-icons/fi';
import { DateBox } from 'devextreme-react';
import { Container, Body } from './styles';
import { useDocumentTitle } from '../../../hooks/documentTitle';
import { DialogGenerateProfessionalEvaluations } from './DialogGenerateProfessionalEvaluations';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { DialogGenerateSetupAssessment } from './DialogGenerateSetupAssessment';

export const Reports: React.FC<{ title?: string }> = () => {
  const { addToast } = useToast();
  const [openGenerateSetupAssessment, setOpenGenerateSetupAssessment] =
    useState(false);
  const [
    openGenerateProfessionalEvaluations,
    setOpenGenerateProfessionalEvaluations,
  ] = useState(false);

  const handleGenerateGovernance = useCallback(async () => {
    try {
      addToast({
        type: 'success',
        title: 'We are generating your report. This could take a while...',
      });

      const response = await api.get('/api/reports/governance-material', {
        responseType: 'blob',
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-governance-material.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: 'success',
        title: 'Report generated with success. Open or save it.',
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
    }
  }, [addToast]);

  const handleGenerateOnePage = useCallback(async () => {
    try {
      addToast({
        type: 'success',
        title: 'We are generating your report. This could take a while...',
      });

      const response = await api.get('/api/reports/one-page', {
        responseType: 'blob',
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-one-page.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: 'success',
        title: 'Report generated with success. Open or save it.',
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
    }
  }, [addToast]);

  const handleGenerateClient = useCallback(async () => {
    try {
      addToast({
        type: 'success',
        title: 'We are generating your report. This could take a while...',
      });

      const response = await api.get('/api/reports/client-segments', {
        responseType: 'blob',
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-client-segments.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: 'success',
        title: 'Report generated with success. Open or save it.',
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
    }
  }, [addToast]);

  const handleGenerateProject = useCallback(async () => {
    try {
      addToast({
        type: 'success',
        title: 'We are generating your report. This could take a while...',
      });

      const response = await api.get('/api/reports/project-status', {
        responseType: 'blob',
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-project-status.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: 'success',
        title: 'Report generated with success. Open or save it.',
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
    }
  }, [addToast]);

  const handleGenerateCompany = useCallback(async () => {
    try {
      addToast({
        type: 'success',
        title: 'We are generating your report. This could take a while...',
      });

      const response = await api.get('/api/reports/company-context', {
        responseType: 'blob',
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-company-context.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: 'success',
        title: 'Report generated with success. Open or save it.',
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
    }
  }, [addToast]);

  const handleGenerateFinancial = useCallback(async () => {
    try {
      addToast({
        type: 'success',
        title: 'We are generating your report. This could take a while...',
      });

      const response = await api.get('/api/reports/financial-kpi', {
        responseType: 'blob',
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-financial.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: 'success',
        title: 'Report generated with success. Open or save it.',
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
    }
  }, [addToast]);

  const handleGenerateTD = useCallback(async () => {
    try {
      addToast({
        type: 'success',
        title: 'We are generating your report. This could take a while...',
      });

      const response = await api.get('/api/reports/training-development', {
        responseType: 'blob',
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-training-development.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: 'success',
        title: 'Report generated with success. Open or save it.',
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
    }
  }, [addToast]);

  const handleGenerateIDOne = useCallback(async () => {
    try {
      addToast({
        type: 'success',
        title: 'We are generating your report. This could take a while...',
      });

      const response = await api.get('/api/reports/idone', {
        responseType: 'blob',
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-idone.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: 'success',
        title: 'Report generated with success. Open or save it.',
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
    }
  }, [addToast]);

  return (
    <Container>
      <Body>
        <div className="header">
          <h1>Reports</h1>
        </div>
        <hr />
        <div className="row">
          <div className="col">
            <div>
              <p>One Page</p>
              <p>One Pages Exports</p>
            </div>
            <Tooltip title="Download">
              <div
                className="downloadIconWrapper"
                onClick={handleGenerateOnePage}
              >
                <FiDownload className="downloadIcon" />
              </div>
            </Tooltip>
          </div>
          <div className="col">
            <div>
              <p>Client</p>
              <p>Client and Projects Segments</p>
            </div>
            <Tooltip title="Download">
              <div
                className="downloadIconWrapper"
                onClick={handleGenerateClient}
              >
                <FiDownload className="downloadIcon" />
              </div>
            </Tooltip>
          </div>
          <div className="col">
            <div>
              <p>Project</p>
              <p>Project Status</p>
            </div>
            <Tooltip title="Download">
              <div
                className="downloadIconWrapper"
                onClick={handleGenerateProject}
              >
                <FiDownload className="downloadIcon" />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div>
              <p>Project Evaluations</p>
              <p>Project Professional Evaluations Report</p>
            </div>
            <Tooltip title="Download">
              <div
                className="downloadIconWrapper"
                onClick={() => setOpenGenerateProfessionalEvaluations(true)}
              >
                <FiDownload className="downloadIcon" />
              </div>
            </Tooltip>
          </div>

          <div className="col">
            <div>
              <p>Company</p>
              <p>Company Context</p>
            </div>
            <Tooltip title="Download">
              <div
                className="downloadIconWrapper"
                onClick={handleGenerateCompany}
              >
                <FiDownload className="downloadIcon" />
              </div>
            </Tooltip>
          </div>
          <div className="col">
            <div>
              <p>Financial</p>
              <p>Financial KPI</p>
            </div>
            <Tooltip title="Download">
              <div
                className="downloadIconWrapper"
                onClick={handleGenerateFinancial}
              >
                <FiDownload className="downloadIcon" />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div>
              <p>Governance Material Excel</p>
              <p>Governance Material on Excel format</p>
            </div>
            <Tooltip title="Download">
              <div
                className="downloadIconWrapper"
                onClick={handleGenerateGovernance}
              >
                <FiDownload className="downloadIcon" />
              </div>
            </Tooltip>
          </div>
          <div className="col">
            <div>
              <p>Setup Assessment</p>
              <p>Setup Assessment Report</p>
            </div>
            <Tooltip title="Download">
              <div
                className="downloadIconWrapper"
                onClick={() => setOpenGenerateSetupAssessment(true)}
              >
                <FiDownload className="downloadIcon" />
              </div>
            </Tooltip>
          </div>
          <div className="col">
            <div>
              <p>Training and Development</p>
              <p>Training and Development Projects Report</p>
            </div>
            <Tooltip title="Download">
              <div className="downloadIconWrapper" onClick={handleGenerateTD}>
                <FiDownload className="downloadIcon" />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div>
              <p>ID One® Human Factor Restitutions</p>
              <p>ID One® Restitutions Report</p>
            </div>
            <Tooltip title="Download">
              <div
                className="downloadIconWrapper"
                onClick={handleGenerateIDOne}
              >
                <FiDownload className="downloadIcon" />
              </div>
            </Tooltip>
          </div>
          <div className="col">
            <div>
              <p>Governance Material PPT</p>
              <p>Governance Material on PPT format</p>
            </div>
            <Tooltip title="Download">
              <div
                className="downloadIconWrapper"
                onClick={() =>
                  window.open('https://inewportal.azurewebsites.net')
                }
              >
                <FiExternalLink className="downloadIcon" />
              </div>
            </Tooltip>
          </div>
        </div>
      </Body>

      {openGenerateSetupAssessment && (
        <DialogGenerateSetupAssessment
          open={openGenerateSetupAssessment}
          handleClose={() => setOpenGenerateSetupAssessment(false)}
        />
      )}
      {openGenerateProfessionalEvaluations && (
        <DialogGenerateProfessionalEvaluations
          open={openGenerateProfessionalEvaluations}
          handleClose={() => setOpenGenerateProfessionalEvaluations(false)}
        />
      )}
    </Container>
  );
};
