import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  Editing,
  HeaderFilter,
  Lookup,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { useParams } from 'react-router';
import { WhisperSpinner } from 'react-spinners-kit';
import api from '../../../services/api';
import master from '../../../services/master';
import { Body, Container, ContainerLoading } from './styles';
import { BoardHeader } from '../../../components/BoardHeader';
import { DialogAddFinalization } from './DialogAddFinalization';
import portalGroups from '../../../config/portalGroups';
import { useAuth } from '../../../hooks/auth';
import { set } from 'date-fns';

export const SetupFinalizationTab: React.FC<{ title?: string }> = ({
  title,
}) => {
  const { id } = useParams<{
    id: string;
  }>();
  const { hasScopes } = useAuth();
  const isAdmin = useMemo(() => {
    return hasScopes([portalGroups.Admin]);
  }, [hasScopes]);
  const [setupFinished, setSetupFinished] = useState(false);
  const [projectFinalization, setProjectFinalization] = useState<DataSource>();
  const [loading, setLoading] = useState(false);
  // const [openAddDialogFinalization, setOpenAddDialogFinalization] =
  //   useState(false);
  const [filteredProfessionals, setFilteredProfessionals] =
    useState<DataSource>();

  const loadProjectInfos = useCallback(async () => {
    setLoading(true);
    const projectResponse = await api.get(`/api/projects/${id}`);
    setSetupFinished(projectResponse.data.idStatus !== 2);

    const projectTeamDetails = projectResponse.data.projectTeam.map(
      (x: any) => {
        return {
          ID: x.professional.id,
          Name: x.professional.name,
          isManager: x.isManager,
          isPartner: x.isPartner,
        };
      },
    );

    setFilteredProfessionals(projectTeamDetails);

    setLoading(false);
  }, [id]);

  const loadSetupFinalization = useCallback(async () => {
    const projectStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `/api/project-setup-finalizations/project/${id}`,
        );
        return data;
      },
      insert: async data => {
        await api.post('/api/project-setup-finalizations', {
          ...data,
          idProject: id,
        });
      },
      update: async (key, data) => {
        await api.put(`/api/project-setup-finalizations/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project-setup-finalizations/${key}`);
      },
    });

    setProjectFinalization(
      new DataSource({
        store: projectStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id]);

  useEffect(() => {
    loadSetupFinalization();
    loadProjectInfos();
  }, [loadSetupFinalization, loadProjectInfos]);

  const presenceTypeOptions = [
    {
      presence: 'Physical',
    },
    {
      presence: 'Call',
    },
    {
      presence: 'VC',
    },
    {
      presence: 'Not Present',
    },
  ];

  return (
    <Container>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}
      <BoardHeader title="SETUP" subtitle="" />
      <span>
        <h1
          style={{
            textTransform: 'uppercase',
            color: '#6c6c6c',
            fontSize: '24px',
            fontWeight: 700,
            letterSpacing: '0.1em',
          }}
        >
          Finalization
        </h1>
      </span>
      <Body>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {/* <Button
            style={{ width: 'fit-content', alignSelf: 'flex-end' }}
            primary
            onClick={() => setOpenAddDialogFinalization(true)}
            disabled={setupFinished}
          >
            NEW FINALIZATION
          </Button> */}
        </div>
        <DataGrid
          dataSource={projectFinalization}
          onRowUpdating={options => {
            options.newData = {
              ...options.oldData,
              ...options.newData,
            };
          }}
        >
          <HeaderFilter visible />
          <Column
            dataField="idProfessional"
            alignment="left"
            caption="Professional"
          >
            <Lookup
              dataSource={filteredProfessionals}
              displayExpr="Name"
              valueExpr="ID"
            />
          </Column>
          <Column dataField="presenceType" alignment="left">
            <Lookup
              dataSource={presenceTypeOptions}
              displayExpr="presence"
              valueExpr="presence"
            />
          </Column>
          <Column
            dataField="hasAssessment"
            dataType="boolean"
            caption="Assessment Filled"
            allowEditing={false}
          />
          <Editing
            allowAdding={isAdmin || !setupFinished}
            allowUpdating={isAdmin || !setupFinished}
            allowDeleting={isAdmin || !setupFinished}
          />
        </DataGrid>
      </Body>

      {/* {openAddDialogFinalization && (
        <DialogAddFinalization
          open={openAddDialogFinalization}
          handleClose={() => {
            setOpenAddDialogFinalization(false);
            // eslint-disable-next-line no-unused-expressions
            projectFinalization?.reload();
          }}
          idProject={parseInt(id, 10)}
        />
      )} */}
    </Container>
  );
};
