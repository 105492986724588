import React, { useState, useCallback, useEffect } from 'react';

import { DataGrid } from 'devextreme-react';
import { Column, HeaderFilter } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { useHistory } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import { FiSearch } from 'react-icons/fi';
import { Container, Body } from './styles';
import { useDocumentTitle } from '../../../hooks/documentTitle';
import api from '../../../services/api';
import { DialogSetupAssessmentDetails } from './DialogSetupAssessmentDetails';
import { BoardHeader } from '../../../components/BoardHeader';

export const SetupAssessment: React.FC<{ title?: string }> = ({ title }) => {
  const [projects, setProjects] = useState<DataSource>();
  const [idAssessment, setIdAssessment] = useState(0);
  const [openSetupAssessmentDetails, setOpenSetupAssessmentDetails] =
    useState(false);
  const { setTitle } = useDocumentTitle();
  const history = useHistory();

  const loadProjects = useCallback(async () => {
    const projectStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/projects');
        return data
          .filter((x: any) => x.closedAt)
          .sort((a: any, b: any) => {
            if (a.closedAt > b.closedAt) {
              return -1;
            }
            if (a.closedAt < b.closedAt) {
              return 1;
            }
            return 0;
          });
      },
    });

    setProjects(
      new DataSource({
        store: projectStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    setTitle(title);
    loadProjects();
  }, [setTitle, title, loadProjects]);

  const textCells = useCallback(e => {
    return (
      <p
        style={{
          fontWeight: '600',
          textTransform: 'uppercase',
          letterSpacing: '0.05em',
        }}
      >
        {e.text}
      </p>
    );
  }, []);

  const setupAssessmentCell = useCallback(
    e => (
      <>
        <Tooltip title="Setup Assessment">
          <IconButton
            onClick={() => {
              setIdAssessment(e.key);
              setOpenSetupAssessmentDetails(true);
            }}
          >
            <FiSearch
              size={20}
              style={{
                color: '#8b0304',
              }}
            />
          </IconButton>
        </Tooltip>
      </>
    ),
    [],
  );

  return (
    <Container>
      <Body>
        <div
          style={{
            padding: '8px 14px',
          }}
        >
          <BoardHeader title="Setup Assessment Supervision" subtitle="" />
        </div>
        <hr />
        <DataGrid
          style={{ marginBottom: '10px' }}
          className="grid"
          dataSource={projects}
        >
          <HeaderFilter visible />
          <Column
            caption="Project Code"
            dataField="code"
            width={150}
            // cellRender={textCells}
            alignment="center"
          />
          <Column
            dataField="name"
            caption="Project Name"
            // cellRender={textCells}
            alignment="center"
          />
          <Column
            dataField="closedAt"
            caption="Closed Date"
            dataType="date"
            // cellRender={textCells}
            width={180}
            alignment="center"
          />
          <Column
            cellRender={setupAssessmentCell}
            width={50}
            alignment="center"
          />
        </DataGrid>
      </Body>

      {openSetupAssessmentDetails && (
        <DialogSetupAssessmentDetails
          open={openSetupAssessmentDetails}
          handleClose={() => {
            setOpenSetupAssessmentDetails(false);
          }}
          idSetupAssessment={idAssessment}
        />
      )}
    </Container>
  );
};
