/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  Editing,
  Form,
  HeaderFilter,
  Lookup,
  Popup,
  SearchPanel,
} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Controller, useForm } from 'react-hook-form';
import { WhisperSpinner } from 'react-spinners-kit';
import { Item } from 'devextreme-react/form';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { TextBox } from '../../../components/TextBox';
import { TextArea } from '../../../components/TextArea';
import master, { CustomStoreProps } from '../../../services/master';
import { Body, Container, TabMenu } from './styles';
import { BoardHeader } from '../../../components/BoardHeader';
import { Proposal } from './Proposal';
import { ContainerLoading } from '../ClientTab/styles';
import { SelectBox } from '../../../components/SelectBox';
import { useAuth } from '../../../hooks/auth';
import portalGroups from '../../../config/portalGroups';

export const ProjectTab: React.FC<{ title?: string }> = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const { user } = useAuth();
  const [setupFinished, setSetupFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const { control, setValue } = useForm();
  const [idSetupSession, setIdSetupSession] = useState(0);
  const [projectTitle, setProjectTitle] = useState('');
  const [partnerDirectorResponsible, setPartnerDirectorResponsible] =
    useState<any>();
  const [projectManager, setProjectManager] = useState<any>();
  const [expectations, setExpectations] = useState<DataSource>();
  const [deliverables, setDeliverables] = useState<DataSource>();
  const [risks, setRisks] = useState<DataSource>();
  const [whatSalesContext, setWhatSalesContext] = useState('');
  const [whyClientNeedHelp, setWhyClientNeedHelp] = useState('');
  const [hasProposalFile, setHasProposalFile] = useState(false);

  const [practices, setPractices] = useState<any>();
  const [methodologies, setMethodologies] = useState<any>();
  const [geographics, setGeographics] = useState<any>();
  const [geographicScopes, setGeographicScopes] = useState<any>();
  const [offices, setOffices] = useState<any>();
  const [coMgts, setCoMgts] = useState<any>();
  const [allowEditClientInformations, setAllowEditClientInformations] =
    useState(false);
  const [filteredProfessionals, setFilteredProfessionals] =
    useState<DataSource>();
  const [isManagerOrPartner, setIsManagerOrPartner] = useState(false);

  const [openTab, setOpenTab] = useState<number>(1);

  const isAdmin = useMemo(() => {
    return user?.scopes.includes(portalGroups.Admin);
  }, [user]);

  const loadProjectInfos = useCallback(async () => {
    setLoading(true);

    const projectResponse = await api.get(`/api/projects/${id}`);
    setSetupFinished(projectResponse.data.idStatus !== 2);
    const practicesResponse = await master.get('master/practices');
    setPractices(practicesResponse.data);
    const methodologiesResponse = await master.get('master/methodologies');
    setMethodologies(methodologiesResponse.data);
    const geographicsResponse = await master.get('master/geographics');
    setGeographics(geographicsResponse.data);
    const officeResponse = await master.get('master/offices');
    setOffices(officeResponse.data);
    const geographicScopeResponse = await master.get(
      'master/geographic-scopes',
    );
    setGeographicScopes(geographicScopeResponse.data);
    const coMgtResponse = await master.get('master/comanagements');
    setCoMgts(coMgtResponse.data);

    const professionalResponse = await master.get('/master/professionals', {
      params: {
        where: {
          IsAnyStatus: true,
        },
      },
    });

    const projectTeamIds = projectResponse.data.projectTeam.map((x: any) => {
      return x.professional?.id;
    });

    const professionalAccess = projectResponse.data.projectTeam.find(
      (x: any) => x.professional.id === user.userId,
    );

    setIsManagerOrPartner(
      professionalAccess?.isManager || professionalAccess?.isPartner,
    );

    setFilteredProfessionals(
      professionalResponse.data
        .map((p: any) => {
          return { ID: p.ID, Name: p.Name };
        })
        .filter((x: any) => projectTeamIds.includes(x.ID)),
    );

    setIdSetupSession(
      projectResponse.data.projectSession?.find((x: any) => x.isSetup)?.id || 0,
    );

    setValue('idDuringType', projectResponse.data.idDuringType);

    setValue(
      'projectScope',
      projectResponse.data.projectSession?.find((x: any) => x.isSetup)
        ?.projectScope || '',
    );
    setValue(
      'projectOutScope',
      projectResponse.data.projectSession?.find((x: any) => x.isSetup)
        ?.projectOutScope || '',
    );
    setProjectTitle(projectResponse.data.name);
    if (
      projectResponse.data.projectPractices &&
      projectResponse.data.projectPractices.length > 0
    ) {
      setValue(
        'idPractice',
        practicesResponse.data.find(
          (x: any) =>
            x.ID === projectResponse?.data.projectPractices[0].idPractice,
        )?.ID,
      );
    }
    if (
      projectResponse.data.projectMethodologies &&
      projectResponse.data.projectMethodologies.length > 0
    ) {
      setValue(
        'idMethodology',
        methodologiesResponse.data.find(
          (x: any) =>
            x.ID ===
            projectResponse?.data.projectMethodologies[0].idMethodology,
        )?.ID,
      );
    }

    if (
      projectResponse.data.projectGeographics &&
      projectResponse.data.projectGeographics.length > 0
    ) {
      setValue(
        'idGeography',
        geographicsResponse.data.find(
          (x: any) =>
            x.ID === projectResponse?.data.projectGeographics[0].idGeographic,
        )?.ID,
      );
    }

    if (
      projectResponse.data.projectGeographicScopes &&
      projectResponse.data.projectGeographicScopes.length > 0
    ) {
      setValue(
        'idGeographicScope',
        geographicScopeResponse.data.find(
          (x: any) =>
            x.ID ===
            projectResponse?.data.projectGeographicScopes[0].idGeographicScope,
        )?.ID,
      );
    }

    if (
      projectResponse.data.projectOffices &&
      projectResponse.data.projectOffices.length > 0
    ) {
      setValue(
        'idOffice',
        officeResponse.data.find(
          (x: any) => x.ID === projectResponse?.data.projectOffices[0].idOffice,
        )?.ID,
      );
    }

    setPartnerDirectorResponsible(
      projectResponse.data.projectTeam.find((x: any) => x.isPartner),
    );

    setProjectManager(
      projectResponse.data.projectTeam.find((x: any) => x.isManager),
    );

    if (
      projectResponse.data.projectCoManagements &&
      projectResponse.data.projectCoManagements.length > 0
    ) {
      setValue(
        'idCoMgt',
        coMgtResponse.data.find(
          (x: any) =>
            x.ID ===
            projectResponse?.data.projectCoManagements[0].idCoManagement,
        )?.ID,
      );
    }

    if (projectResponse.data.idSupportComanagement) {
      setValue(
        'idSupportCoMgt',
        coMgtResponse.data.find(
          (x: any) => x.ID === projectResponse.data.idSupportComanagement,
        )?.ID,
      );
    }

    setWhatSalesContext(projectResponse.data.whatSalesContext);
    setWhyClientNeedHelp(projectResponse.data.whyClientNeedHelp);
    setHasProposalFile(!!projectResponse.data.proposalFilename);
    setLoading(false);
  }, [id, setValue]);

  const loadExpectations = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `api/project/${id}/expectations/session/${idSetupSession}`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `api/project/${id}/expectations/session/${idSetupSession}`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`api/project/${id}/expectations/${key}`, data);
      },
      remove: async key => {
        await api.delete(`api/project/${id}/expectations/${key}`);
      },
    });

    setExpectations(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id, idSetupSession]);

  const loadRisks = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `/api/project/${id}/risks/session/${idSetupSession}`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `/api/project/${id}/risks/session/${idSetupSession}`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/project/${id}/risks/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project/${id}/risks/${key}`);
      },
    });

    setRisks(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id, idSetupSession]);

  const loadDeliverables = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `/api/project/${id}/deliverables/session/${idSetupSession}`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `/api/project/${id}/deliverables/session/${idSetupSession}`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/project/${id}/deliverables/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project/${id}/deliverables/${key}`);
      },
    });

    setDeliverables(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id, idSetupSession]);

  useEffect(() => {
    loadProjectInfos();
    loadExpectations();
    loadDeliverables();
    loadRisks();
  }, [loadDeliverables, loadExpectations, loadProjectInfos, loadRisks]);

  const updateProjectInfo = useCallback(
    async (fieldName, value) => {
      try {
        await api.patch(`/api/projects/${id}`, {
          [fieldName]: value,
        });
        addToast({
          type: 'success',
          title: 'Saved',
          description: '',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          description: '',
        });
      }
    },
    [addToast, id],
  );

  const updateProjectSessionInfo = useCallback(
    async (fieldName, value) => {
      try {
        await api.patch(`/api/projects/${id}/sessions/${idSetupSession}`, {
          [fieldName]: value,
        });
        addToast({
          type: 'success',
          title: 'Saved',
          description: '',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          description: '',
        });
      }
    },
    [addToast, id, idSetupSession],
  );

  const statusRisk = [
    {
      statusId: 1,
      status: 'Green',
    },
    {
      statusId: 2,
      status: 'Yellow',
    },
    {
      statusId: 3,
      status: 'Red',
    },
    {
      statusId: 4,
      status: 'Blue',
    },
    {
      statusId: 5,
      status: 'Legacy',
    },
  ];

  const statusCell = useCallback(e => {
    if (e.value === 1) {
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#2ecc71',
          }}
        />
      );
    }
    if (e.value === 2) {
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#F1C40F',
          }}
        />
      );
    }
    if (e.value === 3) {
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#e74c3c',
          }}
        />
      );
    }
    if (e.value === 4) {
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#2980b9',
          }}
        />
      );
    }
    if (e.value === 5) {
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#bdc3c7',
          }}
        />
      );
    }

    return '';
  }, []);

  const updateProjectMetadata = useCallback(
    async (fieldName, value) => {
      try {
        await api.put(`/api/projects/${id}/metadata`, {
          [fieldName]: value,
        });
        addToast({
          type: 'success',
          title: 'Saved',
          description: '',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          description: '',
        });
      }
    },
    [addToast, id],
  );

  return (
    <>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}
      {!loading && (
        <Container>
          <BoardHeader title="SETUP" subtitle="" />
          <span>
            <h1
              style={{
                textTransform: 'uppercase',
                color: '#6c6c6c',
                fontSize: '24px',
                fontWeight: 700,
                letterSpacing: '0.1em',
              }}
            >
              Project
            </h1>
          </span>

          <TabMenu>
            <li
              onClick={() => setOpenTab(1)}
              className={openTab === 1 ? 'focused' : ''}
            >
              Project Info
            </li>
            <li
              onClick={() => setOpenTab(2)}
              className={openTab === 2 ? 'focused' : ''}
            >
              Proposal
            </li>
            <li
              onClick={() => setOpenTab(3)}
              className={openTab === 3 ? 'focused' : ''}
            >
              Project Scope
            </li>
            <li
              onClick={() => setOpenTab(4)}
              className={openTab === 4 ? 'focused' : ''}
            >
              Client Expectations
            </li>
            <li
              onClick={() => setOpenTab(5)}
              className={openTab === 5 ? 'focused' : ''}
            >
              Deliverables
            </li>
            <li
              onClick={() => setOpenTab(6)}
              className={openTab === 6 ? 'focused' : ''}
            >
              Risks
            </li>
          </TabMenu>

          {loading && (
            <WhisperSpinner size={15} backColor="#fff" frontColor="#fff" />
          )}

          {openTab === 1 && (
            <Body className="content">
              <div className="smallTextField">
                <label>Project title</label>
                <TextBox value={projectTitle} disabled={!isAdmin} />
              </div>

              <div className="smallTextField">
                <label>Practice</label>
                <Controller
                  name="idPractice"
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      disabled={!isAdmin}
                      dataSource={practices}
                      displayExpr="Name"
                      valueExpr="ID"
                      onChanged={value => {
                        setValue(field.name, value);
                        updateProjectMetadata(field.name, value);
                      }}
                      searchEnabled
                      value={field.value}
                    />
                  )}
                />
              </div>
              <div className="smallTextField">
                <label>Methodology</label>
                <Controller
                  name="idMethodology"
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      disabled={!isAdmin}
                      dataSource={methodologies}
                      displayExpr="Name"
                      valueExpr="ID"
                      onChanged={value => {
                        setValue(field.name, value);
                        updateProjectMetadata(field.name, value);
                      }}
                      searchEnabled
                      value={field.value}
                    />
                  )}
                />
              </div>

              <div className="smallTextField">
                <label>Project Geography</label>
                <Controller
                  name="idGeography"
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      disabled={!isAdmin}
                      dataSource={geographics}
                      displayExpr="Name"
                      valueExpr="ID"
                      onChanged={value => {
                        setValue(field.name, value);
                        updateProjectMetadata(field.name, value);
                      }}
                      searchEnabled
                      value={field.value}
                    />
                  )}
                />
              </div>
              <div className="smallTextField">
                <label>Geographic Scope</label>
                <Controller
                  name="idGeographicScope"
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      disabled={!isAdmin}
                      dataSource={geographicScopes}
                      displayExpr="Name"
                      valueExpr="ID"
                      onChanged={value => {
                        setValue(field.name, value);
                        updateProjectMetadata(field.name, value);
                      }}
                      searchEnabled
                      value={field.value}
                    />
                  )}
                />
              </div>
              <div className="smallTextField">
                <label>Office</label>
                <Controller
                  name="idOffice"
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      disabled={!isAdmin}
                      dataSource={offices}
                      displayExpr="Name"
                      valueExpr="ID"
                      onChanged={value => {
                        setValue(field.name, value);
                        updateProjectMetadata(field.name, value);
                      }}
                      searchEnabled
                      value={field.value}
                    />
                  )}
                />
              </div>

              <div className="smallTextField">
                <label>Responsible</label>
                <TextBox
                  value={partnerDirectorResponsible?.professional?.name}
                  disabled
                />
              </div>

              <div className="smallTextField">
                <label>Leader</label>
                <TextBox value={projectManager?.professional?.name} disabled />
              </div>

              <div className="smallTextField">
                <label>Co-Management</label>
                <Controller
                  name="idCoMgt"
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      disabled={!isAdmin}
                      dataSource={coMgts}
                      displayExpr="Name"
                      valueExpr="ID"
                      onChanged={value => {
                        setValue(field.name, value);
                        updateProjectMetadata(field.name, value);
                      }}
                      value={field.value}
                    />
                  )}
                />
              </div>

              <div className="smallTextField">
                <label>Support Co-Management</label>
                <Controller
                  name="idSupportCoMgt"
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      disabled={!isAdmin}
                      dataSource={coMgts}
                      displayExpr="Name"
                      valueExpr="ID"
                      onChanged={value => {
                        setValue(field.name, value);
                        updateProjectMetadata(field.name, value);
                      }}
                      value={field.value}
                    />
                  )}
                />
              </div>

              <div className="smallTextField">
                <label>During Type</label>
                <Controller
                  name="idDuringType"
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      disabled={!isAdmin && !isManagerOrPartner}
                      dataSource={[
                        { id: 1, name: 'Normal' },
                        { id: 2, name: 'Internal' },
                        { id: 3, name: 'Ad Hoc' },
                      ]}
                      displayExpr="name"
                      valueExpr="id"
                      onChanged={value => {
                        setValue(field.name, value);
                        updateProjectMetadata(field.name, value);
                      }}
                      value={field.value}
                    />
                  )}
                />
              </div>
            </Body>
          )}

          {openTab === 2 && (
            <Proposal
              idProject={Number(id)}
              setupFinished={setupFinished}
              updateProjectInfo={updateProjectInfo}
              hasProposalFile={hasProposalFile}
              whatSalesContext={whatSalesContext}
              whyClientNeedHelp={whyClientNeedHelp}
            />
          )}

          {openTab === 3 && (
            <Body
              className="content, content-fix"
              style={{
                justifyContent: 'flex-start',
                gap: '50px',
                display: 'content !important',
              }}
            >
              <div className="smallTextField">
                <label>What is the Project?</label>
                <Controller
                  name="projectScope"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      disabled={setupFinished}
                      value={field.value}
                      onChanged={value => {
                        setValue(field.name, value);
                        updateProjectSessionInfo(field.name, value);
                      }}
                    />
                  )}
                />
              </div>
              <div className="smallTextField">
                <label>
                  What is <b>Not</b> the Project?
                </label>
                <Controller
                  name="projectOutScope"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      disabled={setupFinished}
                      value={field.value}
                      onChanged={value => {
                        setValue(field.name, value);
                        updateProjectSessionInfo(field.name, value);
                      }}
                    />
                  )}
                />
              </div>
            </Body>
          )}

          {openTab === 4 && (
            <Body className="content">
              <DataGrid
                dataSource={expectations}
                onRowUpdating={options => {
                  options.newData = {
                    ...options.oldData,
                    ...options.newData,
                  };
                }}
              >
                <HeaderFilter visible />
                <SearchPanel visible width={250} />
                <Column
                  dataField="stakeholder"
                  alignment="left"
                  caption="Stakeholder"
                />
                <Column
                  dataField="expectation"
                  alignment="left"
                  caption="Project Expectation Raised"
                />
                <Column
                  dataField="observation"
                  alignment="left"
                  caption="Observation"
                />

                <Editing
                  allowUpdating={!setupFinished}
                  allowAdding={!setupFinished}
                  allowDeleting={!setupFinished}
                  mode="popup"
                >
                  <Popup title="Client Expectations" />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item
                        dataField="stakeholder"
                        editorType="dxTextBox"
                        colSpan={1}
                      />
                      <Item
                        dataField="expectation"
                        editorType="dxTextArea"
                        colSpan={1}
                      />
                      <Item
                        dataField="observation"
                        editorType="dxTextArea"
                        colSpan={1}
                      />
                    </Item>
                  </Form>
                </Editing>
              </DataGrid>
            </Body>
          )}

          {openTab === 5 && (
            <Body>
              <DataGrid
                dataSource={deliverables}
                onRowUpdating={options => {
                  options.newData = {
                    ...options.oldData,
                    ...options.newData,
                  };
                }}
              >
                <HeaderFilter visible />
                <SearchPanel visible width={250} />
                <Column dataField="deliverable" alignment="left" />
                <Column dataField="detail" alignment="left" />
                <Editing
                  allowUpdating={!setupFinished}
                  allowAdding={!setupFinished}
                  allowDeleting={!setupFinished}
                  mode="popup"
                >
                  <Popup title="Deliverables" />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item
                        dataField="deliverable"
                        editorType="dxTextArea"
                        colSpan={1}
                      />
                      <Item
                        dataField="detail"
                        editorType="dxTextArea"
                        colSpan={1}
                      />
                    </Item>
                  </Form>
                </Editing>
              </DataGrid>
            </Body>
          )}

          {openTab === 6 && (
            <Body>
              <DataGrid
                dataSource={risks}
                onRowUpdating={options => {
                  options.newData = {
                    ...options.oldData,
                    ...options.newData,
                  };
                }}
              >
                <HeaderFilter visible />
                <SearchPanel visible width={250} />
                <Column dataField="risk" alignment="left" />
                <Column
                  dataField="riskStatus"
                  alignment="center"
                  cellRender={statusCell}
                  width="200"
                >
                  <Lookup
                    dataSource={statusRisk}
                    valueExpr="statusId"
                    displayExpr="status"
                  />
                </Column>
                <Column dataField="observation" alignment="left" />

                <Editing
                  allowUpdating={!setupFinished}
                  allowAdding={!setupFinished}
                  allowDeleting={!setupFinished}
                  mode="popup"
                >
                  <Popup title="Risks" />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item
                        dataField="risk"
                        editorType="dxTextArea"
                        colSpan={1}
                      />
                      <Item dataField="riskStatus" colSpan={1} />
                      <Item
                        dataField="observation"
                        editorType="dxTextArea"
                        colSpan={1}
                      />
                    </Item>
                  </Form>
                </Editing>
              </DataGrid>
            </Body>
          )}
        </Container>
      )}
    </>
  );
};
