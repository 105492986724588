import React, { useCallback } from 'react';
import { FiUsers, FiCheckSquare } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Buttons, Container } from './styles';

export const KNMHome: React.FC<{ title?: string }> = ({ title }) => {
  const history = useHistory();

  const handleChecklist = useCallback(() => {
    history.push('/admin/knm-checklist');
  }, [history]);

  const handleClients = useCallback(() => {
    history.push('/admin/knm-clients');
  }, [history]);

  return (
    <Container>
      <Buttons onClick={handleChecklist}>
        <FiCheckSquare size="24" style={{ margin: '5px' }} />
        KNM - Check List
      </Buttons>
      <Buttons onClick={handleClients}>
        <FiUsers size="24" style={{ margin: '5px' }} />
        KNM - Clients
      </Buttons>
    </Container>
  );
};
