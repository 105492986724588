import React, { useState, useCallback, useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { WhisperSpinner } from 'react-spinners-kit';
import { SelectBox } from 'devextreme-react';
import api from '../../../services/api';
import { Button } from '../../../components/Button';
import { useToast } from '../../../hooks/toast';

interface DialogCustomProps {
  id: number;
  open: boolean;
  handleClose(): void;
  handleCloseAndReload(): void;
  currentStatus: number;
}

export const DialogChangeStatus: React.FC<DialogCustomProps> = ({
  id,
  open,
  handleClose,
  handleCloseAndReload,
  currentStatus,
}) => {
  const [loading, setLoading] = useState(false);
  const [statusId, setStatusId] = useState(0);
  const { addToast } = useToast();

  const changeProjectStatus = useCallback(async () => {
    setLoading(true);
    try {
      if (!statusId) return;
      await api.put(`/api/projects/${id}/status`, {
        idStatus: statusId,
      });
      addToast({
        type: 'success',
        title: 'Project status changed successfully.',
        description: '',
      });
    } catch (e) {
      addToast({
        type: 'error',
        title:
          'Unable to change status. Verify every ritual has been done correctly, or try contacting IT.',
        description: '',
      });
    }
    setLoading(false);
    handleCloseAndReload();
  }, [addToast, handleCloseAndReload, id, statusId]);

  const statusOptions = useMemo(
    () => [
      {
        id: 2,
        name: 'Setup',
      },
      {
        id: 3,
        name: 'During',
      },
      {
        id: 4,
        name: 'Closure',
      },
      {
        id: 5,
        name: 'Closed',
      },
      {
        id: 6,
        name: 'Paused',
      },
    ],
    [],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Project Status Change</DialogTitle>
      <DialogContent>
        <SelectBox
          dataSource={statusOptions}
          displayExpr="name"
          valueExpr="id"
          value={statusId}
          onValueChanged={e => setStatusId(e.value)}
          defaultValue={currentStatus}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          primary
          onClick={changeProjectStatus}
          autoFocus
          disabled={loading}
        >
          Confirm{' '}
          {loading && (
            <WhisperSpinner size={15} backColor="#fff" frontColor="#fff" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
