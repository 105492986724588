import styled from 'styled-components';

export const Container = styled.div``;

export const SubmenuButtonBox = styled.div`
  display: flex;
  margin-top: 14px;
  padding: 12px;
  gap: 24px;
  justify-content: center;
`;

export const SubmenuButton = styled.button`
  border: none;
  color: #d1d1d1;
  background: #ffffff;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Roboto Condensed', sans-serif;
  border-radius: 4px;
  padding: 14px;
  letter-spacing: 0.7px;
  cursor: pointer;

  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  transition: 0.2s;

  &:hover {
    background-color: #d1d1d1;
    color: #ffffff;
  }
`;
