import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { TextBox, TextArea, SelectBox, DateBox } from 'devextreme-react';
import { useParams } from 'react-router';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Button } from '../../../../components/Button';
import { Container } from './styles';
import api from '../../../../services/api';
import { FormGroup } from '../../../../components/FormGroup';
import { useToast } from '../../../../hooks/toast';
import master, { CustomStoreProps } from '../../../../services/master';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
}

export const DialogAddToolkit: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
}) => {
  const { id } = useParams<{
    id: string;
  }>();

  const { addToast } = useToast();

  const [idToolkit, setIdToolkit] = useState(0);
  const [idStatus, setIdStatus] = useState(0);

  const [toolkits] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('master/toolkits');
        const practiceResponse = await master.get('/master/practices');
        const dataWithPractice = data
          .map((t: any) => {
            return {
              ...t,
              fullName: `${
                practiceResponse.data.find(
                  (p: any) => p.ID === t.Methodology?.IDPractice,
                )?.Name
              } - ${t.Name}`,
            };
          })
          .filter((x: any) => x.IsActive)
          .sort((a: any, b: any) => {
            if (a.Methodology?.IDPractice < b.Methodology?.IDPractice) {
              return -1;
            }
            if (a.Methodology?.IDPractice > b.Methodology?.IDPractice) {
              return 1;
            }
            return 0;
          });
        return dataWithPractice;
      },
    }),
    paginate: true,
  });

  const handleAddToolkit = useCallback(async () => {
    try {
      await api.post(`/api/project/${id}/toolkits`, {
        idToolkit,
        idStatus,
      });

      addToast({
        type: 'success',
        title: 'Added with success.',
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
    }
    handleClose();
  }, [handleClose, id, idToolkit, idStatus, addToast]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">Toolkit</DialogTitle>
      <Container>
        <DialogContent className="dialog-content">
          <FormGroup fieldSetLabel="Toolkit">
            <SelectBox
              onValueChange={e => setIdToolkit(e)}
              openOnFieldClick
              searchEnabled
              dataSource={toolkits}
              valueExpr="ID"
              displayExpr="fullName"
            />
          </FormGroup>
        </DialogContent>
      </Container>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleAddToolkit} primary>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
