import styled from 'styled-components';

type Props = {
  isInProject: boolean;
};

export const Container = styled.div<Props>`
  position: relative;
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
  border-radius: 0;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;

  opacity: 1;
  visibility: visible;

  -webkit-transition: margin-left 200ms ease, visibility 200ms ease,
    opacity 200ms ease;
  -o-transition: margin-left 200ms ease, visibility 200ms ease,
    opacity 200ms ease;
  transition: margin-left 200ms ease, visibility 200ms ease, opacity 200ms ease;
`;

export const Content = styled.div`
  overflow: auto;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  position: relative;
`;

export const Board = styled.div<Props>`
  margin-left: 80px;
  padding: ${props => (props.isInProject ? '25px 50px 25px 50px' : '0')};
`;
