import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, SubmenuButton, SubmenuButtonBox } from './styles';

interface SubmenuProps {
  open: boolean;
}

export const KNMSubMenu: React.FC<SubmenuProps> = ({ open }) => {
  const history = useHistory();

  const handleKNMChecklist = useCallback(() => {
    history.push('/admin/knm-checklist');
  }, [history]);

  const handleKNMReports = useCallback(() => {
    history.push('/admin/knm-reports');
  }, [history]);

  const handleKNMCatalogs = useCallback(() => {
    history.push('/admin/knm-catalogs');
  }, [history]);

  return (
    <Container>
      <SubmenuButtonBox>
        <SubmenuButton onClick={handleKNMCatalogs}>CATALOG</SubmenuButton>
        <SubmenuButton onClick={handleKNMChecklist}>CHECKLIST</SubmenuButton>
        <SubmenuButton onClick={handleKNMReports}>REPORTS</SubmenuButton>
      </SubmenuButtonBox>
    </Container>
  );
};
