import React from 'react';

import { Container, ContentWrapper, Content, Board } from './styles';

type Props = {
  isInProject: boolean;
};

export const ApplicationContent: React.FC<Props> = ({
  children,
  isInProject,
}) => {
  return (
    <Container isInProject={isInProject}>
      <ContentWrapper>
        <Content>
          <Board isInProject={isInProject}>{children}</Board>
        </Content>
      </ContentWrapper>
    </Container>
  );
};
