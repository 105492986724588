/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Tooltip } from '@material-ui/core';
import { DataGrid } from 'devextreme-react';
import { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  FiCalendar,
  FiChevronLeft,
  FiCrop,
  FiFileText,
  FiList,
  FiSkipForward,
  FiStar,
  FiTool,
  FiUser,
  FiX,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { WhisperSpinner } from 'react-spinners-kit';
import useSWR from 'swr';
import { Button } from '../../components/Button';
import { FormGroup } from '../../components/FormGroup';
import { SelectBox } from '../../components/SelectBox';
import { TextArea } from '../../components/TextArea';
import portalGroups from '../../config/portalGroups';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import api, {
  ICycle,
  IProfessionalProjectEvaluation,
} from '../../services/api';
import { ContainerLoading } from '../ProjectDuring/DashboardModal/styles';
import { DialogConfirmChangeProjectStatus } from './DialogConfirmChangeProjectStatus';
import { DialogIDOne } from './DialogIDOne';
import { Body, Container, CycleTitle, ExpandButton } from './styles';
import { useMethodology } from '../../hooks/methodologyContext';

export const ProjectOverview: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const id = history.location.pathname.split('/')[2];
  // const { id } = useParams<{
  //   id: string;
  // }>();
  // console.log(pageId);
  const { hasScopes, user } = useAuth();

  const isAdmin = useMemo(() => {
    return hasScopes([portalGroups.Admin, portalGroups.OnePage]);
  }, [hasScopes]);
  const { control, setValue } = useForm();
  const [loading, setLoading] = useState(false);

  const [openIDOne, setOpenIDOne] = useState(false);
  const [
    openConfirmationChangeProjectStatusDialog,
    setOpenConfirmationChangeProjectStatusDialog,
  ] = useState(false);

  const [projectStatus, setProjectStatus] = useState<number>();
  // const [beginClosure, setEnableBeginClosure] = useState(false);
  // const [closeProject, setEnableCloseProject] = useState(false);
  const [projectInfo, setProjectInfo] = useState<any>({});
  const [kpis, setKpis] = useState<any>({});
  // const buttonList = ['End Setup', 'Start Closure', 'End Project'];
  const [isProjectManagerOrPartner, setIsProjectManagerOrPartner] =
    useState(false);
  const [projectEvaluations, setProjectEvaluations] = useState<DataSource>();
  const [cycles, setCycles] = useState<ICycle[]>([]);
  const [selectedCycle, setSelectedCycle] = useState<number>(0);
  const [projectTeam, setProjectTeam] = useState();
  // const [isStatusDropDownOpen, setIsStatusDropDownOpen] = useState(false);
  const [isEvaluationDialogOpen, setIsEvaluationDialogOpen] = useState(false);
  const [isLearningDialogOpen, setIsLearningDialogOpen] = useState(false);
  const [isSupportDialogOpen, setIsSupportDialogOpen] = useState(false);
  const [isProjectOverviewExpanded, setIsProjectOverviewExpanded] =
    useState(false);
  const [masterPermission, setMasterPermission] = useState(false);
  const [hasProjectEvaluation, setHasProjectEvaluation] = useState(false);
  const {methodology} = useMethodology();

  const fetcherProject = (url: string) => api.get(url).then(res => res.data);
  const { data: project } = useSWR(`/api/projects/${id}`, fetcherProject);

  const fetcherKPI = (url: string) => api.get(url).then(res => res.data);
  const { data: kpi } = useSWR(`/api/projects/${id}/kpis`, fetcherKPI, {
    refreshInterval: 5000,
  });

  const loadProjectInfo = useCallback(async () => {
    setLoading(true);
    if (!project || !kpi) return;

    // NAO TA FUNCIONANDO A CHAMADA DE PROFISSIONAL OLHAR AQUI CORRETAMENTE
    setProjectInfo(project);

    const projectTeamResponse = await api.get(`/api/projects/${id}/team`);

    setProjectTeam(projectTeamResponse.data);

    setIsProjectManagerOrPartner(
      project.projectTeam.find(
        (x: any) =>
          x.idProfessional === user?.userId && (x.isPartner || x.isManager),
      ),
    );

    setMasterPermission(
      project.projectTeam.find(
        (x: any) =>
          x.idProfessional === user?.userId && (x.isPartner || x.isManager),
      ) || isAdmin,
    );

    setValue('tdOportunity', project.projectTrainingDevelopment?.tdOportunity);
    setValue('text', project.projectTrainingDevelopment?.text);

    setProjectStatus(project.idStatus);
    setKpis(kpi);
    setLoading(false);
  }, [project, kpi, id, isAdmin, setValue, user]);

  const loadProjectEvaluations = useCallback(async () => {
    setLoading(true);
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get<IProfessionalProjectEvaluation[]>(
          `api/project-evaluations/project/${id}`,
        );

        const cycleArray = data.map(
          x =>
            x.cycle && {
              ...x.cycle,
              displayName: `${x.cycle?.period} - ${x.cycle?.year}`,
            },
        );
        const uniqueArr = cycleArray.filter(
          (obj, index, self) =>
            obj?.id && index === self.findIndex(t => t.id === obj.id),
        );
        const activeCycle = uniqueArr.find(x => x.isActive);
        setCycles(uniqueArr);
        setHasProjectEvaluation(data.length > 0);
        setLoading(false);
        return data.filter(x => x.id === selectedCycle || activeCycle?.id);
      },
      insert: async data => {
        await api.post(`api/project-evaluations/project/${id}`, data);
      },
      update: async (key, data) => {
        await api.put(`/api/project-evaluations/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project-evaluations/${key}`);
      },
    });

    setProjectEvaluations(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id, selectedCycle]);

  useEffect(() => {
    loadProjectInfo();
    loadProjectEvaluations();
  }, [loadProjectInfo, loadProjectEvaluations]);

  const updateTD = useCallback(
    async (fieldName, value) => {
      try {
        await api.patch(`/api/projects/${id}/training-development`, {
          [fieldName]: value,
        });
        addToast({
          type: 'success',
          title: 'Saved',
          description: '',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          description: '',
        });
      }
    },
    [addToast, id],
  );

  const statusToChange = useMemo(() => {
    if (projectStatus === 1) return 2;
    if (projectStatus === 2) return 3;
    if (projectStatus === 3) return 4;
    if (projectStatus === 4) return 5;
    return 0;
  }, [projectStatus]);

  return (
    <Container isProjectOverviewExpanded={isProjectOverviewExpanded}>
      {loading ? (
        <ContainerLoading>
          <WhisperSpinner
            size={isProjectOverviewExpanded ? 58 : 30}
            backColor="#8b0304"
            frontColor="#fff"
          />
        </ContainerLoading>
      ) : (
        <>
          <Body isProjectOverviewExpanded={isProjectOverviewExpanded}>
            <div className="header">
              <div className="titleStatus">
                <div className="statusIcon">
                  {projectStatus === 1 && (
                    <Tooltip placement="left" title="Project Draft">
                      <svg
                        className="draft"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20.1081 4C28.9934 4 36.2162 11.2228 36.2162 20.1081C36.2162 28.9934 28.9934 36.2022 20.1081 36.2022C11.2228 36.2022 4 28.9794 4 20.0941C4 11.2088 11.2368 4 20.1081 4ZM20.1081 34.0088C27.778 34.0088 34.0088 27.7779 34.0088 20.1081C34.0088 12.4382 27.7779 6.20735 20.1081 6.20735C12.4382 6.20735 6.20736 12.4382 6.20736 20.1081C6.20736 27.7779 12.4522 34.0088 20.1081 34.0088Z" />
                      </svg>
                    </Tooltip>
                  )}
                  {projectStatus === 2 && (
                    <Tooltip placement="left" title="Project Setup">
                      <svg
                        className="setup"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M22.1993 36.4879C13.314 36.4879 6.09119 29.2651 6.09119 20.3798C6.09119 11.4945 13.314 4.28572 22.1993 4.28572C31.0846 4.28572 38.3074 11.5085 38.3074 20.3938C38.3074 29.2791 31.0706 36.4879 22.1993 36.4879ZM22.1993 6.4791C14.5294 6.4791 8.29854 12.71 8.29854 20.3798C8.29854 28.0497 14.5294 34.2806 22.1993 34.2806C29.8691 34.2806 36.1 28.0497 36.1 20.3798C36.1 12.71 29.8552 6.4791 22.1993 6.4791Z" />
                        <path d="M7.125 27.5188C11.06 27.5188 14.25 24.3288 14.25 20.3938C14.25 16.4588 11.06 13.2688 7.125 13.2688C3.18997 13.2688 0 16.4588 0 20.3938C0 24.3288 3.18997 27.5188 7.125 27.5188Z" />
                      </svg>
                    </Tooltip>
                  )}

                  {projectStatus === 3 && (
                    <Tooltip placement="left" title="Project During">
                      <svg
                        className="during"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20.1081 4C28.9934 4 36.2162 11.2228 36.2162 20.1081C36.2162 28.9934 28.9934 36.2022 20.1081 36.2022C11.2228 36.2022 4 28.9794 4 20.0941C4 11.2088 11.2368 4 20.1081 4ZM20.1081 34.0088C27.778 34.0088 34.0088 27.7779 34.0088 20.1081C34.0088 12.4382 27.7779 6.20735 20.1081 6.20735C12.4382 6.20735 6.20736 12.4382 6.20736 20.1081C6.20736 27.7779 12.4522 34.0088 20.1081 34.0088Z" />
                        <path d="M20.125 13C16.19 13 13 16.19 13 20.125C13 24.06 16.19 27.25 20.125 27.25C24.06 27.25 27.25 24.06 27.25 20.125C27.25 16.19 24.06 13 20.125 13Z" />
                      </svg>
                    </Tooltip>
                  )}
                  {projectStatus === 4 && (
                    <Tooltip placement="left" title="Project Closure">
                      <svg
                        className="closure"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M17.5367 4.28571C26.422 4.28571 33.6448 11.5085 33.6448 20.3938C33.6448 29.2791 26.422 36.4879 17.5367 36.4879C8.65139 36.4879 1.42859 29.2651 1.42859 20.3798C1.42859 11.4945 8.66536 4.28571 17.5367 4.28571ZM17.5367 34.2945C25.2065 34.2945 31.4374 28.0636 31.4374 20.3938C31.4374 12.7239 25.2065 6.49306 17.5367 6.49306C9.86683 6.49306 3.63594 12.7239 3.63594 20.3938C3.63595 28.0637 9.8808 34.2945 17.5367 34.2945Z" />
                        <path d="M32.611 13.2548C28.6759 13.2548 25.486 16.4448 25.486 20.3798C25.486 24.3149 28.6759 27.5048 32.611 27.5048C36.546 27.5048 39.736 24.3148 39.736 20.3798C39.736 16.4448 36.546 13.2548 32.611 13.2548Z" />
                      </svg>
                    </Tooltip>
                  )}

                  {projectStatus === 5 && (
                    <Tooltip placement="left" title="Project Finished">
                      <svg
                        className="finished"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20.1081 4C28.9934 4 36.2162 11.2228 36.2162 20.1081C36.2162 28.9934 28.9934 36.2022 20.1081 36.2022C11.2228 36.2022 4 28.9794 4 20.0941C4 11.2088 11.2368 4 20.1081 4ZM20.1081 34.0088C27.778 34.0088 34.0088 27.7779 34.0088 20.1081C34.0088 12.4382 27.7779 6.20735 20.1081 6.20735C12.4382 6.20735 6.20736 12.4382 6.20736 20.1081C6.20736 27.7779 12.4522 34.0088 20.1081 34.0088Z" />
                        <circle cx="20" cy="20" r="15" />
                      </svg>
                    </Tooltip>
                  )}
                  {projectStatus === 6 && (
                    <Tooltip placement="left" title="Project Paused">
                      <svg
                        className="paused"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20.1081 4C28.9934 4 36.2162 11.2228 36.2162 20.1081C36.2162 28.9934 28.9934 36.2022 20.1081 36.2022C11.2228 36.2022 4 28.9794 4 20.0941C4 11.2088 11.2368 4 20.1081 4ZM20.1081 34.0088C27.778 34.0088 34.0088 27.7779 34.0088 20.1081C34.0088 12.4382 27.7779 6.20735 20.1081 6.20735C12.4382 6.20735 6.20736 12.4382 6.20736 20.1081C6.20736 27.7779 12.4522 34.0088 20.1081 34.0088Z" />
                        <rect x="21" y="14" width="6" height="12" rx="2.5" />
                        <rect x="13" y="14" width="6" height="12" rx="2.5" />
                      </svg>
                    </Tooltip>
                  )}
                </div>

                <div>
                  <h1>{projectInfo && projectInfo.name}</h1>
                  <h2>{projectInfo && projectInfo.code}</h2>
                </div>
              </div>
            </div>
            <h2 style={{ color: '#CCC' }}>Team</h2>
            <div className="team">
              {projectInfo?.projectTeam?.map((p: any) => (
                <Tooltip
                  placement="top"
                  title={p?.professional?.name}
                  key={p?.id}
                >
                  <a
                    href={`https://teams.microsoft.com/l/chat/0/0?users=${p?.professional?.email}`}
                    target="_blank"
                    style={{ display: 'flex', flexDirection: 'column' }}
                    rel="noreferrer"
                  >
                    <img
                      src={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${p?.professional?.login}_192_192.png`}
                      alt={p?.professional?.name}
                    />
                  </a>
                </Tooltip>
              ))}
            </div>
            <h2 style={{ color: '#CCC' }}>Project Week Overview</h2>
            <div className="statusRows">
              <Tooltip
                placement="left"
                title={isProjectOverviewExpanded ? '' : 'One Page Submitted'}
              >
                <div
                  className="iconCard"
                  onClick={() =>
                    history.replace(`/project/${id}/during/one-page`)
                  }
                >
                  <div>
                    <FiFileText
                      stroke={!kpis.onePageSubmitted ? '#E74C3C' : '#2ecc71'}
                      size="25"
                    />
                  </div>
                  <h3>One Page Submitted</h3>
                </div>
              </Tooltip>

              <Tooltip
                placement="left"
                title={
                  isProjectOverviewExpanded ? '' : 'Weekly Status Submitted'
                }
              >
                <div
                  className="iconCard"
                  onClick={() =>
                    history.replace(`/project/${id}/during/weekly-status`)
                  }
                >
                  <div>
                    <FiCalendar
                      stroke={
                        !kpis.weeklyStatusSubmitted ? '#E74C3C' : '#2ecc71'
                      }
                      size="25"
                    />
                  </div>
                  <h3>Weekly Status Submitted</h3>
                </div>
              </Tooltip>

              <Tooltip
                placement="left"
                title={isProjectOverviewExpanded ? '' : 'Professional DNA'}
              >
                <div
                  className="iconCard"
                  onClick={() =>
                    history.replace(`/project/${id}/during/professional-DNA`)
                  }
                >
                  <div>
                    <FiUser
                      stroke={!kpis.hasProfessionalDNA ? '#E74C3C' : '#2ecc71'}
                      size="25"
                    />
                  </div>
                  <h3>Professional DNA</h3>
                </div>
              </Tooltip>

              <Tooltip
                placement="left"
                title={isProjectOverviewExpanded ? '' : 'Technical DNA'}
              >
                <div
                  className="iconCard"
                  onClick={() =>
                    history.replace(`/project/${id}/during/project-session`)
                  }
                >
                  <div>
                    <FiCrop
                      stroke={!kpis.hasTechDNA ? '#E74C3C' : '#2ecc71'}
                      size="25"
                    />
                  </div>

                  <h3>Technical DNA</h3>
                </div>
              </Tooltip>

              {(isProjectManagerOrPartner || isAdmin) && (
                <>
                  <Tooltip
                    placement="left"
                    title={
                      isProjectOverviewExpanded ? '' : 'Evaluation Management'
                    }
                  >
                    <div
                      className="iconCard"
                      style={{ width: '100%' }}
                      onClick={() => setIsEvaluationDialogOpen(true)}
                    >
                      <div>
                        <FiStar
                          stroke={
                            kpis.evaluationManagementFulfilled
                              ? '#2ecc71'
                              : '#E74C3C'
                          }
                          size="25"
                        />
                      </div>

                      <h3>Evaluation Management</h3>
                    </div>
                  </Tooltip>
                </>
              )}
            </div>
            <h2 style={{ color: '#CCC' }}>Quick Tools</h2>
            <div className="quickTools">
              {masterPermission && (
                <Tooltip
                  placement="left"
                  title={
                    isProjectOverviewExpanded
                      ? ''
                      : 'Project Learning and Development'
                  }
                >
                  <button
                    type="button"
                    onMouseUp={() => setIsLearningDialogOpen(true)}
                  >
                    <FiList />
                    <span>Project Learning and Development</span>
                  </button>
                </Tooltip>
              )}
              <Tooltip
                placement="left"
                title={isProjectOverviewExpanded ? '' : 'Project Support'}
              >
                <button
                  type="button"
                  onMouseUp={() => setIsSupportDialogOpen(true)}
                >
                  <FiTool />
                  <span>Project Support</span>
                </button>
              </Tooltip>
              {projectStatus !== 5 && (
                <Tooltip
                  placement="left"
                  title={
                    isProjectOverviewExpanded
                      ? ''
                      : `Forward to
                ${
                  (projectStatus === 1 && ' Setup') ||
                  (projectStatus === 2 && ' During') ||
                  (projectStatus === 3 && ' Closure') ||
                  (projectStatus === 4 && ' Finished')
                }
              `
                  }
                >
                  <button
                    type="button"
                    onMouseUp={() => {
                      if (projectStatus === 4 && (methodology === null || methodology === "" || methodology === undefined || methodology.length === 0)) {
                        alert("Complete the Methodology field on the form to Finish the Project");
                      } else {
                        setOpenConfirmationChangeProjectStatusDialog(true);
                      }
                    }}
                  >
                    <FiSkipForward />
                    <span>
                      Forward to
                      {projectStatus === 1 && ' Setup'}
                      {projectStatus === 2 && ' During'}
                      {projectStatus === 3 && ' Closure'}
                      {projectStatus === 4 && ' Finished'}
                    </span>
                  </button>
                </Tooltip>
              )}
            </div>
          </Body>
          <ExpandButton
            isProjectOverviewExpanded={isProjectOverviewExpanded}
            onClick={() => setIsProjectOverviewExpanded(x => !x)}
          >
            <FiChevronLeft size={15} />
          </ExpandButton>
        </>
      )}
      {isEvaluationDialogOpen && (
        <>
          <div className="customDialog">
            <FiX
              className="closeButton"
              onMouseDown={() => setIsEvaluationDialogOpen(false)}
            />
            <CycleTitle>Select the cycle</CycleTitle>
            <SelectBox
              dataSource={cycles}
              displayExpr="displayName"
              valueExpr="id"
              value={selectedCycle}
              onChanged={value => setSelectedCycle(Number(value))}
            />
            <DataGrid
              dataSource={projectEvaluations}
              width="100%"
              onRowUpdating={options => {
                options.newData = {
                  ...options.oldData,
                  ...options.newData,
                };
              }}
            >
              <Column
                dataField="idProfessional"
                caption="Professional"
                alignment="center"
                cellRender={e => e.data.professional?.name}
              >
                <Lookup
                  dataSource={projectTeam}
                  displayExpr="professional.name"
                  valueExpr="professional.id"
                />
              </Column>
              <Column
                dataField="idEvaluator"
                caption="Evaluator"
                alignment="center"
                cellRender={e => e.data.evaluator?.name}
              >
                <Lookup
                  dataSource={projectTeam}
                  displayExpr="professional.name"
                  valueExpr="professional.id"
                />
              </Column>

              <Column
                dataField="idValidator"
                caption="Validator"
                cellRender={e => e.data.validator?.name}
              >
                <Lookup
                  dataSource={projectTeam}
                  displayExpr="professional.name"
                  valueExpr="professional.id"
                />
              </Column>
              <Column
                caption="Project Partner"
                dataField="idPartner"
                cellRender={e => e.data.partner?.name}
              >
                <Lookup
                  dataSource={projectTeam}
                  displayExpr="professional.name"
                  valueExpr="professional.id"
                />
              </Column>
              <Column
                caption="Allocated Days"
                dataField="totalDays"
                alignment="center"
                allowEditing={false}
              />
              <Column dataField="isEligible" alignment="center">
                <Lookup
                  dataSource={[
                    { value: true, name: 'Yes' },
                    { value: false, name: 'No' },
                  ]}
                  displayExpr="name"
                  valueExpr="value"
                />
              </Column>
              <Column
                caption="Evaluation Status"
                dataField="evaluationStatus.description"
                alignment="center"
                allowEditing={false}
              />
              <Editing
                allowDeleting={(e: any) => !e.row.data.isReadOnly}
                allowUpdating={(e: any) => !e.row.data.isReadOnly}
              />
            </DataGrid>
          </div>
        </>
      )}
      {isLearningDialogOpen && (
        <>
          <div
            className="customDialogBG"
            onMouseUp={() => setIsLearningDialogOpen(false)}
          />
          <div className="customDialog">
            <FiX
              className="closeButton"
              onMouseUp={() => setIsLearningDialogOpen(false)}
            />
            <h2> Project Learning and Development</h2>

            <div className="tdev">
              <FormGroup
                fieldSetLabel="Does your project team have any opportunities for
                        learning or development?"
              >
                <Controller
                  name="tdOportunity"
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      searchEnabled
                      dataSource={[
                        { value: true, name: 'Yes' },
                        { value: false, name: 'No' },
                      ]}
                      valueExpr="value"
                      displayExpr="name"
                      value={field.value}
                      onChanged={value => {
                        setValue(field.name, value);
                        updateTD(field.name, value);
                      }}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup
                fieldSetLabel="Which learning &amp; development opportunities or tools
                        could be provided to help the team deliver this project?
                        e.g. technical training, specific tool training,
                        methodology, cultural training, method training,
                        sessions with methodology specialists etc."
              >
                <Controller
                  name="text"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      value={field.value}
                      onChanged={value => {
                        setValue(field.name, value);
                        updateTD(field.name, value);
                      }}
                    />
                  )}
                />
              </FormGroup>
            </div>
          </div>
        </>
      )}
      {isSupportDialogOpen && (
        <>
          <div
            className="customDialogBG"
            onMouseUp={() => setIsSupportDialogOpen(false)}
          />
          <div className="customDialog">
            <FiX
              className="closeButton"
              onMouseUp={() => setIsSupportDialogOpen(false)}
            />
            <h2>Project Support</h2>
            <div className="row">
              <div className="card">
                <span className="titleContainer">
                  <p className="title">Sugestions/Others</p>
                </span>
                <Button primary>
                  <a
                    style={{
                      textDecoration: 'none',
                      color: '#fff',
                    }}
                    href="mailto:deliverycell@integrationconsulting.com"
                  >
                    Contact us
                  </a>
                </Button>
                <p className="disclaimer">Expected response next Monday</p>
              </div>
              <div className="card">
                <span className="titleContainer">
                  <p className="title">Allocation</p>
                </span>
                <Button primary>
                  {' '}
                  <a
                    style={{
                      textDecoration: 'none',
                      color: '#fff',
                    }}
                    href="mailto:lurdaneta@integrationconsulting.com"
                  >
                    Contact us
                  </a>
                </Button>
              </div>
              <div className="card">
                <span className="titleContainer">
                  <p className="title">System Issue</p>
                </span>
                <Button primary>
                  {' '}
                  <a
                    style={{
                      textDecoration: 'none',
                      color: '#fff',
                    }}
                    href="mailto:support@integrationconsulting.com"
                  >
                    Contact us
                  </a>
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="card">
                <span className="titleContainer">
                  <p className="title">Marketing</p>
                </span>
                <Button primary>
                  {' '}
                  <a
                    style={{
                      textDecoration: 'none',
                      color: '#fff',
                    }}
                    href="mailto:mkt.experience@integrationconsulting.com"
                  >
                    Contact us
                  </a>
                </Button>
              </div>
              <div className="card">
                <span className="titleContainer">
                  <p className="title">Knowledge Management</p>
                </span>
                <Button primary>
                  {' '}
                  <a
                    style={{
                      textDecoration: 'none',
                      color: '#fff',
                    }}
                    href="mailto:knowledge.management@integrationconsulting.com"
                  >
                    Contact us
                  </a>
                </Button>
              </div>
              <div className="card">
                <span className="titleContainer">
                  <p className="title">TECH.LAB</p>
                </span>
                <Button primary>
                  {' '}
                  <a
                    style={{
                      textDecoration: 'none',
                      color: '#fff',
                    }}
                    href="mailto:tknw.integrationconsulting.com"
                  >
                    Contact us
                  </a>
                </Button>
                <p className="disclaimer">Expected response next Monday</p>
              </div>
            </div>
            <div className="row bordered">
              <p className="title">Powerpoint Upgrade References</p>
              <p className="description">
                Improvement of Powerpoint documents, aiming visual enhancing,
                animations and diagraming of presentation and content. The
                upgrade work takes into consideration the importance of
                capturing the attention of the spectator, as a way to accomplish
                an efficient result, both on the material, and also on the way
                of presenting it.
              </p>
              <Button
                primary
                onClick={() =>
                  window.open(
                    'https://portal.integrationconsulting.com/Content/corporative/powerpoint_upgrade_references.pdf',
                    '_blank',
                  )
                }
              >
                Download
              </Button>
            </div>
            <div className="row bordered">
              <p className="title">
                Newsletters and Email Marketing References
              </p>
              <p className="description">
                Material developed to transmit status or other information from
                the Project. Can be used by the client internally of externally.
              </p>
              <Button
                primary
                onClick={() =>
                  window.open(
                    'https://portal.integrationconsulting.com/Content/corporative/newsletters_and_email_marketing_references.pdf',
                    '_blank',
                  )
                }
              >
                Download
              </Button>
            </div>
            <div className="row bordered">
              <p className="title">
                Deliveries and Workshop Materials References
              </p>
              <p className="description">
                Surprise the client during Kick Off meetings, during a
                turnaround, or during project close out workshops with small
                customized souvenir from the occasion. These are relevant
                occasions that represent important points in the project.
              </p>
              <Button
                primary
                onClick={() =>
                  window.open(
                    'https://portal.integrationconsulting.com/Content/corporative/deliveries_and_workshop_materials_references.pdf',
                    '_blank',
                  )
                }
              >
                Download
              </Button>
            </div>
            <div className="row bordered">
              <p className="title">Closing Letters and Gifts References</p>
              <p className="description">
                Gifts and thank-you letters are always a good way for us to make
                our mark and reiterate important points from decisions taken
                during a project. They are, above all, the recognition and
                appreciation of the participation of everyone who made the
                project happen.
              </p>
              <Button
                primary
                onClick={() =>
                  window.open(
                    'https://portal.integrationconsulting.com/Content/corporative/closing_letters_and_gifts_references.pdf',
                    '_blank',
                  )
                }
              >
                Download
              </Button>
            </div>
            <div className="row bordered">
              <p className="title">Binders, Books and Folders References</p>
              <p className="description">
                Differentiated solutions, with a high quality presentation and
                finish, and with the project identity embedded throughout,
                contribute a great deal to a delivery that’s innovative and that
                resonates. It is the manifestation of the end of a journey, of
                the work and involvement of many people. It’s also another
                opportunity to highlight our differentials and unique approach
                to dealing with clients.
              </p>
              <Button
                primary
                onClick={() =>
                  window.open(
                    'https://portal.integrationconsulting.com/Content/corporative/binders_books_and_folders_references.pdf',
                    '_blank',
                  )
                }
              >
                Download
              </Button>
            </div>
            <div className="row bordered">
              <p className="title">Ambience References</p>
              <p className="description">
                The items for setting up the project environment set up in the
                project room are geared towards the following factors: being
                inviting to the client to come and visit us; being transparent
                in demonstrating who we are; creating a well put together
                project status; showing that we are attentive to detail and
                highly professional.
              </p>
              <Button
                primary
                onClick={() =>
                  window.open(
                    'https://portal.integrationconsulting.com/Content/corporative/%20ambience_references.pdf',
                    '_blank',
                  )
                }
              >
                Download
              </Button>
            </div>
            <div className="row bordered">
              <p className="title">Visual Identity References</p>
              <p className="description">
                The use of customized visual identities is one of Integration’s
                main differentials, an important part of our culture and
                methodology that reinforces the fact that the project is not
                ours, but the clients’.
              </p>
              <Button
                primary
                onClick={() =>
                  window.open(
                    'https://portal.integrationconsulting.com/Content/corporative/visual_identity_references.pdf',
                    '_blank',
                  )
                }
              >
                Download
              </Button>
            </div>
          </div>
        </>
      )}
      {openConfirmationChangeProjectStatusDialog && (
        <DialogConfirmChangeProjectStatus
          id={parseInt(id, 10)}
          key={id}
          open={openConfirmationChangeProjectStatusDialog}
          handleCloseAndReload={() => {
            setOpenConfirmationChangeProjectStatusDialog(false);
            loadProjectInfo();
          }}
          handleClose={() => {
            setOpenConfirmationChangeProjectStatusDialog(false);
          }}
          statusId={statusToChange}
          project={project}
        />
      )}
      {openIDOne && (
        <DialogIDOne
          id={parseInt(id, 10)}
          key={id}
          open={openIDOne}
          handleClose={() => {
            setOpenIDOne(false);
          }}
        />
      )}
    </Container>
  );
};
