import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: absolute;
  z-index: 1;
  align-items: center;
  justify-content: center;
`;
