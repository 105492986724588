import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  Editing,
  Lookup,
  HeaderFilter,
  SearchPanel,
  Popup,
  Form,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { FiEdit2 } from 'react-icons/fi';
import { useParams } from 'react-router';
import { WhisperSpinner } from 'react-spinners-kit';
import { Item } from 'devextreme-react/form';
import api from '../../../services/api';
import master from '../../../services/master';
import { ContainerLoading } from '../ClientTab/styles';
import { BoardHeader } from '../../../components/BoardHeader';
import { Body } from './styles';
import portalGroups from '../../../config/portalGroups';
import { useAuth } from '../../../hooks/auth';

export const RolesResponsibilitiesTab: React.FC<{ title?: string }> = ({
  title,
}) => {
  const { id } = useParams<{
    id: string;
  }>();
  const { hasScopes } = useAuth();
  const isAdmin = useMemo(() => {
    return hasScopes([portalGroups.Admin]);
  }, [hasScopes]);
  const [loading, setLoading] = useState(false);
  const [setupFinished, setSetupFinished] = useState(false);
  const [projectRAR, setProjectRAR] = useState<DataSource>();
  const [idRar, setIdRar] = useState(0);
  const [openEditRar, setOpenEditRar] = useState(false);
  const [idSetupSession, setIdSetupSession] = useState(0);
  const [filteredProfessionals, setFilteredProfessionals] =
    useState<DataSource>();

  const loadProjectInfos = useCallback(async () => {
    setLoading(true);
    const projectResponse = await api.get(`/api/projects/${id}`);
    setSetupFinished(projectResponse.data.idStatus !== 2);

    const professionalResponse = await master.get('/master/professionals', {
      params: {
        where: {
          IsAnyStatus: true,
        },
      },
    });

    const projectTeamIds = projectResponse.data.projectTeam.map((x: any) => {
      return x.professional?.id;
    });

    setFilteredProfessionals(
      professionalResponse.data
        .map((p: any) => {
          return { ID: p.ID, Name: p.Name };
        })
        .filter((x: any) => projectTeamIds.includes(x.ID)),
    );

    setIdSetupSession(
      projectResponse.data.projectSession?.find((x: any) => x.isSetup)?.id || 0,
    );
    setLoading(false);
  }, [id]);

  const loadProjectRAR = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `/api/project/${id}/rar/session/${idSetupSession}`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `/api/project/${id}/rar/session/${idSetupSession}`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/project/${id}/rar/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project/${id}/rar/${key}`);
      },
    });

    setProjectRAR(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id, idSetupSession]);

  useEffect(() => {
    loadProjectInfos();
    loadProjectRAR();
  }, [loadProjectInfos, loadProjectRAR]);

  return (
    <>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}
      <BoardHeader title="SETUP" subtitle="" />

      <span>
        <h1
          style={{
            textTransform: 'uppercase',
            color: '#6c6c6c',
            fontSize: '24px',
            fontWeight: 700,
            letterSpacing: '0.1em',
          }}
        >
          Roles and Responsibilities
        </h1>
      </span>

      <Body>
        <DataGrid
          dataSource={projectRAR}
          onRowUpdating={options => {
            options.newData = {
              ...options.oldData,
              ...options.newData,
            };
          }}
        >
          <HeaderFilter visible />
          <SearchPanel visible width={250} />
          <Column
            dataField="idProfessional"
            caption="Professional"
            alignment="left"
          >
            <Lookup
              dataSource={filteredProfessionals}
              displayExpr="Name"
              valueExpr="ID"
            />
          </Column>
          <Column
            dataField="rarSupportingPoint"
            caption="Supporting Point"
            alignment="left"
          />
          <Column
            dataField="rarTechnicalRole"
            caption="Technical Role"
            alignment="left"
          />
          <Column
            dataField="rarNonTechnicalRole"
            caption="Non-Technical Role"
            alignment="left"
          />

          <Editing
            mode="popup"
            allowDeleting={isAdmin || !setupFinished}
            allowAdding={isAdmin || !setupFinished}
            allowUpdating={isAdmin || !setupFinished}
          >
            <Popup />
            <Form>
              <Item itemType="group" colCount={2} colSpan={2}>
                <Item dataField="idProfessional" colSpan={1} />
                <Item
                  dataField="rarSupportingPoint"
                  editorType="dxTextArea"
                  colSpan={1}
                />
                <Item
                  dataField="rarTechnicalRole"
                  editorType="dxTextArea"
                  colSpan={1}
                />
                <Item
                  dataField="rarNonTechnicalRole"
                  editorType="dxTextArea"
                  colSpan={1}
                />
              </Item>
            </Form>
          </Editing>
        </DataGrid>
      </Body>
    </>
  );
};
