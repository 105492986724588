import React, { useCallback, useEffect, useState } from 'react';

import { DataGrid } from 'devextreme-react';
import { Column, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { IconButton, Tooltip } from '@material-ui/core';
import { FiEdit2 } from 'react-icons/fi';
import { Container, Body } from './styles';
import { useDocumentTitle } from '../../../../hooks/documentTitle';
import api from '../../../../services/api';
import { DialogEditChecklist } from './DialogEditChecklist';
import { BoardHeader } from '../../../../components/BoardHeader';

export const KNMChecklist: React.FC = () => {
  const { setTitle } = useDocumentTitle();

  const [projects, setProjects] = useState<DataSource>();
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [checkListModalKey, setCheckListModalKey] = useState<any>();

  const loadProjects = useCallback(async () => {
    const projectStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/projects');
        return data;
      },
    });

    setProjects(
      new DataSource({
        store: projectStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    loadProjects();
  }, [setTitle, loadProjects]);

  const textCells = useCallback(e => {
    return (
      <p
        style={{
          fontWeight: '600',
          textTransform: 'uppercase',
          letterSpacing: '0.05em',
        }}
      >
        {e.text}
      </p>
    );
  }, []);

  const editCell = useCallback(e => {
    return (
      <Tooltip title="Edit Checklist">
        <IconButton
          aria-label="edit"
          onClick={() => {
            setCheckListModalKey(e.key);
            setOpenModalEdit(true);
          }}
        >
          <FiEdit2 style={{ color: '#8b0305', fontSize: '18px' }} />
        </IconButton>
      </Tooltip>
    );
  }, []);

  return (
    <Container>
      <Body>
        <div
          style={{
            padding: '8px 14px',
          }}
        >
          <BoardHeader title="KNM - Project Check List" subtitle="" />
        </div>
        <hr />
        <DataGrid style={{ marginBottom: '10px' }} dataSource={projects}>
          <SearchPanel visible />
          <HeaderFilter visible />
          <Column
            dataField="code"
            caption="Project Code"
            // cellRender={textCells}
          />
          <Column
            dataField="client.name"
            caption="Client"
            // cellRender={textCells}
          />
          <Column cellRender={editCell} width={80} alignment="center" />
        </DataGrid>
      </Body>

      {openModalEdit && (
        <DialogEditChecklist
          open={openModalEdit}
          handleClose={() => {
            setOpenModalEdit(false);
            // eslint-disable-next-line no-unused-expressions
            projects?.reload();
          }}
          idProject={checkListModalKey}
        />
      )}
    </Container>
  );
};
