import React, { useCallback, useEffect, useState } from 'react';

import { DataGrid } from 'devextreme-react';
import {
  Column,
  HeaderFilter,
  Lookup,
  SearchPanel,
} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { useHistory } from 'react-router-dom';
import { Container, Body } from './styles';
import { useDocumentTitle } from '../../../hooks/documentTitle';
import { Button } from '../../../components/Button';
import api from '../../../services/api';
import master, { CustomStoreProps } from '../../../services/master';
import { useToast } from '../../../hooks/toast';
import { BoardHeader } from '../../../components/BoardHeader';

export const IDOne: React.FC<{ title?: string }> = ({ title }) => {
  const history = useHistory();
  // const { id } = useParams<{
  //   id: string;
  // }>();
  const id = history.location.pathname.split('/')[2];
  const { addToast } = useToast();

  const [projects, setProjects] = useState<DataSource>();

  const { setTitle } = useDocumentTitle();
  const loadProjects = useCallback(async () => {
    const projectStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/projects');

        return data.map((x: any) => ({
          ...x,
          projectIDOne: {
            ...x.projectIDOne,
            hasIDOne: x.projectIDOne?.hasIDOne || false,
          },
        }));
      },
    });

    setProjects(
      new DataSource({
        store: projectStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    setTitle(title);
    loadProjects();
  }, [setTitle, title, loadProjects]);

  const textCells = useCallback(e => {
    return (
      <p
        style={{
          fontWeight: '600',
          textTransform: 'uppercase',
          letterSpacing: '0.05em',
        }}
      >
        {e.text}
      </p>
    );
  }, []);

  const [professionals] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/professionals', {
          params: {
            where: {
              IsAnyStatus: true,
            },
          },
        });
        return data;
      },
    }),
    paginate: true,
  });

  const handleDownloadExcel = useCallback(async () => {
    try {
      addToast({
        type: 'info',
        title: 'Downloading...',
      });

      const response = await api.get('api/reports/idone', {
        responseType: 'blob',
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `id-one.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: 'success',
        title: 'Download finished',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Something were wrong. Try again please.',
      });
    }
  }, [addToast]);

  return (
    <Container>
      <Body>
        <div
          style={{
            padding: '8px 14px',
          }}
        >
          <BoardHeader title="ID One® Accredited Centre" subtitle="" />
        </div>
        <hr />
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '8px 14px',
          }}
        >
          <Button primary onClick={handleDownloadExcel}>
            Export Excel
          </Button>
        </div>
        <DataGrid
          style={{ marginBottom: '10px', cursor: 'unset' }}
          className="grid"
          dataSource={projects}
        >
          <HeaderFilter visible allowSearch />
          <SearchPanel visible />
          <Column
            dataField="code"
            caption="Project Code"
            cellRender={textCells}
            alignment="center"
          />
          <Column
            dataField="projectPartner.idProfessional"
            caption="Responsible"
            cellRender={textCells}
            alignment="center"
          >
            <Lookup
              dataSource={professionals}
              displayExpr="Name"
              valueExpr="ID"
            />
          </Column>
          <Column
            dataField="projectManager.idProfessional"
            caption="Project Manager"
            cellRender={textCells}
            alignment="center"
          >
            <Lookup
              dataSource={professionals}
              displayExpr="Name"
              valueExpr="ID"
            />
          </Column>
          <Column
            dataField="projectIDOne.hasIDOne"
            caption="Has IDOne"
            alignment="center"
            dataType="boolean"
          />
        </DataGrid>
      </Body>
    </Container>
  );
};
