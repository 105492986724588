import styled from 'styled-components';

export const Container = styled.div``;

export const ButtonsMenuBox = styled.div`
  background: #fff;
  display: flex;
  gap: 18px;
  padding: 18px;
  justify-content: flex-end;
`;

export const ButtonMenu = styled.button`
  display: flex;
  gap: 4px;
  align-items: center;
  border: none;
  background: #bbbbbb;
  padding: 16px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  transition: 0.2s;

  &:hover {
    background: #cccccc;
  }

  &:focus {
    background: #8b0304;
  }

  svg {
    margin-top: -2px;
  }
`;
