import React from 'react';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { DocumentTitleProvider } from './documentTitle';
import { CheckCellProvider } from './checkCell';
import { MethodologyProvider } from './methodologyContext';

interface AuxProps {
  children: React.ReactNode;
}

export default function AppProvider({ children }: AuxProps) {
  return (
    <AuthProvider>
      <ToastProvider>
        <DocumentTitleProvider>
          <MethodologyProvider>
            <CheckCellProvider>{children}</CheckCellProvider>
          </MethodologyProvider>
        </DocumentTitleProvider>
      </ToastProvider>
    </AuthProvider>
  );
}
