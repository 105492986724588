import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FiFolder, FiSettings } from 'react-icons/fi';
import { Tooltip } from '@material-ui/core';
import LogOut from '../../assets/images/logout.svg';
import { useAuth } from '../../hooks/auth';
import PortalLogo from '../../assets/images/portal-logo.png';
import PortalLogoMini from '../../assets/images/portal_logo_mini.svg';
import { Container, NavigationDialogItemComponent, SubMenu } from './styles';
import { IProfessionalProps } from '../../services/master';
import api from '../../services/api';
import portalGroups from '../../config/portalGroups';

export const Surface: React.FC = () => {
  const history = useHistory();
  const { user, hasScopes } = useAuth();

  const [projectId, setProjectId] = useState<string>();
  const [activeId, setActiveId] = useState<number>(0);

  const isInLocation = useCallback(
    (link: string) => history.location.pathname.includes(link),
    [history.location.pathname],
  );

  const handleHistoryPush = useCallback(
    (link: string) => {
      history.push(`/project/${projectId}${link}`);
    },
    [history, projectId],
  );

  const handleAdminPush = useCallback(
    (link: string) => {
      history.push(`/admin/${link}`);
    },
    [history],
  );

  const isAdmin = useMemo(() => {
    return hasScopes([
      portalGroups.Admin,
      portalGroups.IDOne,
      portalGroups.KNW,
      portalGroups.PDC,
    ]);
  }, [hasScopes]);

  const isActive = (link: boolean) =>
    link
      ? {
          backgroundColor: '#8b0304',
          color: '#FFF',
        }
      : {
          backgroundColor: '',
          color: '',
        };

  const [isMenuHovered, setIsMenuHovered] = useState<boolean>(false);

  useEffect(() => {
    if (isInLocation('project/')) {
      setProjectId(history.location.pathname.match(/\d/g)?.join('')); // gets project id from url
    }
  }, [history.location.pathname, isInLocation, projectId]);

  const { signOut } = useAuth();
  const [professional, setProfessional] = useState<IProfessionalProps>(
    {} as IProfessionalProps,
  );

  const loadProjectInfo = useCallback(async () => {
    if (user) {
      const { data } = await api.get<IProfessionalProps>(
        `/api/professionals/${user.userId}`,
      );
      setProfessional(data);
    }
  }, [user]);

  useEffect(() => {
    loadProjectInfo();
  }, [loadProjectInfo]);

  return (
    <Container
      onMouseEnter={() => {
        setIsMenuHovered(true);
        setActiveId(() => {
          if (history.location.pathname.includes('setup')) {
            return 1;
          }
          if (history.location.pathname.includes('during')) {
            return 2;
          }
          if (history.location.pathname.includes('closure')) {
            return 3;
          }
          if (history.location.pathname.includes('admin')) {
            return 4;
          }
          return 0;
        });
      }}
      onMouseLeave={() => {
        setIsMenuHovered(false);
        setActiveId(0);
      }}
      isMenuExpanded={isMenuHovered}
    >
      <NavigationDialogItemComponent
        onClick={() => history.push('/')}
        style={{ marginBottom: '2.5em', backgroundColor: 'transparent' }}
      >
        <img
          src={isMenuHovered ? PortalLogo : PortalLogoMini}
          className="portalLogo"
          alt="Portal V Logo"
          style={{ width: 'auto', height: isMenuHovered ? '40px' : '20px' }}
        />
      </NavigationDialogItemComponent>
      {isInLocation('project/') && (
        <>
          <NavigationDialogItemComponent
            className="projectHome title"
            onClick={() => handleHistoryPush('/home')}
            style={isActive(isInLocation('home'))}
          >
            <FiFolder size={20} />
            <div
              className="textContainer"
              style={{ display: isMenuHovered ? 'block' : 'none' }}
            >
              Project Home
            </div>
          </NavigationDialogItemComponent>
          <SubMenu className={`${activeId === 1 ? 'opened' : ''}`}>
            <NavigationDialogItemComponent
              onClick={() => {
                setActiveId(activeId === 1 ? 0 : 1);
              }}
              className="title"
            >
              <svg
                className="customIcon"
                width="25"
                height="23"
                viewBox="0 0 25 23"
                fill="#DDDDDD"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M13.9139 21.9H13.9143C16.7236 21.8951 19.4153 20.7647 21.3994 18.7583C23.3833 16.7522 24.4978 14.0341 24.5002 11.2004V11.2C24.5002 9.08448 23.8792 7.01607 22.7152 5.25641C21.5512 3.49668 19.8962 2.12452 17.959 1.31431C16.0217 0.504065 13.8898 0.292457 11.8332 0.706476C9.77664 1.12049 7.88851 2.14134 6.4074 3.63907C4.92634 5.13674 3.91877 7.04396 3.51137 9.11918C3.10397 11.1944 3.31486 13.3451 4.11757 15.2995C4.92031 17.2539 6.27908 18.9247 8.02279 20.0999C9.76656 21.2752 11.8167 21.9018 13.9139 21.9ZM9.31496 4.28273C10.6779 3.3713 12.2774 2.88949 13.9109 2.89774C16.0917 2.90875 18.1805 3.79172 19.7203 5.35524C21.2602 6.91894 22.1256 9.03616 22.1256 11.2442C22.1255 12.8979 21.6396 14.514 20.7299 15.8876C19.8203 17.2611 18.5281 18.3301 17.0178 18.96C15.5074 19.5898 13.8463 19.7524 12.2447 19.4273C10.6431 19.1023 9.17231 18.3041 8.01902 17.133C6.86567 15.9618 6.0818 14.4705 5.76754 12.8477C5.45327 11.2249 5.62292 9.54445 6.25478 8.01945C6.88661 6.49449 7.95194 5.1942 9.31496 4.28273Z" />
                <path d="M4.46552 15.7116C6.93176 15.7116 8.93104 13.6917 8.93104 11.1999C8.93104 8.70819 6.93176 6.68823 4.46552 6.68823C1.99928 6.68823 0 8.70819 0 11.1999C0 13.6917 1.99928 15.7116 4.46552 15.7116Z" />
              </svg>

              {isMenuHovered && <div className="textContainer">Setup</div>}
            </NavigationDialogItemComponent>
            <NavigationDialogItemComponent
              style={isActive(isInLocation('setup/client'))}
              onClick={() => handleHistoryPush('/setup/client')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">Client</div>
              )}
            </NavigationDialogItemComponent>

            <NavigationDialogItemComponent
              style={isActive(isInLocation('setup/project'))}
              onClick={() => handleHistoryPush('/setup/project')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">Project</div>
              )}
            </NavigationDialogItemComponent>

            <NavigationDialogItemComponent
              style={isActive(isInLocation('setup/toolkit'))}
              onClick={() => handleHistoryPush('/setup/toolkit')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">Toolkit</div>
              )}
            </NavigationDialogItemComponent>

            <NavigationDialogItemComponent
              style={isActive(isInLocation('setup/client-past-projects'))}
              onClick={() => handleHistoryPush('/setup/client-past-projects')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">Client Past Projects</div>
              )}
            </NavigationDialogItemComponent>

            <NavigationDialogItemComponent
              style={isActive(isInLocation('setup/stakeholders'))}
              onClick={() => handleHistoryPush('/setup/stakeholders')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">Stakeholders</div>
              )}
            </NavigationDialogItemComponent>

            <NavigationDialogItemComponent
              style={isActive(isInLocation('setup/professional-dna'))}
              onClick={() => handleHistoryPush('/setup/professional-dna')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">Professional DNA</div>
              )}
            </NavigationDialogItemComponent>

            <NavigationDialogItemComponent
              style={isActive(isInLocation('setup/roles'))}
              onClick={() => handleHistoryPush('/setup/roles')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">
                  Roles and Responsabilities
                </div>
              )}
            </NavigationDialogItemComponent>

            <NavigationDialogItemComponent
              style={isActive(isInLocation('setup/finalization'))}
              onClick={() => handleHistoryPush('/setup/finalization')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">Finalization</div>
              )}
            </NavigationDialogItemComponent>

            <NavigationDialogItemComponent
              style={isActive(isInLocation('setup/assessment'))}
              onClick={() => handleHistoryPush('/setup/assessment')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">Assessment</div>
              )}
            </NavigationDialogItemComponent>
          </SubMenu>
          <SubMenu className={` ${activeId === 2 ? 'opened' : ''}`}>
            <NavigationDialogItemComponent
              className="title"
              onClick={() => {
                setActiveId(activeId === 2 ? 0 : 2);
              }}
            >
              <svg
                className="customIcon"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12.5729 24.5L12.5738 24.5C15.7454 24.4752 18.7788 23.1979 21.0128 20.9464C23.2467 18.6949 24.5003 15.6517 24.5002 12.4799V12.4791C24.496 10.1035 23.7868 7.78255 22.4625 5.81034C21.1382 3.83814 19.2582 2.30338 17.0609 1.4005C14.8636 0.497613 12.4477 0.267234 10.1193 0.738544C7.79095 1.20985 5.65484 2.36164 3.98163 4.048C2.30841 5.73436 1.17336 7.87941 0.72028 10.2114C0.267199 12.5434 0.516469 14.9574 1.43651 17.1476C2.35656 19.3378 3.90597 21.2056 5.88848 22.5145C7.87099 23.8234 10.1974 24.5144 12.5729 24.5ZM7.2497 4.67708C8.80911 3.6331 10.6434 3.07574 12.52 3.07569C15.0213 3.07563 17.421 4.06468 19.1959 5.82709C20.9707 7.58951 21.9766 9.98227 21.9941 12.4834C22.0072 14.36 21.4628 16.1982 20.4298 17.7649C19.3968 19.3316 17.9217 20.5562 16.1918 21.2835C14.4618 22.0107 12.5549 22.2078 10.7127 21.8498C8.87059 21.4917 7.17628 20.5947 5.84467 19.2724C4.51306 17.9501 3.60413 16.2621 3.23317 14.4226C2.8622 12.583 3.04591 10.6747 3.76098 8.93967C4.47606 7.20465 5.6903 5.72107 7.2497 4.67708Z" />
                <path d="M12.5401 17.5866C15.3495 17.5866 17.6269 15.3092 17.6269 12.4999C17.6269 9.69051 15.3495 7.41309 12.5401 7.41309C9.73079 7.41309 7.45337 9.69051 7.45337 12.4999C7.45337 15.3092 9.73079 17.5866 12.5401 17.5866Z" />
              </svg>

              {isMenuHovered && <div className="textContainer">During</div>}
            </NavigationDialogItemComponent>
            <NavigationDialogItemComponent
              style={isActive(isInLocation('during/professional-DNA'))}
              onClick={() => handleHistoryPush('/during/professional-DNA')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">Professional DNA</div>
              )}
            </NavigationDialogItemComponent>

            <NavigationDialogItemComponent
              style={isActive(isInLocation('during/project-session'))}
              onClick={() => handleHistoryPush('/during/project-session')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">Project Session</div>
              )}
            </NavigationDialogItemComponent>
            <NavigationDialogItemComponent
              style={isActive(isInLocation('during/one-page'))}
              onClick={() => handleHistoryPush('/during/one-page')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">One Page</div>
              )}
            </NavigationDialogItemComponent>
            <NavigationDialogItemComponent
              style={isActive(isInLocation('during/weekly-status'))}
              onClick={() => handleHistoryPush('/during/weekly-status')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">Weekly Status</div>
              )}
            </NavigationDialogItemComponent>
            <NavigationDialogItemComponent
              style={isActive(isInLocation('during/dashboard-archive'))}
              onClick={() => handleHistoryPush('/during/dashboard-archive')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">Dashboard Archive</div>
              )}
            </NavigationDialogItemComponent>
          </SubMenu>
          <SubMenu className={` ${activeId === 3 ? 'opened' : ''}`}>
            <NavigationDialogItemComponent
              className="title"
              onClick={() => {
                setActiveId(activeId === 3 ? 0 : 3);
              }}
            >
              <svg
                className="customIcon"
                width="24"
                height="21"
                viewBox="0 0 24 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.5 20.5H10.5004C13.1518 20.4977 15.6939 19.4434 17.5687 17.5687C19.4434 15.6939 20.4977 13.1518 20.5 10.5004V10.5C20.5 8.52219 19.9135 6.58879 18.8147 4.9443C17.7159 3.29981 16.1541 2.01809 14.3268 1.26121C12.4996 0.504333 10.4889 0.306299 8.5491 0.692151C6.60929 1.078 4.82746 2.03041 3.42894 3.42894C2.03041 4.82746 1.078 6.60929 0.692151 8.5491C0.306299 10.4889 0.504333 12.4996 1.26121 14.3268C2.01809 16.1541 3.29981 17.7159 4.9443 18.8147C6.58879 19.9135 8.52219 20.5 10.5 20.5ZM6.20024 4.10615C7.47297 3.25573 8.9693 2.80183 10.5 2.80183C12.5526 2.80183 14.5212 3.61722 15.9726 5.06863C17.424 6.52005 18.2394 8.48859 18.2394 10.5412C18.2394 12.0719 17.7855 13.5682 16.9351 14.841C16.0846 16.1137 14.8759 17.1057 13.4617 17.6914C12.0475 18.2772 10.4914 18.4305 8.99013 18.1319C7.48883 17.8332 6.10981 17.0961 5.02744 16.0138C3.94507 14.9314 3.20796 13.5524 2.90934 12.0511C2.61071 10.5498 2.76398 8.99365 3.34975 7.57947C3.93553 6.16528 4.9275 4.95656 6.20024 4.10615Z" />
                <path d="M19.1739 15.0435C21.4551 15.0435 23.3043 13.0093 23.3043 10.5C23.3043 7.99073 21.4551 5.95654 19.1739 5.95654C16.8927 5.95654 15.0435 7.99073 15.0435 10.5C15.0435 13.0093 16.8927 15.0435 19.1739 15.0435Z" />
              </svg>

              {isMenuHovered && <div className="textContainer">Closure</div>}
            </NavigationDialogItemComponent>
            {/* <NavigationDialogItemComponent
              style={isActive(isInLocation('closure/professional-dna'))}
              onClick={() => handleHistoryPush('/closure/professional-dna')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">
                  Closure Professional DNA
                </div>
              )}
            </NavigationDialogItemComponent> */}

            <NavigationDialogItemComponent
              style={isActive(isInLocation('closure/client'))}
              onClick={() => handleHistoryPush('/closure/client')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">
                  Closure with the Client
                </div>
              )}
            </NavigationDialogItemComponent>

            <NavigationDialogItemComponent
              style={isActive(isInLocation('closure/internal'))}
              onClick={() => handleHistoryPush('/closure/internal')}
            >
              {isMenuHovered && (
                <div className="textContainer noImg">Closure Internal</div>
              )}
            </NavigationDialogItemComponent>
          </SubMenu>
        </>
      )}
      {isAdmin && (
        <SubMenu className={` ${activeId === 4 ? 'opened' : ''}`}>
          <NavigationDialogItemComponent
            className="title"
            onClick={() => {
              setActiveId(activeId === 4 ? 0 : 4);
            }}
          >
            <FiSettings size={20} />
            {isMenuHovered && <div className="textContainer">Admin</div>}
          </NavigationDialogItemComponent>
          <NavigationDialogItemComponent
            style={isActive(isInLocation('admin/weekly-supervision'))}
            onClick={() => handleAdminPush('weekly-supervision')}
          >
            {isMenuHovered && (
              <div className="textContainer noImg">Weekly Supervision</div>
            )}
          </NavigationDialogItemComponent>
          <NavigationDialogItemComponent
            style={isActive(isInLocation('admin/allocation-supervision'))}
            onClick={() => handleAdminPush('allocation-supervision')}
          >
            {isMenuHovered && (
              <div className="textContainer noImg">Allocation Supervision</div>
            )}
          </NavigationDialogItemComponent>
          <NavigationDialogItemComponent
            style={isActive(isInLocation('admin/pdc-supervision'))}
            onClick={() => handleAdminPush('pdc-supervision')}
          >
            {isMenuHovered && (
              <div className="textContainer noImg">PDC Supervision</div>
            )}
          </NavigationDialogItemComponent>
          <NavigationDialogItemComponent
            style={isActive(isInLocation('admin/setup-assessment'))}
            onClick={() => handleAdminPush('setup-assessment')}
          >
            {isMenuHovered && (
              <div className="textContainer noImg">Setup Assessment</div>
            )}
          </NavigationDialogItemComponent>
          <NavigationDialogItemComponent
            style={isActive(isInLocation('admin/reports'))}
            onClick={() => handleAdminPush('reports')}
          >
            {isMenuHovered && (
              <div className="textContainer noImg">Reports</div>
            )}
          </NavigationDialogItemComponent>
          <NavigationDialogItemComponent
            style={isActive(isInLocation('admin/knm-home'))}
            onClick={() => handleAdminPush('knm-home')}
          >
            {isMenuHovered && (
              <div className="textContainer noImg">Knowledge Management</div>
            )}
          </NavigationDialogItemComponent>
          <NavigationDialogItemComponent
            style={isActive(isInLocation('admin/idone'))}
            onClick={() => handleAdminPush('idone')}
          >
            {isMenuHovered && (
              <div className="textContainer noImg">
                ID One® Accredited Centre
              </div>
            )}
          </NavigationDialogItemComponent>
        </SubMenu>
      )}

      <div
        style={{
          position: 'fixed',
          bottom: '25px',
          zIndex: '-1',
        }}
      >
        <NavigationDialogItemComponent onClick={signOut}>
          <Tooltip placement="top" title={`Logged as ${user?.userName}`}>
            <img
              src={`https://professionals.integrationconsulting.com/photos/${professional?.photoGUID}.jpg`}
              alt={user?.userName}
              className="userImage"
            />
          </Tooltip>

          {isMenuHovered && <div className="textContainer">LogOut</div>}
        </NavigationDialogItemComponent>
      </div>
    </Container>
  );
};
