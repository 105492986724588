import axios from 'axios';
import CustomStore from 'devextreme/data/custom_store';

const UNAUTHORIZED = 401;
const FORBBIDEN = 403;

const api = axios.create({
  baseURL: process.env.REACT_APP_MASTER_API_URL,
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const { status } = error.response;
      if (status === UNAUTHORIZED || status === FORBBIDEN) {
        localStorage.clear();
        window.location.href = '/';
      }
    }

    return Promise.reject(error);
  },
);
api.defaults.headers.common.Authorization = localStorage.getItem('accessToken');

export default api;

export interface IProfessionalProps {
  id: number;
  name: string;
  login: string;
  email: string;
  jobTitle: IJobTitleProps;
  JobTitle: IJobTitleProps;
  offices: IOfficeProps[];
  Offices: IOfficeProps[];
  practices: IPracticeProps[];
  Practices: IPracticeProps[];
  IsActive: boolean;
  isActive: boolean;
  Name: string;
  Login: string;
  photoGUID: string;
}

export interface IJobTitleProps {
  id: number;
  name: string;
  Name: string;
}

export interface IOfficeProps {
  id: number;
  name: string;
  IsActive: boolean;
  isActive: boolean;
  Name: string;
}

export interface IPracticeProps {
  id: number;
  name: string;
  IsActive: boolean;
  Area: string;
  Name: string;
}

export interface ICurrencyProps {
  id: number;
  name: string;
  abbreviation: string;
  IsActive: boolean;
}

export interface IClientProps {
  id: number;
  name: string;
  IsActive: boolean;
  isActive: boolean;
  Name: string;
}

export interface IClientEconomicGroupProps {
  ID: number;
  Name: string;
}

export interface IClietSegmentsProps {
  ID: number;
  SegmentLevel: number;
  Segment: ISegmentProps;
}

export interface ISegmentProps {
  ID: number;
  Name: string;
}

export interface ISignIn {
  displayName: string;
  scopes: string[];
  comanagementId: string;
  practiceId: string;
  professionalId: string;
  businessUnitsIds: number[];
  accessToken: string;
  userPrincipalName: string;
  email: string;
}

export interface IClientInfos {
  id: number;
}
export interface IProjectTeam {
  id: number;
  professional: IProfessionalProps;
  idProfessional: number;
  isOnePageAllow: boolean;
  isManager: boolean;
  isPartner: boolean;
}

export interface IProject {
  name: string;
  code: string;
  clientCulture: string;
  clientDescription: string;
  projectTeam: IProjectTeam[];
  missingAssessmentNames: string;
}

export interface IOnePage {
  id: number;
  createdAt: Date;
  submittedAt: Date;
  isActive?: boolean;
  onePageStatus: number;
  onePageProblemLevel: number;
  hasAllocation: boolean;
  keyMessage: string;
  teamPoint: string;
  methodInternal: string;
  methodExternal: string;
  governanceAction: string;
  governanceAllocation: string;
  coManagementComment: string;
  executiveBoardComment: string;
  executiveResumeComment: string;
  pdcResumeComment?: string;
  environmentStatus: number;
  project: IProject;
}

export interface IProjectDashboard {
  fundoCurrentWeek: number;
  fundoFirstAllocation: number;
  fundoInitial: number;
  fundoTarget: number;
  project: IProject;
  onePage: IOnePage;
  lastProfessionalDNADate: Date;
  nextProfessionalDNADate: Date;
  lastProjectSessionDate: Date;
  totalStakeholders: number;
  criticalStakeholders: number;
  weeklyStatusDate: Date;
  setupStatus: boolean;
}

export interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}
