import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  FiAlertCircle,
  FiChevronLeft,
  FiChevronRight,
  FiPlayCircle,
  FiX,
} from 'react-icons/fi';
import { useForm, Controller } from 'react-hook-form';

import { DataGrid, FileUploader } from 'devextreme-react';
import {
  Column,
  Editing,
  EmailRule,
  RequiredRule,
} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { WhisperSpinner } from 'react-spinners-kit';
import { IconButton } from '@material-ui/core';
import api, {
  IWeeklyStatus,
  IWeeklyStatusRecipient,
  SignType,
} from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import {
  Body,
  Container,
  LoadingContainer,
  SubmitConfirmMessage,
  SubmitContainer,
  TabMenu,
} from './styles';
import path1 from '../../../assets/images/sign-path1.png';
import path2 from '../../../assets/images/sign-path2.png';
import path3 from '../../../assets/images/sign-path3.png';
import powered from '../../../assets/images/poweredDocuSign.png';
import { Button } from '../../../components/Button';

interface DialogCustomProps {
  id?: number;
  handleClose(): void;
  projectId: number;
  refresh(): void;
}

export const DialogWeeklyStatus: React.FC<DialogCustomProps> = ({
  id,
  handleClose,
  projectId,
  refresh,
}) => {
  const [weeklyStatusId, setWeeklyStatusId] = useState(id);
  const [allowEditing, setAllowEditing] = useState(true);
  const [loading, setLoading] = useState(true);
  const { control, handleSubmit } = useForm();
  const { addToast } = useToast();
  const [generalStatus, setGeneralStatus] = useState<DataSource>();
  const [recipients, setRecipients] = useState<DataSource>();
  const [fronts, setFronts] = useState<DataSource>();
  const [definitions, setDefinitions] = useState<DataSource>();
  const [pointsOfAttention, setPointsOfAttention] = useState<DataSource>();
  const [nextSteps, setNextSteps] = useState<DataSource>();
  const [signSteps, setSignSteps] = useState(1);
  const [openTab, setOpenTab] = useState<number>(1);
  const [signType, setSignType] = useState<SignType>();
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [hasUnSignedFile, setHasUnSignedFile] = useState(false);
  const [openiframe, setopeniframe] = useState(false);
  const [iframesrc, setiframesrc] = useState('');

  useEffect(() => {
    setWeeklyStatusId(id);
  }, [id]);

  const fetchWeeklyStatus = useCallback(async () => {
    if (weeklyStatusId) {
      setLoading(true);
      const { data } = await api.get<IWeeklyStatus>(
        `api/weekly-status/${weeklyStatusId}`,
      );

      setHasUnSignedFile(!!data.unSignatureFilename);
      setAllowEditing(
        data.signedStatus === 'draft' || data.signedStatus === 'reproved',
      );
      setSignType(data.signedType);
      // Fill a form that generates a PDF and send automatically to DocuSign®
      if (data.signedType === 1) setSignSteps(2);

      // Upload a file to be sent to DocuSign® (signing fields required)
      if (data.signedType === 2) setSignSteps(3);

      // Upload a document that was already signed
      if (data.signedType === 3) setSignSteps(4);
    }

    setLoading(false);
  }, [weeklyStatusId]);

  useEffect(() => {
    fetchWeeklyStatus();
  }, [fetchWeeklyStatus]);

  const loadGeneralStatus = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `api/weekly-status-contents/weekly-status/${weeklyStatusId}/type/1`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `api/weekly-status-contents/weekly-status/${weeklyStatusId}/type/1`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/weekly-status-contents/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/weekly-status-contents/${key}`);
      },
    });

    setGeneralStatus(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [weeklyStatusId]);

  const loadFronts = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `api/weekly-status-contents/weekly-status/${weeklyStatusId}/type/2`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `api/weekly-status-contents/weekly-status/${weeklyStatusId}/type/2`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/weekly-status-contents/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/weekly-status-contents/${key}`);
      },
    });

    setFronts(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [weeklyStatusId]);

  const loadRecipients = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get<IWeeklyStatusRecipient[]>(
          `api/weekly-status/${weeklyStatusId}/recipients`,
        );
        return data.filter(x => x.isActive);
      },
      insert: async data => {
        await api.post(`api/weekly-status/${weeklyStatusId}/recipients`, data);
      },
      update: async (key, data) => {
        await api.put(
          `api/weekly-status/${weeklyStatusId}/recipients/${key}`,
          data,
        );
      },
      remove: async key => {
        await api.delete(
          `api/weekly-status/${weeklyStatusId}/recipients/${key}`,
        );
      },
    });

    setRecipients(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [weeklyStatusId]);

  const loadDefinitions = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `api/weekly-status-contents/weekly-status/${weeklyStatusId}/type/3`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `api/weekly-status-contents/weekly-status/${weeklyStatusId}/type/3`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/weekly-status-contents/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/weekly-status-contents/${key}`);
      },
    });

    setDefinitions(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [weeklyStatusId]);

  const loadPointsOfAttention = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `api/weekly-status-contents/weekly-status/${weeklyStatusId}/type/4`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `api/weekly-status-contents/weekly-status/${weeklyStatusId}/type/4`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/weekly-status-contents/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/weekly-status-contents/${key}`);
      },
    });

    setPointsOfAttention(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [weeklyStatusId]);

  const loadNextSteps = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `api/weekly-status-contents/weekly-status/${weeklyStatusId}/type/5`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `api/weekly-status-contents/weekly-status/${weeklyStatusId}/type/5`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/weekly-status-contents/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/weekly-status-contents/${key}`);
      },
    });

    setNextSteps(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [weeklyStatusId]);

  useEffect(() => {
    loadGeneralStatus();
    loadFronts();
    loadDefinitions();
    loadPointsOfAttention();
    loadNextSteps();
    loadRecipients();
  }, [
    loadDefinitions,
    loadFronts,
    loadGeneralStatus,
    loadNextSteps,
    loadPointsOfAttention,
    loadRecipients,
  ]);

  const previousStep = useMemo(() => {
    if (signType === 1 && signSteps === 1) return null;
    if (signType === 1 && signSteps === 2 && !allowEditing) return null;
    if (signType === 1 && signSteps === 2) return 1;
    if (signType === 1 && signSteps === 3) return 2;

    if (signType === 2 && signSteps === 1) return null;
    if (signType === 2 && signSteps === 3 && !allowEditing) return null;
    if (signType === 2 && signSteps === 3) return 1;
    if (signType === 2 && signSteps === 4) return 3;

    if (signType === 3 && signSteps === 1) return null;
    if (signType === 3 && signSteps === 4 && !allowEditing) return null;
    if (signType === 3 && signSteps === 4) return 1;

    return null;
  }, [signType, signSteps, allowEditing]);

  const nextStep = useMemo(() => {
    if (signType === 1 && signSteps === 2) return 3;
    if (signType === 1 && signSteps === 3) return null;

    if (signType === 2 && signSteps === 3) return 4;
    if (signType === 2 && signSteps === 4) return null;

    if (signType === 3 && signSteps === 3) return null;

    return null;
  }, [signType, signSteps]);

  const handleChangeType = useCallback(
    async (type: SignType, step: number) => {
      setSignSteps(step);
      setSignType(type);

      if (!allowEditing) return;

      if (weeklyStatusId) {
        await api.patch(`api/weekly-status/${weeklyStatusId}/signed-type`, {
          signedType: type,
        });
      } else {
        const { data } = await api.post<IWeeklyStatus>(
          `api/weekly-status/project/${projectId}`,
          {
            signedType: type,
          },
        );
        refresh();
        setWeeklyStatusId(data.id);
      }
    },
    [weeklyStatusId, projectId, refresh, allowEditing],
  );

  const handleConfirmSubmit = useCallback(() => {
    setConfirmSubmit(true);
  }, []);

  const handleSubmitWeeklyStatus = useCallback(
    async data => {
      try {
        if (!allowEditing) return;

        setConfirmSubmit(false);
        setLoading(true);
        addToast({
          type: 'info',
          title: 'Submitting...',
        });
        const formData = new FormData();

        if (signType !== 1) {
          if (!data.file || !data.file[0]) {
            addToast({
              title: 'Please, select a file',
              type: 'error',
            });
            setLoading(false);
            return;
          }

          formData.append('file', data.file[0], data.file[0].name);
        }

        await api.post(`api/weekly-status/${weeklyStatusId}/submit`, formData);

        addToast({
          title: 'Submitted',
          type: 'success',
        });
        handleClose();
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }

      setLoading(false);
    },
    [addToast, weeklyStatusId, handleClose, allowEditing, signType],
  );

  const handleDownloadUnSignedFile = useCallback(async () => {
    addToast({
      type: 'info',
      title: 'We are generating your file. It could take a while',
    });

    const { data, headers } = await api.get(
      `api/weekly-status/${weeklyStatusId}/file?signed=0`,
      {
        responseType: 'blob',
      },
    );

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', headers['x-file-name']);
    document.body.appendChild(link);
    link.click();
    link.remove();

    addToast({
      type: 'success',
      title: 'Your file is ready',
    });
  }, [addToast, weeklyStatusId]);

  return (
    <Container>
      {loading && (
        <LoadingContainer>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </LoadingContainer>
      )}
      <form onSubmit={handleSubmit(handleSubmitWeeklyStatus)}>
        <Body>
          <h2>
            {signSteps === 1 && 'Upload Weekly Status'}
            {signSteps === 2 && 'Weekly Status Details'}
            {signSteps === 3 && 'Add recipients'}
            {signSteps === 4 && 'Upload your file (only PDF)'}
          </h2>

          {signSteps === 1 && (
            <>
              {!openiframe && (
                <>
                  <div className="pathContainer">
                    <div
                      style={{
                        width: '33%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                      }}
                    >
                      <button
                        className="pathDiv"
                        onClick={() => handleChangeType(1, 2)}
                        type="button"
                      >
                        <div className="imageHolder">
                          <img src={path1} className="path1" alt="path" />
                        </div>
                        <h3>
                          Upload and eSign
                          <br />
                          using a template
                        </h3>
                        <p>
                          Fill a form that generates a PDF and send
                          automatically to DocuSign&reg;{' '}
                        </p>
                        <img src={powered} alt="powered" />
                      </button>
                      <span
                        style={{
                          display: 'flex',
                          gap: 10,
                          alignItems: 'center',
                          alignSelf: 'center',
                          marginRight: '2.5vw',
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setopeniframe(true);
                            setiframesrc(
                              'https://player.vimeo.com/video/798751277?h=31436857a0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
                            );
                          }}
                        >
                          <FiPlayCircle />
                        </IconButton>
                        <Button
                          secondary
                          onClick={e => {
                            e.preventDefault();
                            setopeniframe(true);
                            setiframesrc(
                              'https://player.vimeo.com/video/798751277?h=31436857a0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
                            );
                          }}
                        >
                          PLAY TUTORIAL
                        </Button>
                      </span>
                    </div>

                    <div
                      style={{
                        width: '33%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                      }}
                    >
                      <button
                        className="pathDiv"
                        onClick={() => handleChangeType(2, 3)}
                        type="button"
                      >
                        <div className="imageHolder">
                          <img src={path2} alt="path" />
                        </div>

                        <h3>
                          Upload and eSign
                          <br />
                          your document
                        </h3>
                        <p>
                          Upload a file to be sent to DocuSign&reg; (signing
                          fields required){' '}
                        </p>
                        <img src={powered} alt="powered" />
                      </button>
                      <span
                        style={{
                          display: 'flex',
                          gap: 10,
                          alignItems: 'center',
                          alignSelf: 'center',
                          marginRight: '2.5vw',
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setopeniframe(true);
                            setiframesrc(
                              'https://player.vimeo.com/video/798751216?h=90a15dc9fa&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
                            );
                          }}
                        >
                          <FiPlayCircle />
                        </IconButton>
                        <Button
                          secondary
                          onClick={e => {
                            e.preventDefault();
                            setopeniframe(true);
                            setiframesrc(
                              'https://player.vimeo.com/video/798751216?h=90a15dc9fa&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
                            );
                          }}
                        >
                          PLAY TUTORIAL
                        </Button>
                      </span>
                    </div>

                    <div
                      style={{
                        width: '33%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                      }}
                    >
                      <button
                        className="pathDiv"
                        onClick={() => handleChangeType(3, 4)}
                        type="button"
                      >
                        <div className="imageHolder">
                          <img src={path3} alt="path" />
                        </div>
                        <h3>
                          Upload your
                          <br />
                          signed document
                        </h3>
                        <p>Upload a document that was already signed</p>
                      </button>
                      <span
                        style={{
                          display: 'flex',
                          gap: 10,
                          alignItems: 'center',
                          alignSelf: 'center',
                          marginRight: '2.5vw',
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setopeniframe(true);
                            setiframesrc(
                              'https://player.vimeo.com/video/798751406?h=9887ed67bc&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
                            );
                          }}
                        >
                          <FiPlayCircle />
                        </IconButton>
                        <Button
                          secondary
                          onClick={e => {
                            e.preventDefault();
                            setopeniframe(true);
                            setiframesrc(
                              'https://player.vimeo.com/video/798751406?h=9887ed67bc&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22  ',
                            );
                          }}
                        >
                          PLAY TUTORIAL
                        </Button>
                      </span>
                    </div>
                  </div>
                  <span
                    style={{
                      display: 'flex',
                      gap: 10,
                      justifyContent: 'center',
                      paddingBottom: 30,
                      marginRight: 35,
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        setopeniframe(true);
                        setiframesrc(
                          'https://player.vimeo.com/video/798751144?h=7f3336c10d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
                        );
                      }}
                    >
                      <FiPlayCircle />
                    </IconButton>
                    <Button
                      secondary
                      onClick={e => {
                        e.preventDefault();
                        setopeniframe(true);
                        setiframesrc(
                          'https://player.vimeo.com/video/798751144?h=7f3336c10d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
                        );
                      }}
                    >
                      FILE CREATION TUTORIAL
                    </Button>
                  </span>
                </>
              )}
              {openiframe && (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }}
                  >
                    <Button
                      style={{
                        margin: '0 auto',
                        alignSelf: 'center',
                      }}
                      primary
                      onClick={() => {
                        setopeniframe(false);
                        setiframesrc('');
                      }}
                    >
                      Close tutorial
                    </Button>
                    <IconButton
                      onClick={() => {
                        setopeniframe(false);
                        setiframesrc('');
                      }}
                    >
                      <FiX />
                    </IconButton>
                  </div>
                  <iframe
                    style={{
                      width: '100%',
                      marginBottom: '50px',
                      height: '550px',
                    }}
                    src={iframesrc}
                    title="Tutorial"
                  />
                </div>
              )}
            </>
          )}

          {signSteps === 2 && (
            <>
              <TabMenu>
                <li
                  onClick={() => setOpenTab(1)}
                  className={openTab === 1 ? 'focused' : ''}
                >
                  {' '}
                  General Status
                </li>
                <li
                  onClick={() => setOpenTab(2)}
                  className={openTab === 2 ? 'focused' : ''}
                >
                  Weekly Status Update of Previous Activities
                </li>
                <li
                  onClick={() => setOpenTab(3)}
                  className={openTab === 3 ? 'focused' : ''}
                >
                  Definitions{' '}
                </li>
                <li
                  onClick={() => setOpenTab(4)}
                  className={openTab === 4 ? 'focused' : ''}
                >
                  Points of Attention
                </li>
                <li
                  onClick={() => setOpenTab(5)}
                  className={openTab === 5 ? 'focused' : ''}
                >
                  Next Steps
                </li>
              </TabMenu>

              {openTab === 1 && (
                <Body className="content">
                  <DataGrid
                    dataSource={generalStatus}
                    onRowUpdating={options => {
                      options.newData = {
                        ...options.oldData,
                        ...options.newData,
                      };
                    }}
                  >
                    <Column dataField="name" />
                    <Column dataField="description" caption="Status" />
                    <Column
                      dataField="orderPosition"
                      caption="Order"
                      dataType="number"
                    />
                    <Editing
                      allowAdding={allowEditing}
                      allowDeleting={allowEditing}
                      allowUpdating={allowEditing}
                    />
                  </DataGrid>
                </Body>
              )}

              {openTab === 2 && (
                <Body className="content">
                  <DataGrid
                    dataSource={fronts}
                    onRowUpdating={options => {
                      options.newData = {
                        ...options.oldData,
                        ...options.newData,
                      };
                    }}
                  >
                    <Column dataField="frontHeader" caption="Work Front" />
                    <Column dataField="name" caption="Activity Name" />
                    <Column dataField="description" caption="Activity Notes" />
                    <Column dataField="contentStatus" caption="Status" />
                    <Column
                      dataField="orderPosition"
                      caption="Order"
                      dataType="number"
                    />
                    <Editing
                      allowAdding={allowEditing}
                      allowDeleting={allowEditing}
                      allowUpdating={allowEditing}
                    />
                  </DataGrid>
                </Body>
              )}

              {openTab === 3 && (
                <Body className="content">
                  <DataGrid
                    dataSource={definitions}
                    onRowUpdating={options => {
                      options.newData = {
                        ...options.oldData,
                        ...options.newData,
                      };
                    }}
                  >
                    <Column dataField="name" />
                    <Column dataField="description" caption="Definition" />
                    <Column
                      dataField="orderPosition"
                      caption="Order"
                      dataType="number"
                    />
                    <Editing
                      allowAdding={allowEditing}
                      allowDeleting={allowEditing}
                      allowUpdating={allowEditing}
                    />
                  </DataGrid>
                </Body>
              )}

              {openTab === 4 && (
                <Body className="content">
                  <DataGrid
                    dataSource={pointsOfAttention}
                    onRowUpdating={options => {
                      options.newData = {
                        ...options.oldData,
                        ...options.newData,
                      };
                    }}
                  >
                    <Column dataField="name" />
                    <Column dataField="description" caption="Action" />
                    <Column
                      dataField="orderPosition"
                      caption="Order"
                      dataType="number"
                    />
                    <Editing
                      allowAdding={allowEditing}
                      allowDeleting={allowEditing}
                      allowUpdating={allowEditing}
                    />
                  </DataGrid>
                </Body>
              )}

              {openTab === 5 && (
                <Body className="content">
                  <DataGrid
                    dataSource={nextSteps}
                    onRowUpdating={options => {
                      options.newData = {
                        ...options.oldData,
                        ...options.newData,
                      };
                    }}
                  >
                    <Column dataField="name" />
                    <Column dataField="description" caption="Comment" />
                    <Column
                      dataField="orderPosition"
                      caption="Order"
                      dataType="number"
                    />
                    <Editing
                      allowAdding={allowEditing}
                      allowDeleting={allowEditing}
                      allowUpdating={allowEditing}
                    />
                  </DataGrid>
                </Body>
              )}
            </>
          )}

          {signSteps === 3 && (
            <Body className="content">
              <DataGrid
                dataSource={recipients}
                onRowUpdating={options => {
                  options.newData = {
                    ...options.oldData,
                    ...options.newData,
                  };
                }}
              >
                <Column dataField="name">
                  <RequiredRule />
                </Column>
                <Column dataField="email">
                  {' '}
                  <RequiredRule /> <EmailRule />
                </Column>
                <Column
                  dataField="signedCode"
                  caption="Signed Code"
                  allowEditing={false}
                />
                <Column
                  dataField="isSigned"
                  caption="Signed Status"
                  allowEditing={false}
                  visible={!allowEditing}
                  dataType="boolean"
                />
                <Editing
                  allowAdding={signType !== 1 && allowEditing}
                  allowDeleting={signType !== 1 && allowEditing}
                  allowUpdating={allowEditing}
                />
              </DataGrid>
            </Body>
          )}

          {signSteps === 4 && (
            <>
              <Body className="content">
                <div className="smallTextField">
                  <label>Choose your file</label>

                  <Controller
                    name="file"
                    control={control}
                    render={({ field }) => (
                      <FileUploader
                        readOnly={!allowEditing}
                        uploadMode="useForm"
                        showFileList
                        onValueChanged={e => {
                          field.onChange(e.value);
                        }}
                        allowedFileExtensions={['.pdf']}
                      />
                    )}
                  />
                  <small style={{ marginBottom: 15 }}>
                    Only PDF files is allowed
                  </small>
                  <small style={{ marginBottom: 15 }}>
                    Please watch to video below
                  </small>
                </div>
              </Body>
              {hasUnSignedFile && (
                <>
                  <p>Download your file without the signatures</p>
                  <Button type="button" onClick={handleDownloadUnSignedFile}>
                    Download not signed file
                  </Button>
                </>
              )}
            </>
          )}
        </Body>

        {allowEditing &&
          !nextStep &&
          !!previousStep &&
          !confirmSubmit &&
          signType !== 3 && (
            <SubmitContainer>
              <Button type="button" primary onClick={handleConfirmSubmit}>
                Send to sign
              </Button>
            </SubmitContainer>
          )}

        {allowEditing && !nextStep && !!previousStep && signType === 3 && (
          <SubmitContainer>
            <Button type="submit" primary>
              Upload signed file
            </Button>
          </SubmitContainer>
        )}

        {allowEditing && !nextStep && !!previousStep && confirmSubmit && (
          <SubmitContainer>
            <SubmitConfirmMessage>
              <p>
                If you confirm this action, an e-mail message will be send to
                all recipients and you cannot update the data anymore.
              </p>
              <p>If everything is ok, confirm clicking below</p>
            </SubmitConfirmMessage>

            <Button
              type="submit"
              primary
              style={{ gap: 5, backgroundColor: '#edce61' }}
            >
              <FiAlertCircle size={16} />
              Confirm
            </Button>
          </SubmitContainer>
        )}
      </form>

      {signSteps !== 1 && (
        <>
          {!!previousStep && (
            <button
              type="button"
              className="backwardsHandler"
              onClick={() => setSignSteps(previousStep)}
            >
              <FiChevronLeft size={50} />
              <p>Previous</p>
            </button>
          )}

          {!!nextStep && (
            <button
              type="button"
              className="forwardsHandler"
              onClick={() => setSignSteps(nextStep)}
            >
              <FiChevronRight size={50} />
              <p>Next</p>
            </button>
          )}
        </>
      )}
    </Container>
  );
};
