/* eslint-disable jsx-a11y/no-static-element-interactions */
import { IconButton, Tooltip } from '@material-ui/core';
import { DataGrid } from 'devextreme-react';
import { Column, Editing } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useState } from 'react';
import { FiSearch, FiX } from 'react-icons/fi';
import { useParams } from 'react-router';
import api from '../../../services/api';
import { Button } from '../../../components/Button';
import { RARModalPage } from '../RARModalPage';
import { StakeholderModalPage } from '../StakeholderModalPage';
import { TechDNAModalPage } from '../TechDNAModalPage';
import { DialogProjectSessionConfirmSubmit } from '../DialogProjectSessionCofirmSubmit';
import { Body, Container, HeaderContent } from './styles';
import { BoardHeader } from '../../../components/BoardHeader';

export const ProjectSession: React.FC<{ title?: string }> = ({ title }) => {
  const { id } = useParams<{
    id: string;
  }>();
  const [session, setSession] = useState<DataSource>();
  const [idTechDNAModal, setIdTechDNAModal] = useState(0);
  const [idStakeholderModal, setIdStakeholderModal] = useState(0);
  const [openStakeholderModalPage, setOpenStakeholderModalPage] =
    useState(false);
  const [idRARModal, setIdRARModal] = useState(0);
  const [openRARModalPage, setOpenRARModalPage] = useState(false);
  const [openTechDNAModalPage, setOpenTechDNAModalPage] = useState(false);
  const [openProjectSessionSubmitDialog, setOpenProjectSessionSubmitDialog] =
    useState(false);
  const [idSubmitSession, setIdSubmitSession] = useState(0);

  const loadProjectSession = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(`api/projects/${id}/sessions`);
        return data.filter((x: any) => x.isSetup === false);
      },
      insert: async data => {
        await api.post(`api/projects/${id}/sessions`, data);
      },
      update: async (key, data) => {
        await api.put(`api/projects/${id}/sessions/${key}`, data);
      },
      remove: async key => {
        await api.delete(`api/projects/${id}/sessions/${key}`);
      },
    });

    setSession(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id]);

  const projectSessionStakeholderCell = useCallback(e => {
    return (
      <Tooltip title="Stakeholder">
        <IconButton
          onClick={() => {
            setIdStakeholderModal(e.key);
            setOpenStakeholderModalPage(true);
          }}
          disabled={e.row.isEditing}
        >
          <FiSearch size={20} style={{ textAlign: 'center' }} />
        </IconButton>
      </Tooltip>
    );
  }, []);

  const projectSessioRARCell = useCallback(e => {
    return (
      <Tooltip title="Roles &amp; Responsibilities">
        <IconButton
          onClick={() => {
            setIdRARModal(e.key);
            setOpenRARModalPage(true);
          }}
          disabled={e.row.isEditing}
        >
          <FiSearch size={20} style={{ textAlign: 'center' }} />
        </IconButton>
      </Tooltip>
    );
  }, []);

  const projectSessionTechDNACell = useCallback(e => {
    return (
      <Tooltip title="Tech DNA">
        <IconButton
          onClick={() => {
            setIdTechDNAModal(e.key);
            setOpenTechDNAModalPage(true);
          }}
          disabled={e.row.isEditing}
        >
          <FiSearch size={20} style={{ textAlign: 'center' }} />
        </IconButton>
      </Tooltip>
    );
  }, []);

  const projectSessionSubmitCell = useCallback(e => {
    return (
      <div style={{ margin: '0 auto', width: 'fit-content' }}>
        <Button
          primary={!e.data.submittedAt}
          secondary={e.data.submittedAt}
          style={{ width: '8em', justifyContent: 'center' }}
          onClick={() => {
            setOpenProjectSessionSubmitDialog(true);
            setIdSubmitSession(e.key);
          }}
          disabled={e.row.isEditing}
        >
          {e.data.submittedAt ? 'Undo' : 'Submit'}
        </Button>
      </div>
    );
  }, []);

  useEffect(() => {
    loadProjectSession();
  }, [loadProjectSession]);

  return (
    <>
      <HeaderContent>
        <BoardHeader title="DURING" subtitle="" />
      </HeaderContent>
      <Container>
        <span>
          <h1
            style={{
              textTransform: 'uppercase',
              color: '#6c6c6c',
              fontSize: '24px',
              fontWeight: 700,
              letterSpacing: '0.1em',
            }}
          >
            Project Session
          </h1>
        </span>
      </Container>
      <Body>
        <DataGrid
          className="grid"
          onRowUpdating={options => {
            options.newData = {
              ...options.oldData,
              ...options.newData,
            };
          }}
          dataSource={session}
        >
          <Column
            dataField="createdAt"
            dataType="date"
            format="dd MMM yyyy"
            allowEditing={false}
            alignment="center"
          />
          <Column dataField="discussionFocus" alignment="center" />
          <Column
            dataField="techDNA"
            cellRender={projectSessionTechDNACell}
            allowEditing={false}
            alignment="center"
          />
          <Column
            dataField="stakeholder"
            cellRender={projectSessionStakeholderCell}
            allowEditing={false}
            alignment="center"
          />
          <Column
            dataField="roles"
            caption="Roles &amp; Responsibilities"
            cellRender={projectSessioRARCell}
            allowEditing={false}
            alignment="center"
          />
          <Column
            dataField="submit"
            caption=""
            cellRender={projectSessionSubmitCell}
            alignment="center"
          />
          <Editing allowAdding allowDeleting allowUpdating />
        </DataGrid>
      </Body>

      {openTechDNAModalPage && (
        <>
          <div
            className="customDialogBG"
            onMouseUp={() => setOpenTechDNAModalPage(false)}
          />
          <div className="customDialog">
            <FiX
              className="closeButton"
              onMouseUp={() => setOpenTechDNAModalPage(false)}
            />
            <TechDNAModalPage
              openTechDNAModalPage={openTechDNAModalPage}
              idDuringSession={idTechDNAModal}
            />
          </div>
        </>
      )}

      {openStakeholderModalPage && (
        <>
          <div
            className="customDialogBG"
            onMouseUp={() => setOpenTechDNAModalPage(false)}
          />
          <div className="customDialog">
            <FiX
              className="closeButton"
              onMouseUp={() => setOpenStakeholderModalPage(false)}
            />
            <StakeholderModalPage
              openStakeholderModalPage={openStakeholderModalPage}
              handleClose={() => {
                setOpenStakeholderModalPage(false);
              }}
              idSession={idStakeholderModal}
            />
          </div>
        </>
      )}

      {openRARModalPage && (
        <>
          <div
            className="customDialogBG"
            onMouseUp={() => setOpenRARModalPage(false)}
          />
          <div className="customDialog">
            <FiX
              className="closeButton"
              onMouseUp={() => setOpenRARModalPage(false)}
            />

            <RARModalPage
              openRARModalPage={openRARModalPage}
              handleClose={() => {
                setOpenRARModalPage(false);
              }}
              idSession={idRARModal}
            />
          </div>
        </>
      )}

      {openProjectSessionSubmitDialog && (
        <DialogProjectSessionConfirmSubmit
          open={openProjectSessionSubmitDialog}
          handleClose={() => {
            setOpenProjectSessionSubmitDialog(false);
            // eslint-disable-next-line no-unused-expressions
            session?.reload();
          }}
          idSession={idSubmitSession}
        />
      )}
    </>
  );
};
