import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { SelectBox } from 'devextreme-react';
import { WhisperSpinner } from 'react-spinners-kit';
import { useToast } from '../../../../../hooks/toast';
import { FormGroup } from '../../../../../components/FormGroup';
import { Button } from '../../../../../components/Button';
import api from '../../../../../services/api';
import { Container, ContainerLoading } from './styles';

interface DialogCustomProps {
  idProject: number;
  open: boolean;
  handleClose(): void;
}

export const DialogEditChecklist: React.FC<DialogCustomProps> = ({
  idProject,
  open,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const [checkCloseOutForm, setCheckCloseOutForm] = useState(0);
  const [checkProjectNetwork, setCheckProjectNetwork] = useState(0);
  const [checkCase, setCheckCase] = useState(0);
  const [checkCaseAuthorization, setCheckCaseAuthorization] = useState(0);
  const [checkReferences, setCheckReferences] = useState(0);
  const [checkSignedWeeklyStatus, setCheckSignedWeeklyStatus] = useState(0);
  const [checkRecordProjectDelivery, setCheckRecordProjectDelivery] =
    useState(0);
  const [checkSendWarning, setCheckSendWarning] = useState(0);
  const [logoAuthorization, setLogoAuthorization] = useState(false);
  const [projectClosureCheck, setProjectClosureCheck] = useState<any>(0);

  const loadCheckList = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(
      `/api/project-closure-check/project/${idProject}`,
    );
    setProjectClosureCheck(data);
    setCheckCloseOutForm(data.checkCloseOutForm);
    setCheckProjectNetwork(data.checkProjectNetwork);
    setCheckCase(data.checkCase);
    setCheckCaseAuthorization(data.checkCaseAuthorization);
    setCheckReferences(data.checkReferences);
    setCheckSignedWeeklyStatus(data.checkSignedWeeklyStatus);
    setCheckRecordProjectDelivery(data.checkRecordProjectDelivery);
    setCheckSendWarning(data.checkSendWarning);
    setLogoAuthorization(data.logoAuthorization);
    setLoading(false);

    return data;
  }, [idProject]);

  const handleEditChecklist = useCallback(async () => {
    setLoading(true);
    try {
      await api.put(`/api/project-closure-check/project/${idProject}`, {
        ...projectClosureCheck,
        checkCloseOutForm,
        checkProjectNetwork,
        checkCase,
        checkCaseAuthorization,
        checkReferences,
        checkSignedWeeklyStatus,
        checkRecordProjectDelivery,
        checkSendWarning,
        logoAuthorization,
      });
      addToast({
        type: 'success',
        title: 'Edited with success.',
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
    }
    setLoading(false);
    handleClose();
  }, [
    handleClose,
    idProject,
    projectClosureCheck,
    checkCloseOutForm,
    checkProjectNetwork,
    checkCase,
    checkCaseAuthorization,
    checkReferences,
    checkSignedWeeklyStatus,
    checkRecordProjectDelivery,
    checkSendWarning,
    addToast,
    logoAuthorization,
  ]);

  useEffect(() => {
    loadCheckList();
  }, [loadCheckList]);

  const checkListOptions = [
    {
      id: 0,
      checklistOption: 'Not Applicable',
    },
    {
      id: 1,
      checklistOption: 'Pending',
    },
    {
      id: 2,
      checklistOption: 'Done',
    },
  ];

  const boolOptions = [
    {
      id: true,
      option: 'Yes',
    },
    {
      id: false,
      option: 'No',
    },
  ];

  const warningOptions = [
    {
      id: 0,
      option: 'No',
    },
    {
      id: 1,
      option: 'Yes',
    },
  ];

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle id="alert-dialog-title">Edit Checklist</DialogTitle>
      <DialogContent>
        {loading ? (
          <ContainerLoading>
            <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
          </ContainerLoading>
        ) : (
          <Container>
            <FormGroup fieldSetLabel="Send Warning?">
              <SelectBox
                onValueChange={e => setCheckSendWarning(e)}
                value={checkSendWarning}
                openOnFieldClick
                searchEnabled
                dataSource={warningOptions}
                valueExpr="id"
                displayExpr="option"
              />
            </FormGroup>
            <FormGroup fieldSetLabel="Close out Form">
              <SelectBox
                onValueChange={e => setCheckCloseOutForm(e)}
                value={checkCloseOutForm}
                openOnFieldClick
                searchEnabled
                dataSource={checkListOptions}
                valueExpr="id"
                displayExpr="checklistOption"
              />
            </FormGroup>

            <FormGroup fieldSetLabel="Project Network">
              <SelectBox
                onValueChange={e => setCheckProjectNetwork(e)}
                value={checkProjectNetwork}
                openOnFieldClick
                searchEnabled
                dataSource={checkListOptions}
                valueExpr="id"
                displayExpr="checklistOption"
              />
            </FormGroup>

            <FormGroup fieldSetLabel="Case">
              <SelectBox
                onValueChange={e => setCheckCase(e)}
                value={checkCase}
                openOnFieldClick
                searchEnabled
                dataSource={checkListOptions}
                valueExpr="id"
                displayExpr="checklistOption"
              />
            </FormGroup>

            <FormGroup fieldSetLabel="Case Authorization">
              <SelectBox
                onValueChange={e => setCheckCaseAuthorization(e)}
                value={checkCaseAuthorization}
                openOnFieldClick
                searchEnabled
                dataSource={checkListOptions}
                valueExpr="id"
                displayExpr="checklistOption"
              />
            </FormGroup>

            <FormGroup fieldSetLabel="References">
              <SelectBox
                onValueChange={e => setCheckReferences(e)}
                value={checkReferences}
                openOnFieldClick
                searchEnabled
                dataSource={checkListOptions}
                valueExpr="id"
                displayExpr="checklistOption"
              />
            </FormGroup>

            <FormGroup fieldSetLabel="Signed Weekly Status">
              <SelectBox
                onValueChange={e => setCheckSignedWeeklyStatus(e)}
                openOnFieldClick
                value={checkSignedWeeklyStatus}
                searchEnabled
                dataSource={checkListOptions}
                valueExpr="id"
                displayExpr="checklistOption"
              />
            </FormGroup>

            <FormGroup fieldSetLabel="Record Project Delivery">
              <SelectBox
                onValueChange={e => setCheckRecordProjectDelivery(e)}
                value={checkRecordProjectDelivery}
                openOnFieldClick
                searchEnabled
                dataSource={checkListOptions}
                valueExpr="id"
                displayExpr="checklistOption"
              />
            </FormGroup>

            <FormGroup fieldSetLabel="Logo Authorization">
              <SelectBox
                onValueChange={e => setLogoAuthorization(e)}
                value={logoAuthorization}
                openOnFieldClick
                searchEnabled
                dataSource={boolOptions}
                valueExpr="id"
                displayExpr="option"
              />
            </FormGroup>
          </Container>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleEditChecklist} primary>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
