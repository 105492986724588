import React, { useCallback, useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { WhisperSpinner } from 'react-spinners-kit';
import { Header } from './styles';

import PortalLogo from '../../assets/images/portal-logo.png';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { ContainerLoading } from '../../pages/ProjectDuring/DashboardModal/styles';

export const HeaderComponent: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [whichPage, setWhichPage] = useState<string>();

  const [projectInfo, setProjectInfo] = useState<any>();

  const loadProjectInfo = useCallback(async () => {
    setLoading(true);
    if (id) {
      const response = await api.get(`/api/projects/${id}`);
      setProjectInfo(response.data);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    loadProjectInfo();
  }, [loadProjectInfo]);

  const { user } = useAuth();

  const isInProject = history.location.pathname.includes('project/home');
  const isInSetupClient = history.location.pathname.includes('/setup/client');
  const isInSetupProject = history.location.pathname.includes('/setup/project');
  const isSetupClientPastProjects = history.location.pathname.includes(
    '/setup/client-past-projects',
  );
  const isSetupStakeholders = history.location.pathname.includes(
    '/setup/stakeholders',
  );
  const isSetupProfessionalDNA = history.location.pathname.includes(
    '/setup/professional-dna',
  );
  const isSetupRoles = history.location.pathname.includes('/setup/roles');
  const isSetupFinalization = history.location.pathname.includes(
    '/setup/finalization',
  );
  const isSetupAssessment =
    history.location.pathname.includes('/setup/assessment');

  const isDuringProfessionalDNA = history.location.pathname.includes(
    '/during/professional-dna',
  );
  const isDuringOnePage =
    history.location.pathname.includes('/during/one-page');
  const isDuringProjectSession = history.location.pathname.includes(
    '/during/project-session',
  );

  const isDuringWeeklyStatus = history.location.pathname.includes(
    '/during/weekly-status',
  );
  const isDuringDashboardArchive = history.location.pathname.includes(
    '/during/dashboard-archive',
  );

  const isClosureProfessionalDNA = history.location.pathname.includes(
    '/closure/professional-dna`',
  );
  const isClosureClient =
    history.location.pathname.includes('/closure/client`');
  const isClosureInternal =
    history.location.pathname.includes('/closure/internal`');

  return (
    <>
      {loading ? (
        <ContainerLoading>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      ) : (
        <Header>
          {history.location.pathname.includes('project/') ? (
            <>
              <div>
                {isInProject && <h1>Project Home</h1>}
                {isInSetupClient && <h1>Setup: Client</h1>}
                {isInSetupProject && <h1>Setup: Project</h1>}
                {isSetupClientPastProjects && (
                  <h1>Setup: Client Past Projects</h1>
                )}
                {isSetupStakeholders && <h1>Setup: Stakeholders</h1>}

                {isSetupProfessionalDNA && <h1>Setup: Professional DNA</h1>}
                {isSetupRoles && <h1>Setup: Roles and Responsabilitives</h1>}
                {isSetupFinalization && <h1>Setup: Finalization</h1>}
                {isSetupAssessment && <h1>Setup: Assessment</h1>}
                {isDuringProfessionalDNA && <h1>During: Assessment</h1>}
                {isDuringOnePage && <h1>During: Assessment</h1>}
                {isDuringProjectSession && <h1>During: Project Session</h1>}
                {isDuringWeeklyStatus && <h1>During: Weekly Status</h1>}
                {isDuringDashboardArchive && <h1>During: Dashboard Archive</h1>}
                {isDuringDashboardArchive && <h1>During: Weekly Status</h1>}
                {isClosureProfessionalDNA && <h1>Closure: Professional DNA</h1>}
                {isClosureClient && <h1>Closure: Client</h1>}
                {isClosureInternal && <h1>Closure: Internal</h1>}
              </div>
              <div>
                <h2>{projectInfo && projectInfo.name}</h2>
                <p>{projectInfo && projectInfo.code}</p>
              </div>
            </>
          ) : (
            <div className="home" style={{ textAlign: 'center' }}>
              <p style={{ textAlign: 'center' }}>
                Welcome to the Project Portal,
              </p>
              <h2 style={{ textAlign: 'center' }}>{user?.userName}</h2>
            </div>
          )}
        </Header>
      )}
    </>
  );
};

export const Project: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [projectId, setProjectId] = useState<string>();
  const [projectInfo, setProjectInfo] = useState<any>();

  useEffect(() => {
    setProjectId(history.location.pathname.match(/\d/g)?.join('')); // gets project id from url
  }, [history.location.pathname, projectId]);

  const loadProjectInfo = useCallback(async () => {
    setLoading(true);
    const response = await api.get(`/api/projects/${projectId}`);
    setProjectInfo(response.data);
    setLoading(false);
  }, [projectId]);

  useEffect(() => {
    loadProjectInfo();
  }, [loadProjectInfo]);

  return (
    <>
      {history.location.pathname.includes('project/') && loading ? (
        <CircularProgress />
      ) : (
        <div className="projectInfo">
          <h1>{projectInfo && projectInfo.name}</h1>
          <p>{projectInfo && projectInfo.code}</p>
        </div>
      )}
    </>
  );
};
