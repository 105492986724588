import React from 'react';

import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import { Surface } from '../Surface';
import { ApplicationContent } from '../ApplicationContent';
import { ProjectOverview } from '../../pages/ProjectOverview';
import { MethodologyProvider } from '../../hooks/methodologyContext';

export const ApplicationLayers: React.FC = ({ children }) => {
  const history = useHistory();

  const isInProject = history.location.pathname.includes('project/');

  return (
    <Container>
      <Surface />
      <ApplicationContent isInProject={isInProject}>
        {children}
      </ApplicationContent>
      {isInProject && <ProjectOverview />}
    </Container>
  );
};
