import React, { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { IconButton, Tooltip } from '@material-ui/core';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  HeaderFilter,
  Lookup,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { FiArrowUpCircle } from 'react-icons/fi';
import { Container, Body } from './styles';
import { useDocumentTitle } from '../../hooks/documentTitle';
import api from '../../services/api';
import master from '../../services/master';
import { ProfessionalPhoto } from '../../components/ProfessionalPhoto';
import background from '../../assets/images/portal-bg.jpg';
import portalLogo from '../../assets/images/portal-logo.png';
import { DialogChangeStatus } from './DialogChangeStatus';
import portalGroups from '../../config/portalGroups';
import { useAuth } from '../../hooks/auth';
import { useMethodology } from '../../hooks/methodologyContext';
interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const Home: React.FC<{ title?: string }> = ({ title }) => {
  const { setTitle } = useDocumentTitle();
  const history = useHistory();
  const [openChangeProjectStatusDialog, setOpenChangeProjectStatusDialog] =
    useState(false);
  const [projects, setProjects] = useState<DataSource>();
  const [projectId, setProjectId] = useState(0);
  const [currentStatus, setCurrentStatus] = useState(0);
  const { hasScopes } = useAuth();
  const { setMethodology } = useMethodology();

  const [professionals] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/professionals', {
          params: {
            where: {
              IsAnyStatus: true,
            },
          },
        });
        return data;
      },
    }),
    paginate: true,
  });

  const [practices] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('master/practices');
        return data;
      },
    }),
    paginate: true,
  });

  const fetcher = (url: string) => api.get(url).then(res => res.data);
  const { data } = useSWR('/api/projects', fetcher);

  const loadProjects = useCallback(async () => {
    const projectStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        return data;
      },
    });

    setProjects(
      new DataSource({
        store: projectStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [data]);

  useEffect(() => {
    setTitle(title);
    loadProjects();
  }, [setTitle, title, loadProjects]);

  const textCells = useCallback(e => {
    return (
      <p
        style={{
          fontWeight: '600',
          textTransform: 'uppercase',
          letterSpacing: '0.05em',
        }}
      >
        {e.text}
      </p>
    );
  }, []);

  const statusCell = useCallback(e => {
    if (e.value === 1) {
      return (
        <Tooltip title="Project Draft">
          <div className="statuscell">
            <svg
              className="draft"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20.1081 4C28.9934 4 36.2162 11.2228 36.2162 20.1081C36.2162 28.9934 28.9934 36.2022 20.1081 36.2022C11.2228 36.2022 4 28.9794 4 20.0941C4 11.2088 11.2368 4 20.1081 4ZM20.1081 34.0088C27.778 34.0088 34.0088 27.7779 34.0088 20.1081C34.0088 12.4382 27.7779 6.20735 20.1081 6.20735C12.4382 6.20735 6.20736 12.4382 6.20736 20.1081C6.20736 27.7779 12.4522 34.0088 20.1081 34.0088Z" />
            </svg>
          </div>
        </Tooltip>
      );
    }
    if (e.value === 2) {
      return (
        <Tooltip title="Project Setup">
          <div className="statuscell">
            <svg
              className="setup"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M22.1993 36.4879C13.314 36.4879 6.09119 29.2651 6.09119 20.3798C6.09119 11.4945 13.314 4.28572 22.1993 4.28572C31.0846 4.28572 38.3074 11.5085 38.3074 20.3938C38.3074 29.2791 31.0706 36.4879 22.1993 36.4879ZM22.1993 6.4791C14.5294 6.4791 8.29854 12.71 8.29854 20.3798C8.29854 28.0497 14.5294 34.2806 22.1993 34.2806C29.8691 34.2806 36.1 28.0497 36.1 20.3798C36.1 12.71 29.8552 6.4791 22.1993 6.4791Z" />
              <path d="M7.125 27.5188C11.06 27.5188 14.25 24.3288 14.25 20.3938C14.25 16.4588 11.06 13.2688 7.125 13.2688C3.18997 13.2688 0 16.4588 0 20.3938C0 24.3288 3.18997 27.5188 7.125 27.5188Z" />
            </svg>
          </div>
        </Tooltip>
      );
    }
    if (e.value === 3) {
      return (
        <Tooltip title="Project During">
          <div className="statuscell">
            <svg
              className="during"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20.1081 4C28.9934 4 36.2162 11.2228 36.2162 20.1081C36.2162 28.9934 28.9934 36.2022 20.1081 36.2022C11.2228 36.2022 4 28.9794 4 20.0941C4 11.2088 11.2368 4 20.1081 4ZM20.1081 34.0088C27.778 34.0088 34.0088 27.7779 34.0088 20.1081C34.0088 12.4382 27.7779 6.20735 20.1081 6.20735C12.4382 6.20735 6.20736 12.4382 6.20736 20.1081C6.20736 27.7779 12.4522 34.0088 20.1081 34.0088Z" />
              <path d="M20.125 13C16.19 13 13 16.19 13 20.125C13 24.06 16.19 27.25 20.125 27.25C24.06 27.25 27.25 24.06 27.25 20.125C27.25 16.19 24.06 13 20.125 13Z" />
            </svg>
          </div>
        </Tooltip>
      );
    }
    if (e.value === 4) {
      return (
        <Tooltip title="Project Closure">
          <div className="statuscell">
            <svg
              className="closure"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.5367 4.28571C26.422 4.2857 33.6448 11.5085 33.6448 20.3938C33.6448 29.2791 26.422 36.4879 17.5367 36.4879C8.65139 36.4879 1.42859 29.2651 1.42859 20.3798C1.42859 11.4945 8.66536 4.28571 17.5367 4.28571ZM17.5367 34.2945C25.2065 34.2945 31.4374 28.0636 31.4374 20.3938C31.4374 12.7239 25.2065 6.49306 17.5367 6.49306C9.86683 6.49306 3.63594 12.7239 3.63595 20.3938C3.63595 28.0637 9.8808 34.2945 17.5367 34.2945Z" />
              <path d="M32.611 13.2548C28.6759 13.2548 25.486 16.4448 25.486 20.3798C25.486 24.3149 28.6759 27.5048 32.611 27.5048C36.546 27.5048 39.736 24.3148 39.736 20.3798C39.736 16.4448 36.546 13.2548 32.611 13.2548Z" />
            </svg>
          </div>
        </Tooltip>
      );
    }
    if (e.value === 5) {
      return (
        <Tooltip title="Project Finished">
          <div className="statuscell">
            <svg
              className="finished"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20.1081 4C28.9934 4 36.2162 11.2228 36.2162 20.1081C36.2162 28.9934 28.9934 36.2022 20.1081 36.2022C11.2228 36.2022 4 28.9794 4 20.0941C4 11.2088 11.2368 4 20.1081 4ZM20.1081 34.0088C27.778 34.0088 34.0088 27.7779 34.0088 20.1081C34.0088 12.4382 27.7779 6.20735 20.1081 6.20735C12.4382 6.20735 6.20736 12.4382 6.20736 20.1081C6.20736 27.7779 12.4522 34.0088 20.1081 34.0088Z" />
              <circle cx="20" cy="20" r="15" />
            </svg>
          </div>
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Project Paused">
        <div className="statuscell">
          <svg
            className="paused"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20.1081 4C28.9934 4 36.2162 11.2228 36.2162 20.1081C36.2162 28.9934 28.9934 36.2022 20.1081 36.2022C11.2228 36.2022 4 28.9794 4 20.0941C4 11.2088 11.2368 4 20.1081 4ZM20.1081 34.0088C27.778 34.0088 34.0088 27.7779 34.0088 20.1081C34.0088 12.4382 27.7779 6.20735 20.1081 6.20735C12.4382 6.20735 6.20736 12.4382 6.20736 20.1081C6.20736 27.7779 12.4522 34.0088 20.1081 34.0088Z" />
            <rect x="21" y="14" width="6" height="12" rx="2.5" />
            <rect x="13" y="14" width="6" height="12" rx="2.5" />
          </svg>
        </div>
      </Tooltip>
    );
  }, []);

  const managerCell = useCallback(e => {
    return (
      <ProfessionalPhoto
        name={e.data.projectManager?.professional?.name || ''}
        guid={e.data.projectManager?.professional?.photoGUID || ''}
      />
    );
  }, []);

  const statusLookup = [
    { idStatus: 1, name: 'Draft' },
    { idStatus: 2, name: 'Setup' },
    { idStatus: 3, name: 'During' },
    { idStatus: 4, name: 'Closure' },
    { idStatus: 5, name: 'Closed' },
    { idStatus: 6, name: 'Paused' },
  ];

  const onEditStatus = useCallback((key, dataToUpdate) => {
    setProjectId(key);
    setCurrentStatus(dataToUpdate.idStatus);
    setOpenChangeProjectStatusDialog(true);
  }, []);

  const commandColumnRender = useCallback(
    e => (
      <>
        {hasScopes([portalGroups.Admin]) && (
          <Tooltip title="Edit status" aria-label="open" placement="left">
            <IconButton
              aria-label="Edit status"
              size="small"
              onClick={() => onEditStatus(e.key, e.data)}
            >
              <FiArrowUpCircle size={18} />
            </IconButton>
          </Tooltip>
        )}
      </>
    ),
    [hasScopes, onEditStatus],
  );
  useEffect(() => {
    setMethodology(null)
  }, [])

  return (
    <Container>
      <div className="background-gradient" />
      <img className="background" src={background} alt="" />

      <Body>
        <img
          className="logo"
          src={portalLogo}
          alt="Welcome to the Project Portal"
        />
        <DataGrid
          style={{ marginBottom: '10px' }}
          onCellClick={e => {
            if (e.rowType === 'data' && e.columnIndex !== 5) {
              history.push(`project/${e.data.id}/home`);
            }
          }}
          className="grid"
          dataSource={projects}
        >
          <HeaderFilter visible allowSearch />
          <SearchPanel visible />
          <Column
            dataField="code"
            caption="CODE"
            alignment="center"
            cellRender={textCells}
          />
          <Column
            dataField="idStatus"
            caption="STATUS"
            alignment="center"
            cellRender={statusCell}
          >
            <Lookup
              dataSource={statusLookup}
              displayExpr="name"
              valueExpr="idStatus"
            />
          </Column>
          <Column
            dataField="client.name"
            caption="CLIENT"
            alignment="center"
            cellRender={textCells}
          />
          <Column
            dataField="projectManager.professional.id"
            caption="MANAGER"
            alignment="center"
            cellRender={managerCell}
          >
            <Lookup
              dataSource={professionals}
              displayExpr="Name"
              valueExpr="ID"
            />
          </Column>
          <Column
            dataField="projectPractices[0].idPractice"
            caption="PRACTICE"
            alignment="center"
          >
            <Lookup dataSource={practices} displayExpr="Name" valueExpr="ID" />
          </Column>

          <Column type="buttons" cellRender={commandColumnRender} width={120} />
        </DataGrid>
      </Body>

      {openChangeProjectStatusDialog && (
        <DialogChangeStatus
          currentStatus={currentStatus}
          handleClose={() => setOpenChangeProjectStatusDialog(false)}
          id={projectId}
          open={openChangeProjectStatusDialog}
          handleCloseAndReload={() => {
            projects?.reload();
            setOpenChangeProjectStatusDialog(false);
          }}
        />
      )}
    </Container>
  );
};
