import React, { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { WhisperSpinner } from 'react-spinners-kit';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { TextBox } from 'devextreme-react';
import { format, parseISO } from 'date-fns';
import {
  FiAward,
  FiBookOpen,
  FiBriefcase,
  FiCalendar,
  FiClock,
  FiDollarSign,
  FiGlobe,
  FiGrid,
  FiHelpCircle,
  FiMaximize,
  FiOctagon,
  FiUser,
} from 'react-icons/fi';
import { Tooltip } from '@material-ui/core';
import { Container, Body, ContainerLoading } from './styles';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import master from '../../services/master';

export const ProjectHome: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const { user } = useAuth();
  const { setValue } = useForm();
  const [loading, setLoading] = useState(false);

  const [closeSetup, setCloseSetup] = useState(false);
  const [beginClosure, setBeginClosure] = useState(false);
  const [closeProject, setCloseProject] = useState(false);
  const [projectInfo, setProjectInfo] = useState<any>({});
  const [isProjectManagerOrPartner, setIsProjectManagerOrPartner] =
    useState(false);
  const [projectEvaluations, setProjectEvaluations] = useState<DataSource>();
  const [filteredProfessionals, setFilteredProfessionals] =
    useState<DataSource>();
  const [practice, setPractice] = useState<any>();
  const [methodologies, setMethodologies] = useState<any>();
  const [geographic, setGeographic] = useState<any>();
  const [offices, setOffices] = useState<any>();

  const fetcher = (url: string) => api.get(url).then(res => res.data);
  const { data: project } = useSWR(`/api/projects/${id}`, fetcher);

  const loadProjectInfo = useCallback(async () => {
    setLoading(true);
    if (!project) return;
    setCloseSetup(false);
    setBeginClosure(false);
    setCloseProject(false);

    const professionalResponse = await master.get('/master/professionals', {
      params: {
        where: {
          IsAnyStatus: true,
        },
      },
    });

    setProjectInfo(project);
    const projectTeamIds = project.projectTeam.map((x: any) => {
      return x.professional?.id;
    });

    setFilteredProfessionals(
      professionalResponse.data
        .map((p: any) => {
          return { ID: p.ID, Name: p.Name };
        })
        .filter((x: any) => projectTeamIds.includes(x.ID)),
    );
    setIsProjectManagerOrPartner(
      project.projectTeam.find(
        (x: any) =>
          x.idProfessional === user.userId && (x.isPartner || x.isManager),
      ),
    );
    setValue('tdOportunity', project.projectTrainingDevelopment?.tdOportunity);
    setValue('text', project.projectTrainingDevelopment?.text);

    if (project.idStatus === 2) {
      setCloseSetup(true);
    }

    if (project.idStatus === 3) {
      setBeginClosure(true);
    }

    if (project.idStatus === 4) {
      setCloseProject(true);
    }

    setLoading(false);
  }, [setValue, user, project]);

  const loadProjectInformationsToPractices = useCallback(async () => {
    if (!project) return;
    setLoading(true);
    const practicesResponse = await master.get('master/practices');
    const methodologiesResponse = await master.get('master/methodologies');
    const geographicsResponse = await master.get('master/geographics');
    const officesResponse = await master.get('master/offices');

    if (project.projectPractices && project.projectPractices.length > 0) {
      setPractice(
        practicesResponse.data.find(
          (x: any) => x.ID === project.projectPractices[0].idPractice,
        ).Name,
      );
    }

    if (
      project.projectMethodologies &&
      project.projectMethodologies.length > 0
    ) {
      setMethodologies(
        methodologiesResponse.data.find(
          (x: any) => x.ID === project.projectMethodologies[0].idMethodology,
        ).Name,
      );
    }

    if (project.projectGeographics && project.projectGeographics.length > 0) {
      setGeographic(
        geographicsResponse.data.find(
          (x: any) => x.ID === project.projectGeographics[0].idGeographic,
        ).Name,
      );
    }

    if (project.projectOffices && project.projectOffices.length > 0) {
      setOffices(
        officesResponse.data.find(
          (x: any) => x.ID === project.projectOffices[0].idOffice,
        ).Name,
      );
    }
    setLoading(false);
  }, [project]);

  const loadProjectEvaluations = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(`api/project-evaluations/project/${id}`);
        return data;
      },
      insert: async data => {
        await api.post(`api/project-evaluations/project/${id}`, data);
      },
      update: async (key, data) => {
        await api.put(`/api/project-evaluations/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project-evaluations/${key}`);
      },
    });

    setProjectEvaluations(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id]);

  useEffect(() => {
    loadProjectInfo();
    loadProjectEvaluations();
    loadProjectInformationsToPractices();
  }, [
    loadProjectInfo,
    loadProjectEvaluations,
    loadProjectInformationsToPractices,
  ]);

  return (
    <Container>
      {loading ? (
        <ContainerLoading>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      ) : (
        <>
          <div className="header">
            <div>
              <h1>{projectInfo && projectInfo.name}</h1>
              <h2>{projectInfo && projectInfo.code}</h2>
            </div>
          </div>
          <Body>
            <div className="card">
              <FiMaximize />
              <h2>Project Scope</h2>
              <p>
                {(projectInfo &&
                  projectInfo.projectSession &&
                  projectInfo.projectSession.length > 0 &&
                  projectInfo.projectSession[0] &&
                  projectInfo.projectSession[0].projectScope) ||
                  ''}
              </p>
            </div>

            <div className="card">
              <FiDollarSign />
              <h2>Sales Context</h2>
              <p>{projectInfo && projectInfo.whatSalesContext}</p>
            </div>
            <div className="card">
              <FiHelpCircle />
              <h2>Why the Client Need Help</h2>

              <p>{projectInfo && projectInfo.whyClientNeedHelp}</p>
            </div>
            <div className="card">
              <div className="team">
                <FiUser />
                <h2>Project Team</h2>

                <div className="userRow">
                  {projectInfo?.projectTeam?.map((p: any) => (
                    <Tooltip
                      placement="top"
                      title={p?.professional?.name}
                      key={p?.id}
                    >
                      <a
                        href={`https://teams.microsoft.com/l/chat/0/0?users=${p?.professional?.email}`}
                        target="_blank"
                        style={{ display: 'flex', flexDirection: 'column' }}
                        rel="noreferrer"
                        key={p.id}
                      >
                        <img
                          src={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${p?.professional?.login}_192_192.png`}
                          alt={p?.professional?.name}
                        />
                      </a>
                    </Tooltip>
                  ))}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="team">
                <FiAward />
                <h2>Project Manager</h2>
                <div className="userRow">
                  {projectInfo?.projectTeam
                    ?.filter((x: any) => x.isManager)
                    .map((p: any) => (
                      <Tooltip
                        placement="top"
                        title={p?.professional?.name}
                        key={p?.professional?.id}
                      >
                        <a
                          href={`https://teams.microsoft.com/l/chat/0/0?users=${p?.professional?.email}`}
                          target="_blank"
                          style={{ display: 'flex', flexDirection: 'column' }}
                          rel="noreferrer"
                          key={p?.id}
                        >
                          <img
                            src={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${p?.professional?.login}_192_192.png`}
                            alt={p?.professional?.name}
                          />
                        </a>
                      </Tooltip>
                    ))}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="team">
                <FiOctagon />
                <h2>Responsible Partner</h2>
                <div className="userRow">
                  {projectInfo?.projectTeam
                    ?.filter((x: any) => x.isPartner)
                    .map((p: any) => (
                      <Tooltip
                        placement="top"
                        title={p?.professional?.name}
                        key={p?.professional?.id}
                      >
                        <a
                          href={`https://teams.microsoft.com/l/chat/0/0?users=${p?.professional?.email}`}
                          target="_blank"
                          style={{ display: 'flex', flexDirection: 'column' }}
                          rel="noreferrer"
                          key={p?.id}
                        >
                          <img
                            src={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${p?.professional?.login}_192_192.png`}
                            alt={p?.professional?.name}
                          />
                        </a>
                      </Tooltip>
                    ))}
                </div>
              </div>
            </div>
            <div className="card">
              <FiBookOpen />
              <h2>Project Methodologies</h2>
              <p>{methodologies}</p>
            </div>
            <div className="card">
              <FiGlobe />
              <h2>Project Geographics</h2>
              <p>{geographic}</p>
            </div>
            <div className="card">
              <FiBriefcase />
              <h2>Project Offices</h2>
              {offices}
            </div>
            <div className="card">
              <FiGrid />
              <h2>Project Practices</h2>
              <p>{practice}</p>
            </div>
            <div className="card">
              <FiCalendar />
              <h2>Created At</h2>
              <p>
                {projectInfo &&
                  projectInfo.createdAt &&
                  format(
                    new Date(projectInfo.createdAt.toString()),
                    'dd MMM yyyy',
                  )}
              </p>
            </div>
            <div className="card">
              <FiClock />
              <h2>Updated At</h2>
              <p>
                {projectInfo &&
                  projectInfo.updatedAt &&
                  format(
                    new Date(projectInfo.updatedAt.toString()),
                    'dd MMM yyyy',
                  )}
              </p>
            </div>
          </Body>
        </>
      )}
    </Container>
  );
};
