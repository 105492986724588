import { DataGrid, Popup } from 'devextreme-react';
import {
  Column,
  Editing,
  Form,
  HeaderFilter,
  Lookup,
  SearchPanel,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { useParams } from 'react-router';
import { WhisperSpinner } from 'react-spinners-kit';
import { IconButton, Tooltip } from '@mui/material';
import { Item } from 'devextreme-react/form';
import { Button } from '../../../components/Button';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import master, { CustomStoreProps } from '../../../services/master';
import { ExcelDialog } from './ExcelDialog';
import { Body, Container } from './styles';
import { BoardHeader } from '../../../components/BoardHeader';
import { ContainerLoading } from '../ClientTab/styles';
import { DialogEditStakeholder } from './DialogEditStakeholder';
import { DialogAddStakeholder } from './DialogAddStakeholder';
import portalGroups from '../../../config/portalGroups';
import { useAuth } from '../../../hooks/auth';

export const StakeholdersTab: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const { hasScopes } = useAuth();
  const isAdmin = useMemo(() => {
    return hasScopes([portalGroups.Admin]);
  }, [hasScopes]);
  const [setupFinished, setSetupFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const { addToast } = useToast();
  const [openExcelUpload, setOpenExcelUpload] = useState(false);
  const [stakeholders, setStakeholders] = useState<DataSource>();
  const [idSetupSession, setIdSetupSession] = useState(0);
  const [filteredProfessionals, setFilteredProfessionals] =
    useState<DataSource>();
  const [idStakeholder, setIdStakeholder] = useState(0);
  // const [openAddStakeholder, setOpenAddStakeholder] = useState(false);
  // const [openEditStakeholder, setOpenEditStakeholder] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const loadProjectInfo = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/api/projects/${id}`);
    setSetupFinished(data.idStatus !== 2);
    setIdSetupSession(
      data.projectSession?.find((x: any) => x.isSetup)?.id || 0,
    );

    const professionalResponse = await master.get('/master/professionals', {
      params: {
        where: {
          IsAnyStatus: true,
        },
      },
    });

    const projectTeamIds = data.projectTeam.map((x: any) => {
      return x.professional?.id;
    });

    setFilteredProfessionals(
      professionalResponse.data
        .map((p: any) => {
          return { ID: p.ID, Name: p.Name };
        })
        .filter((x: any) => projectTeamIds.includes(x.ID)),
    );
    setLoading(false);
    return data;
  }, [id]);

  const loadStakeholders = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `/api/project/${id}/stakeholders/sessions/${idSetupSession}`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `/api/project/${id}/stakeholders/sessions/${idSetupSession}`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/project/${id}/stakeholders/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project/${id}/stakeholders/${key}`);
      },
    });

    setStakeholders(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id, idSetupSession]);

  const reloadProjectSession = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/api/projects/${id}`);
    setIdSetupSession(data.projectSession.find((x: any) => x.isSetup)?.id || 0);
    setLoading(false);
  }, [id]);

  useEffect(() => {
    loadStakeholders();
    loadProjectInfo();
  }, [loadProjectInfo, loadStakeholders]);

  const handleConfirmedImport = useCallback(async () => {
    setLoadingImport(true);
    addToast({
      title:
        "Importing this client's past stakeholders. This might take a while...",
      type: 'info',
      description: '',
    });

    try {
      await api.get(
        `/api/project/${id}/stakeholders/sessions/${idSetupSession}/client`,
      );
      reloadProjectSession();
      loadStakeholders();
      addToast({
        title: 'Client past stakeholders imported successfully.',
        type: 'success',
        description: '',
      });
    } catch (error) {
      addToast({
        title: 'Something went wrong.',
        type: 'error',
        description: '',
      });
    }

    setLoadingImport(false);
    setShowPopup(false);
  }, [addToast, id, idSetupSession, loadStakeholders, reloadProjectSession]);

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const statusRisk = [
    {
      statusId: 1,
      status: 'Green',
    },
    {
      statusId: 2,
      status: 'Yellow',
    },
    {
      statusId: 3,
      status: 'Red',
    },
    {
      statusId: 4,
      status: 'Blue',
    },
    {
      statusId: 5,
      status: 'Legacy',
    },
  ];

  const statusCell = useCallback(e => {
    if (e.value === 1) {
      return (
        <Tooltip title="Green">
          <div
            style={{
              width: 35,
              height: 35,
              boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
              borderRadius: '50%',
              margin: '0 auto',
              border: '5px solid white',
              backgroundColor: '#2ecc71',
            }}
          />
        </Tooltip>
      );
    }
    if (e.value === 2) {
      return (
        <Tooltip title="Yellow">
          <div
            style={{
              width: 35,
              height: 35,
              boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
              borderRadius: '50%',
              margin: '0 auto',
              border: '5px solid white',
              backgroundColor: '#F1C40F',
            }}
          />
        </Tooltip>
      );
    }
    if (e.value === 3) {
      return (
        <Tooltip title="Red">
          <div
            style={{
              width: 35,
              height: 35,
              boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
              borderRadius: '50%',
              margin: '0 auto',
              border: '5px solid white',
              backgroundColor: '#e74c3c',
            }}
          />
        </Tooltip>
      );
    }
    if (e.value === 4) {
      return (
        <Tooltip title="Blue">
          <div
            style={{
              width: 35,
              height: 35,
              boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
              borderRadius: '50%',
              margin: '0 auto',
              border: '5px solid white',
              backgroundColor: '#2980b9',
            }}
          />
        </Tooltip>
      );
    }
    if (e.value === 5) {
      return (
        <Tooltip title="Gray">
          <div
            style={{
              width: 35,
              height: 35,
              boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
              borderRadius: '50%',
              margin: '0 auto',
              border: '5px solid white',
              backgroundColor: '#bdc3c7',
            }}
          />
        </Tooltip>
      );
    }

    return '';
  }, []);

  const [languages] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('master/languages');
        return data.sort((a: any, b: any) => {
          if (a.Name < b.Name) {
            return -1;
          }
          if (a.Name > b.Name) {
            return 1;
          }
          return 0;
        });
      },
    }),
    paginate: true,
  });

  // const editStakeholderCell = useCallback(
  //   e => (
  //     <>
  //       <Tooltip title="Edit Stakeholder">
  //         <IconButton
  //           onClick={() => {
  //             setIdStakeholder(e.key);
  //             setOpenEditStakeholder(true);
  //           }}
  //         >
  //           <FiEdit2
  //             size={20}
  //             style={{
  //               color: '#8b0304',
  //             }}
  //           />
  //         </IconButton>
  //       </Tooltip>
  //     </>
  //   ),
  //   [],
  // );

  return (
    <div>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}
      {!loading && (
        <Container>
          <Popup
            visible={showPopup}
            onHiding={() => setShowPopup(false)}
            dragEnabled={false}
            closeOnOutsideClick
            showTitle
            title="Warning"
            width={350}
            height={230}
          >
            <p>
              This function can import a large number of stakeholders. Please
              use it with caution.
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '2em',
                marginTop: '2em',
              }}
            >
              <Button primary onClick={handleConfirmedImport}>
                {' '}
                {loadingImport ? 'Importing...' : 'Confirm'}
                {loadingImport && (
                  <WhisperSpinner
                    size={15}
                    backColor="#fff"
                    frontColor="#eee"
                  />
                )}
              </Button>
              <Button secondary onClick={() => setShowPopup(false)}>
                Cancel
              </Button>
            </div>
          </Popup>
          <BoardHeader title="SETUP" subtitle="" />
          <span>
            <h1
              style={{
                textTransform: 'uppercase',
                color: '#6c6c6c',
                fontSize: '24px',
                fontWeight: 700,
                letterSpacing: '0.1em',
              }}
            >
              Stakeholders
            </h1>
          </span>
          <div
            style={{
              display: 'flex',
              gap: '10px',
              justifyContent: 'space-between',
              padding: '14px',
            }}
          >
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button
                primary
                onClick={handleButtonClick}
                disabled={loadingImport || (!isAdmin && setupFinished)}
              >
                Import from Client Past Projects
              </Button>
              <Button
                primary
                onClick={() => setOpenExcelUpload(true)}
                disabled={!isAdmin && setupFinished}
              >
                Excel uploads
              </Button>
            </div>
          </div>
          <Body>
            <DataGrid
              dataSource={stakeholders}
              onRowUpdating={options => {
                options.newData = {
                  ...options.oldData,
                  ...options.newData,
                };
              }}
            >
              <HeaderFilter visible />
              <SearchPanel visible width={250} />
              <Column dataField="name" alignment="left" sortOrder="asc" />
              <Column
                dataField="idProfessionalResponsible"
                alignment="left"
                caption="Responsible"
              >
                <Lookup
                  dataSource={filteredProfessionals}
                  displayExpr="Name"
                  valueExpr="ID"
                />
              </Column>
              <Column dataField="position" alignment="left" />
              <Column
                dataField="birthday"
                dataType="date"
                format="dd MMM yyyy"
                alignment="left"
              />
              <Column dataField="language" alignment="left">
                <Lookup
                  dataSource={languages}
                  displayExpr="Name"
                  valueExpr="ID"
                />
              </Column>
              <Column
                dataField="currentStatus"
                alignment="left"
                cellRender={statusCell}
                width={85}
                caption="Status"
              >
                <Lookup
                  dataSource={statusRisk}
                  valueExpr="statusId"
                  displayExpr="status"
                />
              </Column>
              <Column dataField="actionPlan" alignment="left" />

              <Editing
                mode="popup"
                allowDeleting={isAdmin || !setupFinished}
                allowUpdating={isAdmin || !setupFinished}
                allowAdding={isAdmin || !setupFinished}
              >
                <Form>
                  <Item itemType="group" colCount={2} colSpan={2}>
                    <Item dataField="name" colSpan={1} isRequired />
                    <Item dataField="position" colSpan={1} />
                    <Item dataField="idProfessionalResponsible" colSpan={1} />
                    <Item dataField="birthday" colSpan={1} />
                    <Item dataField="language" colSpan={1} />
                    <Item dataField="currentStatus" colSpan={1} />
                    <Item
                      dataField="actionPlan"
                      editorType="dxTextArea"
                      colSpan={2}
                    />
                  </Item>
                </Form>
              </Editing>
            </DataGrid>
          </Body>
        </Container>
      )}
      {/* {openExcelUpload && (
        <ExcelDialog
          open={openExcelUpload}
          handleClose={() => setOpenExcelUpload(false)}
          handleUpload={() => stakeholders?.reload()}
          idProject={parseInt(id, 10)}
          idSession={idSetupSession}
        />
      )} */}
      {/* {openAddStakeholder && (
        <DialogAddStakeholder
          open={openAddStakeholder}
          handleClose={() => {
            setOpenAddStakeholder(false);
            reloadProjectSession();
            // eslint-disable-next-line no-unused-expressions
            stakeholders?.reload();
          }}
          idSetupSession={idSetupSession}
          idProject={parseInt(id, 10)}
        />
      )} */}

      {/* {openEditStakeholder && (
        <DialogEditStakeholder
          open={openEditStakeholder}
          handleClose={() => {
            setOpenEditStakeholder(false);
            // eslint-disable-next-line no-unused-expressions
            stakeholders?.reload();
          }}
          idStakeholder={idStakeholder}
          idProject={parseInt(id, 10)}
        />
      )} */}
    </div>
  );
};
