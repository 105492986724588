import React, { useCallback, useState, useEffect } from 'react';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { SelectBox } from 'devextreme-react';
import { Controller, useForm } from 'react-hook-form';
import { WhisperSpinner } from 'react-spinners-kit';
import { format } from 'date-fns';
import { Button } from '../../../../components/Button';
import { FormGroup } from '../../../../components/FormGroup';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';

import { LoadingContainer } from './styles';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
  projectId: number;
}

export const DialogEdit: React.FC<DialogCustomProps> = ({
  handleClose,
  open,
  projectId,
}) => {
  const { addToast } = useToast();
  const { control, handleSubmit, setValue } = useForm();
  const [duringTypes, setDuringTypes] = useState<DataSource>({} as DataSource);
  const [booleanSource, setBooleanSource] = useState<DataSource>(
    {} as DataSource,
  );
  const [loading, setLoading] = useState(false);
  const [onePageDate, setOnePageDate] = useState('');
  const [opIsSubmitted, setOpIsSubmitted] = useState('');

  const handleEdit = useCallback(
    async data => {
      setLoading(true);
      try {
        await api.patch(`/api/projects/${projectId}/priority`, data);

        addToast({
          title: 'Saved',
          type: 'success',
        });

        handleClose();
      } catch {
        addToast({
          title: 'Something went wrong',
          type: 'error',
        });
      }

      setLoading(false);
    },
    [projectId, addToast, handleClose],
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    const projectResponse = await api.get(`/api/projects/${projectId}`);
    const duringTypesResponse = await api.get(`/api/during-types`);
    setValue('priority', projectResponse.data.priority || false);
    setValue('internal', projectResponse.data.internal || false);
    setValue('idDuringType', projectResponse.data.idDuringType);
    setOnePageDate(
      format(new Date(projectResponse.data.onePageCreatedAt), 'dd MMM yyyy'),
    );
    setOpIsSubmitted(projectResponse.data.onePageSubmitted ? 'Yes' : 'No');

    setValue('isSubmitted', projectResponse.data.onePageSubmitted);
    setValue('onePageProblemLevel', projectResponse.data.onePageProblemLevel);
    setValue(
      'onePageEnvironmentStatus',
      projectResponse.data.onePageEnvironmentStatus,
    );

    const projectStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => {
        return duringTypesResponse.data;
      },
    });

    setDuringTypes(
      new DataSource({
        store: projectStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );

    const booleanStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        return [
          {
            id: true,
            name: 'Yes',
          },
          {
            id: false,
            name: 'No',
          },
        ];
      },
    });

    setBooleanSource(
      new DataSource({
        store: booleanStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );

    setLoading(false);
  }, [projectId, setValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
      onSubmit={() => {
        handleClose();
      }}
      fullWidth
    >
      <form onSubmit={handleSubmit(handleEdit)}>
        {loading && (
          <LoadingContainer>
            <WhisperSpinner size={30} backColor="#8b0304" frontColor="#fff" />
          </LoadingContainer>
        )}
        <DialogTitle id="form-dialog-title">Edit</DialogTitle>
        <DialogContent>
          <FormGroup fieldSetLabel="Priority">
            <Controller
              control={control}
              name="priority"
              render={({ field }) => (
                <SelectBox
                  dataSource={booleanSource}
                  valueExpr="id"
                  displayExpr="name"
                  onValueChanged={e => field.onChange(e.value)}
                  value={field.value}
                />
              )}
            />
          </FormGroup>
          <FormGroup fieldSetLabel="Internal">
            <Controller
              control={control}
              name="internal"
              render={({ field }) => (
                <SelectBox
                  dataSource={booleanSource}
                  valueExpr="id"
                  displayExpr="name"
                  onValueChanged={e => field.onChange(e.value)}
                  value={field.value}
                />
              )}
            />
          </FormGroup>
          <FormGroup fieldSetLabel="During Type">
            <Controller
              control={control}
              name="idDuringType"
              render={({ field }) => (
                <SelectBox
                  dataSource={duringTypes}
                  valueExpr="id"
                  displayExpr="name"
                  onValueChanged={e => field.onChange(e.value)}
                  value={field.value}
                />
              )}
            />
          </FormGroup>
          <h1
            style={{
              fontSize: 'larger',
              fontWeight: 'bold',
            }}
          >
            One Page Info
          </h1>
          <p
            style={{
              fontWeight: 600,
            }}
          >
            Date created: {onePageDate}
          </p>
          <p
            style={{
              fontWeight: 600,
              marginBottom: '10px',
            }}
          >
            Is submitted: {opIsSubmitted}
          </p>
          <FormGroup fieldSetLabel="One Page Status">
            <Controller
              control={control}
              name="onePageProblemLevel"
              render={({ field }) => (
                <SelectBox
                  dataSource={[
                    { id: 0, color: 'Not Submitted' },
                    { id: 1, color: 'Green' },
                    { id: 2, color: 'Yellow' },
                    { id: 3, color: 'Red' },
                  ]}
                  valueExpr="id"
                  displayExpr="color"
                  onValueChanged={e => field.onChange(e.value)}
                  value={field.value}
                />
              )}
            />
          </FormGroup>
          <FormGroup fieldSetLabel="Environment Status">
            <Controller
              control={control}
              name="onePageEnvironmentStatus"
              render={({ field }) => (
                <SelectBox
                  dataSource={[
                    { id: 1, color: 'Green' },
                    { id: 2, color: 'Yellow' },
                    { id: 3, color: 'Red' },
                  ]}
                  valueExpr="id"
                  displayExpr="color"
                  onValueChanged={e => field.onChange(e.value)}
                  value={field.value}
                />
              )}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button type="submit" primary disabled={loading}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
