import { IconButton, Tooltip } from '@material-ui/core';
import { DataGrid } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useParams } from 'react-router';
import api from '../../../services/api';
import { Body, Container, HeaderContent } from './styles';
import { BoardHeader } from '../../../components/BoardHeader';
import { DashboardModal } from '../DashboardModal';

export const DashboardArchive: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const [dashboards, setDashboards] = useState<DataSource>();
  const [idDashboardModal, setIdDashboardModal] = useState(0);
  const [openDashboardModal, setOpenDashboardModal] = useState(false);

  const loadDashboards = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get(`api/project-dashboards/project/${id}`);
        return response.data;
      },
    });

    setDashboards(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id]);

  useEffect(() => {
    loadDashboards();
  }, [loadDashboards]);

  const onePageStatusCell = useCallback(e => {
    if (e.value === 1)
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#2ecc71',
          }}
        />
      );
    if (e.value === 2)
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#F7D900',
          }}
        />
      );
    if (e.value === 3)
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#e74c3c',
          }}
        />
      );
    return (
      <div
        style={{
          width: 35,
          height: 35,
          boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
          borderRadius: '50%',
          margin: '0 auto',
          border: '5px solid white',
          backgroundColor: '#bdc3c7',
        }}
      />
    );
  }, []);

  const viewDashboardCell = useCallback(e => {
    return (
      <Tooltip title="DNA">
        <IconButton
          onClick={() => {
            setIdDashboardModal(e.key);
            setOpenDashboardModal(true);
          }}
        >
          <FiSearch size={20} />
        </IconButton>
      </Tooltip>
    );
  }, []);

  return (
    <>
      <HeaderContent>
        <BoardHeader title="DURING" subtitle="" />
      </HeaderContent>
      <Container>
        <div className="content">
          <span>
            <h1
              style={{
                textTransform: 'uppercase',
                color: '#6c6c6c',
                fontSize: '24px',
                fontWeight: 700,
                letterSpacing: '0.1em',
              }}
            >
              Dashboard Archive
            </h1>
          </span>
        </div>
      </Container>

      <Body className="content">
        <DataGrid dataSource={dashboards}>
          <Column dataField="createdAt" dataType="date" format="dd MMM yyyy" />
          <Column
            dataField="onePage.onePageProblemLevel"
            caption="One Page Status"
            alignment="center"
            cellRender={onePageStatusCell}
          />
          <Column caption="View Dashboard" cellRender={viewDashboardCell} />
        </DataGrid>
      </Body>

      {openDashboardModal && (
        <DashboardModal
          open={openDashboardModal}
          handleClose={() => {
            setOpenDashboardModal(false);
          }}
          id={idDashboardModal}
        />
      )}
    </>
  );
};
