import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { TextBox, TextArea, SelectBox, DateBox } from 'devextreme-react';
import { useParams } from 'react-router';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Button } from '../../../../components/Button';
import { Container } from './styles';
import api from '../../../../services/api';
import { FormGroup } from '../../../../components/FormGroup';
import { useToast } from '../../../../hooks/toast';
import master, { CustomStoreProps } from '../../../../services/master';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
}

export const DialogGenerateProfessionalEvaluations: React.FC<DialogCustomProps> =
  ({ open, handleClose }) => {
    const [idProfessional, setIdProfessional] = useState(undefined);
    const [idProject, setIdProject] = useState(undefined);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const { addToast } = useToast();

    const [professionals] = useState<CustomStoreProps>({
      store: new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          const { data } =await master.get('/master/professionals', {
          params: {
            where: {
              IsAnyStatus: true,
            },
          },
        });
          return data;
        },
      }),
      paginate: true,
    });

    const [projects] = useState<CustomStoreProps>({
      store: new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          const { data } = await api.get('api/projects');
          return data;
        },
      }),
      paginate: true,
    });

    const handleGenerate = useCallback(async () => {
      try {
        addToast({
          type: 'success',
          title: 'We are generating your report. This could take a while...',
        });

        const response = await api.get(
          `/api/reports/project-professional-evaluations?idProfessional=${idProfessional}&idProject=${idProject}&startDate=${
            startDate !== undefined && startDate.toUTCString()
          }&endDate=${endDate !== undefined && endDate.toUTCString()}`,
          {
            responseType: 'blob',
          },
        );

        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data]),
        );

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `report-project-evaluations.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();

        addToast({
          type: 'success',
          title: 'Report generated with success. Open or save it.',
        });
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Something went wrong...',
        });
      }
      handleClose();
    }, [handleClose, idProfessional, idProject, startDate, endDate, addToast]);

    const projectRender = useCallback(e => {
      return (
        <p>
          {e.code} - {e.name}
        </p>
      );
    }, []);

    return (
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          Project Professional Evaluations Report
        </DialogTitle>
        <Container>
          <DialogContent className="dialog-content">
            <FormGroup fieldSetLabel="Professional">
              <SelectBox
                searchEnabled
                dataSource={professionals}
                displayExpr="Name"
                valueExpr="ID"
                onValueChange={e => setIdProfessional(e)}
              />
            </FormGroup>
            <FormGroup fieldSetLabel="Project">
              <SelectBox
                searchEnabled
                searchExpr={['name', 'code']}
                dataSource={projects}
                displayExpr="name"
                itemRender={projectRender}
                valueExpr="id"
                onValueChange={e => setIdProject(e)}
              />
            </FormGroup>
            <FormGroup fieldSetLabel="Start Date">
              <DateBox openOnFieldClick onValueChange={e => setStartDate(e)} />
            </FormGroup>
            <FormGroup fieldSetLabel="End Date">
              <DateBox
                openOnFieldClick
                min={startDate}
                onValueChange={e => setEndDate(e)}
              />
            </FormGroup>
          </DialogContent>
        </Container>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleGenerate} primary>
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
