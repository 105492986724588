import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;

  .background {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    opacity: 0.5;
  }
  .background-gradient {
    z-index: -1;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(255 255 255 / 0%) 0%, white 50%);
    position: absolute;
  }
`;

export const Body = styled.section`
  width: 100%;
  text-align: center;

  .logo {
    margin-top: 50px;
    height: 80px;
    width: auto;
    filter: drop-shadow(0 0 10px white);
  }

  .dx-datagrid-header-panel {
    padding: 0;
  }

  .dx-texteditor.dx-editor-underlined .dx-texteditor-buttons-container {
    margin-right: 10px;
  }

  .dx-toolbar-after {
    padding: 0;
    width: 100%;
  }

  .pillars {
    padding: 18px 28px;
    background-color: white;
    width: 95%;
    margin: 0 auto;
    display: flex;
    text-align: center;
    box-shadow: 0px 0px 10px 0px #0000002e;

    div {
      padding: 15px;
      display: flex;
      flex-direction: column;

      h1 {
        font-weight: bold;
        font-size: 16px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .dx-datagrid {
    background-color: initial;
  }

  .dx-datagrid .statuscell {
    margin: auto;
    position: relative;
  }

  .dx-datagrid .statuscell svg {
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    will-change: transform;
    fill: #000;
  }

  .dx-datagrid .statuscell svg.closure {
    transform: translate(calc(-50% + 1px), -50%);
    will-change: transform;
  }

  .dx-datagrid .statuscell svg.setup {
    transform: translate(calc(-50% - 1px), -50%);
    will-change: transform;
  }

  .dx-datagrid-content.dx-datagrid-table {
    border-collapse: separate;
    border-spacing: 0;
  }

  .dx-datagrid .dx-row-lines > td {
    border-bottom: 0 !important;
  }

  .dx-datagrid .dx-row > td {
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 0.75rem;
    line-height: 1.25;
  }

  .dx-datagrid-headers.dx-datagrid-nowrap,
  .dx-datagrid-header-panel {
    border: none;
    background: none;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border: none;
  }

  .dx-datagrid-rowsview {
    overflow: visible;
  }

  .dx-datagrid-table tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .dx-datagrid-table tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .dx-row {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 7%);
    border: none !important;
  }

  .dx-row.dx-header-row {
    background: transparent;
    box-shadow: none;
    border: none;
  }

  .dx-toolbar {
    background: transparent;
  }

  .grid {
    margin-top: 40px !important;
    width: 95%;
    margin: 0 auto;

    .dx-datagrid-search-panel {
      width: 35em !important;
      border: none;
      border-radius: 50px;
      overflow: hidden;
      border-bottom: 0;
      background-color: white;
      box-shadow: 0 0 30px rgba(0 0 0 / 15%);
      padding: 0.5em 1em;
      margin: auto;
      transition: all 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .dx-datagrid-search-panel.dx-state-focused,
    .dx-datagrid-search-panel.dx-state-hover {
      transition: all 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .dx-texteditor.dx-editor-underlined::after {
      border: none;
    }

    .dx-searchbox .dx-placeholder {
      margin-left: 4px;
    }

    .dx-datagrid-headers.dx-datagrid-nowrap {
      margin-top: 50px;
    }

    input {
      margin-left: 3%;

      outline: none;
    }

    .dx-row-lines {
      cursor: pointer;
      transition: all 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .dx-row-lines:hover {
      transform: scale(1.03);
      transition: all 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .dx-datagrid-action {
      font-weight: bold !important;
      color: #333;
      background-color: none;
    }
  }
`;
