/* eslint-disable no-nested-ternary */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FiX } from 'react-icons/fi';

import { WhisperSpinner } from 'react-spinners-kit';
import { Controller, useForm } from 'react-hook-form';
import {
  CloseModalButton,
  Container,
  ProgressBar,
  FloatsContainer,
  AllRitualsContainer,
  SectionTitle,
  AllOnePagesContainer,
  ContainerLoading,
} from './styles';
import api from '../../../services/api';
import { IProjectDashboard } from '../../../services/master';
import { FormGroup } from '../../../components/FormGroup';
import { TextArea } from '../../../components/TextArea';
import { useToast } from '../../../hooks/toast';
import { HeaderComponent } from '../../../components/Header';
import { useAuth } from '../../../hooks/auth';
import portalGroups from '../../../config/portalGroups';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
  id: number;
}

export const DashboardModal: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  id,
}) => {
  const { hasScopes } = useAuth();
  const canSeePDCField = useMemo(() => {
    return hasScopes([portalGroups.Admin, portalGroups.PDC]);
  }, [hasScopes]);
  const [loading, setLoading] = useState(false);
  const { control, setValue } = useForm();
  const { addToast } = useToast();
  const [idOnePage, setIdOnePage] = useState(0);
  const [dashboardInfo, setDashboardInfo] = useState<IProjectDashboard>(
    {} as IProjectDashboard,
  );
  const loadDashboardInfo = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get<IProjectDashboard>(
      `/api/project-dashboards/${id}`,
    );
    setDashboardInfo(data);
    setIdOnePage(data.onePage?.id);
    setValue('keyMessage', data.onePage?.keyMessage);
    setValue('teamPoint', data.onePage?.teamPoint);
    setValue('methodInternal', data.onePage?.methodInternal);
    setValue('methodExternal', data.onePage?.methodExternal);
    setValue('governanceAction', data.onePage?.governanceAction);
    setValue('governanceAllocation', data.onePage?.governanceAllocation);
    setValue('coManagementComment', data.onePage?.coManagementComment);
    setValue('executiveBoardComment', data.onePage?.executiveBoardComment);
    setValue('executiveResumeComment', data.onePage?.executiveResumeComment);
    setValue('pdcResumeComment', data.onePage?.pdcResumeComment);
    setValue('environmentStatus', data.onePage?.environmentStatus);
    setLoading(false);
  }, [id, setValue]);

  useEffect(() => {
    loadDashboardInfo();
  }, [loadDashboardInfo]);

  const updateProjectDashboard = useCallback(
    async (fieldName, value) => {
      try {
        await api.patch(`/api/project-dashboards/one-page/${idOnePage}`, {
          [fieldName]: value,
        });
        addToast({
          type: 'success',
          title: 'Saved',
          description: '',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          description: '',
        });
      }
    },
    [addToast, idOnePage],
  );

  const onePageStatusCell = useCallback(level => {
    if (level === 1)
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#2ecc71',
          }}
        />
      );
    if (level === 2)
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#F7D900',
          }}
        />
      );
    if (level === 3)
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#e74c3c',
          }}
        />
      );
    return (
      <div
        style={{
          width: 35,
          height: 35,
          boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
          borderRadius: '50%',
          margin: '0 auto',
          border: '5px solid white',
          backgroundColor: '#bdc3c7',
        }}
      />
    );
  }, []);

  return (
    <>
      <HeaderComponent />
      <Container>
        <Dialog open={open} onClose={handleClose} fullScreen>
          {loading && (
            <ContainerLoading>
              <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
            </ContainerLoading>
          )}
          <DialogTitle>
            <CloseModalButton>
              <div className="title">
                <SectionTitle>Project Dashboard</SectionTitle>
                <p>
                  {dashboardInfo?.project?.code} -{' '}
                  {dashboardInfo?.project?.name}
                </p>
              </div>
              <IconButton
                onClick={handleClose}
                style={{ height: 'fit-content' }}
              >
                <FiX size={20} />
              </IconButton>
            </CloseModalButton>
          </DialogTitle>

          <DialogContent>
            <FloatsContainer>
              <div className="float">
                <span className="square">
                  <p className="title">Current Week</p>
                  <span className="progressContainer">
                    <p className="percentageNumber">
                      {dashboardInfo.fundoCurrentWeek || 0}%
                    </p>
                    <div className="backBar">
                      <ProgressBar
                        value={dashboardInfo.fundoCurrentWeek || 0}
                      />
                    </div>
                  </span>
                </span>
              </div>
              <div className="float">
                <span className="square">
                  <p className="title">First Allocation</p>
                  <span className="progressContainer">
                    <p className="percentageNumber">
                      {dashboardInfo.fundoFirstAllocation || 0}%
                    </p>
                    <div className="backBar">
                      <ProgressBar
                        value={dashboardInfo.fundoFirstAllocation || 0}
                      />
                    </div>
                  </span>
                </span>
              </div>
              <div className="float">
                <span className="square">
                  <p className="title">Sales Margin</p>
                  <span className="progressContainer">
                    <p className="percentageNumber">
                      {dashboardInfo.fundoInitial || 0}%
                    </p>
                    <div className="backBar">
                      <ProgressBar value={dashboardInfo.fundoInitial || 0} />
                    </div>
                  </span>
                </span>
              </div>
              <div className="float">
                <span className="square">
                  <p className="title">Target</p>
                  <span className="progressContainer">
                    <p className="percentageNumber">
                      {dashboardInfo.fundoTarget || 0}%
                    </p>
                    <div className="backBar">
                      <ProgressBar value={dashboardInfo.fundoTarget || 0} />
                    </div>
                  </span>
                </span>
              </div>
            </FloatsContainer>

            <SectionTitle>Execution of Integration Rituals</SectionTitle>
            <AllRitualsContainer>
              <div className="ritualContainer">
                <p className="title">Set Up Status</p>
                <p className="status">
                  {dashboardInfo?.setupStatus === true ? 'Done' : 'Pending'}
                </p>
              </div>
              <div className="ritualContainer">
                <p className="title">Professional DNA</p>
                <p className="lastDate">
                  Last Date:{' '}
                  {dashboardInfo?.lastProfessionalDNADate &&
                  dashboardInfo?.lastProfessionalDNADate
                    ? new Date(
                        dashboardInfo?.lastProfessionalDNADate,
                      ).toLocaleDateString()
                    : '-'}
                </p>
                <p className="nextDate">
                  Next Date:{' '}
                  {dashboardInfo?.nextProfessionalDNADate &&
                  dashboardInfo?.nextProfessionalDNADate
                    ? new Date(
                        dashboardInfo?.nextProfessionalDNADate,
                      ).toLocaleDateString()
                    : '-'}
                </p>
              </div>
              <div className="ritualContainer">
                <p className="title">Project Session</p>
                <p className="lastDate">
                  Last Date:{' '}
                  {dashboardInfo?.lastProjectSessionDate &&
                  dashboardInfo?.lastProjectSessionDate
                    ? new Date(
                        dashboardInfo?.lastProjectSessionDate,
                      ).toLocaleDateString()
                    : '-'}
                </p>
              </div>
              <div className="ritualContainer">
                <p className="title">Weekly Status</p>
                <p className="lastDate">
                  Last Date:{' '}
                  {dashboardInfo?.weeklyStatusDate &&
                  dashboardInfo?.weeklyStatusDate
                    ? new Date(
                        dashboardInfo?.weeklyStatusDate,
                      ).toLocaleDateString()
                    : '-'}
                </p>
              </div>
              <div className="ritualContainer">
                <p className="title">Total Stakeholders</p>
                <p className="number">
                  {dashboardInfo?.totalStakeholders || 0}
                </p>
              </div>
              <div className="ritualContainer">
                <p className="title">Critical Stakeholders</p>
                <p className="number" style={{ color: 'red' }}>
                  {dashboardInfo?.criticalStakeholders || 0}
                </p>
              </div>
            </AllRitualsContainer>

            <SectionTitle>One-Page</SectionTitle>
            <AllOnePagesContainer>
              <div style={{ gap: '25px' }}>
                <div className="ritualContainer">
                  <p className="title">SUBMISSION DATE</p>
                  <p className="status">
                    {(dashboardInfo?.onePage?.submittedAt &&
                      new Date(
                        dashboardInfo?.onePage?.submittedAt,
                      ).toLocaleDateString()) ||
                      '-'}
                  </p>
                </div>
                <div className="ritualContainer">
                  <p className="title">PROJECT STATUS</p>
                  {onePageStatusCell(
                    dashboardInfo?.onePage?.onePageProblemLevel,
                  )}
                </div>
                <div className="ritualContainer">
                  <p className="title">ENVIRONMENT STATUS</p>
                  {onePageStatusCell(dashboardInfo?.onePage?.environmentStatus)}
                </div>
              </div>
              <FormGroup fieldSetLabel="Key Messages">
                <Controller
                  name="keyMessage"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      value={field.value}
                      onChanged={value => {
                        setValue(field.name, value);
                        updateProjectDashboard(field.name, value);
                      }}
                      disabled
                    />
                  )}
                />
              </FormGroup>
              <FormGroup fieldSetLabel="Team Points">
                <Controller
                  name="teamPoint"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      value={field.value}
                      onChanged={value => {
                        setValue(field.name, value);
                        updateProjectDashboard(field.name, value);
                      }}
                      disabled
                    />
                  )}
                />
              </FormGroup>
              <div>
                <FormGroup fieldSetLabel="Internal">
                  <Controller
                    name="methodInternal"
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        value={field.value}
                        onChanged={value => {
                          setValue(field.name, value);
                          updateProjectDashboard(field.name, value);
                        }}
                        disabled
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup fieldSetLabel="External &amp; Extra Mile Actions">
                  <Controller
                    name="methodExternal"
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        value={field.value}
                        onChanged={value => {
                          setValue(field.name, value);
                          updateProjectDashboard(field.name, value);
                        }}
                        disabled
                      />
                    )}
                  />
                </FormGroup>
              </div>
              <div>
                <FormGroup fieldSetLabel="Actions, Needs and Definitions">
                  <Controller
                    name="governanceAction"
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        value={field.value}
                        onChanged={value => {
                          setValue(field.name, value);
                          updateProjectDashboard(field.name, value);
                        }}
                        disabled
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup fieldSetLabel="Allocations of the Week">
                  <Controller
                    name="governanceAllocation"
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        value={field.value}
                        onChanged={value => {
                          setValue(field.name, value);
                          updateProjectDashboard(field.name, value);
                        }}
                        disabled
                      />
                    )}
                  />
                </FormGroup>
              </div>
              <FormGroup fieldSetLabel="Co-Management Comments">
                <Controller
                  name="coManagementComment"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      value={field.value}
                      onChanged={value => {
                        setValue(field.name, value);
                        updateProjectDashboard(field.name, value);
                      }}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup fieldSetLabel="Delivery Comments">
                <Controller
                  name="executiveBoardComment"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      value={field.value}
                      onChanged={value => {
                        setValue(field.name, value);
                        updateProjectDashboard(field.name, value);
                      }}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup fieldSetLabel="Delivery Resume (Priority Projects)">
                <Controller
                  name="executiveResumeComment"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      value={field.value}
                      onChanged={value => {
                        setValue(field.name, value);
                        updateProjectDashboard(field.name, value);
                      }}
                    />
                  )}
                />
              </FormGroup>
              {canSeePDCField && (
                <FormGroup fieldSetLabel="PDC Resume (Priority Projects)">
                  <Controller
                    name="pdcResumeComment"
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        value={field.value}
                        onChanged={value => {
                          setValue(field.name, value);
                          updateProjectDashboard(field.name, value);
                        }}
                      />
                    )}
                  />
                </FormGroup>
              )}
            </AllOnePagesContainer>
          </DialogContent>
        </Dialog>
      </Container>
    </>
  );
};
