import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Body = styled.section`
  width: 100%;
  background-color: transparent;

  .pillars {
    padding: 18px 28px;
    background-color: white;
    width: 95%;
    margin: 0 auto;
    display: flex;
    text-align: center;
    box-shadow: 0px 0px 10px 0px #0000002e;

    div {
      padding: 15px;
      display: flex;
      flex-direction: column;

      h1 {
        font-weight: bold;
        font-size: 16px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .grid {
    margin-top: 40px !important;
    width: 95%;
    margin: 0 auto;

    .dx-datagrid-header-panel,
    .dx-toolbar-items-container {
      background-color: #f5f5f5;
    }

    .dx-datagrid-search-panel {
      width: 35rem !important;
      margin-right: 24vw;
      border: 2px solid #aeaeae;
      border-radius: 0px;
      border-bottom: 0;
      background-color: white;
    }

    input {
      margin-left: 3%;
      outline: none;
    }

    .dx-row-lines {
      cursor: pointer;
    }
    .dx-datagrid-action {
      font-weight: bold;
      color: #bbbbbb;
    }
  }
`;
