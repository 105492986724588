import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { WhisperSpinner } from 'react-spinners-kit';
import api from '../../../services/api';
import { Button } from '../../../components/Button';
import { useAuth } from '../../../hooks/auth';

interface DialogCustomProps {
  id: number;
  open: boolean;
  handleClose(): void;
}

export const DialogConfirmSubmitOrUndoOnePage: React.FC<DialogCustomProps> = ({
  id,
  open,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [onePageInfo, setOnePageInfo] = useState<any>();

  const loadOnePageInfo = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/api/one-pages/${id}`);
    setOnePageInfo(data);
    setLoading(false);
  }, [id]);

  useEffect(() => {
    loadOnePageInfo();
  }, [loadOnePageInfo]);

  const submitOrUndo = useCallback(async () => {
    setLoading(true);
    if (onePageInfo?.submittedAt) {
      await api.patch(`/api/one-pages/${id}/unsubmit`);
    } else {
      await api.patch(`/api/one-pages/${id}/submit`);
    }
    setLoading(false);
    handleClose();
  }, [handleClose, id, onePageInfo]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {onePageInfo?.submittedAt ? 'Undo Submit' : 'Submit'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to{' '}
          {onePageInfo?.submittedAt ? 'undo the submit' : 'submit the one page'}
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button primary onClick={submitOrUndo} autoFocus disabled={loading}>
          Confirm{' '}
          {loading && (
            <WhisperSpinner size={15} backColor="#fff" frontColor="#fff" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
