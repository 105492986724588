import styled from 'styled-components';

interface ContainerProps {
  isMenuExpanded: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  background-color: #ffffff;
  top: 0;
  left: 0;
  bottom: 0;
  border-left: 10px solid #666;
  box-shadow: 0 10px 50px rgba(0 0 0 / 10%);
  padding-top: 25px;
  width: ${props => (props.isMenuExpanded ? '240px' : '80px')};
  z-index: 10;
  transition: all 100ms cubic-bezier(0.075, 0.82, 0.165, 1);
  padding: 15px;


`;

export const NavigationDialogItemComponent = styled.div`
  height: 2rem;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  transition: all 100ms ease;
  position: relative;
  cursor: pointer;
  color: #333;
  font-size: 0.75rem;
  padding: 0 10px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: white;

  svg.customIcon {
    height: 1rem;
    fill: #333;
  }

  .title {
    cursor: default;
  }

  .projectHome {
    margin-bottom: 3em;
  }

  img {
    height: 1rem;
  }

  .userImage {
    filter: brightness(90%);
    background-color: #ccc;
    object-fit: cover;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin-left: -5px;
  }

  & .textContainer {
    margin-left: 1em;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.25;
    transition: all 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  & .textContainer.noImg {
    margin-left: 2rem;
  }

  &:hover {
    fill: #fff;
    color: #fff;
    background-color: #ccc;
  }

  & .portalLogo {
    transition: all 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
    margin: auto;
  }
`;
export const SubMenu = styled.div`
  max-height: 2.5em;
  overflow: hidden;
  margin-top: 0.5em;
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: white;

  @media (max-height: 600px) {
    margin-top: 0em;
  }

  &.opened {
    max-height: 80em;
    transition: all 500ms ease-in-out;
  }

  &.opened .title {
    background-color: #999;
    color: #fff;
    transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &.opened .title svg.customIcon {
    fill: #fff;
    transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
`;
