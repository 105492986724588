/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import { FiInfo, FiSearch, FiX } from 'react-icons/fi';
import { DataGrid, Tabs } from 'devextreme-react';
import { Column, Lookup, Editing } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { useParams } from 'react-router';
import { Container, Body, HeaderContent } from './styles';
import { HeaderComponent } from '../../../components/Header';
import { useDocumentTitle } from '../../../hooks/documentTitle';
import Play from '../../../assets/images/play.png';
import { Button } from '../../../components/Button';
import api from '../../../services/api';
import { ProfessionalDNAModalPage } from '../ProfessionalDNAModalPage';
import { useAuth } from '../../../hooks/auth';
import { BoardHeader } from '../../../components/BoardHeader';

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const ProfessionalDNA: React.FC<{ title?: string }> = ({ title }) => {
  const { id } = useParams<{
    id: string;
  }>();
  const { user } = useAuth();
  const [professionalDNAs, setProfessionalDNAs] = useState<DataSource>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [idProjectProfessionalDNA, setIdProjectProfessionalDNA] = useState(0);

  const loadProfessionalDNAs = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `/api/project-professional-dnas/project/${id}/session-type/2`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `/api/project-professional-dnas/project/${id}/session-type/2`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/project-professional-dnas/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project-professional-dnas/${key}`);
      },
    });

    setProfessionalDNAs(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id]);

  const { setTitle } = useDocumentTitle();

  useEffect(() => {
    loadProfessionalDNAs();
  }, [loadProfessionalDNAs]);

  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);

  const datesCell = useCallback(e => {
    return <p>{e.text}</p>;
  }, []);

  const dnaCell = useCallback(e => {
    return (
      <IconButton
        onClick={() => {
          setIdProjectProfessionalDNA(e.key);
          setIsDialogOpen(true);
        }}
        disabled={e.row.isEditing}
      >
        <FiSearch size={20} style={{ textAlign: 'center' }} />
      </IconButton>
    );
  }, []);

  return (
    <>
      <HeaderContent>
        <BoardHeader title="DURING" subtitle="" />
      </HeaderContent>
      <Body>
        <div className="content">
          <Container>
            <span>
              <h1
                style={{
                  textTransform: 'uppercase',
                  color: '#6c6c6c',
                  fontSize: '24px',
                  fontWeight: 700,
                  letterSpacing: '0.1em',
                }}
              >
                Professional DNA
              </h1>
            </span>
          </Container>
        </div>
        <div className="client">
          <DataGrid
            dataSource={professionalDNAs}
            onRowUpdating={options => {
              options.newData = {
                ...options.oldData,
                ...options.newData,
              };
            }}
          >
            <Column
              dataField="sessionDate"
              dataType="date"
              format="dd MMM yyyy"
              caption="SESSION DATE"
              cellRender={datesCell}
            />
            <Column
              dataField="sessionNextDate"
              dataType="date"
              format="dd MMM yyyy"
              caption="SESSION NEXT DATE"
              cellRender={datesCell}
              allowEditing={false}
            />
            <Column
              dataField="dna"
              caption="DNA"
              cellRender={dnaCell}
              alignment="center"
              width={140}
              allowEditing={false}
            />
            <Editing allowAdding allowDeleting allowUpdating />
          </DataGrid>
        </div>
      </Body>
      {isDialogOpen && (
        <>
          <div
            className="customDialogBG"
            onMouseUp={() => setIsDialogOpen(false)}
          />
          <div className="customDialog">
            <h2>Professional DNAs</h2>
            <FiX
              className="closeButton"
              onMouseUp={() => setIsDialogOpen(false)}
            />
            <ProfessionalDNAModalPage
              open={isDialogOpen}
              handleClose={() => {
                setIsDialogOpen(false);
              }}
              idProject={parseInt(id, 10)}
              idProjectProfessionalDNA={idProjectProfessionalDNA}
            />
          </div>
        </>
      )}
    </>
  );
};
