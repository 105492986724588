import styled from 'styled-components';

export const Container = styled.section`
  padding: 15px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -4px;
  width: 100%;

  .header-name {
    h1 {
      font-size: 18px;
      white-space: nowrap;
      font-weight: bold;
    }
    p {
      font-size: 12px;
      color: #9699a6;
      white-space: nowrap;
      font-weight: bold;
    }
  }
`;
