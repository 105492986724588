import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import jwt from 'jsonwebtoken';

import { config } from '../config/ms-auth';

import api, { ISignIn } from '../services/master';
import { Permissions } from '../services/api';

interface UserData {
  userName: string;
  userEmail: string;
  scopes: string[];
  comanagementId: number;
  login: string;
  userId: number;
  practiceId: number;
  businessUnitsIds: number[];
}

interface JWTPaylod {
  displayName: string;
  email: string;
  scopes: string[];
  professionalId: number;
  comanagementId: number;
  comanagementsIds: number[];
  practiceId: number;
  officeId: number;
  businessUnits: number[];
}

interface AuthState {
  token: string;
  user: UserData;
}

interface AuthContextData {
  user: UserData;
  signInAuthorize(accessToken: string): Promise<void>;
  signOut(): void;
  redirectMicrosoft(): void;
  isAuthenticated: boolean;
  hasScopes(scopes: string[]): boolean;
}

interface IPermissions {
  [key: string]: any;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data] = useState<AuthState>(() => {
    const token = localStorage.getItem('accessToken') as string;

    if (token) {
      const decoded = jwt.decode(token.split(' ')[1]) as JWTPaylod;

      return {
        token,
        user: {
          userName: decoded?.displayName,
          userEmail: decoded?.email,
          scopes: decoded?.scopes,
          comanagementId: decoded?.comanagementId,
          login: decoded?.email.split('@')[0],
          userId: decoded?.professionalId,
          practiceId: decoded?.practiceId,
          businessUnitsIds: decoded?.businessUnits,
        },
      };
    }

    return {} as AuthState;
  });

  const handleSignInMicrosoft = useCallback(() => {
    const baseUri = `https://login.microsoftonline.com/${config.tenantId}/oauth2/v2.0`;

    const uriPieces = {
      client_id: config.clientId as string,
      response_type: 'token',
      redirect_uri: config.redirectUri as string,
      scope: config.scopes.toString(),
      response_mode: 'fragment',
      nonce: config.nonce as string,
      prompt: 'select_account',
    };

    const params = new URLSearchParams(uriPieces).toString();
    const url = `${baseUri}/authorize?${params}`;

    window.location.href = url;
  }, []);

  const handleSignIn = useCallback(async (accessToken: string) => {
    try {
      const response = await api.post<ISignIn>(
        '/auth/signin',
        {
          scopes: ['portal'],
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      const permissions = Permissions as IPermissions;
      const hasScopes = [] as string[];
      Object.keys(permissions as IPermissions).forEach(key => {
        if (response.data.scopes.includes(permissions[key])) {
          hasScopes.push(key);
        }
      });

      localStorage.setItem(
        'accessToken',
        `Bearer ${response.data.accessToken}`,
      );
    } catch (err) {
      throw new Error('Error on sign in');
    }
  }, []);

  const handleSignOut = useCallback(() => {
    localStorage.clear();

    const baseUri = `https://login.microsoftonline.com/${config.tenantId}/oauth2/v2.0`;
    const uriPieces = {
      post_logout_redirect_uri: config.redirectUriSignout as string,
    };

    const params = new URLSearchParams(uriPieces).toString();
    window.location.href = `${baseUri}/logout?${params}`;
  }, []);

  const isAuthenticated = useMemo(() => {
    const accessToken = localStorage.getItem('accessToken');

    return !!accessToken;
  }, []);

  const hasScopes = useCallback(
    scopes => {
      return data.user && data.user.scopes?.some(x => scopes.includes(x));
    },
    [data.user],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        redirectMicrosoft: handleSignInMicrosoft,
        signInAuthorize: handleSignIn,
        signOut: handleSignOut,
        isAuthenticated,
        hasScopes,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
