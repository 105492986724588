import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, SubmenuButton, SubmenuButtonBox } from './styles';

interface SubmenuProps {
  open: boolean;
}

export const IdoneSubMenu: React.FC<SubmenuProps> = ({ open }) => {
  const history = useHistory();

  const handleIDOneDashboard = useCallback(() => {
    history.push('/admin/idone');
  }, [history]);
  return (
    <Container>
      <SubmenuButtonBox>
        <SubmenuButton onClick={handleIDOneDashboard}>DASHBOARD</SubmenuButton>
        <SubmenuButton onClick={handleIDOneDashboard}>REPORTS</SubmenuButton>
      </SubmenuButtonBox>
    </Container>
  );
};
