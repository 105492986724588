import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { WhisperSpinner } from 'react-spinners-kit';
import { SelectBox } from 'devextreme-react';
import api from '../../../../services/api';
import { Button } from '../../../../components/Button';

interface IStatus {
  id: number;
  name: string;
}

interface DialogCustomProps {
  idProject: number;
  idToolkit: number;
  statusOptions?: IStatus[];
  open: boolean;
  handleClose(): void;
}

export const DialogConfirmApproveToolkit: React.FC<DialogCustomProps> = ({
  idProject,
  idToolkit,
  open,
  statusOptions,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [statusId, setStatusId] = useState(0);

  const approveToolkit = useCallback(async () => {
    setLoading(true);
    await api.put(
      `/api/project/${idProject}/toolkits/${idToolkit}/change/${statusId}`,
    );
    setLoading(false);
    handleClose();
  }, [handleClose, idProject, idToolkit, statusId]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Change Toolkit Status</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Which status would you like to change this toolkit to?
        </DialogContentText>
        {!!statusOptions?.find(x => x.name === 'request') && (
          <DialogContentText id="alert-dialog-description">
            If you change the status to &ldquo;request&ldquo;, an e-mail message
            will be sent to project partner
          </DialogContentText>
        )}

        <SelectBox
          style={{ marginTop: '10px' }}
          value={statusId}
          onValueChange={e => setStatusId(e)}
          openOnFieldClick
          searchEnabled
          dataSource={statusOptions}
          displayExpr="name"
          valueExpr="id"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button primary onClick={approveToolkit} autoFocus disabled={loading}>
          Confirm{' '}
          {loading && (
            <WhisperSpinner size={15} backColor="#fff" frontColor="#fff" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
