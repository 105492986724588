import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { WhisperSpinner } from 'react-spinners-kit';
import { useParams } from 'react-router';
import api from '../../../services/api';
import { Button } from '../../../components/Button';
import { useAuth } from '../../../hooks/auth';

interface DialogCustomProps {
  idSession: number;
  open: boolean;
  handleClose(): void;
}

export const DialogProjectSessionConfirmSubmit: React.FC<DialogCustomProps> = ({
  idSession,
  open,
  handleClose,
}) => {
  const { id } = useParams<{
    id: string;
  }>();
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [projectSessionInfo, setProjectSessionInfo] = useState<any>();

  const loadProjectSessionInfo = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`api/projects/${id}/sessions/${idSession}`);
    setProjectSessionInfo(data);
    setLoading(false);
  }, [id, idSession]);

  useEffect(() => {
    loadProjectSessionInfo();
  }, [loadProjectSessionInfo]);

  const submitOrUndo = useCallback(async () => {
    setLoading(true);
    if (projectSessionInfo?.submittedAt) {
      await api.put(`api/projects/${id}/sessions/${idSession}`, {
        ...projectSessionInfo,
        submittedAt: null,
        idSubmittedBy: null,
      });
    } else {
      await api.put(`api/projects/${id}/sessions/${idSession}`, {
        ...projectSessionInfo,
        submittedAt: new Date(),
        idSubmittedBy: user.userId,
      });
    }
    setLoading(false);
    handleClose();
  }, [handleClose, id, idSession, projectSessionInfo, user.userId]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {projectSessionInfo?.submittedAt ? 'Undo Submit' : 'Submit'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to{' '}
          {projectSessionInfo?.submittedAt
            ? 'undo the submit'
            : 'submit the project session'}
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button primary onClick={submitOrUndo} autoFocus disabled={loading}>
          Confirm{' '}
          {loading && (
            <WhisperSpinner size={15} backColor="#fff" frontColor="#fff" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
