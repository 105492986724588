import React, { useCallback, useState } from 'react';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { WhisperSpinner } from 'react-spinners-kit';
import { Button } from '../../../../components/Button';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
  projectId: number;
}

export const DialogRemoveDocument: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  projectId,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const handleRemoveLegalDocuments = useCallback(async () => {
    setLoading(true);
    try {
      await api.delete(`api/projects/${projectId}/legal-documents/file`);
      addToast({
        type: 'success',
        title: 'File deleted successfully.',
      });
      setLoading(false);
      handleClose();
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Something went wrong',
      });
      setLoading(false);
    }
  }, [projectId, addToast, handleClose]);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Remove Document</DialogTitle>
      <DialogContent>
        <p style={{ fontWeight: 500 }}>
          Are you sure you wish to remove this legal closure document? This
          action cannot be undone.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button primary onClick={handleRemoveLegalDocuments} disabled={loading}>
          Delete{' '}
          {loading && (
            <WhisperSpinner size={15} backColor="#8b0304" frontColor="#fff" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
