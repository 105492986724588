import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import { SignIn } from '../pages/SignIn';
import { Home } from '../pages/Home';
import { ApplicationLayers } from '../components/ApplicationLayers';

import { ProjectClosure } from '../pages/ProjectClosure/ClosureInternal';
import { AdminProjects } from '../pages/Admin/AdminProjects';
import { WeeklySupervision } from '../pages/Admin/WeeklySupervision';
import { AllocationSupervision } from '../pages/Admin/AllocationSupervision';
import { Reports } from '../pages/Admin/Reports';
import { IDOne } from '../pages/Admin/IDOne';
import { SetupAssessment } from '../pages/Admin/SetupAssessment';
import { AdminHome } from '../pages/Admin/AdminHome';
import { AdminDashboard } from '../pages/Admin/AdminDashboard';
import { ProfessionalDNA } from '../pages/ProjectDuring/ProfessionalDNA';
import { ProjectTab } from '../pages/ProjectSetup/ProjectTab';
import { ClientTab } from '../pages/ProjectSetup/ClientTab';
import { ClientPastProjectsTab } from '../pages/ProjectSetup/ClientPastProjectsTab';
import { StakeholdersTab } from '../pages/ProjectSetup/StakeholdersTab';
import { ProfessionalDNATab } from '../pages/ProjectSetup/ProfessionalDNATab';
import { SetupFinalizationTab } from '../pages/ProjectSetup/SetupFinalizationTab';
import { SetupAssessmentTab } from '../pages/ProjectSetup/SetupAssessmentTab';
import { RolesResponsibilitiesTab } from '../pages/ProjectSetup/RolesResponsibilitiesTab';
import { ContainerHome } from './styles';
import { ProjectSession } from '../pages/ProjectDuring/ProjectSession';
import { WeeklyStatus } from '../pages/ProjectDuring/WeeklyStatus';
import { OnePage } from '../pages/ProjectDuring/OnePage';
import { DashboardArchive } from '../pages/ProjectDuring/DashboardArchive';
import { ProfessionalDNAClosure } from '../pages/ProjectClosure/ProfessionalDNAClosure';
import { ClosureWithClient } from '../pages/ProjectClosure/ClosureWithClient';
import { ToolkitsTab } from '../pages/ProjectSetup/ToolkitsTab';
import { ProjectHome } from '../pages/ProjectHome/index';
import { KNMHome } from '../pages/Admin/KNMHome';
import { KNMChecklist } from '../pages/Admin/KNMHome/KNMChecklist';
import { KNMClient } from '../pages/Admin/KNMHome/KNMClient';
import { PDCSupervision } from '../pages/Admin/PDCSupervision';
import { MethodologyProvider } from '../hooks/methodologyContext';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/auth/signin" exact component={SignIn} />

    <ApplicationLayers>
      <Route path="/" exact isPrivate component={Home} />
      <Route path="/admin" exact isPrivate component={AdminHome} />
      <Route path="/project/:id/home" isPrivate exact component={ProjectHome} />
      <ContainerHome>
        <Route
          path="/project/:id/setup/client"
          isPrivate
          exact
          component={ClientTab}
        />
        <Route
          path="/project/:id/setup/project"
          isPrivate
          exact
          component={ProjectTab}
        />
        <Route
          path="/project/:id/setup/toolkit"
          isPrivate
          exact
          component={ToolkitsTab}
        />
        <Route
          path="/project/:id/setup/client-past-projects"
          isPrivate
          exact
          component={ClientPastProjectsTab}
        />
        <Route
          path="/project/:id/setup/stakeholders"
          isPrivate
          exact
          component={StakeholdersTab}
        />
        <Route
          path="/project/:id/setup/professional-dna"
          isPrivate
          exact
          component={ProfessionalDNATab}
        />
        <Route
          path="/project/:id/setup/roles"
          isPrivate
          exact
          component={RolesResponsibilitiesTab}
        />
        <Route
          path="/project/:id/setup/finalization"
          isPrivate
          exact
          component={SetupFinalizationTab}
        />
        <Route
          path="/project/:id/setup/assessment"
          isPrivate
          exact
          component={SetupAssessmentTab}
        />

        <Route
          path="/project/:id/during/professional-dna"
          isPrivate
          exact
          component={ProfessionalDNA}
        />
        <Route
          path="/project/:id/during/one-page"
          isPrivate
          exact
          component={OnePage}
        />
        <Route
          path="/project/:id/during/project-session"
          isPrivate
          exact
          component={ProjectSession}
        />
        <Route
          path="/project/:id/during/weekly-status"
          isPrivate
          exact
          component={WeeklyStatus}
        />
        <Route
          path="/project/:id/during/dashboard-archive"
          isPrivate
          exact
          component={DashboardArchive}
        />
        {/* <Route
          path="/project/:id/closure/professional-dna"
          isPrivate
          exact
          component={ProfessionalDNAClosure}
        /> */}
        <Route
          path="/project/:id/closure/client"
          isPrivate
          exact
          component={ClosureWithClient}
        />
        <Route
          path="/project/:id/closure/internal"
          isPrivate
          exact
          component={() => <ProjectClosure />}
        />
      </ContainerHome>

      <Route path="/admin/projects" isPrivate exact component={AdminProjects} />
      <Route
        path="/admin/weekly-supervision"
        isPrivate
        exact
        component={WeeklySupervision}
      />
      <Route
        path="/admin/allocation-supervision"
        isPrivate
        exact
        component={AllocationSupervision}
      />
      <Route
        path="/admin/pdc-supervision"
        isPrivate
        exact
        component={PDCSupervision}
      />
      <Route path="/admin/reports" isPrivate exact component={Reports} />
      <Route path="/admin/idone" isPrivate exact component={IDOne} />
      <Route
        path="/admin/setup-assessment"
        isPrivate
        exact
        component={SetupAssessment}
      />
      <Route
        path="/admin/dashboard"
        isPrivate
        exact
        component={AdminDashboard}
      />
      <Route path="/admin/knm-home" isPrivate exact component={KNMHome} />
      <Route path="/admin/knm-clients" isPrivate exact component={KNMClient} />
      <Route
        path="/admin/knm-checklist"
        isPrivate
        exact
        component={KNMChecklist}
      />
    </ApplicationLayers>
  </Switch>
);

export default Routes;
