import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { DateBox } from 'devextreme-react';
import { Button } from '../../../../components/Button';
import { Container } from './styles';
import api from '../../../../services/api';
import { FormGroup } from '../../../../components/FormGroup';
import { useToast } from '../../../../hooks/toast';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
}

export const DialogGenerateSetupAssessment: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
}) => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const { addToast } = useToast();

  const handleGenerate = useCallback(async () => {
    try {
      addToast({
        type: 'success',
        title: 'We are generating your report. This could take a while...',
      });

      const response = await api.get(
        `/api/reports/setup-assessment?startDate=${
          startDate !== undefined && startDate.toUTCString()
        }&endDate=${endDate !== undefined && endDate.toUTCString()}`,
        {
          responseType: 'blob',
        },
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-setup-assessments.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: 'success',
        title: 'Report generated with success. Open or save it.',
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
    }
    handleClose();
  }, [handleClose, startDate, endDate, addToast]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">Setup Assessment Report</DialogTitle>
      <Container>
        <DialogContent className="dialog-content">
          <FormGroup fieldSetLabel="Start Date">
            <DateBox openOnFieldClick onValueChange={e => setStartDate(e)} />
          </FormGroup>
          <FormGroup fieldSetLabel="End Date">
            <DateBox
              openOnFieldClick
              min={startDate}
              onValueChange={e => setEndDate(e)}
            />
          </FormGroup>
        </DialogContent>
      </Container>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleGenerate} primary>
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};
