import React, { useCallback, useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { SelectBox } from 'devextreme-react';
import { Controller, useForm } from 'react-hook-form';
import { WhisperSpinner } from 'react-spinners-kit';
import { Button } from '../../../../components/Button';
import { FormGroup } from '../../../../components/FormGroup';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';

import { LoadingContainer } from './styles';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
  projectId: number;
}

export const DialogEdit: React.FC<DialogCustomProps> = ({
  handleClose,
  open,
  projectId,
}) => {
  const { addToast } = useToast();
  const { control, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);

  const handleEdit = useCallback(
    async data => {
      setLoading(true);
      try {
        await api.patch(`/api/projects/${projectId}/allocation-status`, data);

        addToast({
          title: 'Saved',
          type: 'success',
        });

        handleClose();
      } catch {
        addToast({
          title: 'Something went wrong',
          type: 'error',
        });
      }

      setLoading(false);
    },
    [projectId, addToast, handleClose],
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    const projectResponse = await api.get(`/api/projects/${projectId}`);
    setValue(
      'idAllocationStatus',
      projectResponse.data.idAllocationStatus || 0,
    );
    setLoading(false);
  }, [projectId, setValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
      onSubmit={() => {
        handleClose();
      }}
      fullWidth
    >
      <form onSubmit={handleSubmit(handleEdit)}>
        {loading && (
          <LoadingContainer>
            <WhisperSpinner size={30} backColor="#8b0304" frontColor="#fff" />
          </LoadingContainer>
        )}
        <DialogTitle id="form-dialog-title">Edit</DialogTitle>
        <DialogContent>
          <FormGroup fieldSetLabel="Allocation Status">
            <Controller
              control={control}
              name="idAllocationStatus"
              render={({ field }) => (
                <SelectBox
                  dataSource={[
                    { id: 1, name: 'Ad Hoc' },
                    { id: 2, name: 'Closed' },
                    { id: 3, name: 'Considered' },
                    { id: 4, name: 'No Points' },
                    { id: 5, name: 'Not Submitted' },
                    { id: 6, name: 'Written not Submitted' },
                  ]}
                  valueExpr="id"
                  displayExpr="name"
                  onValueChanged={e => field.onChange(e.value)}
                  value={field.value}
                />
              )}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button type="submit" primary disabled={loading}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
