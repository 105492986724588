import React, { createContext, useCallback, useState, useContext } from 'react';

interface CheckCellContextData {
  addIds(ids: string[]): void;
  removeIds(ids: string[]): void;
  getIds(): string[];
}

const CheckCellContext = createContext<CheckCellContextData>(
  {} as CheckCellContextData,
);

export const CheckCellProvider: React.FC = ({ children }) => {
  const [errorIds, setErrorIds] = useState<string[]>([]);

  const addIds = useCallback((ids: string[]) => {
    if (ids)
      setErrorIds(state => {
        const notExistsIds = ids.filter(x => !state.includes(x));

        return [...state, ...notExistsIds];
      });
  }, []);

  const removeIds = useCallback((ids: string[]) => {
    if (ids) setErrorIds(state => state.filter(x => !ids.includes(x)));
  }, []);

  const getIds = useCallback(() => errorIds, [errorIds]);

  return (
    <CheckCellContext.Provider value={{ addIds, getIds, removeIds }}>
      {children}
    </CheckCellContext.Provider>
  );
};

export function useCheckCell(): CheckCellContextData {
  const context = useContext(CheckCellContext);

  if (!context) {
    throw new Error('useCheckCell must be used within an CheckCellContext');
  }

  return context;
}
