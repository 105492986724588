import React, { useCallback, useEffect, useState } from 'react';

import {
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { FiEdit2, FiX } from 'react-icons/fi';
import { DataGrid } from 'devextreme-react';
import { Column, SearchPanel } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { Container, Body } from './styles';
import { HeaderComponent } from '../../../components/Header';
import { useDocumentTitle } from '../../../hooks/documentTitle';
import ProjectSetup from '../../../assets/images/project-setup.svg';
import ProjectDuring from '../../../assets/images/project-during.svg';
import ProjectClosure from '../../../assets/images/project-closure.svg';
import { Button } from '../../../components/Button';
import { TextBox } from '../../../components/TextBox';
import { SelectBox } from '../../../components/SelectBox';
import { TagBox } from '../../../components/TagBox';
import api from '../../../services/api';

import master from '../../../services/master';

export const AdminProjects: React.FC<{ title?: string }> = ({ title }) => {
  const { setTitle } = useDocumentTitle();

  const [openModalEdit, setOpenModalEdit] = useState(false);

  const history = useHistory();
  const [projects, setProjects] = useState<DataSource>();
  const [clients, setClients] = useState<DataSource>();
  const [comanagements, setComanagements] = useState<DataSource>();
  const [geographic, setGeographic] = useState<DataSource>();
  const [offices, setOffices] = useState<DataSource>();
  const [practices, setPractices] = useState<DataSource>();
  const [methodologies, setMethodologies] = useState<DataSource>();
  const [toolkits, setToolkits] = useState<DataSource>();
  const [professionals, setProfessionals] = useState<DataSource>();
  const [editProjectInfo, setEditProjectInfo] = useState<any>();

  const loadProjects = useCallback(async () => {
    const projectStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/projects');
        return data;
      },
    });

    setProjects(
      new DataSource({
        store: projectStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    setTitle(title);
    loadProjects();
  }, [setTitle, title, loadProjects]);

  useEffect(() => {
    const clientStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/clients');
        return data;
      },
    });

    setClients(
      new DataSource({
        store: clientStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );

    const comgtStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/comanagements');
        return data;
      },
    });

    setComanagements(
      new DataSource({
        store: comgtStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );

    const geographicStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/geographics');
        return data;
      },
    });

    setGeographic(
      new DataSource({
        store: geographicStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );

    const officesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/offices');
        return data;
      },
    });

    setOffices(
      new DataSource({
        store: officesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
    const practiceStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/practices');
        return data;
      },
    });

    setPractices(
      new DataSource({
        store: practiceStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
    const methodologiesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/methodologies');
        return data;
      },
    });

    setMethodologies(
      new DataSource({
        store: methodologiesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
    const toolkitsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/toolkits');
        return data;
      },
    });

    setToolkits(
      new DataSource({
        store: toolkitsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
    const professionalsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/professionals', {
          params: {
            where: {
              IsAnyStatus: true,
            },
          },
        });
        return data;
      },
    });

    setProfessionals(
      new DataSource({
        store: professionalsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  const textCells = useCallback(e => {
    return (
      <p
        style={{
          fontWeight: '600',
          textTransform: 'uppercase',
          letterSpacing: '0.05em',
        }}
      >
        {e.text}
      </p>
    );
  }, []);

  const statusCell = useCallback(e => {
    if (e.value === 2) {
      return (
        <Tooltip title="Project Setup">
          <img src={ProjectSetup} alt="Project Setup" />
        </Tooltip>
      );
    }
    if (e.value === 3) {
      return (
        <Tooltip title="Project During">
          <img src={ProjectDuring} alt="Project During" />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Project Closure">
        <img src={ProjectClosure} alt="Project Closure" />
      </Tooltip>
    );
  }, []);

  const editCell = useCallback(e => {
    return (
      <Tooltip title="Edit">
        <IconButton aria-label="edit">
          <FiEdit2
            style={{ color: '#8b0305', fontSize: '18px' }}
            onClick={async () => {
              const { data } = await api.get(`/api/projects/${e.data.id}`);
              setEditProjectInfo(data);
              setOpenModalEdit(true);
            }}
          />
        </IconButton>
      </Tooltip>
    );
  }, []);

  return (
    <Container>
      <HeaderComponent />
      <Body>
        <div className="pillars">
          <div>
            <h1>Technical knowledge is</h1>
            <p>
              lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
          <div>
            <h1>The method represents</h1>
            <p>
              lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
          <div>
            <h1>As an enabler of the method</h1>
            <p>
              lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </div>
        <DataGrid
          onCellClick={e => {
            if (e.rowType !== 'header' && e.text !== '' && e.value !== null) {
              history.push(`/project/${e.data.id}/home`);
            }
          }}
          className="grid"
          dataSource={projects}
        >
          <SearchPanel visible />
          <Column
            dataField="code"
            caption="PROJECT CODE"
            width={140}
            alignment="center"
            cellRender={textCells}
          />
          <Column
            dataField="idStatus"
            caption="STATUS"
            width={100}
            alignment="center"
            cellRender={statusCell}
          />
          <Column
            dataField="name"
            caption="SCOPE"
            alignment="left"
            cellRender={textCells}
          />
          <Column
            dataField="client.name"
            caption="CLIENT"
            alignment="left"
            cellRender={textCells}
          />
          <Column cellRender={e => editCell(e)} width={80} alignment="center" />
        </DataGrid>
      </Body>
      <Dialog open={openModalEdit} fullWidth maxWidth="md">
        <DialogTitle>
          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
            Edit Project - {editProjectInfo && editProjectInfo.name}
            <FiX
              style={{ cursor: 'pointer' }}
              onClick={() => setOpenModalEdit(false)}
            />
          </span>
        </DialogTitle>
        <DialogContent>
          <p style={{ fontSize: 'medium', fontWeight: '500' }}>Project Code</p>
          <TextBox
            style={{ paddingBottom: '7px' }}
            value={editProjectInfo && editProjectInfo.code}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            Project Name
          </p>
          <TextBox
            style={{ paddingBottom: '7px' }}
            value={editProjectInfo && editProjectInfo.name}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            Client Name
          </p>
          <SelectBox
            dataSource={clients}
            displayExpr="Name"
            valueExpr="ID"
            searchEnabled
            style={{
              backgroundColor: 'white',
              border: '1px solid #999999',
              borderRadius: '5px',
            }}
            onChanged={function (value: string): void {
              throw new Error('Function not implemented.');
            }}
            value={editProjectInfo && editProjectInfo.client.id}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            Support Co-Management
          </p>
          <SelectBox
            dataSource={comanagements}
            displayExpr="Name"
            valueExpr="ID"
            searchEnabled
            style={{
              backgroundColor: 'white',
              border: '1px solid #999999',
              borderRadius: '5px',
            }}
            onChanged={function (value: string): void {
              throw new Error('Function not implemented.');
            }}
            value={editProjectInfo && editProjectInfo.idSupportComanagement}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            Geographic
          </p>
          <TagBox
            dataSource={geographic}
            displayExpr="Name"
            valueExpr="ID"
            searchEnabled
            style={{
              backgroundColor: 'white',
              border: '1px solid #999999',
              borderRadius: '5px',
            }}
            value={editProjectInfo && editProjectInfo.projectGeographic}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            Geographic Scope
          </p>
          <TagBox
            dataSource={geographic}
            displayExpr="Name"
            valueExpr="ID"
            searchEnabled
            style={{
              backgroundColor: 'white',
              border: '1px solid #999999',
              borderRadius: '5px',
            }}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            Office
          </p>
          <TagBox
            dataSource={offices}
            displayExpr="Name"
            valueExpr="ID"
            searchEnabled
            style={{
              backgroundColor: 'white',
              border: '1px solid #999999',
              borderRadius: '5px',
            }}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            Co-Management
          </p>
          <TagBox
            dataSource={comanagements}
            displayExpr="Name"
            valueExpr="ID"
            searchEnabled
            style={{
              backgroundColor: 'white',
              border: '1px solid #999999',
              borderRadius: '5px',
            }}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            Practice
          </p>
          <TagBox
            dataSource={practices}
            displayExpr="Name"
            valueExpr="ID"
            searchEnabled
            style={{
              backgroundColor: 'white',
              border: '1px solid #999999',
              borderRadius: '5px',
            }}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            Methodology
          </p>
          <TagBox
            dataSource={methodologies}
            displayExpr="Name"
            valueExpr="ID"
            searchEnabled
            style={{
              backgroundColor: 'white',
              border: '1px solid #999999',
              borderRadius: '5px',
            }}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            Toolkit
          </p>
          <TagBox
            dataSource={toolkits}
            displayExpr="Name"
            valueExpr="ID"
            searchEnabled
            style={{
              backgroundColor: 'white',
              border: '1px solid #999999',
              borderRadius: '5px',
            }}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            Partner/Responsible
          </p>
          <TagBox
            dataSource={professionals}
            displayExpr="Name"
            valueExpr="ID"
            searchEnabled
            style={{
              backgroundColor: 'white',
              border: '1px solid #999999',
              borderRadius: '5px',
            }}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            Project Leader
          </p>
          <TagBox
            dataSource={professionals}
            displayExpr="Name"
            valueExpr="ID"
            searchEnabled
            style={{
              backgroundColor: 'white',
              border: '1px solid #999999',
              borderRadius: '5px',
            }}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            Members
          </p>
          <TagBox
            dataSource={professionals}
            displayExpr="Name"
            valueExpr="ID"
            searchEnabled
            style={{
              backgroundColor: 'white',
              border: '1px solid #999999',
              borderRadius: '5px',
            }}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            Project Status
          </p>
          <SelectBox
            style={{
              backgroundColor: 'white',
              border: '1px solid #999999',
              borderRadius: '5px',
            }}
            onChanged={function (value: string): void {
              throw new Error('Function not implemented.');
            }}
            value={undefined}
            dataSource={[
              'Draft',
              'Setup',
              'During',
              'Closure',
              'Closed',
              'Paused',
            ]}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            During Type
          </p>
          <SelectBox
            style={{
              backgroundColor: 'white',
              border: '1px solid #999999',
              borderRadius: '5px',
            }}
            onChanged={function (value: string): void {
              throw new Error('Function not implemented.');
            }}
            value={editProjectInfo && editProjectInfo.idDuringType}
            dataSource={['Normal', 'Internal', 'Ad Hoc']}
          />
          <p
            style={{ fontSize: 'medium', fontWeight: '500', marginTop: '20px' }}
          >
            Send Notifications
          </p>
          <SelectBox
            style={{
              backgroundColor: 'white',
              border: '1px solid #999999',
              borderRadius: '5px',
              marginBottom: '20px',
            }}
            onChanged={function (value: string): void {
              throw new Error('Function not implemented.');
            }}
            value={
              editProjectInfo && editProjectInfo.sendNotification === true
                ? 'Yes'
                : 'No'
            }
            dataSource={['Yes', 'No']}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalEdit(false)}>Cancel</Button>
          <Button onClick={() => setOpenModalEdit(false)} primary>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
