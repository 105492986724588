import React, {
  useState,
  useCallback,
  useEffect,
  InputHTMLAttributes,
} from 'react';

import { Container, Input } from './styles';

interface TextAreaProps extends InputHTMLAttributes<HTMLInputElement> {
  onChanged?(value: string): void;
  maxLength?: number;
  disabled?: boolean;
}

export const TextArea: React.FC<TextAreaProps> = ({
  value: defaultValue,
  onChanged,
  readOnly: _readOnly,
  maxLength,
  disabled,
}) => {
  const [readOnly, setReadOnly] = useState<boolean | undefined>(false);
  const [value, setValue] = useState<
    string | number | readonly string[] | undefined
  >('');
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  useEffect(() => {
    setReadOnly(_readOnly);
  }, [_readOnly]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = useCallback(
    (v: string) => {
      const newValue = v;

      setValue(newValue || '');

      if (newValue === value) return;

      if (typingTimeout) clearTimeout(typingTimeout);

      setTypingTimeout(
        setTimeout(() => {
          if (!readOnly && onChanged) onChanged(newValue || '');
        }, 3000),
      );
    },
    [onChanged, readOnly, typingTimeout, value],
  );

  const handleBlur = useCallback(
    (v: string) => {
      const newValue = v;

      if (newValue === value) return;

      if (typingTimeout) clearTimeout(typingTimeout);

      if (!readOnly && onChanged) onChanged(newValue || '');
    },
    [onChanged, readOnly, typingTimeout, value],
  );

  return (
    <Container>
      <Input
        disabled={disabled}
        maxLength={maxLength}
        onChange={e => handleChange(e.target.value)}
        onBlur={e => handleBlur(e.target.value)}
        value={value}
        readOnly={readOnly}
      />
    </Container>
  );
};
