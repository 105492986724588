import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Body = styled.section`
  width: 100%;
  background-color: transparent;

  .dx-datagrid .dx-row > td {
    font-weight: bold;
    text-transform: uppercase;
  }

  .pillars {
    padding: 18px 28px;
    background-color: white;
    width: 95%;
    margin: 0 auto;
    display: flex;
    text-align: center;
    box-shadow: 0px 0px 10px 0px #0000002e;

    div {
      padding: 15px;
      display: flex;
      flex-direction: column;

      h1 {
        font-weight: bold;
        font-size: 16px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .dx-datagrid {
    background-color: initial;
  }

  .dx-datagrid .statuscell {
    background-color: #333;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: auto;
    position: relative;
  }

  .dx-datagrid .statuscell svg {
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  .dx-datagrid .statuscell svg.closure {
    transform: translate(calc(-50% + 1px), -50%);
    will-change: transform;
  }

  .dx-datagrid .statuscell svg.setup {
    transform: translate(calc(-50% - 1px), -50%);
    will-change: transform;
  }

  .dx-datagrid-content.dx-datagrid-table {
    border-collapse: separate;
    border-spacing: 0;
  }

  .dx-datagrid .dx-row-lines > td {
    border-bottom: 0 !important;
  }

  .dx-datagrid-headers.dx-datagrid-nowrap,
  .dx-datagrid-header-panel {
    border: none;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border: none;
  }

  .dx-datagrid-rowsview {
    overflow: visible;
  }

  .dx-datagrid-table tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .dx-datagrid-table tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .dx-row {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 7%);
    border: none !important;
  }

  .dx-row.dx-header-row {
    background: transparent;
    box-shadow: none;
    border: none;
  }

  .grid {
    margin-top: 40px !important;
    width: 95%;
    margin: 0 auto;

    .dx-datagrid-header-panel,
    .dx-toolbar-items-container {
      background-color: #ffffff;
    }

    .dx-datagrid-search-panel {
      width: 20rem !important;
      border: none;
      border-radius: 0px;
      border-bottom: 0;
      background-color: white;
    }

    input {
      margin-left: 3%;
      outline: none;
    }

    .dx-row-lines {
      cursor: unset;
    }
    .dx-datagrid-action {
      font-weight: bold;
      color: #bbbbbb;
      background-color: none;
    }
  }
`;
