import { Tooltip } from '@mui/material';
import React, { FieldsetHTMLAttributes } from 'react';
import { Container } from './styles';

interface ProfessionalPhotoProps
  extends FieldsetHTMLAttributes<HTMLFieldSetElement> {
  name: string;
  guid: string;
}

export const ProfessionalPhoto: React.FC<ProfessionalPhotoProps> = ({
  name,
  guid,
}) => {
  return (
    <Container>
      <Tooltip title={name}>
        <img
          src={`https://professionals.integrationconsulting.com/photos/${guid}.jpg`}
          alt={name}
        />
      </Tooltip>
    </Container>
  );
};
