import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Column,
  DataGrid,
  Editing,
  Lookup,
  Paging,
} from 'devextreme-react/data-grid';

import { FiX } from 'react-icons/fi';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import api from '../../../services/api';
import { Button } from '../../../components/Button';
import master from '../../../services/master';
import { Body } from './styles';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
  idProject: number;
  idProjectProfessionalDNA: number;
}

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const ProfessionalDNAModalPage: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  idProject,
  idProjectProfessionalDNA,
}) => {
  const [professionalDNAs, setProfessionalDNAs] = useState<DataSource>();

  const [professionals] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const projectTeamProfessionalIds = await (
          await api.get(`/api/projects/${idProject}`)
        ).data.projectTeam.map((x: any) => {
          return x.professional?.id;
        });
        const { data } = await master.get('/master/professionals', {
          params: {
            where: {
              IsAnyStatus: true,
            },
          },
        });
        return data
          .map((p: any) => {
            return { ID: p.ID, Name: p.Name };
          })
          .filter((x: any) => projectTeamProfessionalIds.includes(x.ID));
      },
    }),
    paginate: true,
  });

  const loadProfessionalDNAs = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `/api/professional-dnas/project-professional-dna/${idProjectProfessionalDNA}`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `/api/professional-dnas/project-professional-dna/${idProjectProfessionalDNA}`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/professional-dnas/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/professional-dnas/${key}`);
      },
    });
    setProfessionalDNAs(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [idProjectProfessionalDNA]);

  useEffect(() => {
    loadProfessionalDNAs();
  }, [loadProfessionalDNAs]);

  const metExpectationCell = useCallback(e => {
    if (e.value === 1) return <p>Yes</p>;
    if (e.value === 0) return <p>No</p>;
    return <p>Not Applicable</p>;
  }, []);

  return (
    <Body>
      <DataGrid
        dataSource={professionalDNAs}
        onRowUpdating={options => {
          options.newData = {
            ...options.oldData,
            ...options.newData,
          };
        }}
      >
        <Column dataField="idProfessional" caption="Professional">
          <Lookup
            dataSource={professionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>
        <Column
          dataField="dnaMetExpectation"
          cellRender={metExpectationCell}
          caption="Did this session meet your expectations?"
        >
          <Lookup
            dataSource={[
              { id: 0, value: 'No' },
              { id: 1, value: 'Yes' },
              { id: 2, value: 'Not Applicable' },
            ]}
            displayExpr="value"
            valueExpr="id"
          />
        </Column>
        <Editing allowAdding allowDeleting allowUpdating />
        <Paging pageSize={10} />
      </DataGrid>
    </Body>
  );
};
