import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { WhisperSpinner } from 'react-spinners-kit';
import { TextArea } from '../../../components/TextArea';
import api from '../../../services/api';
import master from '../../../services/master';

import { useToast } from '../../../hooks/toast';
import { Container, ContainerLoading } from './styles';
import { BoardHeader } from '../../../components/BoardHeader';
import portalGroups from '../../../config/portalGroups';
import { useAuth } from '../../../hooks/auth';

export const ClientTab: React.FC<{ title?: string }> = ({ title }) => {
  const { addToast } = useToast();

  const { id } = useParams<{
    id: string;
  }>();
  const { hasScopes } = useAuth();
  const isAdmin = useMemo(() => {
    return hasScopes([portalGroups.Admin]);
  }, [hasScopes]);
  const [loading, setLoading] = useState(false);
  const { control, setValue } = useForm();
  const [idClient, setIdClient] = useState(0);
  const [restClient, setRestClient] = useState<any>({});
  const [setupFinished, setSetupFinished] = useState(false);

  const loadProjectInfos = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/api/projects/${id}`);
    setSetupFinished(data.idStatus !== 2);
    setIdClient(data.idClient);

    const clientsResponse = await master.get(
      `/master/clients/${data.idClient}`,
    );

    setRestClient(clientsResponse.data);
    setValue('ClientDescription', clientsResponse.data.ClientDescription);
    setValue('ClientCulture', clientsResponse.data.ClientCulture);

    setLoading(false);
  }, [id, setValue]);

  useEffect(() => {
    loadProjectInfos();
  }, [loadProjectInfos]);

  const updateClientInfo = useCallback(
    async (fieldName, value) => {
      try {
        await master.put(`/master/clients/${idClient}`, {
          ...restClient,
          [fieldName]: value,
        });
        addToast({
          type: 'success',
          title: 'Saved',
          description: '',
        });
        const clientsResponse = await master.get(`/master/clients/${idClient}`);

        setRestClient(clientsResponse.data);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          description: '',
        });
      }
    },
    [addToast, idClient, restClient],
  );

  return (
    <Container>
      <div>
        {loading && (
          <ContainerLoading>
            <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
          </ContainerLoading>
        )}
        {/* <Accordion className="accordion">
          <AccordionSummary className="header">
            <FiChevronDown size="22px" /> */}
        <BoardHeader title="SETUP" subtitle="" />
        <span>
          <h1
            style={{
              textTransform: 'uppercase',
              color: '#6c6c6c',
              fontSize: '24px',
              fontWeight: 700,
              letterSpacing: '0.1em',
              marginBottom: '20px',
            }}
          >
            Client
          </h1>
        </span>
        {/* </AccordionSummary> */}

        {/* <AccordionDetails className="content"> */}
        <div className="inputArea">
          <p>CLIENT DESCRIPTION</p>
          <Controller
            name="ClientDescription"
            control={control}
            render={({ field }) => (
              <TextArea
                disabled={!isAdmin && setupFinished}
                value={field.value}
                onChanged={value => {
                  setValue(field.name, value);
                  updateClientInfo(field.name, value);
                }}
              />
            )}
          />
        </div>

        <div className="inputArea">
          <p>CLIENT CULTURE</p>
          <Controller
            name="ClientCulture"
            control={control}
            render={({ field }) => (
              <TextArea
                disabled={!isAdmin && setupFinished}
                value={field.value}
                onChanged={value => {
                  updateClientInfo(field.name, value);
                }}
              />
            )}
          />
        </div>
        {/* </AccordionDetails>
        </Accordion> */}
      </div>
    </Container>
  );
};
