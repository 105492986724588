/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { FiChevronDown, FiInfo } from 'react-icons/fi';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { DataGrid } from 'devextreme-react';
import {
  Column,
  Editing,
  HeaderFilter,
  Paging,
  Lookup,
  Popup,
  Form,
} from 'devextreme-react/data-grid';
import { useParams } from 'react-router';
import { Item } from 'devextreme-react/form';
import { Body, Container, TabMenu } from './styles';
import api from '../../../services/api';
import { TextArea } from '../../../components/TextArea';
import { useToast } from '../../../hooks/toast';
import master from '../../../services/master';

interface DialogCustomProps {
  openTechDNAModalPage: boolean;
  idDuringSession: number;
}

export const TechDNAModalPage: React.FC<DialogCustomProps> = ({
  openTechDNAModalPage,
  idDuringSession,
}) => {
  const { id } = useParams<{
    id: string;
  }>();
  const { addToast } = useToast();

  const [techDNAExpectations, setTechDNAExpectations] = useState<DataSource>();
  const [techDNADeliverables, setTechDNADeliverables] = useState<DataSource>();
  const [techDNARisks, setTechDNARisks] = useState<DataSource>();
  const [idDuringSessionLoad, setIdDuringSessionLoad] = useState(0);
  const [filteredProfessionals, setFilteredProfessionals] =
    useState<DataSource>();
  const [openTab, setOpenTab] = useState<number>(1);
  const [techDNASubmitted, setTechDNASubmittes] = useState('');
  const [techDNARadioValidation, setTechDNARadioValidation] = useState(false);

  const { control, setValue } = useForm();

  const loadProjectInfo = useCallback(async () => {
    const { data } = await api.get(`/api/projects/${id}`);
    const professionalResponse = await master.get('/master/professionals', {
      params: {
        where: {
          IsAnyStatus: true,
        },
      },
    });

    const projectTeamIds = data.projectTeam.map((x: any) => {
      return x.professional?.id;
    });

    setFilteredProfessionals(
      professionalResponse.data
        .map((p: any) => {
          return { ID: p.ID, Name: p.Name };
        })
        .filter((x: any) => projectTeamIds.includes(x.ID)),
    );

    return data;
  }, [id]);

  const loadProjectSession = useCallback(async () => {
    const projectSessionDetails = await api.get(
      `api/projects/${id}/sessions/${idDuringSession}`,
    );
    setTechDNASubmittes(projectSessionDetails.data.submittedAt);
    setTechDNARadioValidation(!!techDNASubmitted);

    setValue('projectScope', projectSessionDetails.data.projectScope);
    setValue('projectOutScope', projectSessionDetails.data.projectOutScope);
    setValue('adjustScopeObs', projectSessionDetails.data.adjustScopeObs);
    setValue(
      'deliveringClientNeedsObs',
      projectSessionDetails.data.deliveringClientNeedsObs,
    );
    setValue(
      'deliveringClientNeeds',
      projectSessionDetails.data.deliveringClientNeeds,
    );
    setValue('adjustScope', projectSessionDetails.data.adjustScope);
    setValue(
      'discussionOutcomes',
      projectSessionDetails.data.discussionOutcomes,
    );
  }, [id, idDuringSession, setValue, techDNASubmitted]);

  const loadTechDNAExpectation = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `/api/project/${id}/expectations/session/${idDuringSession}`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `/api/project/${id}/expectations/session/${idDuringSession}`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/project/${id}/expectations/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project/${id}/expectations/${key}`);
      },
    });
    setTechDNAExpectations(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id, idDuringSession]);

  const loadTechDNADeliverables = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `api/project/${id}/deliverables/session/${idDuringSession}`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `/api/project/${id}/deliverables/session/${idDuringSession}`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/project/${id}/deliverables/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project/${id}/deliverables/${key}`);
      },
    });
    setTechDNADeliverables(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id, idDuringSession]);

  const loadTechDNARisks = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `/api/project/${id}/risks/session/${idDuringSession}`,
        );
        return data;
      },
      insert: async data => {
        await api.post(
          `/api/project/${id}/risks/session/${idDuringSession}`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/project/${id}/risks/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project/${id}/risks/${key}`);
      },
    });
    setTechDNARisks(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id, idDuringSession]);

  useEffect(() => {
    loadTechDNAExpectation();
    loadTechDNADeliverables();
    loadTechDNARisks();
    loadProjectSession();
    loadProjectInfo();
  }, [
    loadTechDNADeliverables,
    loadTechDNAExpectation,
    loadTechDNARisks,
    loadProjectSession,
    loadProjectInfo,
  ]);

  // console.log(techDNARadioValidation);

  // const handleClose = () => setOpen(false);

  const updateObs = useCallback(
    async (fieldName, value) => {
      try {
        await api.patch(`api/projects/${id}/sessions/${idDuringSession}`, {
          [fieldName]: value,
        });
        addToast({
          type: 'success',
          title: 'Saved',
          description: '',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          description: '',
        });
      }
    },
    [addToast, id, idDuringSession],
  );

  const statusRisk = [
    {
      statusId: 1,
      status: 'Green',
    },
    {
      statusId: 2,
      status: 'Yellow',
    },
    {
      statusId: 3,
      status: 'Red',
    },
    {
      statusId: 4,
      status: 'Blue',
    },
    {
      statusId: 5,
      status: 'Legacy',
    },
  ];

  const statusCell = useCallback(e => {
    if (e.value === 1) {
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#2ecc71',
          }}
        />
      );
    }
    if (e.value === 2) {
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#F1C40F',
          }}
        />
      );
    }
    if (e.value === 3) {
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#e74c3c',
          }}
        />
      );
    }
    if (e.value === 4) {
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#2980b9',
          }}
        />
      );
    }
    if (e.value === 5) {
      return (
        <div
          style={{
            width: 35,
            height: 35,
            boxShadow: '0 0 10px rgba(0 0 0 / 25%)',
            borderRadius: '50%',
            margin: '0 auto',
            border: '5px solid white',
            backgroundColor: '#bdc3c7',
          }}
        />
      );
    }
    return '';
  }, []);

  return (
    <Container>
      <TabMenu>
        <li
          onClick={() => setOpenTab(1)}
          className={openTab === 1 ? 'focused' : ''}
        >
          Project Session
        </li>
        <li
          onClick={() => setOpenTab(2)}
          className={openTab === 2 ? 'focused' : ''}
        >
          Client Expectations
        </li>
        <li
          onClick={() => setOpenTab(3)}
          className={openTab === 3 ? 'focused' : ''}
        >
          Deliverables
        </li>
        <li
          onClick={() => setOpenTab(4)}
          className={openTab === 4 ? 'focused' : ''}
        >
          Risks
        </li>
        <li
          onClick={() => setOpenTab(5)}
          className={openTab === 5 ? 'focused' : ''}
        >
          Discussion Outcomes
        </li>
      </TabMenu>

      {openTab === 1 && (
        <Body className="content">
          <div className="smallTextField">
            <label>Are we delivering what the client needs?</label>
            <Controller
              name="deliveringClientNeeds"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  style={{ marginLeft: '7px' }}
                  onChange={e => {
                    setValue(field.name, e.target.value);
                    updateObs(field.name, e.target.value);
                  }}
                >
                  <FormControlLabel
                    disabled={techDNARadioValidation}
                    value="true"
                    checked={field.value === true || field.value === 'true'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    disabled={techDNARadioValidation}
                    value="false"
                    checked={field.value === false || field.value === 'false'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              )}
            />
            <label>Observation</label>
            <Controller
              name="deliveringClientNeedsObs"
              control={control}
              render={({ field }) => (
                <TextArea
                  disabled={techDNARadioValidation}
                  value={field.value}
                  onChanged={value => {
                    setValue(field.name, value);
                    updateObs(field.name, value);
                  }}
                />
              )}
            />
          </div>

          <div className="smallTextField">
            <label>Do we need to adjust the scope?</label>
            <Controller
              name="adjustScope"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  style={{ marginLeft: '7px' }}
                  onChange={e => {
                    setValue(field.name, e.target.value);
                    updateObs(field.name, e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="true"
                    disabled={techDNARadioValidation}
                    checked={field.value === true || field.value === 'true'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    disabled={techDNARadioValidation}
                    checked={field.value === false || field.value === 'false'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              )}
            />

            <label>Observation</label>
            <Controller
              name="adjustScopeObs"
              control={control}
              render={({ field }) => (
                <TextArea
                  disabled={techDNARadioValidation}
                  value={field.value}
                  onChanged={value => {
                    setValue(field.name, value);
                    updateObs(field.name, value);
                  }}
                />
              )}
            />
          </div>

          <div className="smallTextField">
            <label>What is the project?</label>
            <Controller
              name="projectScope"
              control={control}
              render={({ field }) => (
                <TextArea
                  value={field.value}
                  disabled={techDNARadioValidation}
                  onChanged={value => {
                    setValue(field.name, value);
                    updateObs(field.name, value);
                  }}
                />
              )}
            />
          </div>

          <div className="smallTextField">
            <label>
              What is <b>not</b> the project?
            </label>
            <Controller
              name="projectOutScope"
              control={control}
              render={({ field }) => (
                <TextArea
                  disabled={techDNARadioValidation}
                  value={field.value}
                  onChanged={value => {
                    setValue(field.name, value);
                    updateObs(field.name, value);
                  }}
                />
              )}
            />
          </div>
        </Body>
      )}
      {openTab === 2 && (
        <Body className="content">
          <DataGrid
            dataSource={techDNAExpectations}
            onRowUpdating={options => {
              options.newData = {
                ...options.oldData,
                ...options.newData,
              };
            }}
          >
            <HeaderFilter visible />
            <Column caption="Stakeholder" dataField="stakeholder" />

            <Column
              caption="Project Expectation Raised"
              dataField="expectation"
            />
            <Column caption="Observation" dataField="observation" />
            <Paging pageSize={8} />
            <Editing
              mode="popup"
              allowAdding={!techDNASubmitted}
              allowUpdating={!techDNASubmitted}
              allowDeleting={!techDNASubmitted}
            >
              <Popup />
              <Form>
                <Item dataField="stakeholder" colSpan={2} />
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item
                    dataField="expectation"
                    colSpan={1}
                    editorType="dxTextArea"
                  />
                  <Item
                    dataField="observation"
                    editorType="dxTextArea"
                    colSpan={1}
                  />
                </Item>
              </Form>
            </Editing>
          </DataGrid>
        </Body>
      )}
      {openTab === 3 && (
        <Body className="content">
          <DataGrid
            dataSource={techDNADeliverables}
            onRowUpdating={options => {
              options.newData = {
                ...options.oldData,
                ...options.newData,
              };
            }}
          >
            <HeaderFilter visible />
            <Column caption="Deliverable" dataField="deliverable" />
            <Column caption="Detail" dataField="detail" />
            <Paging pageSize={8} />
            <Editing
              mode="popup"
              allowAdding={!techDNASubmitted}
              allowUpdating={!techDNASubmitted}
              allowDeleting={!techDNASubmitted}
            >
              <Popup />
              <Form>
                <Item dataField="deliverable" colSpan={2} />
                <Item dataField="detail" colSpan={2} editorType="dxTextArea" />
              </Form>
            </Editing>
          </DataGrid>
        </Body>
      )}
      {openTab === 4 && (
        <Body className="content">
          <DataGrid
            dataSource={techDNARisks}
            onRowUpdating={options => {
              options.newData = {
                ...options.oldData,
                ...options.newData,
              };
            }}
          >
            <HeaderFilter visible />
            <Column dataField="risk" />
            <Column
              dataField="riskStatus"
              alignment="center"
              cellRender={statusCell}
            >
              <Lookup
                dataSource={statusRisk}
                valueExpr="statusId"
                displayExpr="status"
              />
            </Column>
            <Column dataField="observation" />
            <Editing
              mode="popup"
              allowAdding={!techDNASubmitted}
              allowUpdating={!techDNASubmitted}
              allowDeleting={!techDNASubmitted}
            >
              <Popup />
              <Form>
                <Item dataField="risk" colSpan={1} />
                <Item dataField="riskStatus" colSpan={1} />
                <Item
                  dataField="observation"
                  colSpan={2}
                  editorType="dxTextArea"
                />
              </Form>
            </Editing>
          </DataGrid>
        </Body>
      )}
      {openTab === 5 && (
        <Body className="content" style={{ justifyContent: 'center' }}>
          <div className="smallTextField">
            <label>Discussion Outcomes</label>
            <Controller
              name="discussionOutcomes"
              control={control}
              render={({ field }) => (
                <TextArea
                  disabled={techDNARadioValidation}
                  value={field.value}
                  onChanged={value => {
                    setValue(field.name, value);
                    updateObs(field.name, value);
                  }}
                />
              )}
            />
          </div>
        </Body>
      )}
    </Container>
  );
};
