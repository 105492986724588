import React, { useCallback, useEffect, useState } from 'react';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  Scrolling,
  Paging,
  HeaderFilter,
  Lookup,
} from 'devextreme-react/data-grid';
import { IconButton, Tooltip } from '@material-ui/core';
import { FiEdit2, FiExternalLink } from 'react-icons/fi';
import { WhisperSpinner } from 'react-spinners-kit';
import { Container, Body, WeeklyLevel, ContainerLoading } from './styles';
import { useDocumentTitle } from '../../../hooks/documentTitle';
import { Button } from '../../../components/Button';
import { DialogEdit } from './DialogEdit';
import api, { IWeeklySupervision } from '../../../services/api';
import { useToast } from '../../../hooks/toast';

export const WeeklySupervision: React.FC<{ title?: string }> = ({ title }) => {
  const { setTitle } = useDocumentTitle();
  const { addToast } = useToast();
  const [projects, setProjects] = useState<DataSource>({} as DataSource);
  const [total, setTotal] = useState(0);
  const [green, setGreen] = useState(0);
  const [yellow, setYellow] = useState(0);
  const [red, setRed] = useState(0);
  const [notSubmitted, setNotSubmitted] = useState(0);
  const [loading, setLoading] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true);
  const [excelLoading, setExcelLoading] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);
  const [editId, setEditId] = useState(0);

  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);

  const fetchData = useCallback(async () => {
    const projectStore = new CustomStore({
      key: 'idProject',
      loadMode: 'raw',
      load: async () => {
        setLoading(true);
        const response = await api.get<IWeeklySupervision[]>(
          '/api/weekly-supervision',
        );

        setTotal(response.headers['x-total'] || 0);
        setGreen(response.headers['x-total-green'] || 0);
        setYellow(response.headers['x-total-yellow'] || 0);
        setRed(response.headers['x-total-red'] || 0);
        setNotSubmitted(response.headers['x-total-notsubmitted'] || 0);

        setLoading(false);
        setFirstLoading(false);

        return response.data;
      },
    });

    setProjects(
      new DataSource({
        store: projectStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const textCells = useCallback(e => {
    return (
      <p
        style={{
          fontWeight: '600',
          textTransform: 'uppercase',
          letterSpacing: '0.05em',
        }}
      >
        {e.text}
      </p>
    );
  }, []);

  const handleExport = useCallback(async () => {
    try {
      addToast({
        title: 'We are generating your report. It could take a while',
        type: 'info',
      });
      setExcelLoading(true);

      const response = await api.get(`api/weekly-supervision/export`, {
        responseType: 'blob',
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');

      link.href = downloadUrl;
      link.setAttribute('download', `report-all-weekly-supervisions.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: 'success',
        title: 'Your report is ready. Open or save it below',
      });
    } catch {
      addToast({
        type: 'error',
        title: 'Something went wrong with your report. Try again please.',
      });
    }

    setExcelLoading(false);
  }, [addToast]);

  const weeklyLevelCell = useCallback(
    e => <WeeklyLevel level={e.value || 0} />,
    [],
  );

  const buttonsCell = useCallback(e => {
    return (
      <div style={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              setEditId(e.key);
              setOpenEdit(true);
            }}
          >
            <FiEdit2 size={20} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Open Project">
          <IconButton
            onClick={() => {
              window.open(`/project/${e.key}/during/dashboard-archive`);
            }}
          >
            <FiExternalLink size={20} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }, []);

  return (
    <>
      {firstLoading && loading && (
        <ContainerLoading>
          <WhisperSpinner size={50} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}
      <Container>
        <Body>
          <div className="header">
            <h1>Weekly Supervision</h1>
            <Button primary onClick={handleExport} loading={excelLoading}>
              Export Excel
            </Button>
          </div>
          <hr />
          <div className="kpi">
            <div className="totalProjects">
              <p>PROJECTS</p>
              <p>{total}</p>
            </div>
            <div className="onePage">
              <p>ONE-PAGE</p>
              <hr />
              <div className="statuses">
                <div style={{ borderTop: '0.25rem solid #808080' }}>
                  <p>NOT SUBMITTED</p>
                  <p>{notSubmitted}</p>
                </div>
                <div style={{ borderTop: '0.25rem solid #2e7d32' }}>
                  <p>GREEN</p>
                  <p>{green}</p>
                </div>
                <div style={{ borderTop: '0.25rem solid #F7D900' }}>
                  <p>YELLOW</p>
                  <p>{yellow}</p>
                </div>
                <div style={{ borderTop: '0.25rem solid #D60000' }}>
                  <p>RED</p>
                  <p>{red}</p>
                </div>
              </div>
            </div>
          </div>
          <DataGrid className="grid" dataSource={projects} allowColumnResizing>
            <HeaderFilter visible allowSearch />
            <Column
              dataField="projectCode"
              caption="Code"
              cellRender={textCells}
            />
            <Column
              dataField="projectName"
              caption="Name"
              cellRender={textCells}
            />
            <Column
              dataField="clientName"
              caption="Client"
              cellRender={textCells}
            />
            <Column
              dataField="statusName"
              caption="Status"
              cellRender={textCells}
            />
            <Column dataField="priority" dataType="boolean" />
            <Column dataField="internal" dataType="boolean" />
            <Column
              dataField="coManagementName"
              caption="Co-Mgmt"
              cellRender={textCells}
            />
            <Column
              dataField="duringTypeName"
              caption="During Type"
              cellRender={textCells}
            />
            <Column
              dataField="onePageSubmitted"
              caption="OP Submitted?"
              dataType="boolean"
            />
            <Column
              dataField="onePageProblemLevel"
              caption="OP Status"
              cellRender={weeklyLevelCell}
            >
              <Lookup
                dataSource={[
                  { id: 0, color: 'Not Submitted' },
                  { id: 1, color: 'Green' },
                  { id: 2, color: 'Yellow' },
                  { id: 3, color: 'Red' },
                ]}
                valueExpr="id"
                displayExpr="color"
              />
            </Column>
            <Column dataField="environmentStatus" cellRender={weeklyLevelCell}>
              <Lookup
                dataSource={[
                  { id: 1, color: 'Green' },
                  { id: 2, color: 'Yellow' },
                  { id: 3, color: 'Red' },
                ]}
                valueExpr="id"
                displayExpr="color"
              />
            </Column>
            <Column type="button" cellRender={buttonsCell} />

            <Paging enabled={false} />
            <Scrolling mode="infinite" />
          </DataGrid>
        </Body>
      </Container>

      {openEdit && (
        <DialogEdit
          handleClose={() => {
            setOpenEdit(false);
            projects.reload();
          }}
          open={openEdit}
          projectId={editId}
        />
      )}
    </>
  );
};
