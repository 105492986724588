import React, { useCallback, useState } from 'react';
import { FiGrid } from 'react-icons/fi';
import { GoTasklist, GoReport } from 'react-icons/go';
import { BiBookReader } from 'react-icons/bi';
import { BsEgg } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { IdoneSubMenu } from './IdoneSubMenu';
import { KNMSubMenu } from './KNMSubMenu';
import { Container, ButtonsMenuBox, ButtonMenu } from './styles';
import { SupervisionSubMenu } from './SupervisionSubMenu';

export const AdminHome: React.FC = () => {
  const history = useHistory();

  const [supervisionSubMenu, setSupervisionSubMenu] = useState(false);
  const [knmSubMenu, setKnmSubMenu] = useState(false);
  const [idoneSubMenu, setIdoneSubMenu] = useState(false);

  const handleSupervisionSubMenu = useCallback(() => {
    setSupervisionSubMenu(prevState => !prevState);
    setKnmSubMenu(false);
    setIdoneSubMenu(false);
  }, []);

  const handleKNMSubMenu = useCallback(() => {
    setKnmSubMenu(prevState => !prevState);
    setSupervisionSubMenu(false);
    setIdoneSubMenu(false);
  }, []);

  const handleIdonSubMenu = useCallback(() => {
    setIdoneSubMenu(prevState => !prevState);
    setSupervisionSubMenu(false);
    setKnmSubMenu(false);
  }, []);

  const handleDashboardMenu = useCallback(() => {
    setIdoneSubMenu(false);
    setSupervisionSubMenu(false);
    setKnmSubMenu(false);
    history.push('/admin/dashboard');
  }, [history]);

  const handleReportsMenu = useCallback(() => {
    setIdoneSubMenu(false);
    setSupervisionSubMenu(false);
    setKnmSubMenu(false);
    history.push('/admin/reports');
  }, [history]);

  return (
    <Container>
      <ButtonsMenuBox>
        <ButtonMenu onClick={handleDashboardMenu}>
          <FiGrid />
          DASHBOARD
        </ButtonMenu>

        <ButtonMenu onClick={handleSupervisionSubMenu}>
          <GoTasklist />
          SUPERVISION
        </ButtonMenu>

        <ButtonMenu onClick={handleReportsMenu}>
          <GoReport />
          REPORTS
        </ButtonMenu>

        <ButtonMenu onClick={handleKNMSubMenu}>
          <BiBookReader />
          KNM
        </ButtonMenu>

        <ButtonMenu onClick={handleIdonSubMenu}>
          <BsEgg />
          IDONE
        </ButtonMenu>
      </ButtonsMenuBox>

      {supervisionSubMenu && <SupervisionSubMenu open={supervisionSubMenu} />}

      {knmSubMenu && <KNMSubMenu open={knmSubMenu} />}

      {idoneSubMenu && <IdoneSubMenu open={idoneSubMenu} />}
    </Container>
  );
};
