import { DataGrid } from 'devextreme-react';
import {
  Column,
  HeaderFilter,
  Lookup,
  SearchPanel,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { WhisperSpinner } from 'react-spinners-kit';
import { BoardHeader } from '../../../components/BoardHeader';
import api from '../../../services/api';
import master, { CustomStoreProps } from '../../../services/master';
import { ContainerLoading } from '../ClientTab/styles';

import { Body, Container } from './styles';

export const ClientPastProjectsTab: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const [loading, setLoading] = useState(false);
  const [clientPastProjects, setClientPastProjects] = useState<DataSource>();

  const loadProjectInfos = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/api/projects/${id}`);

    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const r = await api.get(`/api/projects/${id}/client/${data.idClient}`);
        return r.data;
      },
    });

    setClientPastProjects(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
    setLoading(false);
  }, [id]);

  const [methodologyLookUp] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('master/methodologies');
        return data;
      },
    }),
    paginate: true,
  });

  useEffect(() => {
    loadProjectInfos();
  }, [loadProjectInfos]);

  return (
    <Container>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}
      {!loading && (
        <div>
          <BoardHeader title="SETUP" subtitle="" />
          <span>
            <h1
              style={{
                textTransform: 'uppercase',
                color: '#6c6c6c',
                fontSize: '24px',
                fontWeight: 700,
                letterSpacing: '0.1em',
              }}
            >
              Client Past Projects
            </h1>
          </span>

          <Body>
            <span
              style={{
                display: 'flex',
                gap: '8px',
              }}
            />
            <DataGrid dataSource={clientPastProjects}>
              <HeaderFilter visible />
              <SearchPanel visible width={250} />
              <Column dataField="project.code" alignment="left" />
              <Column dataField="project.name" alignment="left" />
              <Column
                dataField="project.projectMethodologies[0].idMethodology"
                alignment="left"
                caption="Methodology"
              >
                <Lookup
                  dataSource={methodologyLookUp}
                  displayExpr="Name"
                  valueExpr="ID"
                />
              </Column>
            </DataGrid>
          </Body>
          {/* </AccordionDetails> */}
          {/* </Accordion> */}
        </div>
      )}
    </Container>
  );
};
