import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { TagBox, TextBox } from 'devextreme-react';
import { WhisperSpinner } from 'react-spinners-kit';
import { useToast } from '../../../../../hooks/toast';
import { FormGroup } from '../../../../../components/FormGroup';
import { Button } from '../../../../../components/Button';
import { Container, ContainerLoading } from './styles';
import master from '../../../../../services/master';

interface DialogCustomProps {
  idClient: number;
  open: boolean;
  handleClose(): void;
}

export const DialogEditClient: React.FC<DialogCustomProps> = ({
  idClient,
  open,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [clientName, setClientName] = useState('');
  const [idPrimarySegment, setIdPrimarySegment] = useState([] as number[]);
  const [idSecondarySegment, setIdSecondarySegment] = useState([] as number[]);
  const [segmentsName, setSegmentsName] = useState([]);
  const [clientData, setClientData] = useState<any>();
  const { addToast } = useToast();

  const loadClientsDetails = useCallback(async () => {
    setLoading(true);

    const { data } = await master.get(`/master/clients/${idClient}`);
    const segments = await master.get(`/master/segments`);

    const primarySegments = data.ClientSegments.filter(
      (x: any) => x.SegmentLevel === 1,
    ).map((s: any) => {
      return s.Segment.ID;
    });
    setIdPrimarySegment(primarySegments);

    const secondarySegments = data.ClientSegments.filter(
      (x: any) => x.SegmentLevel === 2,
    ).map((s: any) => {
      return s.Segment.ID;
    });
    setIdSecondarySegment(secondarySegments);

    setClientData(data);
    setClientName(data.Name);
    setSegmentsName(segments.data);
    setLoading(false);

    return data;
  }, [idClient]);

  const handleEditClientInformations = useCallback(async () => {
    setLoading(true);
    try {
      await master.put(`/master/clients/${idClient}`, {
        ...clientData,
        Name: clientName,
      });

      await master.delete(`/master/clients/${idClient}/segments`);

      const primarySegmentsAsObjects = idPrimarySegment.map((p: any) => {
        const segment = segmentsName.find((x: any) => x.ID === p);
        return {
          Segment: segment,
          SegmentLevel: 1,
        };
      });

      const secondarySegmentsAsObjects = idSecondarySegment.map((p: any) => {
        const segment = segmentsName.find((x: any) => x.ID === p);
        return {
          Segment: segment,
          SegmentLevel: 2,
        };
      });

      const totalSegments = primarySegmentsAsObjects.concat(
        secondarySegmentsAsObjects,
      );

      await master.post(`/master/clients/${idClient}/segments`, totalSegments);

      addToast({
        type: 'success',
        title: 'Edited with success.',
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
    }
    setLoading(false);
    handleClose();
  }, [
    addToast,
    clientData,
    clientName,
    handleClose,
    idClient,
    idPrimarySegment,
    idSecondarySegment,
    segmentsName,
  ]);

  useEffect(() => {
    loadClientsDetails();
  }, [loadClientsDetails]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle id="alert-dialog-title">Edit Client</DialogTitle>
      <DialogContent>
        {loading ? (
          <ContainerLoading>
            <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
          </ContainerLoading>
        ) : (
          <Container>
            <FormGroup fieldSetLabel="Client Name">
              <TextBox
                value={clientName}
                onValueChange={e => setClientName(e)}
              />
            </FormGroup>

            <FormGroup fieldSetLabel="Primary Segment">
              <TagBox
                dataSource={segmentsName}
                displayExpr="Name"
                valueExpr="ID"
                defaultValue={idPrimarySegment}
                onValueChange={e => setIdPrimarySegment(e)}
              />
            </FormGroup>

            <FormGroup fieldSetLabel="Secondary Segment">
              <TagBox
                dataSource={segmentsName}
                displayExpr="Name"
                valueExpr="ID"
                defaultValue={idSecondarySegment}
                onValueChange={e => setIdSecondarySegment(e)}
              />
            </FormGroup>
          </Container>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleEditClientInformations} primary>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
