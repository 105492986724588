import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, SubmenuButton, SubmenuButtonBox } from './styles';

interface SubmenuProps {
  open: boolean;
}

export const SupervisionSubMenu: React.FC<SubmenuProps> = ({ open }) => {
  const history = useHistory();

  const handleWeeklySupervision = useCallback(() => {
    history.push('/admin/weekly-supervision');
  }, [history]);

  const handlSetupAssessment = useCallback(() => {
    history.push('/admin/setup-assessment');
  }, [history]);

  const handleAllocationSupervision = useCallback(() => {
    history.push('/admin/allocation-supervision');
  }, [history]);

  return (
    <Container>
      <SubmenuButtonBox>
        <SubmenuButton onClick={handleWeeklySupervision}>WEEKLY</SubmenuButton>
        <SubmenuButton onClick={handlSetupAssessment}>SETUP</SubmenuButton>
        <SubmenuButton onClick={handleAllocationSupervision}>
          ALLOCATION
        </SubmenuButton>
      </SubmenuButtonBox>
    </Container>
  );
};
