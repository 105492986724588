import React, { BaseHTMLAttributes } from 'react';

import { Container } from './styles';

interface BoardHeaderProps extends BaseHTMLAttributes<HTMLDivElement> {
  title: string;
  subtitle: string;
}

export const BoardHeader: React.FC<BoardHeaderProps> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <Container>
      <div className="header-name">
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </div>

      {children}
    </Container>
  );
};
