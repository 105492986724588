import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FileUploader } from 'devextreme-react';
import { Body } from './styles';
import { TextArea } from '../../../../components/TextArea';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import { Button } from '../../../../components/Button';

interface ProposalProps {
  idProject: number;
  setupFinished: boolean;
  updateProjectInfo: (name: string, value: any) => void;
  hasProposalFile: boolean;
  whatSalesContext: string;
  whyClientNeedHelp: string;
}

export const Proposal: React.FC<ProposalProps> = ({
  setupFinished,
  updateProjectInfo,
  idProject,
  hasProposalFile: _hasProposalFile,
  whatSalesContext,
  whyClientNeedHelp,
}) => {
  const [hasProposalFile, setHasProposalFile] = useState(_hasProposalFile);
  const { control, setValue, handleSubmit } = useForm();
  const [uploadLoading, setUploadLoading] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    setValue('whatSalesContext', whatSalesContext);
    setValue('whyClientNeedHelp', whyClientNeedHelp);
    setHasProposalFile(_hasProposalFile);
  }, [whatSalesContext, whyClientNeedHelp, setValue, _hasProposalFile]);

  const handleChangeProposal = useCallback(
    async data => {
      setUploadLoading(true);
      try {
        addToast({
          type: 'info',
          title: 'Uploading your file...',
        });
        const formData = new FormData();

        if (!data.file || !data.file[0]) {
          addToast({
            title: 'Please, select a file',
            type: 'error',
          });
          return;
        }

        formData.append('file', data.file[0], data.file[0].name);

        await api.post(`api/projects/${idProject}/proposal`, formData);

        setHasProposalFile(true);

        addToast({
          title: 'Proposal uploaded',
          type: 'success',
        });
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }

      setUploadLoading(false);
    },
    [addToast, idProject],
  );

  const handleDownloadProposal = useCallback(async () => {
    setUploadLoading(true);
    addToast({
      type: 'info',
      title: 'We are generating your file. It could take a while',
    });

    const { data, headers } = await api.get(
      `api/projects/${idProject}/proposal`,
      {
        responseType: 'blob',
      },
    );

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', headers['x-file-name']);
    document.body.appendChild(link);
    link.click();
    link.remove();

    addToast({
      type: 'success',
      title: 'Your file is ready',
    });

    setUploadLoading(false);
  }, [idProject, addToast]);

  return (
    <Body className="content">
      <div className="smallTextField">
        <label>Proposal file</label>
        <small className="upload-note">
          Uploading new files will replace existing ones on the database.
        </small>
        <form onSubmit={handleSubmit(handleChangeProposal)}>
          <Controller
            name="file"
            control={control}
            render={({ field }) => (
              <FileUploader
                disabled={setupFinished || uploadLoading}
                uploadMode="useForm"
                showFileList={false}
                onValueChanged={e => {
                  field.onChange(e.value);
                  handleSubmit(handleChangeProposal)();
                }}
              />
            )}
          />
        </form>

        {hasProposalFile && (
          <Button onClick={handleDownloadProposal} loading={uploadLoading}>
            Download proposal file
          </Button>
        )}
      </div>
      <div className="smallTextField">
        <label>What is the context of this sale?</label>
        <Controller
          name="whatSalesContext"
          control={control}
          render={({ field }) => (
            <TextArea
              disabled={setupFinished}
              value={field.value}
              onChanged={value => {
                setValue(field.name, value);
                updateProjectInfo(field.name, value);
              }}
            />
          )}
        />
      </div>
      <div className="smallTextField">
        <label>Why and for what does the client need help?</label>
        <Controller
          name="whyClientNeedHelp"
          control={control}
          render={({ field }) => (
            <TextArea
              disabled={setupFinished}
              value={field.value}
              onChanged={value => {
                setValue(field.name, value);
                updateProjectInfo(field.name, value);
              }}
            />
          )}
        />
      </div>
    </Body>
  );
};
