import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  gap: 18px;
  margin: 6px;

  .dx-tag .dx-tag-content {
    padding: 14px 32px 14px 12px;
    margin-top: 4px;
    background-color: #8b0304;
    color: #fff;

    transition: 0.2s ease-in-out;

    &:hover {
      background-color: #7c210d;
      color: #fff;
    }
  }
`;

export const ContainerLoading = styled.div`
  z-index: 9999;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;
