import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { DialogActions } from '@mui/material';
import { WhisperSpinner } from 'react-spinners-kit';
import { Container } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';

import { DataGrid } from 'devextreme-react';
import { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import api from '../../../services/api';
import { IOnePage } from '../../../services/master';
import { Button } from '../../../components/Button';
import { FormGroup } from '../../../components/FormGroup';
import { SelectBox } from '../../../components/SelectBox';
import { useToast } from '../../../hooks/toast';
import { TextArea } from '../../../components/TextArea';
import { useAuth } from '../../../hooks/auth';
import portalGroups from '../../../config/portalGroups';

interface DialogCustomProps {
  id: number;
  projectId: number;
  handleClose(): void;
}

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const DialogOnePage: React.FC<DialogCustomProps> = ({
  id,
  projectId,
  handleClose,
}) => {
  const { hasScopes, user } = useAuth();
  const { control, setValue } = useForm();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [onePageInfo, setOnePageInfo] = useState<any>();
  const [allocations, setAllocations] = useState<DataSource>();
  const [isPartnerOrManager, setIsPartnerOrManager] = useState(false);
  const [externalProfessionalMessage, setExternalProfessionalMessage] = useState();
  const [
    allGovernanceAllocationsApproved,
    setAllGovernanceAllocationsApproved,
  ] = useState(false);

  const [professionals, setProfessionals] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );

  const loadOnePageInfo = useCallback(async () => {
    const [onepage, team] = await Promise.all([
      api.get<IOnePage>(`/api/one-pages/${id}`),
      api.get(`/api/projects/${projectId}/team`),
    ]);
    setOnePageInfo(onepage.data);
    const member = onepage.data.project?.projectTeam?.find(
      x => x.idProfessional === user.userId,
    );
    setIsPartnerOrManager(member?.isPartner || member?.isManager || false);
    setValue('onePageProblemLevel', onepage.data.onePageProblemLevel);
    setValue('hasAllocation', onepage.data.hasAllocation);
    setValue('keyMessage', onepage.data.keyMessage);
    setValue('teamPoint', onepage.data.teamPoint);
    setValue('methodInternal', onepage.data.methodInternal);
    setValue('methodExternal', onepage.data.methodExternal);
    setValue('governanceAction', onepage.data.governanceAction);
    setValue('environmentStatus', onepage.data.environmentStatus);

    setProfessionals({
      store: new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          return team.data;
        },
      }),
      paginate: true,
    });
  }, [id, setValue, user, projectId]);

  const allowSubmit = useMemo(() => {
    return (
      hasScopes([portalGroups.OnePage, portalGroups.Admin]) ||
      isPartnerOrManager
    );
  }, [hasScopes, isPartnerOrManager]);

  const loadAllocations = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `api/project-allocation-suggestions/one-page/${id}`,
        );

        // Handle message if there is an external professional
        if (data.infoMessage) {
          setExternalProfessionalMessage(data.infoMessage);
        }

        // Handle the suggestions data
        const suggestions = data.suggestions;
        setAllGovernanceAllocationsApproved(
          suggestions.every((x: { isApproved: boolean }) => x.isApproved),
        );
        return suggestions.filter(
          (x: { professional: any }) => x.professional?.isActive,
        );
      },
      insert: async data => {
        await api.post(
          `api/project-allocation-suggestions/one-page/${id}`,
          data,
        );
      },
      update: async (key, data) => {
        await api.put(`/api/project-allocation-suggestions/${key}`, data);
      },
      remove: async key => {
        await api.delete(`/api/project-allocation-suggestions/${key}`);
      },
    });

    setAllocations(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id]);

  useEffect(() => {
    loadOnePageInfo();
    loadAllocations();
  }, [loadAllocations, loadOnePageInfo]);

  const updateOnePageInfo = useCallback(
    async (fieldName, value) => {
      try {
        await api.patch(`/api/one-pages/${id}`, {
          [fieldName]: value,
        });
        addToast({
          type: 'success',
          title: 'Saved',
          description: '',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          description: '',
        });
      }
    },
    [addToast, id],
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    if (
      // eslint-disable-next-line no-underscore-dangle
      Object.values(control._formValues).includes(null) ||
      // eslint-disable-next-line no-underscore-dangle
      Object.values(control._formValues).includes(undefined) ||
      // eslint-disable-next-line no-underscore-dangle
      Object.values(control._formValues).includes('')
    ) {
      // eslint-disable-line
      addToast({
        type: 'error',
        title: 'All fields must be filled in order to submit the One Page.',
        description: '',
      });
      setLoading(false);
      return;
    }
    if (!allGovernanceAllocationsApproved) {
      addToast({
        type: 'error',
        title: 'All Governance Allocations must be approved',
        description: '',
      });
      setLoading(false);
      return;
    }
    await api.patch(`/api/one-pages/${id}/submit`);
    setLoading(false);
    handleClose();
  }, [
    addToast,
    allGovernanceAllocationsApproved,
    control._formValues, // eslint-disable-line
    handleClose,
    id,
  ]);

  const approveAllAllocations = useCallback(async () => {
    setLoading(true);
    await api.patch(
      `api/project-allocation-suggestions/one-page/${id}/approve-all`,
    );
    await loadAllocations();
    addToast({
      type: 'success',
      title: 'All Governance Allocations approved',
      description: '',
    });
    setLoading(false);
  }, [addToast, id, loadAllocations]);

  return (
    <Container>
      <FormGroup fieldSetLabel="Project Status">
        <Controller
          name="onePageProblemLevel"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SelectBox
              disabled={onePageInfo?.submittedAt}
              dataSource={[
                { name: 'Green', id: 1 },
                { name: 'Yellow', id: 2 },
                { name: 'Red', id: 3 },
              ]}
              displayExpr="name"
              valueExpr="id"
              value={field.value}
              onChanged={value => {
                setValue(field.name, value);
                updateOnePageInfo(field.name, value);
              }}
            />
          )}
        />
      </FormGroup>
      <FormGroup fieldSetLabel="Environment Status">
        <Controller
          name="environmentStatus"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SelectBox
              disabled={onePageInfo?.submittedAt}
              dataSource={[
                { name: 'Green', id: 1 },
                { name: 'Yellow', id: 2 },
                { name: 'Red', id: 3 },
              ]}
              displayExpr="name"
              valueExpr="id"
              value={field.value}
              onChanged={value => {
                setValue(field.name, value);
                updateOnePageInfo(field.name, value);
              }}
            />
          )}
        />
      </FormGroup>
      <FormGroup fieldSetLabel="Had allocation in the current week?">
        <Controller
          name="hasAllocation"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SelectBox
              disabled={onePageInfo?.submittedAt}
              dataSource={[
                { name: 'Yes', value: true },
                { name: 'No', value: false },
              ]}
              displayExpr="name"
              valueExpr="value"
              value={field.value}
              onChanged={value => {
                setValue(field.name, value);
                updateOnePageInfo(field.name, value);
              }}
            />
          )}
        />
      </FormGroup>
      <FormGroup fieldSetLabel="Key Messages">
        <Controller
          name="keyMessage"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextArea
              disabled={onePageInfo?.submittedAt}
              value={field.value}
              onChanged={value => {
                setValue(field.name, value);
                updateOnePageInfo(field.name, value);
              }}
            />
          )}
        />
      </FormGroup>
      <FormGroup fieldSetLabel="Team Points">
        <Controller
          name="teamPoint"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextArea
              style={{
                height: '188px',
              }}
              disabled={onePageInfo?.submittedAt}
              value={field.value}
              onChanged={value => {
                setValue(field.name, value);
                updateOnePageInfo(field.name, value);
              }}
            />
          )}
        />
      </FormGroup>

      <div style={{ marginBottom: '40px' }}>
        <h1 style={{ fontWeight: '700', fontSize: 22 }}>
          Execution of the Method
        </h1>
        <hr />
        <FormGroup fieldSetLabel="Internal">
          <Controller
            name="methodInternal"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextArea
                disabled={onePageInfo?.submittedAt}
                value={field.value}
                onChanged={value => {
                  setValue(field.name, value);
                  updateOnePageInfo(field.name, value);
                }}
              />
            )}
          />
        </FormGroup>
        <FormGroup fieldSetLabel="External &amp; Extra Mile Actions">
          <Controller
            name="methodExternal"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextArea
                disabled={onePageInfo?.submittedAt}
                value={field.value}
                onChanged={value => {
                  setValue(field.name, value);
                  updateOnePageInfo(field.name, value);
                }}
              />
            )}
          />
        </FormGroup>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '40px',
        }}
      >
        <h1 style={{ fontWeight: '700', fontSize: '22px' }}>Governance</h1>
        <hr />
        <FormGroup fieldSetLabel="Actions, Needs and Definitions">
          <Controller
            name="governanceAction"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextArea
                disabled={onePageInfo?.submittedAt}
                value={field.value}
                onChanged={value => {
                  setValue(field.name, value);
                  updateOnePageInfo(field.name, value);
                }}
              />
            )}
          />
        </FormGroup>
        <p style={{ fontWeight: 700, letterSpacing: '.1em', textTransform: 'uppercase' }}>
          Governance allocation
        </p>
        <DataGrid
          dataSource={allocations}
          onRowUpdating={options => {
            options.newData = {
              ...options.oldData,
              ...options.newData,
            };
          }}
        >
          <Column
            dataField="idProfessional"
            caption="Professional"
            cellRender={e => e.data.professional?.name}
            allowEditing={false}
          >
            <Lookup
              dataSource={professionals}
              displayExpr="professional.name"
              valueExpr="professional.id"
            />
          </Column>
          <Column
            dataField="totalDays"
            caption="Total Days Allocated"
            dataType="number"
          >
            <Lookup
              dataSource={(e: any) =>
                e?.data?.idPractice === 7
                  ? [
                    0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5,
                    2.75, 3, 3.25, 3.5, 3.75, 4, 4.25, 4.5, 4.75, 5,
                  ]
                  : [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]
              }
            />
          </Column>
          <Column dataField="isApproved" dataType="boolean" width={110} />
          <Editing allowUpdating={!onePageInfo?.submittedAt} />
        </DataGrid>
        {externalProfessionalMessage &&
          <div style={{
            background: '#ffffff',
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.07)',
            border: 'none',
            textAlign: 'left',
            padding: '1em',
            marginBottom: '1em',
            borderRadius: '5px',
            fontWeight: 'normal',
          }}>
            {externalProfessionalMessage}
          </div>
        }
        <Button
          primary
          disabled={loading}
          onClick={approveAllAllocations}
          style={{
            width: 'fit-content',
            alignSelf: 'center',
            marginTop: 30,
          }}
        >
          Approve All{' '}
          {loading && (
            <WhisperSpinner size={15} backColor="#fff" frontColor="#fff" />
          )}
        </Button>
      </div>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        {allowSubmit && !onePageInfo?.submittedAt && (
          <Button primary disabled={loading} onClick={handleSubmit}>
            Submit One-Page{' '}
            {loading && (
              <WhisperSpinner size={15} backColor="#fff" frontColor="#fff" />
            )}
          </Button>
        )}
      </DialogActions>
    </Container >
  );
};
